import React, { ExoticComponent } from 'react'
import styled from 'styled-components'
import { Card, Row, Col, Table, message } from 'antd'
import { FileUpload, Button } from '.'
import { AitoClient } from 'api'
import { ColorProps, SpaceProps, TypographyProps, color, space, typography } from 'styled-system'

interface Props {
  instanceId: string
  aitoClient: AitoClient
  tables: string[]
  previewRows: any
  selected: string | undefined
  loading: boolean
  handleSelectedTable: (tableName: string) => void
  doneButton: () => void
  tableDeleted: () => void
}

interface State {
  stage: number
  loading: boolean
}

class DataPreview extends React.Component<Props, State> {
  public readonly state: State = {
    stage: 0,
    loading: false
  }

  rowsToColumns() {
    const rows = this.props.previewRows
    const columns = rows.length !== 0 ? Object.keys(this.props.previewRows[0]) : []
    return columns.map(c => ({ title: c, dataIndex: c, key: c }))
  }

  async fileUploaded(table: string) {
    this.setState({ stage: 1 })
    this.props.handleSelectedTable(table)
  }

  async deleteTable() {
    this.setState({ loading: true })
    const { selected, aitoClient } = this.props
    if (selected) {
      await aitoClient.truncateTable(selected)
      await aitoClient.deleteTableSchema(selected)
    }
    this.setState({ loading: false, stage: 0 })
    message.warn(`Deleted table ${selected}`)
    this.props.tableDeleted()
  }

  trackFileDownload(fileName: string) {
    window.analytics.track('File download', { fileName, instanceId: this.props.instanceId })
  }

  public render() {
    const loading = this.props.loading || this.state.loading
    const table =
      <StyledCol md={24} xs={24}>
        <h4>Data snapshot from table <i>{this.props.selected}</i></h4>
        <StyledTable
          dataSource={this.props.previewRows}
          columns={this.rowsToColumns()}
          rowKey={(row: any) => [...Object.values(row), Math.random()].join()}
          size="small"
          pagination={false}
          bordered={true}
          loading={loading}
        />
        <Row>
          <StyledButton onClick={this.props.doneButton} disabled={loading}>Let's predict</StyledButton>
          <StyledButton onClick={this.deleteTable.bind(this)} ghost danger disabled={loading}>Delete this table</StyledButton>
        </Row>
      </StyledCol>

    const fileUpload =
      <React.Fragment>
        <StyledCol md={11} xs={24}>
          <FileUpload instanceId={this.props.instanceId} fileLoaded={this.fileUploaded.bind(this)} />
        </StyledCol>
        <StyledCol md={2} xs={0} />
        <StyledCol md={11} xs={24}>
          <StyledText mt={[4, 0]} mb={3} color="mullWine" fontWeight="semibold" fontSize={2}>
              No data at arms reach?
          </StyledText>
          <p>If you don't have any data readily available but still
              want to try using aito right away then you can use one of the following
            <a href="https://aito.ai/showcases/" target="_blank" rel="noopener noreferrer"> showcase </a>
            example files:
          </p>
          <ul>
            <li><a onClick={() => this.trackFileDownload('lead-qualification')} href="https://aito.ai/showcases/datasets/lead-qualification.csv">lead-qualification.csv</a>: predict the potential of a new sales lead</li>
            <li><a onClick={() => this.trackFileDownload('missing-values')} href="https://aito.ai/showcases/datasets/missing-values.csv">missing-values.csv</a>: predict missing values from product catalog</li>
            <li><a onClick={() => this.trackFileDownload('order-handling')} href="https://aito.ai/showcases/datasets/order-handling.csv">order-handling.csv</a>: predict matching values from orders</li>
          </ul>
        </StyledCol>
      </React.Fragment>

    return (
      <StyledCard>
        <StyledRow gutter={[24, 24]}>
          { this.state.stage === 0 ? fileUpload : table }
        </StyledRow>
      </StyledCard>
    )
  }
}

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-right: 30px;
`
const StyledRow = styled(Row)`
  transition: ease-in-out;
`
const StyledCard = styled(Card)`
  .ant-card-head {
    border-bottom: none;
  }
`
const StyledCol = styled(Col)`
  height: 350px;
`
const StyledTable = styled(Table)`
  max-height: 250px;
` as any

const StyledText: ExoticComponent<any> = styled.p<ColorProps | SpaceProps | TypographyProps>`
  ${color};
  ${space};
  ${typography};
`

export default DataPreview
