import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system'
import { Avatar as AntAvatar, Popover, Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { Close } from '@styled-icons/material/Close'
import MediaQuery from 'react-responsive'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Flex } from '@rebass/grid'
import { Menu as MenuIcon } from '@styled-icons/feather/Menu'

import { ReactComponent as Logo } from '../svg-assets/aito-logo.svg'
import { theme, media } from '../styles/theme'
import { Button } from './index'
import { AppState } from '../ducks'
import { ConsoleUser as User } from '../types/User'
import { getNameInitials } from '../utils/getNameInitials'
import { instancesPath, teamsPath, profileOverviewPath } from '../route'

const Avatar = styled(AntAvatar)<SpaceProps | ColorProps | TypographyProps>`
  ${color};
  ${space};
  ${typography};
  cursor: pointer;
`

const UserEmail = styled.span<ColorProps>`
  ${color};
`

const UserFullName = styled.span<ColorProps>`
  ${color};
`

const ProfileLink = styled(Link)<ColorProps | SpaceProps>`
  ${color};
  ${space};
  display: block;
`

const popoverMenuHeader = (userProfile: User) => {
  return (
  <Flex alignItems="center">
    <Avatar my={1} mr={2} size="large" bg="jade.medium" fontWeight="bold">
      {getNameInitials(userProfile.fullName)}
    </Avatar>
    <div>
      <UserEmail color="scorpion.dark">{userProfile.email}</UserEmail>
      <br />
      <UserFullName color="scorpion.light">{userProfile.fullName}</UserFullName>
    </div>
  </Flex>
)}

const popoverMenuContent = (handleSignOut: () => void, hidePopover: () => void) => {
  return (
    <div>
      <ProfileLink to={profileOverviewPath} onClick={hidePopover} color="scorpion.dark" mb={3}>
        Profile
      </ProfileLink>
      <Button type="link" onClick={handleSignOut} color="scorpion.dark">
        Sign out
      </Button>
    </div>
  )
}

interface PopoverMenuProps {
  handleSignOut: () => void
  hidePopover: () => void
  handlePopoverVisibleChange: (visible: boolean) => void
  popoverVisible: boolean
  userProfile?: User
}

const PopoverMenu: React.FC<PopoverMenuProps> = props => {
  const user: User | null = props.userProfile || null
  // @ts-ignore
  return (
    <div>
      <Popover
        placement="bottomRight"
        title={user ? popoverMenuHeader(user) : ''}
        content={popoverMenuContent(props.handleSignOut, props.hidePopover)}
        trigger="click"
        visible={props.popoverVisible}
        onVisibleChange={props.handlePopoverVisibleChange}
      >
        <Avatar mx={3} size="large" bg="jade.medium" fontWeight="bold">
          {user ? getNameInitials(user.fullName): ''}
        </Avatar>
      </Popover>
    </div>
  )
}

const navItems = [
  {
    text: 'Instances',
    url: instancesPath,
  },
  {
    text: 'Teams',
    url: teamsPath,
  }
]

type NavbarProps = {
  handleSignOut: () => void,
  userProfile?: User
}

type NavbarState = {
  menuVisible: boolean,
  popoverVisible: boolean
  hasUpdates: boolean | undefined
}

declare const rnw: {
  (param: 'show'): void
  unseen_count: number
}

export class NavigationBar extends React.Component<NavbarProps, NavbarState> {
  private pollReleases?: any

  readonly state: NavbarState = {
    menuVisible: false,
    popoverVisible: false,
    hasUpdates: undefined,
  }

  openMenu = () => {
    this.setState({
      menuVisible: true,
    });
  };

  closeMenu = () => {
    this.setState({
      menuVisible: false,
    });
  };

  hidePopover = () => {
    this.setState({
      popoverVisible: false
    })
  }

  handlePopoverVisibleChange = (visible: boolean) => {
    this.setState({
      popoverVisible: visible
    })
  }

  componentDidMount = () => {
    this.pollReleases = setInterval(() => {
      if (typeof rnw.unseen_count === 'number') {
        this.setState({ hasUpdates: rnw.unseen_count > 0 })
        clearInterval(this.pollReleases)
        this.pollReleases = undefined
      }
    }, 1000);
  }

  componentWillUnmount = () => {
    if (this.pollReleases !== undefined) {
      clearInterval(this.pollReleases)
    }
  }

  renderDesktopNavbar = () => {
    return (
      <ContainerDiv
        bg="oracle.darker"
        width={1}
        height="navBarHeight"
        py={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <LinksContainerDiv alignItems="center">
          <AitoLogo mx={3} size={56} />
          <LinkList alignItems="center" m={0} p={0}>
            {_.map(navItems, (item, i) => {
              return (
                <LinkItem to={item.url} mx={3} key={i}>
                  {item.text}
                </LinkItem>
              )
            })}
          </LinkList>
        </LinksContainerDiv>
        <LinksContainerDiv alignItems="center" ml="auto">
          <LinkItem mx={2} to="#" onClick={(e) => {
            e.preventDefault()
            this.setState({ hasUpdates: false })
            rnw('show')
          }}>
            Latest Updates{' '}
            <UpdateIndicator className={this.state.hasUpdates ? 'has-updates' : ''}>{'\u2022'}</UpdateIndicator>
          </LinkItem>
        </LinksContainerDiv>
        <PopoverMenu
          handleSignOut={this.props.handleSignOut}
          popoverVisible={this.state.popoverVisible}
          hidePopover={this.hidePopover}
          handlePopoverVisibleChange={this.handlePopoverVisibleChange}
          userProfile={this.props.userProfile}
        />
      </ContainerDiv>
    )
  }

  renderMobileNavbar = () => {
    return (
      <ContainerDiv
        bg="oracle.darker"
        width={1}
        height="navBarHeight"
        py={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <StyledMenuIcon color="white" size={36} mx={3} onClick={this.openMenu} />
        <Drawer
          placement="left"
          closable={false}
          onClose={this.closeMenu}
          visible={this.state.menuVisible}
        >
          <MobileMenuContainerDiv>
            <CloseIcon size={24} color="silverTree.medium" onClick={this.closeMenu}/>
            <MobileLinkList px={3} py={4}>
              {_.map(navItems, (item, i) => {
                return (
                  <MobileLink to={item.url} fontSize={3} mb={3} onClick={this.closeMenu} key={i}>
                    {item.text}
                  </MobileLink>
                )
              })}
            </MobileLinkList>
          </MobileMenuContainerDiv>
        </Drawer>
        <AitoLogo size={56} />
        <PopoverMenu
          handleSignOut={this.props.handleSignOut}
          popoverVisible={this.state.popoverVisible}
          hidePopover={this.hidePopover}
          handlePopoverVisibleChange={this.handlePopoverVisibleChange}
          userProfile={this.props.userProfile}
        />
      </ContainerDiv>
    )
  }

  render() {
    return (
      <MediaQuery query={media.sm}>
        {(matches: any) => {
          if (matches) {
            return (
              this.renderDesktopNavbar()
            )
          }
          return (
            this.renderMobileNavbar()
          )
        }}
      </MediaQuery>
    )
  }
}

const ContainerDiv = styled(Flex)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
` as typeof Flex

const AitoLogo: ExoticComponent<any> = styled(Logo)<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
`

const LinksContainerDiv = styled(Flex)<SpaceProps>`
  ${space};
  height: 100%;
`
const LinkList = styled(Flex)<SpaceProps>`
  ${space};
  height: 100%;
  border-left: 1px solid ${theme.colors.silverTree.medium};
`

const LinkItem = styled(Link)<SpaceProps>`
  ${space};
`

const UpdateIndicator = styled.span`
  visibility: hidden;
  &.has-updates {
    visibility: visible;
    color: ${theme.colors.carnationPink.medium};
  }
`

const StyledMenuIcon: ExoticComponent<any> = styled(MenuIcon)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

const MobileMenuContainerDiv = styled.div`
  position: relative;
`

const CloseIcon: ExoticComponent<any> = styled(Close)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  position: absolute;
  right: 0;
`

const MobileLinkList = styled.div<TypographyProps | SpaceProps>`
  ${typography};
  ${space};
`

const MobileLink = styled(Link)<TypographyProps | SpaceProps>`
  ${typography};
  ${space};
  display: block;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: state.auth.userProfile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
