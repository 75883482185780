import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import { TooltipPlacement } from 'antd/lib/tooltip'

const InfoTooltip: React.ExoticComponent<{
  text?: string
  children?: React.ReactNode
  placement?: TooltipPlacement 
}> = React.memo(({ text, placement, children }) => {
  return (
    <Tooltip title={children || text} placement={placement} overlayStyle={{ maxWidth: '500px' }}>
      <InfoIcon />
    </Tooltip>
  )
})

const InfoIcon = styled(InfoCircleOutlined)`
  margin: 2px 0px 0px 5px;
`

export default InfoTooltip
