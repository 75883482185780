import auth0 from 'auth0-js'
import config from '../config'
import { oAuthRedirectPath } from '../route'

const webAuth: auth0.WebAuth = new auth0.WebAuth({ ...config.auth0 })
const BaseUri: string = config.aito.applicationBaseUri
const BaseRedirectUri = `${BaseUri}${oAuthRedirectPath}`

export const auth0Login = async (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    webAuth.login({
      realm: 'Username-Password-Authentication',
      username: email,
      password,
      redirectUri: BaseRedirectUri, // add query parameters to go back
    }, (error, result) => {
      if (error) reject(error)
      if (result) resolve(result)
    })
  })
}

export const auth0GoogleLogin = async () => {
  webAuth.authorize({
    connection: 'google-oauth2',
    redirectUri: BaseRedirectUri, // add query parameters to go back
  })
}

export const auth0GithubLogin = async () => {
  webAuth.authorize({
    connection: 'github',
    redirectUri: BaseRedirectUri
  })
}
