import { BusinessImpact, EvaluationSettings, PredictorConfiguration, PredictorTarget, PredictionTarget } from 'api'
import { Analyzer, TableSchema } from 'api/schema/aito'
import { AitoColumn } from 'api/schema/datasets'
import { ColumnType } from 'api/schema/jobs/csvupload'
import { InstanceApiStats, InstanceDataStats, isInstanceApiStats, isInstanceDataStats } from 'api/schema/metrics'
import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'
import * as URI from 'uri-js'

export interface AnalyzedSample {
  query: {
    sample: string
    analyzer: Analyzer
  }
  results: {
    feature: string
    offset: {
      start: number
      end: number
    }
    text: string
  }[]
}

export function getInstanceById(instanceId: string) {
  return axios.get(`/api/instances/${instanceId}`)
}

export async function isInstanceNameAvailable(name: string): Promise<boolean> {
  try {
    const response = await axios.get(`/api/instance-names/${name}`)
    return response.data.isAvailable
  } catch (e) {
    return false
  }
}

export function getApiKeyByKeyId(instanceId: string, keyId: string) {
  return axios.get(`/api/instances/${instanceId}/api-keys/${keyId}/key`)
}

export function getApiKeyByUserIdAndInstanceId(userId: string, instanceId: string) {
  return axios.get(`/api/users/${userId}/instances/${instanceId}/api-keys`)
}

export function getInstanceMetrics(instanceId: string) {
  return axios.get(`/api/instances/${instanceId}/metrics`)
}

export async function getInstanceApiMetrics(instanceId: string, date?: Date): Promise<InstanceApiStats> {
  try {
    const param = date ? `?date=${date.toISOString().substring(0, 7)}` : ''
    const response = await axios.get(`/api/instances/${instanceId}/metrics/api${param}`)
    return isInstanceApiStats.validate(response.data)
  } catch(e) {
    console.error(e)
    return { api: [] }
  }
}

export async function getInstanceDataMetrics(instanceId: string, date?: Date): Promise<InstanceDataStats> {
  try {
    const param = date ? `?date=${date.toISOString().substring(0, 7)}` : ''
    const response = await axios.get(`/api/instances/${instanceId}/metrics/data${param}`)
    return isInstanceDataStats.validate(response.data)
  } catch(e) {
    console.error(e)
    return { data: [] }
  }
}

export async function analyzeSample(instanceId: string, sample: string, analyzer: Analyzer): Promise<AnalyzedSample> {
  const r = await axios.post(`/api/instances/${instanceId}/schema/analyzes`, { sample, analyzer })
  return r.data
}

export async function cancelInstanceSubscription(instanceId: string) {
  const r = await axios.delete(`/api/instances/${instanceId}/subscription`)
  return r.data
}

export function getFileUploadUrl(instanceId: string, tableName: string, fileSize: number) {
  return axios.post(`/api/instances/${instanceId}/jobs/convert-and-upload-file`, { table: tableName, size: fileSize })
}

export function getFileUploadAndEditUrl(instanceId: string, fileSize: number) {
  return axios.post(`/api/instances/${instanceId}/jobs`, { size: fileSize, type: 'upload-file-and-edit-schema' })
}

export function putFileToS3(upload: { url: string, fields: object }, file: File, onProgress?: (progress: number) => void) {
  const formData = new FormData()

  // Add form fields to include in the upload
  _.forEach(upload.fields, (value, key) => {
    formData.append(key, value)
  })

  // "file" must be last
  formData.append('file', file, 'file')

  return axios.post(upload.url, formData, {
    ...(onProgress && { onUploadProgress: (ev) => onProgress(ev.loaded) }),
  })
}

export function setFileJobSchema(
  instanceId: string,
  jobId: string,
  tableName: string,
  hasHeader: boolean,
  columnSchema: ColumnType[],
  aitoSchema: TableSchema,
) {
  return axios.post(`/api/instances/${instanceId}/jobs/${jobId}`, {
    type: 'define-csv-schema',
    tableName,
    hasHeader,
    columnSchema,
    aitoSchema,
  })
}

export function getInstanceJob(instanceId: string, jobId: string) {
  return axios.get(`/api/instances/${instanceId}/jobs/${jobId}`)
}

export function getAitoUploadStatus(hostname: string, table: string, jobId: string, apiKey: string) {
  const path = `/api/v1/data/${table}/file/${jobId}`

  const uploadStatusUrl = URI.serialize({
    scheme: 'https',
    host: hostname,
    path
  })

  return axios.get(uploadStatusUrl, { headers: { 'x-api-key': apiKey }})
}

export function modifyInstanceSubscription(instanceId: string, body: any) {
  return axios.put(`/api/instances/${instanceId}/subscription`, body)
}

export async function getInstanceEvaluations(instanceId: string): Promise<AxiosResponse<PredictionTarget[]>> {
  return axios.get<PredictionTarget[]>(`/api/instances/${instanceId}/prediction-targets`)
}

export function getEvaluations(evaluationTargetId: string) {
  return axios.get(`/api/prediction-targets/${evaluationTargetId}/evaluations`)
}

export function getEvaluationStatus(evaluationId: string) {
  return axios.get(`/api/evaluations/${evaluationId}`)
}

export function createInstanceEvaluations(
  instanceId: string,
  values: any,
  inputColumns: AitoColumn[],
  targetColumn: AitoColumn) {

    const testSizes = {
      'S': { maxSamples: 2000, sampleSplitRatio: 0.05 },
      'M': { maxSamples: 4000, sampleSplitRatio: 0.1 },
      'L': { maxSamples: 8000, sampleSplitRatio: 0.2 }
    }
    const testSize = (testSizes as any)[values.testSize]

    const evalBody: {
      tableName: string,
      predictor: PredictorConfiguration,
      settings: EvaluationSettings,
      target: PredictorTarget,
      impact: BusinessImpact
    } = {
      tableName: values.tableName,
      predictor: {
        inputColumns,
        type: 'default'
      },
      target: {
        targetColumn,
        type: 'single-column'
      },
      settings: {
        sampleSplitRatio: testSize.sampleSplitRatio,
        maxSamples: testSize.maxSamples
      },
      impact: {
        unit: values.unit,
        successCaseSavings: values.successCaseSavings,
        casesPerInterval: values.casesPerInterval,
        errorCaseCost: values.errorCaseCost,
        intervalUnit: 'month',
        type: 'default'
      }
    }
    return axios.post(`/api/instances/${instanceId}/prediction-targets`, evalBody)
}

export function startEvaluation(evId: string) {
  return axios.post(`/api/prediction-targets/${evId}/evaluations`)
}

export function getEvaluationMetrics(evId: string, confidence: number) {
  return axios.post(`/api/evaluations/${evId}/evaluation-metrics`, { confidence })
}

export function deletePredictionTargetById(predictionTargetId: string) {
  return axios.delete(`/api/prediction-targets/${predictionTargetId}`)
}
