import _ from 'lodash'
import { action } from 'typesafe-actions'
import { Reducer } from 'redux'

import { UUID } from 'api'

import { InstanceDetailsObject } from '../types/InstanceDetails'
import {
  getInstanceById,
  cancelInstanceSubscription as cancelInstanceSubscriptionApi,
  getApiKeyByUserIdAndInstanceId,
  getInstanceJob,
  getApiKeyByKeyId,
  getAitoUploadStatus,
  modifyInstanceSubscription as modifyInstanceSubscriptionApi,
  getInstanceApiMetrics,
  getInstanceDataMetrics,
} from '../utils/api/instanceApi'
import { getUserInstances as getUserInstancesApi } from '../utils/api/userApi'

import { InstancePreview } from '../types/InstancePreview'
import { CreateInstanceRequest, createInstance as createInstanceApi } from '../utils/api/teamApi'
import { Error } from '../types/Error'
import { ApiKey } from '../types/ApiKey'

import { getFileUploadUrl, putFileToS3 } from '../utils/api/instanceApi'
import { mockFileUpload } from '../utils/mockApiCalls'
import config from '../config'
import { InstanceApiStats, InstanceDataStats } from 'api/schema/metrics'

enum actionTypes {
  INSTANCE_LOAD_SUCCESS = 'instances/INSTANCE_LOAD_SUCCESS',
  INSTANCE_REQUEST_PROCESSING = 'instance/START_LOAD_INSTANCE',
  INSTANCE_REQUEST_ERROR = 'instance/INSTANCE_REQUEST_ERROR',

  GET_USER_INSTANCES_REQUEST = 'instances/GET_USER_INSTANCES_REQUEST',
  GET_USER_INSTANCES_SUCCESS = 'instances/GET_USER_INSTANCES_SUCCESS',
  GET_USER_INSTANCES_ERROR = 'instances/GET_USER_INSTANCES_ERROR',

  DELETE_INSTANCE_REQUEST = 'instances/DELETE_INSTANCES_REQUEST',
  DELETE_INSTANCE_SUCCESS = 'instances/DELETE_INSTANCES_SUCCESS',
  DELETE_INSTANCE_ERROR =   'instances/DELETE_INSTANCES_ERROR',

  CREATE_INSTANCE_REQUEST = 'instances/CREATE_INSTANCE_REQUEST',
  CREATE_INSTANCE_SUCCESS = 'instances/CREATE_INSTANCE_SUCCESS',
  CREATE_INSTANCE_ERROR =   'instances/CREATE_INSTANCE_ERROR',

  FILE_UPLOAD_REQUEST = 'instances/FILE_UPLOAD_REQUEST',
  FILE_UPLOAD_SUCCESS = 'instances/FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_UPDATE = 'instances/FILE_UPLOAD_UPDATE',
  FILE_UPLOAD_CLEAR = 'instances/FILE_UPLOAD_CLEAR',
  FILE_UPLOAD_ERROR = 'instances/FILE_UPLOAD_ERROR',

  UPDATE_INSTANCE_SUBSCRIPTION_REQUEST = 'instances/UPDATE_INSTANCE_SUBSCRIPTION_REQUEST',
  UPDATE_INSTANCE_SUBSCRIPTION_SUCCESS = 'instances/UPDATE_INSTANCE_SUBSCRIPTION_SUCCESS',
  UPDATE_INSTANCE_SUBSCRIPTION_ERROR = 'instances/UPDATE_INSTANCE_SUBSCRIPTION_ERROR'
}

export interface CreateInstanceState {
  loading: boolean
  error: Error | null
  instanceId: UUID | null
}

export interface UserInstancesState {
  loading: boolean
  error?: Error
  instances: InstancePreview[]
}

export interface InstanceStatus {
  instanceId: string
  loading: boolean
  error?: Error
  instance?: InstanceDetailsObject
}

export interface FileUploadStatus {
  instanceId: string,
  loading: boolean,
  error?: Error,
  status: string,
  info?: string,
  aitoStatus?: any
}

export interface InstanceDetailsState {
  createInstance: CreateInstanceState
  userInstances: UserInstancesState
  fileUploads: { [k: string]: FileUploadStatus }
  instances: { [k: string]: InstanceStatus }
  apiKeys: { [k: string]: ApiKey[] }
}

function updateApiKeys(state: InstanceDetailsState, instanceId: string, key: ApiKey[]): InstanceDetailsState {
  const keys = state.apiKeys
  return { ...state, apiKeys: { ...keys, [instanceId]: key }}
}

function updateInstance(state: InstanceDetailsState, instanceId: string, patch: Partial<InstanceStatus>): InstanceDetailsState {
      const instances = state.instances
      const instance = state.instances[instanceId] || { instanceId, loading: false }
      return { ...state, instances: { ...instances, [instanceId]: { ...instance, ...patch } }}
}

function updateFileUpload(state: InstanceDetailsState, instanceId: string, patch: Partial<FileUploadStatus>): InstanceDetailsState {
  const fileUploads = state.fileUploads
  const fileUpload = state.fileUploads[instanceId] || { instanceId, loading: false }
  return { ...state, fileUploads: { ...fileUploads, [instanceId]: { ...fileUpload, ...patch } }}
}

export const getUserInstances = (userId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_USER_INSTANCES_REQUEST, { userId }))
    try {
      const res = await getUserInstancesApi(userId)
      const instances: InstancePreview[] = res.data
      dispatch(action(actionTypes.GET_USER_INSTANCES_SUCCESS, { userId, instances }))
    } catch (e) {
      dispatch(action(actionTypes.GET_USER_INSTANCES_ERROR, { message: e.message, response: e.response.data, userId }))
    }
  }
}

export const getInstance = (instanceId: string, userId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.INSTANCE_REQUEST_PROCESSING, { instanceId }))
    try {
      const instanceData = await getInstanceById(instanceId).then(async response => {
        if (response.status === 200 && response.data) {
          const instance = response.data
          if (['READY', 'UPDATING'].some(state => state === instance.state)) {
            const metadata = await Promise.all([
              getApiKeyByUserIdAndInstanceId(userId, instanceId),
              getInstanceApiMetrics(instanceId),
              getInstanceDataMetrics(instanceId),
            ])
            return [instance, ...metadata]
          } else {
            return [instance, {}, { api: [] }, { data: [] }]
          }
        }
        return [response.data, {}, { api: [] }, { data: [] }]
      })

      const instance = instanceData[0]
      const keys = _.get(_.nth(instanceData, 1), 'data')
      const apiMetrics: InstanceApiStats = instanceData[2]
      const dataMetrics: InstanceDataStats  = instanceData[3]

      instance.queryUsage = apiMetrics.api
      instance.diskUsage = dataMetrics.data

      dispatch(action(actionTypes.INSTANCE_LOAD_SUCCESS, { instanceId, instance, keys }))
    } catch (e) {
      const data = _.get(e.response, 'data')
      const message = _.get(data, 'message') || e.message
      dispatch(action(actionTypes.INSTANCE_REQUEST_ERROR, { message, response: data, instanceId }))
    }
  }
}

export const createInstance = (request: CreateInstanceRequest) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.CREATE_INSTANCE_REQUEST))
    try {
      const response = await createInstanceApi(request)
      dispatch(action(actionTypes.CREATE_INSTANCE_SUCCESS, { instanceId: response.data && response.data.id }))
    } catch (e) {
      dispatch(action(actionTypes.CREATE_INSTANCE_ERROR, { response: e.response.data }))
    }
  }
}

export const deleteInstance = (instanceId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.DELETE_INSTANCE_REQUEST, { instanceId }))
    try {
      // TODO: This returns a PendingInstanceUpdate.  Use the appliedAt date to tell the user when it happens.
      await cancelInstanceSubscriptionApi(instanceId)
      dispatch(action(actionTypes.DELETE_INSTANCE_SUCCESS, { instanceId }))
    } catch (e) {
      dispatch(action(actionTypes.DELETE_INSTANCE_ERROR, { message: e.message, response: e.response.data, instanceId }))
    }
  }
}

export const startFileUpload = (instanceId: string, file: File, tableName: string, apiKey: ApiKey) => {
  if (config.development) {
    return mockFileUpload(actionTypes, instanceId, tableName)
  } else {
    return async (dispatch: any) => {
      try {
        dispatch(action(actionTypes.FILE_UPLOAD_REQUEST, { instanceId }))
        dispatch(action(actionTypes.FILE_UPLOAD_UPDATE, { instanceId, status: 'UPLOADING', info: 'Uploading data' }))
        const fileUploadUrl = await getFileUploadUrl(instanceId, tableName, file.size)
        const { data } = fileUploadUrl
        await putFileToS3(data.upload, file)
        dispatch(action(actionTypes.FILE_UPLOAD_UPDATE, { instanceId, status: 'UPLOADING', info: 'Converting data' }))
        let status
        do {
          const uploadStatus = await getInstanceJob(instanceId, data.jobId)
          status = uploadStatus.data
          if (status.stage !== 'DONE') await new Promise(r => setTimeout(r, 2000))
        } while (status.stage !== 'DONE' && status.stage !== 'FAILED')

        if (status.stage === 'DONE') {
          dispatch(action(actionTypes.FILE_UPLOAD_UPDATE, { instanceId, status: 'UPLOADING', info: 'Inserting data' }))
        } else {
          dispatch(action(actionTypes.FILE_UPLOAD_ERROR, { error: { message: 'Upload failed' }, instanceId }))
          return false
        }

        const { aitoJobId, hostname, table } = status.params
        const keyReq = await getApiKeyByKeyId(instanceId, apiKey.id)
        const { key } = keyReq.data
        let aitoStatus
        do {
          try {
            const aitoStatusReq = await getAitoUploadStatus(hostname, table, aitoJobId, key)
            aitoStatus = aitoStatusReq.data
          } catch (aitoError) {
            if (aitoError.message !== 'Network Error') throw aitoError
          }
          if (!aitoStatus.status.finished) await new Promise(r => setTimeout(r, 2000))
        } while (!aitoStatus.status.finished)

        dispatch(action(actionTypes.FILE_UPLOAD_SUCCESS,
          { instanceId, aitoStatus: {...aitoStatus.status, table: status.params.table } }))

        window.analytics.track('File upload', { success: true, instanceId })
        return true
      } catch (e) {
        dispatch(action(actionTypes.FILE_UPLOAD_ERROR, { error: e, instanceId }))
        window.analytics.track('File upload', { success: false, error: e, instanceId })
        return false
      }
    }
  }
}

export const clearFileUpload = (instanceId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.FILE_UPLOAD_CLEAR, { instanceId }))
  }
}

export const modifyInstanceSubscription = (instanceId: string, body: any) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.UPDATE_INSTANCE_SUBSCRIPTION_REQUEST, { instanceId }))
    try {
      await modifyInstanceSubscriptionApi(instanceId, body)
      dispatch(action(actionTypes.UPDATE_INSTANCE_SUBSCRIPTION_SUCCESS, { instanceId }))
    } catch (e) {
      dispatch(action(actionTypes.UPDATE_INSTANCE_SUBSCRIPTION_ERROR, { message: e.message, response: e.response.data, instanceId }))
    }
  }
}

const initialState: InstanceDetailsState = {
  createInstance: { loading: false, instanceId: null, error: null },
  instances: {},
  apiKeys: {},
  fileUploads: {},
  userInstances: {
    loading: false,
    instances: []
  },
}

const reducer: Reducer = (state: InstanceDetailsState = initialState, action: any): InstanceDetailsState => {
  switch (action.type) {
    case actionTypes.CREATE_INSTANCE_REQUEST: {
      return {...state, createInstance: {...state.createInstance, instanceId: null, loading: true}}
    }

    case actionTypes.CREATE_INSTANCE_SUCCESS: {
      const instanceId = action.payload.instanceId || null
      return {...state, createInstance: {...state.createInstance, instanceId, loading: false, error: null }}
    }

    case actionTypes.CREATE_INSTANCE_ERROR: {
      return {...state, createInstance: {...state.createInstance, instanceId: null, loading: false, error: action.payload.response }}
    }

    case actionTypes.INSTANCE_REQUEST_PROCESSING:
    case actionTypes.DELETE_INSTANCE_REQUEST:
    case actionTypes.UPDATE_INSTANCE_SUBSCRIPTION_REQUEST: {
      const instanceId = action.payload.instanceId
      return updateInstance(state, instanceId, { loading: true })
    }

    case actionTypes.INSTANCE_LOAD_SUCCESS: {
      const { instanceId, instance, keys } = action.payload
      const keyState = updateApiKeys(state, instanceId, keys)
      return updateInstance(keyState, instanceId, { instance, loading: false })
    }

    case actionTypes.INSTANCE_REQUEST_ERROR: {
      const { instanceId, response: error } = action.payload
      return updateInstance(state, instanceId, { loading: false, error, instance: undefined })
    }

    case actionTypes.GET_USER_INSTANCES_REQUEST: {
      const userInstances = { ...state.userInstances, error: undefined, loading: true }
      return { ...state, userInstances }
    }

    case actionTypes.GET_USER_INSTANCES_SUCCESS: {
      const { instances } = action.payload
      const userInstances = { instances, error: undefined, loading: false }
      return { ...state, userInstances }
    }

    case actionTypes.GET_USER_INSTANCES_ERROR: {
      const { response: error } = action.payload
      const userInstances = { ...state.userInstances, error, loading: false }
      return { ...state, userInstances }
    }

    case actionTypes.DELETE_INSTANCE_SUCCESS: {
      const { instanceId } = action.payload
      return updateInstance(state, instanceId, { loading: false, error: undefined })
    }

    case actionTypes.DELETE_INSTANCE_ERROR: {
      const { response, instanceId } = action.payload
      return updateInstance(state, instanceId, { loading: false, error: response })
    }

    case actionTypes.UPDATE_INSTANCE_SUBSCRIPTION_SUCCESS: {
      const { instanceId } = action.payload
      return updateInstance(state, instanceId, { loading: false, error: undefined })
    }

    case actionTypes.UPDATE_INSTANCE_SUBSCRIPTION_ERROR: {
      const { response, instanceId } = action.payload
      return updateInstance(state, instanceId, { loading: false, error: response })
    }

    case actionTypes.FILE_UPLOAD_REQUEST: {
      const { instanceId } = action.payload
      return updateFileUpload(state, instanceId, { loading: true })
    }

    case actionTypes.FILE_UPLOAD_UPDATE: {
      const { status, info, instanceId } = action.payload
      return updateFileUpload(state, instanceId, { status, info })
    }

    case actionTypes.FILE_UPLOAD_SUCCESS: {
      const { instanceId, aitoStatus } = action.payload
      return updateFileUpload(state, instanceId, { loading: false, status: 'READY', aitoStatus })
    }

    case actionTypes.FILE_UPLOAD_CLEAR: {
      const { instanceId } = action.payload
      return { ...state, fileUploads: { ...state.fileUploads, [instanceId]: undefined } }
    }

    case actionTypes.FILE_UPLOAD_ERROR: {
      const { error, instanceId } = action.payload
      return updateFileUpload(state, instanceId, { loading: false, status: undefined, aitoStatus: undefined, error })
    }

    default:
      return state
  }
}

export { reducer as instancesReducer }
