import React from 'react'
import styled from 'styled-components'
import { Tag as AntTag} from 'antd'
import { space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { theme } from '../styles/theme'
import { InstanceType } from 'api'

interface Props extends SpaceProps, TypographyProps  {
  instanceType: string
}

function instanceTypeTag(instanceType: string): { color: string, name: string } {
  switch (instanceType) {
    case InstanceType.Production:
      return { color: theme.colors.carnationPink.medium, name: 'Prod' }
    case InstanceType.Developer:
      return { color: theme.colors.eunry.medium, name: 'Dev' }
    default:
      return { color: theme.colors.jade.medium, name: 'Sandbox' }
  }
}

const InstanceTypeTag: React.FC<Props> = ({ instanceType, ...rest }) => {
  const { color: tagColor, name: tagName } =
    instanceTypeTag(instanceType)

  return (
    <Tag fontSize={[0, 1]} color={tagColor}  {...rest}>
      {tagName}
    </Tag>
  )
}

const Tag = styled(AntTag)<TypographyProps | SpaceProps>`
  ${typography};
  ${space};
  line-height: unset;
`

export default InstanceTypeTag
