import React, { ExoticComponent } from 'react'
import { Input, Form } from 'antd'
import { Button } from './index'
import { passwordValidateRule } from '../utils/formValidateRules'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { Flex } from '@rebass/grid'
import { auth0GoogleLogin, auth0GithubLogin } from '../utils/auth0Login'
import { ReactComponent as GoogleLogo } from '../svg-assets/google-logo.svg'
import { ReactComponent as GithubLogo } from '../svg-assets/github-logo.svg'

interface Props {
  handleUserSignUp: (fullName: string, email: string, password: string) => void
}

interface State {
  email: string,
}

class SignUpForm extends React.Component<Props, State> {
  readonly state: State = {
    email: ''
  }

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState({
      email: value
    })
  }

  handleSignUpSubmit = (values: any) => {
    const { fullName, email, password } = values
    this.props.handleUserSignUp(fullName, email, password)
  }

  render() {
    return (
      <Form
        layout="vertical"
        onFinish={this.handleSignUpSubmit}
        hideRequiredMark data-cy="sign-up-form"
      >
        <Flex flexDirection="column" alignItems="center" mt={4}>
          <SocialLoginButton
            bgcolor="white"
            color="scorpion.medium"
            type="default"
            onClick={auth0GoogleLogin}
            mb={3}
          >
            <Flex alignItems="center">
              <GoogleIcon size={[20, 20]} mr={2}/>
              Sign up with Google
            </Flex>
          </SocialLoginButton>
          <SocialLoginButton
            bgcolor="white"
            color="scorpion.medium"
            type="default"
            onClick={auth0GithubLogin}
            mb={4}
          >
            <Flex alignItems="center">
              <GithubIcon size={[20, 20]} mr={2}/>
              Sign up with Github
            </Flex>
          </SocialLoginButton>
        </Flex>

        <FormDivider />

        <Form.Item label="Full name" name="fullName" rules={[{required: true, message: 'Please enter your name'}]}>
          <Input size="large" placeholder="John Doe" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email'
            },
            {
              required: true,
              message: 'Please enter your email'
            }
          ]}
        >
          <Input size="large" placeholder="john.doe@gmail.com" onChange={this.handleEmailChange} />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={passwordValidateRule}>
          <Input.Password size="large" placeholder="• • • • • •" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }: any) => ({
              validator(rule: any, value: any) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }

                return Promise.reject('The passwords do not match!')
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            placeholder="• • • • • •"
          />
        </Form.Item>
        <Flex flexDirection="column" alignItems="center">
          <Form.Item>
            <StyledButton htmlType="submit" data-cy="sign-up-button" mt={3} px={[3, 4]}>
              Sign up with email
            </StyledButton>
          </Form.Item>
        </Flex>

        <AgreeTermText fontSize={0} color="scorpion.light" mb={4}>
          By signing up, you agree to the
          <a target="_blank" rel="noopener noreferrer" href="https://aito.ai/terms-of-service/"> terms of service </a>
          and
          <a target="_blank" rel="noopener noreferrer" href="https://aito.ai/privacy-policy/"> privacy policies.</a>
        </AgreeTermText>

      </Form>
    )
  }
}

const StyledButton = styled(Button)<SpaceProps>`
  ${space};
`

const GoogleIcon: ExoticComponent<any> = styled(GoogleLogo)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
`

const GithubIcon: ExoticComponent<any> = styled(GithubLogo)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
`

const SocialLoginButton = styled(Button)<SpaceProps>`
  ${space};
`

const AgreeTermText = styled.p<SpaceProps | ColorProps | TypographyProps>`
  ${space};
  ${color};
  ${typography};
  font-style: italic;
  text-align: center;
`

const FormDivider = styled.hr`
  border-style: solid;
  border-color: #d9d9d9;
  width: 70%;
  margin: 0 auto 32px auto;
`

export default SignUpForm
