import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import {
  space,
  SpaceProps,
  layout,
  LayoutProps
} from 'styled-system'
import { PageHeader, ActionBar, TeamCard } from '../component'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Flex } from '@rebass/grid'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserTeams } from '../ducks/teams'
import { AppState } from '../ducks'
import { TeamPreview } from '../types/Team'
import { Spin } from 'antd'
import { Error } from '../types/Error'
import { ConsoleUser as User } from '../types/User'
import { createTeamPath, teamOverviewPath } from '../route'
import { TeamRole } from 'api'

interface RouteInfo {
  userId: string
}

interface Props extends RouteComponentProps<RouteInfo>, ReturnType<typeof mapDispatchToProps> {
  userProfile: User
  teams: Array<TeamPreview>,
  loading: boolean,
  error: null | Error
}

interface State {}

const breadcrumbItems = [
  {
    text: 'Teams',
    icon: false
  }
]

const actionButton = {
  url: createTeamPath,
  text: 'Create new team'
}

const sortedRoles = (role: string) => {
  switch(role) {
    case TeamRole.Owner: return 1
    case TeamRole.Member: return 2
    case TeamRole.Guest: return 3
    default: return 4
  }
}

class Teams extends React.Component<Props, State> {

  async componentDidMount() {
    window.analytics.page('Teams')
    const { id: userId } = this.props.userProfile
    await this.props.getUserTeams(userId)
  }

  navigateToDetailPage = (teamId: string) => {
    const { history } = this.props
    history.push(teamOverviewPath(teamId))
  }

  render() {
    const userId = this.props.userProfile.id
    const { teams, loading, error } = this.props
    const sortedTeams = _.orderBy(
      teams,
      team => {
        if (team.members) return [sortedRoles(_.filter(team.members, { user: { id: userId }})[0].role), _.lowerCase(team.name)]
        else return [ team.name ]
      }
    )
    return (
      <div>
        <PageHeader items={breadcrumbItems} />
        <ContentDiv p={[2, 4]} maxWidth={900}>
          <ActionBar actionButton={actionButton} />
          {loading ?
            <Flex flexWrap="wrap" justifyContent="center" p={5}>
              <Spin size="large"/>
            </Flex>
            :
            error ?
              <Flex flexWrap="wrap" justifyContent="center" p={5}>
                <p>{error.message}</p>
              </Flex>
              :
              <Flex flexWrap="wrap">
                {_.map(sortedTeams, (team, index) => {
                  return (
                    <Box width={1} p={2} onClick={() => this.navigateToDetailPage(team.id)} key={index}>
                      <TeamCard team={team} userId={userId} />
                    </Box>
                  )
                })}
              </Flex>
          }
        </ContentDiv>
      </div>
    )
  }
}

const ContentDiv = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ getUserTeams }, dispatch);
}

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.teams.userTeams.loading,
    error: state.teams.userTeams.error,
    teams: state.teams.userTeams.teams,
    userProfile: state.auth.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
