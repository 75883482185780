import { action } from 'typesafe-actions'
import { Reducer } from 'redux'

import { getInvitationBySecret } from '../utils/api/invitationApi'
import {
  acceptInvitation as acceptInvitationApi,
  ignoreInvitation as ignoreInvitationApi
} from '../utils/api/invitationApi'
import { Invitation } from '../types/Invitation'

enum actionTypes {
  INVITATION_REQUEST_PROCESSING = 'invitations/INVITATION_REQUEST_PROCESSING',
  INVITATION_REQUEST_ERROR = 'invitations/INVITATION_REQUEST_ERROR',
  GET_INVITATION_SUCCESS = 'invitations/GET_INVITATION_SUCCESS',
  ACCEPT_INVITATION_SUCCESS = 'invitations/ACCEPT_INVITATION_SUCCESS',
  IGNORE_INVITATION_SUCCESS = 'invitations/IGNORE_INVITATION_SUCCESS',
}

export interface InvitationState {
  loading: boolean
  errorMessage: string | null
  invitation: Invitation | null
}

const initialState: InvitationState = {
  loading: false,
  errorMessage: null,
  invitation: null,
}

export const getInvitation = (invitationSecret: string | string[]) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.INVITATION_REQUEST_PROCESSING))
    try {
      const param = (typeof invitationSecret === 'string') ? invitationSecret : invitationSecret[0]
      const response = await getInvitationBySecret(param)
      const invitation = response.data
      dispatch(action(actionTypes.GET_INVITATION_SUCCESS, invitation))
    } catch (e) {
      const message = 'Failed to fetch invitation'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.INVITATION_REQUEST_ERROR, { response: data, message }))
    }
  }
}

export const acceptInvitation = (body: { secret: string | string[] }) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.INVITATION_REQUEST_PROCESSING))
    try {
      await acceptInvitationApi(body)
      dispatch(action(actionTypes.ACCEPT_INVITATION_SUCCESS))
    } catch (e) {
      const message = 'Failed to accept invitation, please try again.'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.INVITATION_REQUEST_ERROR, { response: data, message }))
    }
  }
}
export const ignoreInvitation = (body: { secret: string | string[] }) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.INVITATION_REQUEST_PROCESSING))
    try {
      await ignoreInvitationApi(body)
      dispatch(action(actionTypes.IGNORE_INVITATION_SUCCESS))
    } catch (e) {
      const message = 'Failed cancel invitation, please try again.'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.INVITATION_REQUEST_ERROR, { response: data, message }))
    }
  }
}

const reducer: Reducer = (
  state: InvitationState = initialState,
  action: any,
): InvitationState => {
  switch (action.type) {
    case actionTypes.INVITATION_REQUEST_PROCESSING: {
      return { ...state, loading: true }
    }

    case actionTypes.INVITATION_REQUEST_ERROR: {
      return { ...state, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.GET_INVITATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        invitation: action.payload,
        errorMessage: null,
      }
    }

    // Fall-through on purpose
    case actionTypes.IGNORE_INVITATION_SUCCESS:
    case actionTypes.ACCEPT_INVITATION_SUCCESS: {
      return { ...state, loading: false, errorMessage: null }
    }

    default: {
      return state
    }
  }
}

export { reducer as invitationsReducer }
