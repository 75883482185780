import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import { getNameInitials } from '../utils/getNameInitials'
import styled from 'styled-components'
import { Card as AntCard, Avatar as AntAvatar, Tag as AntTag } from 'antd'
import { Box, Flex } from '@rebass/grid'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { TeamPreview } from '../types/Team'
import variables from '../variables'
import roleToString from '../utils/roleToString'

interface Props {
  team: TeamPreview
  userId: string
}

const NUMBER_OF_SHOW_AVATARS = 3
const roleColors = variables.ROLE_COLORS as any

const TeamCard: React.FC<Props> = props => {
  const { team, userId } = props
  const memberInitials: Array<string> = _.map(team.members, member => getNameInitials(member.user.fullName))
  const memberAvatars: Array<string> = memberInitials.length <= NUMBER_OF_SHOW_AVATARS ?
    memberInitials
    :
    _.concat(_.take(memberInitials, NUMBER_OF_SHOW_AVATARS), `+${_.toString(memberInitials.length - NUMBER_OF_SHOW_AVATARS)}`)

  const memberRoles: Array<string> = _.map(team.members, member => member.role)
  const user = team && _.first(_.filter(team.members, { user: { id: userId }}))
  const role = user && user.role
  return (
    <Card>
      <Flex flexWrap="wrap" alignItems="center">
        <Box width={[1, 1/2]} my={[2,0]}>
          <TeamCardText fontSize={3} fontWeight="bold" color="mullWine">
            {team.name}
          </TeamCardText>
        </Box>
        <Box width={[1, 1/6]} my={[2,0]}>
          <TeamCardText color="mullWine">
            {role ?
            <Tag fontSize={[0, 1]} color={roleColors[role].bg}>
              <RoleText color={roleColors[role].color}>
                { roleToString(role) }
              </RoleText>
            </Tag>
            : null}
          </TeamCardText>
        </Box>
        <Box width={[1, 1/6]} my={[2,0]}>
          <TeamCardText fontWeight="semibold" color="mullWine">
            {`${team.instanceCount} instances`}
          </TeamCardText>
        </Box>

        <Box width={[1, 1/6]} my={[2,0]}>
          <Flex flexWrap="wrap" justifyContent={["flex-start","flex-end"]}>
            <TeamMemberFlexContainer>
              {_.map(memberAvatars, (initial, index) => {
                const role = memberRoles[index]

                return (
                  <Avatar
                    size="small"
                    fontWeight="bolder"
                    bg={(variables.ROLE_COLORS as any)[role].bg}
                    color={(variables.ROLE_COLORS as any)[role].color}
                    key={index}
                    ml={index !== 0 ? '-5px' : undefined}
                  >
                    {initial}
                  </Avatar>
                )
              })}
            </TeamMemberFlexContainer>
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

const Card = styled(AntCard)`
  cursor: pointer;
`

const TeamCardText: ExoticComponent<any> = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const TeamMemberFlexContainer = styled(Flex)`
  display: inline-flex;
  flex-direction: row;
`
const Avatar = styled(AntAvatar)<SpaceProps | ColorProps | TypographyProps>`
  ${color};
  ${space};
  ${typography};
  cursor: pointer;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;

  .ant-avatar-string {
    font-size: 12px;
  }
`

const Tag = styled(AntTag)<TypographyProps>`
  ${typography};
  line-height: unset;
  margin-left: 20px;
`
const RoleText = styled.span`
  ${color};
`

export default TeamCard
