import React, {ExoticComponent} from 'react'
import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { NativeButtonProps } from 'antd/lib/button/button'
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  height,
  HeightProps
} from 'styled-system'

interface Props extends NativeButtonProps {
  bgcolor?: string,
  color?: string,
  disabled?: boolean
}

const Button: React.FC<Props> = props => {
  if (props.disabled) {
    return (
      <StyledDisabledButton
        {...props}
        px={[2, 3]}
        height={[35, 40]}
        color="rgba(0, 0, 0, 0.25)"
        disabled
      />
    )
  } else {
    return (
      props.type === 'primary' || !props.type ?
        <PrimaryButton
          {...props}
          bg={props.bgcolor}
          color={props.color}
          px={[2, 3]}
          height={[35, 40]}
        >
          {props.children}
        </PrimaryButton>
        :
        <StyledButton
          {...props}
          bg={props.bgcolor}
          color={props.color}
          height={[35, 40]}
          px={props.type === 'link' ? 0 : undefined}
        >
          {props.children}
        </StyledButton>
    )
  }
}

const PrimaryButton: ExoticComponent<any> = styled(AntButton)<ColorProps | SpaceProps | HeightProps>`
  ${space};
  ${color};
  ${height};
  box-shadow: none;
  border: none;
  text-shadow: none;
  border-radius: 25px;
`

// @ts-ignore
PrimaryButton.defaultProps = {
  bg: 'carnationPink.medium',
  color: 'carnationPink.dark'
}

const StyledButton: ExoticComponent<any> = styled(AntButton)<ColorProps | SpaceProps | HeightProps>`
  ${space};
  ${color};
  ${height};
  border-radius: 25px;
`

const StyledDisabledButton: ExoticComponent<any> = styled(AntButton)<ColorProps | SpaceProps | HeightProps>`
  ${space};
  ${color};
  ${height};
  border-radius: 25px;
`

export default Button
