import React from 'react'
import InstanceTemplate from './InstanceTemplate'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import styled from 'styled-components'
import _ from 'lodash'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../ducks'
import { getInstance, getUserInstances, InstanceStatus } from '../../ducks/instances'
import { Box, Flex } from '@rebass/grid'
import { layout, LayoutProps, space, SpaceProps  } from 'styled-system'
import { User } from 'api'
import { Card } from 'antd'
import { AitoClient } from 'api'
import CreateTableForm from '../../component/CreateTableForm'
import { getApiKeyByKeyId } from '../../utils/api/instanceApi'
import { ApiKey } from '../../types/ApiKey'

interface RouteParam {
}
interface Props extends RouteComponentProps<RouteParam>, ReturnType<typeof mapDispatchToProps> {
  match: any
  instances: { [k: string]: InstanceStatus },
  userProfile: User,
  apiKeys: { [k: string]: ApiKey[] },
}

interface State {
  loading: boolean
  client?: AitoClient
}

class CreateEmptyTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      client: undefined
    }
  }

  async componentDidMount() {
    const { instanceId } = this.props.match.params
    const { userProfile } = this.props
    if (instanceId) {
      await this.props.getInstance(instanceId, userProfile.id)
      const { apiKeys, instances } = this.props
      const instanceApiKey = apiKeys[instanceId] || {}
      const instanceStatus = instances[instanceId] || {}
      const instance = instanceStatus.instance
      const apiKey = _.find(instanceApiKey, { type: 'READ_WRITE' })
      if (instance && apiKey) {
        await this.props.getInstance(instanceId, userProfile.id)
        const key = await getApiKeyByKeyId(instanceId, apiKey.id)
        const client = AitoClient.createClient({ hostname: instance.hostname, key: key.data.key })
        this.setState({ loading: false, client })
      }
      window.analytics.page('Instance integrations')
    }
  }

  onReady() {
    const tables = this.props.history.location.pathname.split('/').slice(0, -1).join('/')
    this.props.history.push(tables)
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances } = this.props
    const { instance } = instances[instanceId] || { instance: null, loading: true }
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const isOwner = instance ? instance.ownerId === this.props.userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'
    const { loading, client } = this.state

    return (
      <InstanceTemplate
        instanceId={instanceId}
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        activePage="tables"
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        <Container>
          {!loading && client ?
            <Flex flexWrap="wrap">
              <Box width={1}>
                <Card title="Create an empty table">
                  <CreateTableForm aitoClient={client} onReady={this.onReady.bind(this)}/>
                </Card>
              </Box>
            </Flex>
          : undefined}
        </Container>
      </InstanceTemplate>
    )
  }
}

const Container = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    getApiKeyByKeyId,
    getUserInstances,
   }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    apiKeys: state.instances.apiKeys,
    userProfile: state.auth.userProfile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmptyTable)
