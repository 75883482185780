import * as URI from 'uri-js'

const hostnameToUrl = (hostname: string, enforceHttp: boolean = false): URL => {
  const scheme: string = enforceHttp ? 'http' : 'https'

  const urlString = URI.serialize({
    scheme,
    host: hostname
  })

  return new URL(urlString)
}

const urlToString = (url: URL, trailingSlash: boolean = true): string => {
  if (trailingSlash) return url.toString()
  else return url.toString().replace(/\/$/, '')
}

export { hostnameToUrl, urlToString }
