import React, { ExoticComponent } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { sendPasswordResetCode } from '../ducks/auth'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppState } from '../ducks'
import { Heading, ForgotPasswordForm, Button } from '../component'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../svg-assets/aito-logo.svg'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps
} from 'styled-system'
import { message, Spin, Result } from 'antd'
import { theme } from '../styles/theme'
import { CheckCircle } from '@styled-icons/fa-regular/CheckCircle'
import { signInPath } from '../route'
import { Flex } from '@rebass/grid'

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  loading: boolean,
  errorMessage: string | null,
}

interface State {
  sendResetLinkSuccess: boolean
}

class AccountRecovery extends React.Component<Props, State> {
  readonly state: State = {
    sendResetLinkSuccess: false
  }

  handleSendPasswordResetCode = async (email: string) => {
    await this.props.sendPasswordResetCode(email)
    if (this.props.errorMessage) {
      message.error(this.props.errorMessage)
    } else {
      this.setState({sendResetLinkSuccess: true})
    }
  }

  render() {
    const { loading } = this.props
    const { sendResetLinkSuccess } = this.state
    return (
      <ContainerDiv
        px={[3, 0]}
        pt={5}
        pb={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AitoLogo size={[100, 140]} />
        {
          sendResetLinkSuccess ?
            <FormContainerDiv width={[1, 'auto']} bg="white">
              <Result
                icon={<CheckIcon width={70} color="jade.medium"/>}
                status="success"
                title="Password reset link sent successfully"
                subTitle="We've sent a password reset link to your email address, please check your email and follow the instruction."
                extra={
                  <Button>
                    <Link to={signInPath}>
                      Go to login screen
                    </Link>
                  </Button>
                }
              />
            </FormContainerDiv>
            :
            <FormContainerDiv width={[1, '450px']} bg="white">
              <InputContainerDiv px={4} pt={4}>
                <FormHeader>Account recovery</FormHeader>
                <FormText>
                  Please enter your email to receive reset password code
                </FormText>
                <Spin spinning={loading} size="large">
                  <ForgotPasswordForm
                    handleSendPasswordResetCode={this.handleSendPasswordResetCode}
                  />
                </Spin>
              </InputContainerDiv>
            </FormContainerDiv>
        }
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled(Flex)<SpaceProps>`
  ${space};
  width: 100%;
`

const AitoLogo = styled(Logo)<LayoutProps>`
  ${layout};
`
const CheckIcon: ExoticComponent<any> = styled(CheckCircle)<LayoutProps | ColorProps>`
  ${layout};
  ${color};
`
const FormContainerDiv = styled.div<LayoutProps | ColorProps>`
  ${layout};
  ${color};
  box-shadow: 0px 0px 10px ${theme.colors.scorpion.lighter};
  border-radius: 2px;
  max-width: 100%;
`

const InputContainerDiv = styled.div<SpaceProps>`
  ${space};
`

const FormHeader = styled(Heading)`
  text-align: center;
`
const FormText = styled.p`
  font-style: italic;
  text-align: center;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ sendPasswordResetCode }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountRecovery)
