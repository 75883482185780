import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import Spin from 'antd/lib/spin'
import Modal from 'antd/lib/modal'
import { Form, Progress } from 'antd'
import * as Scroll from 'react-scroll'

import { Button } from '..'

import { ColumnType } from 'api/schema/jobs/csvupload'
import { FileUploadState, SchemaInferred } from '../../types/FileUploadState'
import { CheckCircleOutlined } from '@ant-design/icons'
import SchemaEditContent from './SchemaEditContent'
import FilePreviewContent from './FilePreviewContent'
import { ColumnEditorProps, EditStateDispatcher, useModalStateReducer, toAnalyzer, toEditableColumns, TableFormState, ColumnFormState } from './FileUploadModalState'
import Link from 'antd/lib/typography/Link'
import { instanceEvaluationPath, predictionsPath } from '../../route'

const toColumnSchema = (columnStates: ColumnFormState[]): ColumnType[] => {
  return columnStates.map((col, i): ColumnType => {
    if (col.name) {
      switch (col.type) {
        case 'text':
          return {
            type: col.type,
            name: col.name,
            analyzer: toAnalyzer(col.analyzer),
            isRequired: false,
            null: '',
          }

        case 'decimal':
        case 'string':
        case 'int':
        case 'long':
          return {
            type: col.type,
            name: col.name,
            isRequired: false,
            null: '',
          }

        case 'boolean':
          return {
            type: col.type,
            name: col.name,
            isRequired: false,
            true: 'true',
            false: 'false',
          }
      }
    }
    throw new Error(`Unable to convert column ${i})`)
  })
}

const ViewStates = ['upload','preview','schema','create','done','error'] as const
type ViewState = typeof ViewStates[number]
const isViewState = (state: string): state is ViewState => (ViewStates).includes(state as ViewState)

type ModalFooterComponent = React.FC<{
  nextText?: string
  onNextClick?: () => void
  previousText?: string
  onPreviousClick?: () => void
}>

const ModalFooter: ModalFooterComponent = ({
  nextText,
  previousText,
  onNextClick,
  onPreviousClick,
}) => (
  <FooterToolbar>
    {previousText ? <Button type="ghost" onClick={onPreviousClick}>{previousText}</Button> : <span></span>}
    {nextText ? <Button type="primary" bgcolor="jade.medium" color="white" onClick={onNextClick}>{nextText}</Button> : ''}
  </FooterToolbar>
)

const defaultHasHeader = (state: SchemaInferred): boolean => state.inference.header !== undefined
const defaultTableName = (filename: string): string => filename.trim().replace(/\.|\s/g, '_')

export interface CreateTableEvent {
  jobId: string
  tableName: string
  hasHeader: boolean
  columns: ColumnType[]
  includeColumns: number[]
}

const isNonNameChange = (value: Record<string, unknown>): boolean => {
  for (const key in value) {
    if (key === 'name' || key === 'tableName') {
      continue
    }
    const v = value[key]
    if (v instanceof Object) {
      if (isNonNameChange(v as any)) {
        return true
      }
    } else {
      return true
    }
  }
  return false
}

type FileUploadModalComponent = React.FC<{
  jobState: FileUploadState
  onCancel?: () => void
  onCreateTable?: (event: CreateTableEvent) => void
  history: any
}>

const FileUploadModal: FileUploadModalComponent = ({
  jobState,
  onCancel,
  onCreateTable,
  history,
}) => {
  const title = `Uploading ${jobState.filename}`

  // Local state about the file that we gather in this modal dialog and pass on
  // in onCreateTable
  const [ modalState, dispatch ] = useModalStateReducer()

  const [schema, setSchema] = useState<TableFormState | undefined>(undefined)

  const [form] = Form.useForm()

  const jobStateToViewState: Record<FileUploadState['state'], ViewState> = {
    'uploading-file': 'upload',
    'inferring-schema': 'upload',
    'schema-inferred': modalState.view,
    'creating-table': 'create',
    'table-created': 'done',
    'error': 'error'
  }

  // The currently visible view
  const viewState: ViewState = jobStateToViewState[jobState.state]

  // update path with viewState and send the state to analytics
  useEffect(() => {
    if (isViewState(history.location.pathname.split('/').at(-1))) {
      history.replace(viewState)
    } else {
      history.replace(`${history.location.pathname}/${viewState}`)
    }
    window.analytics.page(`File upload: ${viewState}`)
  }, [viewState, history])

  // Callbacks for navigating between editor pages
  const toPreview = useCallback(() => {
    Scroll.animateScroll.scrollToTop({ containerId: 'file-upload-modal-wrapper', smooth: false, duration: 0, delay: 0 })
    dispatch({ type: 'set-view', view: 'preview' })
  }, [dispatch])
  const toSchema = useCallback(() => {
    Scroll.animateScroll.scrollToTop({ containerId: 'file-upload-modal-wrapper', smooth: false, duration: 0, delay: 0 })
    dispatch({ type: 'set-view', view: 'schema' })
  }, [dispatch])
  //const toSummary = useCallback(() => form.validateFields(), [form])

  const submitForm = useCallback(async () => form.submit(), [form])

  const createTable = useCallback(async (state: TableFormState) => {
    if (onCreateTable) {
      try {
        if (jobState.state === 'schema-inferred') {
          const { hasHeader = defaultHasHeader(jobState) } = modalState
          const columns = Object.values(state.columns)
          const includeColumns: number[] = columns
            .map((value, i) => [value.include, i] as const)
            .filter(([include]) => include)
            .map(([, i]) => i)

          const columnTypes: ColumnType[] =
            toColumnSchema(columns.map((value, i) => {
              if (value.include) {
                return value
              } else {
                return {
                  type: 'string',
                  name: String(i),
                  include: false,
                  analyzer: { type: 'standard' } as any,
                }
              }
            }))

          onCreateTable({
            jobId: jobState.jobId,
            hasHeader,
            tableName: state.tableName,
            columns: columnTypes,
            includeColumns,
          })
        }
      } catch (err) {
        console.error('Error', err)
      }
    }
  }, [onCreateTable, jobState, modalState])

  // Modal configuration
  let children: React.ReactNode[] = []
  let width: string | number | undefined
  let bodyStyle: React.CSSProperties | undefined
  let footer: undefined | null | React.ReactNode
  let columnCount: number

  // Default size: single column in preview, full width. Determine size here in
  // the parent container so that both preview and schema views use the same
  // modal width.
  width = '100%'
  columnCount = 1

  // Respond to actual width of the viewport
  const isThreeColumns = useMediaQuery({ minWidth: 600 })
  const isFourColumns = useMediaQuery({ minWidth: 800 })
  const isFiveColumns = useMediaQuery({ minWidth: 1000 })
  const isSixColumns = useMediaQuery({ minWidth: 1200 })

  // Ugly logic
  if (isThreeColumns) {
    width = 600
    columnCount = 3
  }
  if (isFourColumns) {
    width = 800
    columnCount = 4
  }
  if (isFiveColumns) {
    width = 1000
    columnCount = 5
  }
  if (isSixColumns) {
    width = 1200
    columnCount = 6
  }

  // Create modal content
  switch (viewState) {
    case 'upload':
      const filesizeMb = jobState.filesize / 1024 / 1024
      const amount = (filesizeMb < 1024 ? filesizeMb : filesizeMb / 1024).toFixed(2)
      const unit = filesizeMb < 1024 ? 'MB' : 'GB'

      if (jobState.state === 'uploading-file') {
        children = [
          <SpinContainer my={48} mx={48} key="child">
            <Progress strokeColor="#00b06a" percent={Math.min(jobState.progress, 0.99) * 100} showInfo={false} />
            <p>Uploading {jobState.filename} ({amount} {unit})</p>
          </SpinContainer>
        ]
      } else {
        children = [
          <SpinContainer my={48} mx={48} key="child">
            <Progress strokeColor="#00b06a" percent={100} status="active" showInfo={false} />
            <p>Inferring schema</p>
          </SpinContainer>
        ]
      }
      footer = (
        <ModalFooter
          previousText="Cancel"
          onPreviousClick={onCancel}
        />
      )
      break

    case 'preview': {
      if (jobState.state !== 'schema-inferred') {
        throw new Error('Assertion failed')
      }
      const { hasHeader = defaultHasHeader(jobState) } = modalState
      children = [
        <FilePreviewContent
          hasHeader={hasHeader}
          state={jobState}
          displayColumns={columnCount}
          key="child"
        />
      ]
      footer = (
        <ModalFooter
          previousText="Cancel"
          onPreviousClick={onCancel}
          nextText="Next: Define Table"
          onNextClick={toSchema}
        />
      )
      // Override padding of the modal body. The table rows have some of the
      // padding.
      bodyStyle = { padding: '0px 16px 24px 16px' }
      break
    }

    case 'schema': {
      if (jobState.state !== 'schema-inferred') {
        throw new Error('Assertion failed')
      }
      const { hasHeader = defaultHasHeader(jobState) } = modalState
      const editableColumns: ColumnEditorProps[] = toEditableColumns(
        form,
        jobState,
        hasHeader,
        schema?.columns || new Array(jobState.inference.columns.length)
      )

      const schemaToUse = schema || {
        tableName: defaultTableName(jobState.filename),
        columns: editableColumns.map(c => ({
          analyzer: c.analyzer,
          include: true,
          name: c.originalName,
          type: c.type,
        })),
      }

      children = [
        <SchemaEditContent
          key="child"
          columns={editableColumns}
          form={form}
          schema={schemaToUse}
          onSchemaChange={(changedValues, allValues) => {
            // We only care about Optimization so we don't re-render on every key-press
            // Is there a better way?
            if (isNonNameChange(changedValues)) {
              console.log(allValues)
              setSchema(allValues)
            }
          }}
          onCreate={createTable}
        />
      ]
      footer = (
        <ModalFooter
          previousText="Previous: Preview Data"
          onPreviousClick={toPreview}
          nextText="Create Table"
          onNextClick={submitForm}
        />
      )
      bodyStyle = { padding: '0px 24px 24px 24px' }
      break
    }

    case 'create':
      children = [
        <SpinContainer my={48} key="child">
          <Spin size="large" />
          <p>The table is being created.</p>
          <TipParagraph>
            The process will finish in a few moments.
            You're free to <Link onClick={onCancel}>close</Link> this dialog window
            if you want.
          </TipParagraph>
          </SpinContainer>
      ]
      footer = (
        <ModalFooter
          nextText="Close"
          onNextClick={onCancel}
        />
      )
      break

    case 'done':
      children = [
        <SpinContainer my={48} key="child">
          <CheckCircleOutlined style={{ color: '#00b06a', fontSize: '36px' }} />
          <p>Table created!</p>
          <TipParagraph>
            Tip: If you have a use case in mind, create
            an <Link href={instanceEvaluationPath(jobState.instanceId)}>evaluation</Link> and
            see how well aito's predictions perform or run a
            few <Link href={predictionsPath(jobState.instanceId)}>predictions</Link> yourself
            and expore the potential of your data.
          </TipParagraph>
        </SpinContainer>
      ]
      footer = (
        <ModalFooter nextText="Close" onNextClick={onCancel} />
      )
      break

    case 'error':
      children = [
        <SpinContainer my={48} key="child">
          <p>Something went wrong</p>
        </SpinContainer>
      ]
      footer = (
        <ModalFooter nextText="Close" onNextClick={onCancel} />
      )
      break
  }

  return (
    <EditStateDispatcher.Provider value={dispatch}>
      <Modal
        wrapProps={{id: 'file-upload-modal-wrapper'}}
        visible
        width={width}
        bodyStyle={bodyStyle}
        closable={false}
        title={title}
        footer={footer}
        children={children}
      />
    </EditStateDispatcher.Provider>
  )
}

const SpinContainer = styled.div<SpaceProps>`
  text-align: center;
  ${space};
`

const FooterToolbar = styled.div`
  display: flex;
  justify-content: space-between;
`

const TipParagraph = styled.p`
  display: inline-block;
  max-width: 500px;
`

export default FileUploadModal
