import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppState } from '../ducks'
import { saveLastLocation } from '../ducks/auth'
import _ from 'lodash'
import { signInPath } from './index'
import { bindActionCreators, Dispatch } from 'redux'

interface PrivateRouteProps extends RouteProps, ReturnType<typeof mapDispatchToProps> {
  userId: string,
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, render, children, ...rest }) => {
  const isAuthenticated = Boolean(rest.userId)
  if (isAuthenticated) {
    return (<Route {...rest} render={render} component={component} children={children} />)
  }
  if (rest.location) rest.saveLastLocation(rest.location.pathname + rest.location.search)
  return (<Route><Redirect to={signInPath} /></Route>)
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ saveLastLocation }, dispatch);
}

const mapStateToProps = (state: AppState) => {
  return {
    userId: _.get(state.auth, 'userProfile.id'),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
