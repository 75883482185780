import axios from 'axios'
import _ from 'lodash'
import { User as Session } from 'api/console'

const parseError = (err: any) => {
  const response = err.response
  const theStatus = _.get(response, 'status')
  const theDescription = _.get(response, 'data.error_description')

  if (theStatus && theDescription) {
    throw new Error(theDescription)
  }

  throw err
}

export function logout(): Promise<void> {
  return axios.delete(`/api/authentication/session`)
    .then(() => {}, () => {}) // If we fail the session has likely already ended
}

export async function createUser(fullName: string, email: string, password: string): Promise<void> {
  try {
    await axios
      .post(`/api/authentication`, { fullName, email, password })
  } catch (err) {
    return parseError(err)
  }
}

export async function requestPasswordReset(email: string): Promise<void> {
  try {
    await axios
      .put(`/api/authentication/password`, { email })
  } catch (err) {
    return parseError(err)
  }
}

export async function startSession(accessToken: string): Promise<Session> {
  const res = await axios.post(`/api/authentication/session`, { accessToken })
  return res.data
}
