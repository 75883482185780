import React, { ExoticComponent } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { signIn, signUp, clearSignInError } from '../ducks/auth'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppState } from '../ducks'
import { SignUpForm, SignInForm, Heading, Button } from '../component'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../svg-assets/aito-logo.svg'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps, typography, TypographyProps
} from 'styled-system'
import { CaretRight } from '@styled-icons/fa-solid'
import { message, Result, Spin } from 'antd'
import { theme } from '../styles/theme'
import { CheckCircle } from '@styled-icons/fa-regular'
import { signInPath } from '../route'
import { Flex } from '@rebass/grid'
import queryString from 'query-string'

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  loading: boolean,
  errorMessage: string | null,
}

interface State {
  signInFormVisible: boolean,
  signedUpEmail: string,
  signUpSuccess: boolean
}

class Authentication extends React.Component<Props, State> {
  readonly state: State = {
    signInFormVisible: true,
    signedUpEmail: '',
    signUpSuccess: false
  }

  componentDidMount = () => {
    if (this.props.errorMessage) {
      message.error(this.props.errorMessage)
      this.props.clearSignInError()
    }
    const isSigningUp = queryString.parse(this.props.location.search).signUp || false
    this.setState({
      signInFormVisible: !isSigningUp 
    })
  }

  toggleForm = () => {
    this.setState({
      signInFormVisible: !this.state.signInFormVisible,
    })
  }

  handleUserSignIn = async (email: string, password: string) => {
    await this.props.signIn(email, password)
    if (this.props.errorMessage) {
      message.error(this.props.errorMessage)
    }
  }

  handleUserSignUp = async (fullName: string, email: string, password: string) => {
    await this.props.signUp(fullName, email, password)
    if (this.props.errorMessage) {
      message.error(this.props.errorMessage)
    } else {
      this.setState({
        signedUpEmail: email,
        signUpSuccess: true
      })
    }
  }

  onResultButtonClick = () => {
    this.setState({
      signUpSuccess: false,
      signInFormVisible: true
    })
  }

  render() {
    const { signInFormVisible, signedUpEmail, signUpSuccess } = this.state
    const { loading } = this.props
    const formHeader = signInFormVisible ? 'Log in' : 'Create an account'
    
    return (
      <ContainerDiv
        px={3}
        pt={5}
        pb={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AitoLogo size={[100, 140]} />
        {
          signUpSuccess ?
            <FormContainerDiv width={[1, 'auto']} bg="white">
              <Result
                icon={<CheckIcon width={70} color="jade.medium"/>}
                status="success"
                title="Almost done!"
                subTitle={`You account is created, but before continuing we still need to verify your email address. An email has been sent to ${signedUpEmail} containing a verification link. Please check your email and follow the instructions.`}
                extra={
                  <Button>
                    <Link to={signInPath} onClick={this.onResultButtonClick}>
                      Go to login screen
                    </Link>
                  </Button>
                }
              />
            </FormContainerDiv>
            :
            <FormContainerDiv width={[1, '450px']} bg="white">
              <InputContainerDiv px={4} pt={4}>
                <FormHeader data-cy="form-header">{formHeader}</FormHeader>
                <Spin spinning={loading} size="large">
                  {signInFormVisible ?
                    <SignInForm
                      signedUpEmail={signedUpEmail}
                      handleUserSignIn={this.handleUserSignIn}
                    /> :
                    <SignUpForm
                      handleUserSignUp={this.handleUserSignUp}
                    />
                  }
                </Spin>
              </InputContainerDiv>

              <ToggleFormContainerDiv
                width={1}
                px={4}
                py={3}
                onClick={this.toggleForm}
                alignItems="center"
                justifyContent="space-between"
                data-cy="toggle-form"
              >
                <ToggleFormButtonText color="oracle.darker" fontWeight="bold">
                  {signInFormVisible
                    ? 'Don\'t have an account? Create an account'
                    : 'Already have an account? Log in'}
                </ToggleFormButtonText>
                <CaretIcon color="scorpion.darker" size={16}/>
              </ToggleFormContainerDiv>
            </FormContainerDiv>
        }
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled(Flex)<SpaceProps>`
  ${space};
  width: 100%;
`
const AitoLogo = styled(Logo)<LayoutProps>`
  ${layout};
`
const FormContainerDiv = styled.div<LayoutProps | ColorProps>`
  ${layout};
  ${color};
  box-shadow: 0px 0px 10px ${theme.colors.scorpion.lighter};
  border-radius: 2px;
  max-width: 100%;
`
const InputContainerDiv = styled.div<SpaceProps>`
  ${space};
`
const FormHeader = styled(Heading)`
  text-align: center;
`
const ToggleFormContainerDiv = styled(Flex)<SpaceProps | LayoutProps | ColorProps>`
  ${space};
  border-top: 1px solid #f1f1f1;
  cursor: pointer;
` as typeof Flex
const ToggleFormButtonText = styled.span<ColorProps | TypographyProps>`
  ${color};
  ${typography};
  opacity: 0.5;
`
const CaretIcon: ExoticComponent<any> = styled(CaretRight)<LayoutProps | ColorProps>`
  ${layout};
  ${color};
`
const CheckIcon: ExoticComponent<any> = styled(CheckCircle)<LayoutProps | ColorProps>`
  ${layout};
  ${color};
`
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ signUp, signIn, clearSignInError }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Authentication)
