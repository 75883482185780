import { action } from 'typesafe-actions'

export const mockFileUpload = (actionTypes: any, instanceId: string, tableName: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.FILE_UPLOAD_REQUEST, { instanceId }))
    dispatch(action(actionTypes.FILE_UPLOAD_UPDATE, { instanceId, status: 'UPLOADING', info: 'Uploading data' }))
    await new Promise(r => setTimeout(r, 500))
    dispatch(action(actionTypes.FILE_UPLOAD_UPDATE, { instanceId, status: 'UPLOADING', info: 'Converting data' }))
    await new Promise(r => setTimeout(r, 500))
    dispatch(action(actionTypes.FILE_UPLOAD_UPDATE, { instanceId, status: 'UPLOADING', info: 'Inserting data' }))
    await new Promise(r => setTimeout(r, 500))
    dispatch(action(actionTypes.FILE_UPLOAD_SUCCESS,{ instanceId, aitoStatus: { table: tableName } }))
    return true
  }
}
