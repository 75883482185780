import React, { ExoticComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Card, Table, Spin } from 'antd'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, TypographyProps, typography } from 'styled-system'
import { Box, Flex } from '@rebass/grid'
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown'
import { KeyboardArrowUp } from '@styled-icons/material/KeyboardArrowUp'
import { Stripe } from 'stripe'
import { format, fromUnixTime } from 'date-fns'
import { CreditCard } from '@styled-icons/boxicons-solid/CreditCard'
import _ from 'lodash'
import ProfileTemplate from './ProfileTemplate'
import { getUserInvoices } from '../../ducks/user'
import { getUserInstances, UserInstancesState } from '../../ducks/instances'
import { AppState } from '../../ducks'
import { ConsoleUser as User } from '../../types/User'
import { InstanceTypeTag } from '../../component'
import { theme } from '../../styles/theme'
import variables from '../../variables'

interface StaticProperties {
  userProfile: User,
  loading: boolean,
  errorMessage: string,
  invoices: Stripe.Invoice[] | null,
  instances: UserInstancesState
}

interface Props extends StaticProperties, ReturnType<typeof mapDispatchToProps> {}

class Invoices extends React.Component<Props> {

  public async componentDidMount() {
    window.analytics.page('Invoices')
    if (this.props.invoices === null) {
      await this.props.getUserInvoices()
    }

    if (!!this.props.userProfile) {
      await this.props.getUserInstances(this.props.userProfile.id)
    }
  }

  public priceToDecimal = (price: number) => (price/100).toFixed(2)

  public render() {
    const userProfile = this.props.userProfile || {id: '', fullName: '', email: ''}
    const invoices = this.props.invoices || []
    const { instances } = this.props.instances
    const invoiceTableColumns = [
      {
        title: 'Date',
        dataIndex: 'invoice',
        key: 'created',
        render: (invoice: Stripe.Invoice) => <span>{format(fromUnixTime(invoice.created), 'dd MMM yyyy')}</span>
      },
      {
        title: 'Period',
        dataIndex: 'invoice',
        key: 'period',
        render: (invoice: Stripe.Invoice) => <span>{`${invoice ? format(fromUnixTime(invoice.period_start), 'dd MMM yyyy') : ''} - ${invoice ? format(fromUnixTime(invoice.period_end), 'dd MMM yyyy') : ''}`}</span>
      },
      {
        title: 'Total',
        dataIndex: 'invoice',
        key: 'total',
        render: (invoice: Stripe.Invoice) => {
          const currency = _.find(variables.currencies, c => _.lowerCase(c.code) === _.lowerCase(invoice.currency))
          const currencySymbol = currency ? currency.symbol : ''
          return (
            <Flex flexWrap="wrap" alignItems="center">
              <span>{`${this.priceToDecimal(invoice.amount_due)} ${currencySymbol}`}</span>
              {invoice.paid && (
                <PaidTag fontSize={0} bg="silverTree.lighter" color="jade.light" px={2} py={0} ml={[0, 2]}>
                  PAID
                </PaidTag>
              )}
            </Flex>
          )
        }
      },
      {
        title: 'Card',
        dataIndex: 'invoice',
        key: 'payment',
        render: (invoice: Stripe.Invoice) => {
          const charge = invoice.charge as Stripe.Charge
          return charge ? (
            <Flex>
              <CreditCardIcon color="silverTree.darker" width={20} mr={1} />
              {charge.payment_method_details?.card?.last4}
            </Flex>
          ) : null
        }
      },
      {
        title: '',
        dataIndex: 'invoice',
        key: 'x',
        render: (invoice: Stripe.Invoice) => <a href={invoice.hosted_invoice_url || ''}>See full invoice</a>,
      },
    ]

    const renderInvoiceDetail = (invoiceObject: any) => {
      const { invoice } = invoiceObject
      const instanceDetails = _.find(instances, instance => instance.stripeSubId === invoice.subscription)
      const taxAmount = invoice.tax ? this.priceToDecimal(invoice.tax) : this.priceToDecimal(0)
      const totalPrice = this.priceToDecimal(invoice.amount_paid || invoice.amount_due)
      const currency = _.find(variables.currencies, c => _.lowerCase(c.code) === _.lowerCase(invoice.currency))
      const currencySymbol = currency ? currency.symbol : ''

      return (
        instanceDetails ? (
          <InvoiceDetailContainer py={3} px={5}>
            <Flex flexWrap="wrap" alignItems="center" p={2}>
              <InvoiceDetailText fontWeight="bold" color="mullWine" fontSize={3} mr={3}>
                {instanceDetails.name}
              </InvoiceDetailText>
              <InstanceTypeTag instanceType={instanceDetails.type} />
            </Flex>

            {_.map(invoice.lines.data, (line, i) => {
              const productName = _.trim(line.description.substring(0, line.description.indexOf('(')))
              const productPrice= this.priceToDecimal(line.amount)

              return (
                <Flex flexWrap="wrap" width={1} justifyContent="space-between" p={2} key={i}>
                  <InvoiceDetailText color="mullWine">
                    {productName}
                  </InvoiceDetailText>
                  <InvoiceDetailText color="mullWine">
                    {`${productPrice} ${currencySymbol}`}
                  </InvoiceDetailText>
                </Flex>
              )
            })}

            <Flex flexWrap="wrap" width={1} justifyContent="space-between" p={2}>
              <InvoiceDetailText color="mullWine">
                VAT ({invoice.tax_percent ? invoice.tax_percent : 0}%)
              </InvoiceDetailText>
              <InvoiceDetailText color="mullWine">
                {`${taxAmount} ${currencySymbol}`}
              </InvoiceDetailText>
            </Flex>

            <TotalPriceContainer bg="#F9F9F9" flexWrap="wrap" width={1} justifyContent="space-between" p={2}>
              <InvoiceDetailText color="mullWine" fontWeight={600}>
                Total
              </InvoiceDetailText>
              <InvoiceDetailText color="mullWine" fontWeight={600}>
                {`${totalPrice} ${currencySymbol}`}
              </InvoiceDetailText>
            </TotalPriceContainer>
          </InvoiceDetailContainer>
        ) : (
          <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <Spin size="large"/>
          </SpinContainer>
        )
      )
    }

    return (
      <ProfileTemplate
        activePage="invoices"
        userFullname={userProfile.fullName}
      >
        <ContentDiv p={2}>
          <Flex flexWrap="wrap">
            <Box width={1} px={2} mb={3}>
              <Card title="Invoices">
                {
                  invoices ? (
                    <Table
                      pagination={false}
                      rowKey={(row: any) => row.invoice.id}
                      columns={invoiceTableColumns}
                      expandable={{
                        expandedRowRender: record => renderInvoiceDetail(record),
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            <ArrowUpIcon color="scorpion.dark" size={24} onClick={(e: any) => onExpand(record, e)} />
                          ) : (
                            <ArrowDownIcon color="scorpion.dark" size={24} onClick={(e: any) => onExpand(record, e)} />
                          )
                      }}
                      dataSource={invoices}
                    />
                  ) : (
                    <Flex justifyContent="center">
                      <InvoiceDetailText color="scorpion.light">
                        You don't have any invoice yet
                      </InvoiceDetailText>
                    </Flex>
                  )
                }
              </Card>
            </Box>
          </Flex>
        </ContentDiv>
      </ProfileTemplate>
    )
  }
}

const ContentDiv = styled.div<SpaceProps | LayoutProps | ColorProps>`
  ${space};
  ${layout};
  ${color};
  margin: 0 auto;
  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background: white;
  }
`
const ArrowDownIcon: ExoticComponent<any> = styled(KeyboardArrowDown)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`

const ArrowUpIcon: ExoticComponent<any> = styled(KeyboardArrowUp)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`
const InvoiceDetailContainer = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
`
const InvoiceDetailText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`
const TotalPriceContainer = styled(Flex)<ColorProps>`
  ${color};
`
const PaidTag = styled.div<ColorProps | SpaceProps | TypographyProps>`
  ${typography};
  ${color};
  ${space};
  border: 1px solid ${theme.colors.jade.light};
  border-radius: 3px;
`

const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const CreditCardIcon: ExoticComponent<any> = styled(CreditCard)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getUserInvoices,
    getUserInstances
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: state.auth.userProfile,
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
    invoices: state.user.invoices,
    instances: state.instances.userInstances,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
