import React from 'react'
import { Input, Form, Modal } from 'antd'
import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'

import { Button } from './index'
import { theme } from '../styles/theme'

interface Props {
  teamName: string,
  onSubmit: (teamName: string) => void,
  deleteTeam: (teamId: string, teamName: string) => void,
  loading: boolean,
  teamId: string,
  hasInstances: boolean,
}

class TeamSettingsForm extends React.Component<Props> {

  handleSubmit = (values: any) => {
    this.props.onSubmit(values.teamName)
  }

  handleDeleteTeam = async () => {
    const { confirm } = Modal
    confirm({
      title: `Are you sure to delete this team?`,
      content: 'Once you delete a team, there is no turning back. Please be certain.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      centered: true,
      onOk: async () => {
        await this.props.deleteTeam(this.props.teamId, this.props.teamName)
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12, offset: 2 },
      },
    }
    const { teamName, loading }  = this.props

    return (
      <StyledForm
        {...formItemLayout}
        labelAlign="left"
        colon={false}
        initialValues={{
          teamName
        }}
        onFinish={this.handleSubmit}
      >
        <StyledFormItem label="Team name" name="teamName" mb={3}>
          <Input
            size="large"
            disabled={loading}
          />
        </StyledFormItem>

        <StyledFormItem label="Delete team" mb={3}>
          {this.props.hasInstances ?
            <SettingsDescriptionText>Team instances must be deleted before deleting the team</SettingsDescriptionText> :
            <Button type="default" danger onClick={this.handleDeleteTeam}>Delete team</Button>
          }
        </StyledFormItem>
        <SaveButtonContainerDiv mt={4} pt={3}>
          <Button htmlType="submit" disabled={loading}>Save changes</Button>
        </SaveButtonContainerDiv>
      </StyledForm>
    )
  }
}

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
`

const StyledForm = styled(Form)<SpaceProps>`
  ${space};
`

const SettingsDescriptionText = styled.span<TypographyProps | ColorProps>`
  ${color};
  ${typography};
  display: block;
  font-style: italic;
`

const SaveButtonContainerDiv = styled.div<SpaceProps>`
  ${space};
  border-top: 1px solid ${theme.colors.scorpion.lighter};
`

export default TeamSettingsForm
