import React from 'react'
import InstanceTemplate from './InstanceTemplate'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import styled from 'styled-components'

import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../ducks'
import { getInstance, InstanceStatus } from '../../ducks/instances'
import { Box, Flex } from '@rebass/grid'
import { layout, LayoutProps, space, SpaceProps, typography, TypographyProps  } from 'styled-system'
import { theme } from '../../styles/theme'
import { Tag as AntTag } from 'antd'
import { User } from 'api'
import { Button } from '../../component'

import { ReactComponent as python } from '../../svg-assets/python.svg'
import { ReactComponent as robocorp } from '../../svg-assets/robocorp.svg'
import { ReactComponent as uipath } from '../../svg-assets/uipath-logo.svg'
import { ReactComponent as automation } from '../../svg-assets/automation-anywhere.svg'
import { ReactComponent as blueprism } from '../../svg-assets/blueprism.svg'
import { ReactComponent as airtable } from '../../svg-assets/airtable.svg'
import { ReactComponent as parabola } from '../../svg-assets/parabola.svg'
import { ReactComponent as integromat } from '../../svg-assets/integromat.svg'

interface Integration {
  href: string
  logo: React.FunctionComponent
  type: 'Tutorial' | 'Marketplace'
  text: string
  buttonText: string
}

const integrations: Integration[] = [
  {
    href: 'https://aito-python-sdk.readthedocs.io/en/stable/',
    logo: python,
    type: 'Tutorial',
    text: "Aito's Python SDK makes it easy to integrate with code-driven automations.",
    buttonText: 'Read More'
  }, {
    href: 'https://docs.aito.ai/integrations/robocorp',
    logo: robocorp,
    type: 'Tutorial',
    text: "Robocorp's Gen-2 automation platform is even better with Aito's AI. We've made a library that gets you going fast.",
    buttonText: 'Read More'
  }, {
    href: 'https://marketplace.uipath.com/listings/purchase-invoice-automation-made-easy-with-aito',
    logo: uipath,
    type: 'Marketplace',
    text: 'Aito is UiPath Advanced Technology Partner, and the integration is as easy as dropping some HTTP call activities. Check us out at UiPath Marketplace.',
    buttonText: 'UiPath Martketplace'
  }, {
    href: 'https://docs.aito.ai/integrations/automation-anywhere',
    logo: automation,
    type: 'Tutorial',
    text: 'Integrating with Automation Anywhere is easy with our tutorial.',
    buttonText: 'Read More'
  }, {
    href: 'https://digitalexchange.blueprism.com/dx/entry/3439/solution/aitoai-2',
    logo: blueprism,
    type: 'Marketplace',
    text: 'Blue Prism has created an easy to use wrapper for integrating Aito. Check it out in their Digital Exchange.',
    buttonText: 'Blue Prism DX'
  }, {
    href: 'https://airtable.com/marketplace/blkHZZ8NLJ27bL5oy/aito-instant-predictions',
    logo: airtable,
    type: 'Marketplace',
    text: 'Aito Instant Predictions app for Airtable takes machine learning to the heart of your Airtable base. Test it out by installing from the Airtable Marketplace.',
    buttonText: 'Airtable Marketplace'
  }, {
    href: 'https://docs.aito.ai/integrations/parabola.io',
    logo: parabola,
    type: 'Tutorial',
    text: 'Parabola and Aito is a match made in automation heaven. Check out our tutorial for using Aito from Parabola.',
    buttonText: 'Read More'
  }, {
    href: 'https://docs.aito.ai/integrations/integromat-make',
    logo: integromat,
    type: 'Tutorial',
    text: "Integromat is a seriously powerful automation platform and made even better with Aito's machine learning. Check out how to use Aito with HTTP calls from Integromat.",
    buttonText: 'Read More'
  }
]

interface RouteParam {
}
interface Props extends RouteComponentProps<RouteParam>, ReturnType<typeof mapDispatchToProps> {
  match: any
  instances: { [k: string]: InstanceStatus },
  userProfile: User,
}

interface State {
  loading: boolean
}

class Integrations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  async componentDidMount() {
    const { instanceId } = this.props.match.params
    const { userProfile } = this.props
    if (instanceId) {
      await this.props.getInstance(instanceId, userProfile.id)
      this.setState({ loading: false })
    }
    window.analytics.page('Instance integrations')
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances } = this.props
    const { instance } = instances[instanceId] || { instance: null, loading: true }
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const isOwner = instance ? instance.ownerId === this.props.userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'

    return (
      <InstanceTemplate
        instanceId={instanceId}
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        activePage="integrations"
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        <Container>
          <Flex flexWrap="wrap">
            <Box width={1}>
              <Flex flexWrap="wrap" width={1}>
                {integrations.map(int => {
                  const SingleLogo = Logo(int.logo)
                  return (
                    <IntegrationCard width={[1, 1, 1/2, 1/3]} key={int.href} p={2}>
                      <IntegrationData p={3} width={1}>
                        <Tag fontSize={[1, 2]} type={int.type}>{int.type}</Tag>
                        <SingleLogo m={3} />
                        <IntegrationText fontSize={0} m={3}>{int.text}</IntegrationText>
                        <ButtonContainer href={int.href} target="_blank" rel="noopener noreferrer">
                          <LinkButton m={3} py={2}>
                          {int.buttonText}
                        </LinkButton>
                        </ButtonContainer>
                      </IntegrationData>
                    </IntegrationCard>
                )})}
              </Flex>
            </Box>
          </Flex>
        </Container>
      </InstanceTemplate>
    )
  }
}

const Container = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`
const IntegrationCard = styled(Box)`
  display: flex;
`
const IntegrationData = styled(Box)`
  border-radius: 5px;
  border-color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`
const Tag = styled(AntTag)<TypographyProps | SpaceProps | any>`
  ${typography};
  ${space};
  line-height: unset;
  border-width: 0;
  color: white;
  background-color: ${props=> props.type === 'Marketplace' ? theme.colors.carnationPink.medium : theme.colors.jade.medium};
  align-self: flex-start;
`
const IntegrationText = styled.p<TypographyProps | SpaceProps | LayoutProps>`
  ${typography};
  ${space};
  ${layout};
`
const LinkButton = styled(Button)<SpaceProps>`
  ${space};
  background-color: ${theme.colors.silverTree.medium};
  color: white;
  border-radius: 5px;
`
const ButtonContainer = styled.a`
  margin-top: auto;
`
const Logo = (logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) => (
  styled(logo)<LayoutProps | SpaceProps>`
    ${layout};
    ${space};
    height: 50px;
    max-width: 100%;
  `
)

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
   }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    userProfile: state.auth.userProfile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrations)
