import React from 'react'
import { Input, Form } from 'antd'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Button } from './index'

interface ActionCreators {
  onSubmit: (teamName: string) => void,
}

interface StaticProperties {
  loading: boolean,
}
interface Props extends ActionCreators, StaticProperties {}


class CreateTeamForm extends React.Component<Props> {

  handleSubmit = (values: any) => {
    this.props.onSubmit(values.teamName)
  }

  render() {
    const { loading } = this.props
    return (
      <Form
        layout="vertical"
        hideRequiredMark onFinish={this.handleSubmit}
      >
        <StyledFormItem mb={4} label="Team name" name="teamName" rules={[{required: true, message: 'Please enter the team name'}]}>
          <TeamNameInput size="large" placeholder="My team" />
        </StyledFormItem>

        <Button htmlType="submit" loading={loading}>
          Create team
        </Button>
      </Form>
    )
  }
}

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
  .StripeElement {
    height: 39.78px;
    display: block;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    border: 1px solid #d9d9d9;
  }
`

const TeamNameInput = styled(Input)`
  max-width: 300px;
`

export default CreateTeamForm
