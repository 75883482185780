import { compose, createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { rootReducer, AppState } from './ducks'
import { interceptHttp401 } from './middleware/interceptHttp401'
import _ from 'lodash'

interface PersistedPath {
  itemKey: string,
  path: string,
}

const persistedPaths: PersistedPath[] = [
  { itemKey: 'aito-user-profile', path: 'auth.userProfile' },
  { itemKey: 'aito-last-location', path: 'auth.lastLocation' },
]

const middleware = applyMiddleware(thunk, interceptHttp401)

function getPersistedUserProfile(keys: Array<{ itemKey: string, path: string }>): AppState {
  // Create temporary store so that we can get the current
  // shape of the state (if the reducers/state has changed
  // since the user profile was stored)
  const store = createStore(rootReducer, compose(middleware))
  const defaultState = store.getState()
  try {
    let state = _.cloneDeep(defaultState)
    keys.forEach(({ itemKey, path }) => {
      const json = localStorage.getItem(itemKey)
      if (json === null) {
        // path not persisted
        return
      }
      const persistedValue = JSON.parse(json)
      // Override default object fields with persisted state, replace other value types
      const defaultValue = _.get(state, path)
      if (_.isPlainObject(defaultValue) && _.isPlainObject(persistedValue)) {
        // merge 
        _.merge(defaultValue, persistedValue)
      } else {
        _.set(state, path, persistedValue)
      }
    })
    return state
  } catch (e) {
    return defaultState
  }
}

function persistUserProfile(keys: PersistedPath[], store: Store<AppState>): () => void {
    const defaultState = store.getState()
    const currentState: any = {}
    keys.forEach(({ path }) => {
      currentState[path] = _.get(defaultState, path)
    })

    return _.throttle(() => {
      keys.forEach(({ itemKey, path }) => {
        try {
          // Maintain only selected properties in localStorage. Throttle the
          // updates so that we don't touch localStorage too frequently.
          const newState = _.get(store.getState(), path)

          if (!_.isEqual(currentState[path], newState)) {
            if (newState === null || newState === undefined) {
              localStorage.removeItem(itemKey)
            } else { 
              localStorage.setItem(itemKey, JSON.stringify(newState))
            }
            currentState[path] = _.cloneDeep(newState)
          }
        } catch (e) {
          // If there's any problem, clear out the storage
          localStorage.removeItem(itemKey)
        }
      })
    }, 1000)
  }

export function createConsoleStore(keys: PersistedPath[] = persistedPaths) {
  const store = createStore(
    rootReducer,
    getPersistedUserProfile(keys),
    composeWithDevTools(middleware),
  )

  store.subscribe(persistUserProfile(persistedPaths, store))

  return store
}
