import styled, { createGlobalStyle } from 'styled-components'
import { theme } from './theme'
import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: [
      'Raleway:400,600,700',
      'Source Sans Pro:400,600,700',
      'sans-serif'
    ]
  }
})

export const GlobalStylesDiv = styled.div`
  height: 100%;
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.header};
    font-variant-ligatures: none;
  }

  /*
   * * * * * * * * * * * * * *
   * Ant design custom style
   * * * * * * * * * * * * * *
  */
  
  .ant-card {
    border: none;
  }

  .ant-card-head-title {
    font-family: ${theme.fonts.header};
    font-weight: bold;
    font-size: 20px;
  }

  .ant-form-item-label > label {
    font-weight: bold;
  }
  
  .ant-switch {
    background-color: ${theme.colors.sunsetOrange.lighter};
  }
  
  .ant-switch::after {
    background-color: ${theme.colors.jade.medium};
  }
  
  .ant-table-column-title {
    font-weight: bold;
  }
  
  .ant-tag {
    font-weight: 600;
  }
  
  .ant-table-wrapper {
    overflow: auto;
  }
  
  .ant-result-title {
    font-family: ${theme.fonts.header};
    font-weight: bold;
    color: ${theme.colors.mullWine};
  }
  
  .ant-result-subtitle {
    font-size: 16px;
    color: ${theme.colors.scorpion.light};
  }
  
  .ant-collapse-header {
    font-size: 20px;
    color: ${theme.colors.mullWine};
    font-family: ${theme.fonts.header};
    font-weight: bold;
  }
  .ant-form-item-explain {
    margin-top: 4px;
  }
`


export const GlobalStyle = createGlobalStyle`
  body {
    width: 100%;
    background-color: #EEEEEE !important;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  /* Remove paddings and margins from container elements for predictable layouting */
  html, body {
    margin: 0;
    padding: 0;
  }

  /* https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  ::selection {
    background: #3f896e;
    color: white;
  }

  /*
   * * * * * * *
   * Typography
   * * * * * * *
  */

  html, body {
    font-family: ${theme.fonts.body} !important;
  }
  
  /*
   * * * * * * * * * * * * * 
   * Ant modal, drawer, etc
   * * * * * * * * * * * * *
  */
  
  .ant-drawer-body {
    background-color: ${theme.colors.oracle.darker};
    height: 100%;
  }
  
  .ant-modal-title.ant-modal-title {
    font-family: ${theme.fonts.header};
    color: ${theme.colors.mullWine}; 
    font-weight: bold;
  }

  .ant-table-thead > tr > th {
    font-weight: 700 !important;
  }
  
   /*
   * * * * * * * * * * * * * *
   * React multi email form
   * * * * * * * * * * * * * *
  */
  .react-multi-email.focused.react-multi-email.focused {
    border-color: #8bc7af;
    box-shadow: 0 0 0 2px rgba(100, 185, 154, 0.2);
    background: #fff;
  }
  
  .react-multi-email.react-multi-email [data-tag] {
    color: ${theme.colors.silverTree.medium};
  }
`
