import React, { useState } from 'react'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, FormInstance, Input, Select, message } from 'antd'
import { LanguageOptions } from './FileUploadModal/analyzer'
import _ from 'lodash'
import { TableSchema } from 'api/schema/aito'
import { AitoClient } from 'api'
import { Box, Flex } from '@rebass/grid'
import axios from 'axios'
import styled from 'styled-components'

const { Option } = Select

interface FormColumn {
  name: string
  type: string
  analyzer?: string
  language?: string
  delimiter?: string
  nullable: boolean
}

interface SchemaColumn {
  name: string
  type: string
  analyzer?: string | { type: 'delimiter', delimiter: string }
  nullable: boolean
}

const LanguageFormItems = (props: {
  form: FormInstance<any>,
  restField: any,
  fieldKey: number,
  name: number,
  loading: boolean,
  forceUpdate: any
}) => {
  const {
    form,
    restField,
    fieldKey,
    name,
    forceUpdate,
    loading
  } = props

  const analyzerHidden = form.getFieldValue('columns')[name]?.type !== 'Text'
  const languageHidden = form.getFieldValue('columns')[name]?.analyzer !== 'language' || analyzerHidden
  const delimiterHidden = form.getFieldValue('columns')[name]?.analyzer !== 'delimiter' || analyzerHidden

  const analyzer = (
    <Form.Item
      {...restField}
      hidden={analyzerHidden}
      fieldKey={[fieldKey, 'analyzer']}
      name={[name, 'analyzer']}
      label="Analyzer"
      rules={[{ message: 'Missing analyzer', required: !analyzerHidden }]}
      labelCol={{sm: { span: 6 }, md: { span: 5 }}}
    >
      <Select onChange={(e) => forceUpdate({ name: e })} disabled={loading}>
        <Option value="standard">Standard</Option>
        <Option value="language">Language</Option>
        <Option value="whitespace">Whitespace</Option>
        <Option value="delimiter">Delimiter</Option>
      </Select>
    </Form.Item>
  )

  const language = (
    <Form.Item
      {...restField}
      hidden={languageHidden}
      fieldKey={[fieldKey, 'language']}
      name={[name, 'language']}
      label="Language"
      rules={[{ message: 'Missing language', required: !languageHidden }]}
      labelCol={{sm: { span: 6 }, md: { span: 5 }}}
    >
      <Select disabled={loading}>
        {_.map(LanguageOptions, l => (
          <Option key={l.value} value={l.value}>{l.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )

  const delimiter = (
    <Form.Item
      {...restField}
      hidden={delimiterHidden}
      fieldKey={[fieldKey, 'delimiter']}
      name={[name, 'delimiter']}
      label="Delimiter"
      rules={[{ message: 'Missing delimiter', required: !delimiterHidden }]}
      labelCol={{ span: 5 }}
    >
      <Input disabled={loading} />
    </Form.Item>
  )

    return (
      <>
        {analyzer}
        {language}
        {delimiter}
      </>
    )
}

const CreateTableForm = ( props: { aitoClient: AitoClient, onReady: () => void } ) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [, forceUpdate] = useState({})


  // Convert FormColumn to SchemaColumn so we can send to Aito
  const omitByType = (c: FormColumn) => {
    // Select fields that are always needed
    let schemaColumn: SchemaColumn = _.pick(c, ['name', 'type', 'nullable'])

    // handle analyzers for Text fields
    if (c.type === 'Text') {
      if (c.analyzer === 'whitespace' || c.analyzer === 'standard') {
        schemaColumn.analyzer = c['analyzer']
      } else if (c.analyzer === 'language') {
        schemaColumn.analyzer = c['language']
      } else {
        schemaColumn.analyzer = { type: 'delimiter', delimiter: c['delimiter'] || ''}
      }
    }
    return schemaColumn
  }

  const onFinish = async (e: any) => {
    setLoading(true)
    const nonEmptyFields = e.columns?.map((c: FormColumn) => omitByType(c))
    const columns = _.mapKeys(_.toPlainObject(nonEmptyFields), c => c.name)
    const schema: TableSchema = {
      type: 'table',
      columns
    }
    try {
      await props.aitoClient.createTable(e.tableName, schema)
      message.info(`${e.tableName} created`, 5)
      props.onReady()
    } catch (e) {
        let m = 'unknown error'
        if (axios.isAxiosError(e)) m = e?.response?.data?.message
        else if (e instanceof Error) m = e.message
        message.error(m, 5)
    }
    setLoading(false)
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Box width={[1, 1, 1, 5/6]}>
        <Form.Item
            name="tableName"
            label="Table name"
            labelCol={{sm: { span: 6 }, md: { span: 5 }}}
            rules={[{ message: 'Missing table name', required: true }]}
          >
            <Input disabled={loading} />
          </Form.Item>
        </Box>
        <Divider />
        <Form.List name="columns">
        {(fields, { add, remove }) => (
          <Form.Item>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Flex key={key} width={1} flexWrap="wrap">
                <Box width={[1, 1, 1, 5/6]}>
                  <Form.Item
                    {...restField}
                    fieldKey={[fieldKey, 'columnName']}
                    name={[name, 'name']}
                    label="Column name"
                    rules={[{ message: 'Missing column name', required: true }]}
                    labelCol={{sm: { span: 6 }, md: { span: 5 }}}
                  >
                    <Input disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                      fieldKey={[fieldKey, 'type']}
                      name={[name, 'type']}
                      label="Type"
                      rules={[{ message: 'Missing type', required: true}]}
                      labelCol={{sm: { span: 6 }, md: { span: 5 }}}
                    >
                    <Select onChange={(e) => forceUpdate({name: e})} disabled={loading}>
                      <Option value="Int">Integer</Option>
                      <Option value="Decimal">Decimal</Option>
                      <Option value="Boolean">Boolean</Option>
                      <Option value="String">String</Option>
                      <Option value="Text">Text</Option>
                    </Select>
                  </Form.Item>
                  <LanguageFormItems
                    form={form}
                    restField={restField}
                    fieldKey={fieldKey}
                    name={name}
                    forceUpdate={forceUpdate}
                    loading={loading}
                  />
                  <Form.Item
                    {...restField}
                    name={[name, 'nullable']}
                    valuePropName="checked"
                    label="Nullable"
                    hidden
                    labelCol={{sm: { span: 6 }, md: { span: 5 }}}
                    initialValue={true}
                  >
                    <Input type="hidden" disabled={loading} />
                  </Form.Item>
                </Box>
                <Box width={[1, 1, 1, 1/6]}>
                  <Flex justifyContent="center">
                    <Button onClick={() => remove(name)}>
                      <StyledDelete />
                      Remove
                    </Button>
                  </Flex>
                </Box>
                <Box width={1}>
                  <Divider />
                </Box>
              </Flex>
            ))}
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add column
          </Button>
        </Form.Item>
        )}
        </Form.List>
        <Flex width={1} justifyContent="end">
          <Button htmlType="submit" loading={loading}>Create table</Button>
        </Flex>
    </Form>
  )
}

const StyledDelete = styled(CloseOutlined)`
`

export default CreateTableForm
