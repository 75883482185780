import React, { ExoticComponent } from 'react'
import styled from 'styled-components'
import { color, ColorProps, typography, TypographyProps, SpaceProps, space, LayoutProps, layout } from 'styled-system'
import { Flex, Box } from '@rebass/grid'
import { PaymentMethod } from '@stripe/stripe-js'
import { ReactComponent as mc } from '../svg-assets/creditcard_icons/mc.svg'
import { ReactComponent as visa } from '../svg-assets/creditcard_icons/visa.svg'
import { CreditCardTwoTone } from '@ant-design/icons'
import { Pencil } from '@styled-icons/boxicons-solid/Pencil'

interface Props {
  cardHolder: string | null,
  cardInfo: PaymentMethod.Card,
  editable: boolean,
  togglePaymentModal?: (isCreatingCard: boolean) => void
}

function cardIcon(type: string) {
  switch (type) {
    case 'visa':
      return <Visa />
    case 'mastercard':
      return <MC />
    default:
      return <OtherCard />
  }
}

const CreditCard: React.FC<Props> = ({ cardHolder, cardInfo, editable, togglePaymentModal }) => {
  return (
    <CreditCardContainer py={2} px={4}>
      <Flex justifyContent="space-between" align-items="center">
        {cardIcon(cardInfo.brand)}
        {editable && togglePaymentModal && 
          <Box>
            <Edit size={22} color="jade.medium" onClick={() => togglePaymentModal(false)} />
            <span>*</span>
          </Box>
        }
      </Flex>
      <Flex alignItems="center" my={4}>
        <CreditCardText fontWeight="bold">
          **** **** **** {cardInfo.last4}
        </CreditCardText>
      </Flex>

      <Flex justifyContent="flex-end">
        <SmallText>Month/Year</SmallText>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <CreditCardText fontWeight="semibold">{cardHolder}</CreditCardText>
        </Box>
        
        <Box>
          <Flex alignItems="center">
            <SmallText mr={3}>Valid thru</SmallText>
            <CreditCardText fontWeight="bold">
              {cardInfo.exp_month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}
              /{cardInfo.exp_year.toString().slice(-2)}
            </CreditCardText>
          </Flex>
        </Box>
        
          
      </Flex>
    </CreditCardContainer>
  )
}

const CreditCardContainer = styled.div<SpaceProps>`
  ${space};
  border-style: solid;
  border-color: #ddd;
  border-width: 1px;
  border-radius: 20px;
  display: block;
  width: 450px;
  max-width: 100%;
`
const CreditCardText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${color};
  ${space};
  ${typography};
  display: block;
  font-size: 120%;
`
const SmallText = styled.span<SpaceProps>`
  ${space};
  font-size: 80%;
`
const Visa: ExoticComponent<any> = styled(visa)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 50px;
`
const MC: ExoticComponent<any> = styled(mc)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 32px;
`
const OtherCard: ExoticComponent<any> = styled(CreditCardTwoTone)`
  font-size: 32px;
`
const Edit: ExoticComponent<any> = styled(Pencil)<LayoutProps | SpaceProps | ColorProps>`
  ${color};
  ${layout};
  ${space};
  cursor: pointer;
`

export default CreditCard
