import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { color, ColorProps, } from 'styled-system'
import { Row, Col, Input, Select, Checkbox, Form, Typography, Spin } from 'antd'

import _ from 'lodash'
import { List } from 'antd'
import { FormInstance, Rule } from 'antd/lib/form'

import { PrimitiveType } from 'api/schema/jobs/csvupload'
import { Analyzer, AnalyzerType } from 'api/schema/aito'
import { LanguageOptions } from './analyzer'
import { AnalyzerState, ColumnEditorProps, TableFormState, toAnalyzer } from './FileUploadModalState'
import { AnalyzedSample, analyzeSample } from '../../utils/api/instanceApi'
import InfoTooltip from '../InfoTooltip'
import { Box, Flex } from '@rebass/grid'

const messageTemplates = {
  required: "${label} is required", // eslint-disable-line no-template-curly-in-string
  max: "${label} cannot be longer than ${max}", // eslint-disable-line no-template-curly-in-string
}

const namePatternRule: Rule = {
  message: 'Names are case sensitive and can only contain alphanumeric characters, hyphens, and underscores.',
  type: 'string',
  pattern: /^[a-zA-Z0-9_-]*$/,
}

const nameLengthRule: Rule = {
  max: 60,
}

const nameIsRequiredRule: Rule = {
  required: true,
}

const nameRules = [
  namePatternRule,
  nameLengthRule,
  nameIsRequiredRule,
]

const TypeNames: Record<PrimitiveType, string> = {
  boolean: 'boolean',
  int: 'integer',
  decimal: 'decimal',
  long: 'big integer',
  string: 'string',
  text: 'text',
}

// TODO: show on how many rows a column was empty
/*
const PatternNames: Record<string, string> = {
  'words': 'words',
  'integer': 'integral number',
  'integer': 'integral number',
  'decimal': 'decimal number',
  'empty': 'empty',
  'space': 'empty (only white space)',
  'other': 'other',
}
*/

const AnalyzerNames: Record<AnalyzerType, string> = {
  standard: 'standard',
  whitespace: 'whitespace',
  'char-ngram': 'character n-gram',
  delimiter: 'delimiter',
  language: 'language',
  'token-ngram': 'token n-gram',
}

const AnalyzerTypes: AnalyzerType[] = [
  'standard',
  'language',
  'whitespace',
  'delimiter',
]

const AnalyzerEditor: React.ExoticComponent<{
  analyzer: AnalyzerState
  column: string
  include: boolean
  form: FormInstance
}> = React.memo(({ analyzer, column, include, form }) => {
  const disabled = !include

  useEffect(() => {
    // Changing "include" might require re-validation of the name field
    const delimiterField = ['columns', column, 'analyzer', 'delimiter', 'delimiter']
    form.validateFields([delimiterField])
  })

  return (
    <>
      <Form.Item
        label="Analyzer"
        name={['columns', column, 'analyzer', 'type']}
      >
        <Select
          disabled={disabled}
          options={AnalyzerTypes.map(t => ({ label: AnalyzerNames[t], value: t }))}
        />
      </Form.Item>
      <Form.Item
        label="Language"
        hidden={analyzer.type !== 'language'}
        name={['columns', column, 'analyzer', 'language', 'language']}
      >
        <Select
          style={{display: 'block'}}
          disabled={disabled}
          options={LanguageOptions}
        />
      </Form.Item>
      <Form.Item
        hidden={analyzer.type !== 'language'}
        wrapperCol={{ offset: 6, span: 18 }}
        name={['columns', column, 'analyzer', 'language', 'useDefaultStopWords']}
        valuePropName="checked"
      >
        <Checkbox disabled={disabled} >
          Ignore stop words
        </Checkbox>
        <InfoTooltip>
          Stop words are common words such
          as "with" and "the". In many cases these words carry very little
          information and by ignorng them we make inference a little easier.
          In a few cases, however, it might be desirable to keep them.
          For instance, when texts contain book or movie titles they are
          often significant.
        </InfoTooltip>
      </Form.Item>
      <Form.Item
        label="Delimiter"
        hidden={analyzer.type !== 'delimiter'}
        name={['columns', column, 'analyzer', 'delimiter', 'delimiter']}
        rules={ include && analyzer.type === 'delimiter'
          ? [{ required: true }, { max: 1, message: 'A delimiter is a single character' }]
          : [{ required: false }]
        }
        required={false}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        hidden={analyzer.type !== 'delimiter'}
        wrapperCol={{ offset: 6, span: 18 }}
        name={['columns', column, 'analyzer', 'delimiter', 'trimWhitespace']}
        valuePropName="checked"
      >
        <Checkbox disabled={disabled}>Trim whitespace</Checkbox>
      </Form.Item>
    </>
  )
}, _.isEqual)

const Feature: React.FC<{
  value: string
  type: PrimitiveType
  disabled: boolean
}> = ({ value, type, disabled }) => {
  let json: string
  if (type === 'string' || type === 'text') {
    json = JSON.stringify(value)
  } else {
    json = value
  }
  return (
    <FeatureLabel backgroundColor={disabled ? 'lightgray' : 'jade.medium'} color="white">{json}</FeatureLabel>
  )
}

const toFramgents = (analysis: AnalyzedSample): TextSampleFragment[] => {
  const { query: { sample }, results } = analysis

  const result: TextSampleFragment[] = []
  let offset = 0

  results.forEach(({ offset: { start, end }, feature}) => {
    if (start > offset) {
      result.push({
        offset: offset,
        text: sample.substring(offset, start)
      })
    }
    result.push({
      offset: start,
      text: sample.substring(start, end),
      feature,
    })
    offset = end
  })

  if (offset < sample.length) {
    const text = sample.substring(offset)
    result.push({
      offset,
      text,
    })
  }

  return result
}

interface AnalyzedSampleState {
  requests: string[]
  samples: Record<string, TextSampleFragment[][]>
}

const makeAnalyzerName = (analyzer: Analyzer): string => {
  if (typeof analyzer === 'string') {
    return analyzer
  } else if (analyzer.type === 'delimiter') {
    return `delimiter-${analyzer.trimWhitespace}-${analyzer.delimiter}`
  } else if (analyzer.type === 'language') {
    // TODO: custom stop words and use custom stop words
    return `language-${analyzer.useDefaultStopWords}-${analyzer.language}`
  } else if (analyzer.type === 'char-ngram') {
    return `charngram-${analyzer.minGram}-${analyzer.maxGram}`
  } else if (analyzer.type === 'token-ngram') {
    return `charngram-${analyzer.minGram}-${analyzer.maxGram}-${JSON.stringify(analyzer.tokenSeparator)}-${makeAnalyzerName(analyzer.source)}`
  } else {
    return Math.random().toString()
  }
}

interface TextSampleFragment {
  offset: number,
  text: string,
  feature?: string
}

interface StartRequest {
  type: 'start-request'
  analyzer: string
}

interface FinishRequest {
  type: 'finish-request'
  analyzer: string
  samples: TextSampleFragment[][]
}

function analyzerReducer(
  state: AnalyzedSampleState,
  action: StartRequest | FinishRequest,
): AnalyzedSampleState {
  if (action.type === 'start-request') {
    return {
      ...state,
      requests: [...state.requests, action.analyzer]
    }
  } else {
    return {
      ...state,
      requests: state.requests.filter(s => s !== action.analyzer),
      samples: {
        ...state.samples,
        [action.analyzer]: action.samples
      }
    }
  }
}

const FeatureList: React.ExoticComponent<{
  type: PrimitiveType
  samples: Record<string, string[]>
  formats: Record<string, number>
  disabled: boolean
  instanceId: string
  analyzer: AnalyzerState
}> = React.memo(({ type, samples, disabled, instanceId, analyzer, formats }) => {

  // Interleave samples, one per pattern
  const allSamples: string[] = _.uniq(_.filter(_.flatten(_.zip(..._.values(samples))), (x): x is string => !!x && x.trim().length > 0))

  const avgLength = allSamples.reduce((mean, sample) => mean + sample.length / allSamples.length, 0)
  const isInlineList = type !== 'text' && (type !== 'string' || avgLength < 20)

  let defaultSamples: string[]
  if (type === 'decimal') {
    defaultSamples = samples['decimal'] || samples['integer'] || []
  } else if (type === 'int' || type === 'long') {
    defaultSamples = samples['integer'] || []
  } else {
    defaultSamples = allSamples
  }

  const [analyzes, dispatch] = useReducer(analyzerReducer, { requests: [], samples: {} })

  const aitoAnalyzer = toAnalyzer(analyzer)
  const analyzerName = aitoAnalyzer ? makeAnalyzerName(aitoAnalyzer) : ''

  const isRequesting = analyzes.requests.includes(analyzerName)
  const textSamples = aitoAnalyzer ? analyzes.samples[analyzerName] : []

  useEffect(() => {
    if (type === 'text' && aitoAnalyzer && textSamples === undefined && !isRequesting) {
      let textSamples: string[]
        if (Array.isArray(samples)) {
        textSamples = samples
      } else {
        const words = samples['words'] || []
        const other = samples['other'] || []
        const decimal = samples['decimal'] || []
        const integer = samples['integer'] || []
        textSamples = _.uniq(_.flatten([words, other, decimal, integer]))
      }

      dispatch({
        type: 'start-request',
        analyzer: analyzerName,
      })
      Promise
        .all(_.take(textSamples, 3).map(s => analyzeSample(instanceId, s, aitoAnalyzer)))
        .then(analysis => {
          dispatch({
            type: 'finish-request',
            analyzer: analyzerName,
            samples: analysis.map(toFramgents),
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'finish-request',
            analyzer: analyzerName,
            samples: [],
          })
        })
    }
  })

  let content

  if (type === 'string') {
    if (isInlineList) {
      content = (
        <InlineList>
          {
            _.take(defaultSamples, 8).map((s, i) => (
              <li key={i}><Feature disabled={disabled} value={s} type={type} /></li>
            ))
          }
        </InlineList>
      )
    }
    content = (
      <LongList className="string-type">
        {
          _.take(defaultSamples, 8).map((s, i) => {
            return (
              <li key={i}><Feature disabled={disabled} value={s} type={type} /></li>
            )
          })
        }
      </LongList>
    )
  }
  if (isInlineList) {
    content = (
      <InlineList>
        {
          _.take(defaultSamples, 5).map((s, i) => (
            <li key={i}><Feature disabled={disabled} value={s} type={type} /></li>
          ))
        }
      </InlineList>
    )
  }

  // Long analyzed text
  if (textSamples === undefined && content === undefined) {
    content = (
      <Spin />
    )
  } else if (content === undefined) {
    content = (
      <LongList className="text-type">
        {
          textSamples.map((parts, i) => (
              <li key={i}>
                {
                  parts.map(({ offset, text, feature }) => {
                    if (feature) {
                      return (
                        <InlineFeature key={offset}>
                          <FeatureWord disabled={disabled}>
                            {text}
                          </FeatureWord>
                          <SubscriptFeature>
                            <Feature disabled={disabled} value={feature} type="text" />
                          </SubscriptFeature>
                        </InlineFeature>
                      )
                    } else {
                      return (
                        <InlineFeature key={offset}>
                          <Typography.Text disabled={disabled}>{text}</Typography.Text>
                        </InlineFeature>
                      )
                    }
                  })
                }
              </li>
            )
          )
        }
      </LongList>
    )
  }


  const  l = (samples['other']?.length || 0) +
             (samples['words']?.length || 0) +
             (samples['decimal']?.length || 0) +
             (samples['integer']?.length || 0)
  // Lambda returns most of 1001 unique values
  const uniqueValues = l <= 1000 ? l : 'Over 1000'
  const emptyValues = (formats.empty || 0) + (formats.space || 0)

  return (
    <Flex flexWrap="wrap">
      <Box width={1/2}>
        <Typography.Text strong>Distinct entries</Typography.Text>
        <p>{uniqueValues}</p>
      </Box>
      <Box width={1/2}>
      <Typography.Text strong>Empty fields</Typography.Text>
        <p>{emptyValues}</p>
      </Box>
      <Box width={1}>
        <Typography.Text strong>Examples</Typography.Text>
        {content}
      </Box>
    </Flex>
  )
}, _.isEqual)

const ColumnEditor: React.ExoticComponent<ColumnEditorProps> = React.memo(({
  form,
  originalName,
  type,
  typeOptions,
  formats, // use formats to display how many lines looked like numbers, were empty, etc.
  samples,
  number,
  analyzer,
  include,
  instanceId,
}) => {
  const selectOptions = typeOptions.map(t => ({ value: t, label: TypeNames[t] }))
  const column = number.toString()

  useEffect(() => {
    // Changing "include" might require re-validation of the name field
    const nameField = ['columns', column, 'name']
    form.validateFields([nameField])
  })

  return (
    <List.Item key={number}>
      <Row gutter={24}>
        <Col span={12}>
          <>
            <Row gutter={24}>
             <Col span={6}>
                <StyledFormItem
                  name={['columns', column, 'include']}
                  valuePropName="checked"
                >
                  <Checkbox>Include</Checkbox>
                </StyledFormItem>
             </Col>
             <Col span={18}>
               <Typography.Text strong disabled={!include}>{originalName}</Typography.Text>
             </Col>
           </Row>

            <Form.Item
              label="Name"
              rules={include ? nameRules : [{ required: false }]}
              name={['columns', column, 'name']}
              required={false}
            >
              <Input disabled={!include} />
            </Form.Item>

            <Form.Item
              label="Type"
              name={['columns', column, 'type']}
            >
              <Select
                disabled={!include}
                style={{display: 'block'}}
                options={selectOptions}
              />
            </Form.Item>

            { type === 'text' &&
              <AnalyzerEditor
                column={number.toString()}
                analyzer={analyzer}
                include={include}
                form={form}
              />
            }
          </>
        </Col>
        <Col span={12}>
          <FeatureList
            instanceId={instanceId}
            type={type}
            analyzer={analyzer}
            samples={Array.isArray(samples) ? { other: samples } : samples}
            disabled={!include}
            formats={formats}
          />
        </Col>
      </Row>
    </List.Item>
  )
}, _.isEqual)

type SchemaEditContentComponent = React.FC<{
  form: FormInstance
  columns: ColumnEditorProps[]
  schema: TableFormState
  onSchemaChange?: (changedValues: any, newSchema: TableFormState) => void
  onCreate?: (newSchema: TableFormState) => void
}>

const SchemaEditContent: SchemaEditContentComponent = ({
  form,
  columns,
  schema,
  onSchemaChange,
  onCreate,
}) => {
  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign="right"
      form={form}
      initialValues={schema}
      onValuesChange={onSchemaChange}
      onFinish={onCreate}
      validateMessages={messageTemplates}
    >
      <List
        itemLayout="vertical"
        dataSource={columns}
        pagination={false}
        header={
          <>
            <Typography.Text strong>Table Schema</Typography.Text>
            <p></p>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Table name"
                  name="tableName"
                  rules={nameRules}
                  required={false}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <p>
                  Each table in aito requires a schema. The schema
                  specifies the structure of the data and how columns
                  should be interpreted. Aito extracts features from
                  each row that's uploaded according to the schema.
                </p>
                <p>
                  Textual data can either be treated as a single category
                  label (a single value) or as natural language
                  which has an internal structure build from words
                  (multiple values).
                </p>
              </Col>
            </Row>
          </>
        }
        renderItem={props => <ColumnEditor {...props} />}
      />
    </Form>
  )
}

const InlineList = styled.ul`
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;

  & > li {
    display: inline;
  }
  & > li + li:before {
    content: " ";
  }
`

const LongList = styled.ul`
  margin: 0 0 0 -8px;
  padding: 0;
  display: block;
  list-style: none;

  & > li {
    padding-left: 8px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.string-type > li {
    color: white
  }

  &.text-type > li {
    word-spacing: 1.5ch;
    height: 4em;
  }
`

const FeatureLabel = styled(Typography.Text)<ColorProps>`
  ${color};
  border-radius: 8px;
  padding: 0px 6px;
  text-overflow: ellipsis;
`

const InlineFeature = styled.span`
  display: inline-block;
  vertical-align: top;
  padding-right: 0.25em;

  &:after {
    content: " ";
  }
`

const FeatureWord = styled(Typography.Text)`
  display: block;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #00b06a;
`

const SubscriptFeature = styled(Typography.Text)<ColorProps>`
  display: block;
  text-align: center;
  ${color};
`

const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-control-input {
    min-height: 1px;
  }
`

export default SchemaEditContent
