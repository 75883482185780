import React from 'react'
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { connect } from 'react-redux'
import { getApiKeyByKeyId } from '../utils/api/instanceApi'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { theme } from '../styles/theme'

interface Props {
  type?: string,
  keyId: string,
  description?: string,
  instanceId: string,
  apiUrl?: URL
}

interface State {
  state: string
  key?: string
}

class ApiKeyHolder extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      state: 'empty',
      key: undefined
    }
  }

  getApiKeys = async () => {
    window.analytics.track('Get API keys', { instanceId: this.props.instanceId })
    this.setState({ state: 'loading' })
    const key = await getApiKeyByKeyId(this.props.instanceId, this.props.keyId)
    this.setState({ state: 'show', key: key.data.key })
  }

  selectIcon() {
    const { state } = this.state
    if (state === 'loading') return <LoadingOutlined />
    else if (state === 'hidden') return <EyeOutlined onClick={() => this.setState({ state: 'show' })} />
    else if (state === 'show') return <EyeInvisibleOutlined onClick={() => this.setState({ state: 'hidden' })} />
  }

  render() {
    const { state, key } = this.state
    const { type, apiUrl } = this.props

    return !key ?
    <StyledInput
      mb={4}
      size="large"
      value="API key placeholder"
      type="password"
      disabled
      addonAfter={<EyeOutlined onClick={this.getApiKeys} />}
    />
    :
      type === 'command' ?
        <StyledInput
          mb={4}
          size="large"
          value={apiUrl ? `aito database -i ${apiUrl.toString()} -k ${key} quick-add-table your-data.csv` : ''}
          type={state === 'hidden' ? 'password' : 'text'}
          addonAfter={this.selectIcon()}
          placeholder="Get API key"
        />
      :
        <StyledInput
          mb={4}
          size="large"
          value={key}
          type={state === 'hidden' ? 'password' : 'text'}
          addonAfter={this.selectIcon()}
          placeholder="Get API key"
        />
  }
}

const StyledInput = styled(Input)<SpaceProps>`
  ${space};
  .ant-input-wrapper {
    border-radius: 5px;
    box-shadow: 0 0 10px ${theme.colors.scorpion.lighter};
  }
  .ant-input,.ant-input-group-addon {
    background: white;
    border: none;
  }
`

export default connect()(ApiKeyHolder)
