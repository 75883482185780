import { action } from 'typesafe-actions'
import { Reducer } from 'redux'

import {
  getInstanceJob,
  getApiKeyByKeyId,
  getAitoUploadStatus,
  getFileUploadAndEditUrl,
  setFileJobSchema
} from '../utils/api/instanceApi'

import { ApiKey } from '../types/ApiKey'

import { putFileToS3 } from '../utils/api/instanceApi'
import { FileUploadState } from '../types/FileUploadState'
import { columnSchemaToAitoSchema, ColumnType } from 'api/schema/jobs/csvupload'

enum actionTypes {
  UPDATE_JOB = 'jobs/UPDATE_JOB',
  CLEAR_JOB = 'jobs/CLEAR_JOB',
}

const updateJob = (jobId: string, status: InstanceJobStatus) =>
  action(actionTypes.UPDATE_JOB, { jobId, status })

export interface CsvUploadAndEditStatus {
  type: 'csv-upload-and-edit'
  state: FileUploadState
}

export type InstanceJobStatus =
  | CsvUploadAndEditStatus

export type InstanceJobsState = Record<string, InstanceJobStatus>

export const startEditableFileUpload = (instanceId: string, file: File) => async (dispatch: any): Promise<string | null> => {
  try {
    const fileUploadUrl = await getFileUploadAndEditUrl(instanceId, file.size)
    const { data: { jobId, upload } } = fileUploadUrl
    const filename = file.name
    const filesize = file.size

    dispatch(updateJob(jobId, {
      type: 'csv-upload-and-edit',
      state: {
        state: 'uploading-file',
        instanceId,
        jobId,
        filename,
        filesize,
        progress: 0,
      }
    }))

    const runTask = async () => {
      try {
        // Upload file to S3
        await putFileToS3(upload, file, progress => {
          dispatch(updateJob(jobId, {
            type: 'csv-upload-and-edit',
            state: {
              state: 'uploading-file',
              instanceId,
              jobId,
              filename,
              filesize,
              progress,
            }
          }))
        })

        // Poll customer-api until we get a result
        do {
          const uploadStatus = await getInstanceJob(instanceId, jobId)
          const status = uploadStatus.data
          if (status.stage === 'INFERRING') {
            // Notify progress
            dispatch(updateJob(jobId, {
              type: 'csv-upload-and-edit',
                state: {
                state: 'inferring-schema',
                instanceId,
                jobId,
                filename,
                filesize,
              }
            }))
          } else if (status.stage === 'EDITING') {
            // Complete phase 1
            dispatch(updateJob(jobId, {
              type: 'csv-upload-and-edit',
                state: {
                state: 'schema-inferred',
                instanceId,
                jobId,
                filename,
                filesize,
                inference: status.params.inferredResult,
              }
            }))
            return
          } else if (status.stage === 'FAILED') {
            dispatch(updateJob(jobId, {
              type: 'csv-upload-and-edit',
              state: {
                state: 'error',
                instanceId,
                jobId,
                filename,
                filesize,
                message: 'Failed to convert file'
              }
            }))
            window.analytics.track('File upload', { success: false, message: 'convert-error', instanceId, jobId })
            return
          }

          // Wait and try again later
          await new Promise(r => setTimeout(r, 2000))
        } while (true)
      } catch (e) {
        console.error(e)
        dispatch(updateJob(jobId, {
          type: 'csv-upload-and-edit',
          state: {
            state: 'error',
            instanceId,
            jobId,
            filename,
            filesize,
            message: 'Something went wrong'
          }
        }))
        window.analytics.track('File upload', { success: false, e, instanceId, jobId })
      }
    }

    runTask()
    return jobId // Return job ID as soon as we have it and continue the initial steps in the background
  } catch (e) {
    console.error('Failed to create file upload job', e)
    window.analytics.track('File upload', { success: false, e, instanceId })
    return null
  }
}

export const clearJob = (jobId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.CLEAR_JOB, { jobId }))
  }
}

export const setFileSchema = (
  instanceId: string,
  jobId: string,
  tableName: string,
  hasHeader: boolean,
  columnSchema: ColumnType[],
  includeColumns: number[],
  apiKey: ApiKey,
) => async (dispatch: any, getState: any) => {
  try {
    const state = getState()
    const jobStatus: InstanceJobStatus = state.jobs[jobId]
    if (!jobStatus || jobStatus.type !== 'csv-upload-and-edit') {
      console.error('Incorrect job')
      window.analytics.track('File upload', { success: false, message: 'console-error', instanceId, jobId })
      return
    }
    const filename = jobStatus.state.filename
    const filesize = jobStatus.state.filesize

    const aitoSchema = columnSchemaToAitoSchema(columnSchema, includeColumns)

    dispatch(updateJob(jobId, {
      type: 'csv-upload-and-edit',
      state: {
        state: 'creating-table',
        instanceId,
        jobId,
        filename,
        filesize,
        tableName,
        progress: 0,
      }
    }))

    await setFileJobSchema(instanceId, jobId, tableName, hasHeader, columnSchema, aitoSchema)

    let job: { stage: string, params: { aitoJobId: string, hostname: string, table: string } }
    do {
      const uploadStatus = await getInstanceJob(instanceId, jobId)
      job = uploadStatus.data
      if (job.stage !== 'UPLOADING') {
        break
      }
      await new Promise(r => setTimeout(r, 2000))
    } while (true)

    if (job.stage === 'FAILED') {
      dispatch(
        updateJob(jobId, {
          type: 'csv-upload-and-edit',
          state: {
            state: 'error',
            instanceId,
            jobId,
            filename,
            filesize,
            message: 'Upload failed',
          }
        })
      )
      window.analytics.track('File upload', { success: false, message: 'upload-error', instanceId, jobId })
      return false
    }

    // While the job is finished in customer-api, we need to continue it here
    const { aitoJobId, hostname, table } = job.params
    const keyReq = await getApiKeyByKeyId(instanceId, apiKey.id)
    const { key } = keyReq.data
    let aitoStatus
    do {
      try {
        const aitoStatusReq = await getAitoUploadStatus(hostname, table, aitoJobId, key)
        aitoStatus = aitoStatusReq.data
      } catch (aitoError) {
        if (!(aitoError instanceof Error) || aitoError.message !== 'Network Error') throw aitoError
      }
      if (!aitoStatus.status.finished) await new Promise(r => setTimeout(r, 2000))
    } while (!aitoStatus.status.finished)

    dispatch(
      updateJob(jobId, {
        type: 'csv-upload-and-edit',
        state: {
          state: 'table-created',
          instanceId,
          jobId,
          filename,
          filesize,
          tableName,
        }
      })
    )

    window.analytics.track('File upload', { success: true, instanceId, jobId })
    return true
  } catch (e) {
    window.analytics.track('File upload', { success: false, error: e, instanceId, jobId })
    return false
  }
}

const initialState: InstanceJobsState = {}

const reducer: Reducer = (state: InstanceJobsState = initialState, action: any): InstanceJobsState => {
  switch (action.type) {
    case actionTypes.UPDATE_JOB: {
      const { jobId, status } = action.payload
      return  {
        ...state,
        [jobId]: status,
      }
    }

    case actionTypes.CLEAR_JOB: {
      const { jobId } = action.payload
      const { [jobId]: _, ...jobs } = state
      return  jobs
    }

    default: {
      return state
    }
  }
}

export { reducer as jobsReducer }
