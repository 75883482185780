
import { differenceInSeconds, addSeconds, formatDuration } from 'date-fns'
import { Evaluation, EvaluationResult, isPredictionTarget, isInProgressEvaluation, PredictionTarget, DateLike } from 'api'

interface TextForEvaluation {
  numerator: number,
  denominator: number,
  percent: string
  inProgressDuration: string
  finishedAt: Date
  finishedInDuration: string
}

export const _durationFromNow = (d: Date, unit: 'seconds'|'minutes'|'hours' = 'seconds', now: Date = new Date()): { seconds: number, text: string } => {
  const seconds = Math.abs(differenceInSeconds(d, now))
  let text: string
  const zeroDuration = `0 ${unit}`

  switch (unit) {
    case 'minutes':
      text = formatDuration({ minutes: Math.ceil(seconds/60) })
      break;
    case 'hours':
      text = formatDuration({ hours: Math.floor(seconds/3600) })
      break
    default:
      text = formatDuration({ seconds })
      break;
  }

  return { seconds, text: text || zeroDuration }
}

export const _toPercent = (params: { numerator: number, denominator: number }): { fraction: number, percent: string } => {
  const { numerator, denominator } = params
  if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
    return { fraction: 0, percent: '0' }
  }

  const fraction = numerator / denominator
  const percent = (fraction * 100).toFixed()
  return { fraction, percent }
}

export const _estimate = (params: {
  now: Date,
  createdAt: Date,
  numerator: number,
  denominator: number,
  durationUnit: 'seconds' | 'minutes' | 'hours'
}): TextForEvaluation => {
  const { now, createdAt, numerator, denominator, durationUnit } = params

  const { fraction, percent } = _toPercent({ numerator, denominator })
  const { seconds: ranForSeconds, text: inProgressDuration } = _durationFromNow(createdAt, durationUnit, now)

  const aMonthFromNow = 3600 * 24 * 30
  const completesInSeconds = fraction !== 0 ? ranForSeconds / fraction : aMonthFromNow
  const finishedAt = addSeconds(now, completesInSeconds)
  const { text: finishedInDuration } = _durationFromNow(finishedAt, durationUnit, now)

  return { denominator, numerator, percent, inProgressDuration, finishedInDuration, finishedAt }
}


export const evaluationFinishedIn = (unknown: Evaluation | PredictionTarget | null | undefined): TextForEvaluation | undefined => {
  let evaluationResults: EvaluationResult | undefined | null
  let createdAt: DateLike | undefined | null
  if (isPredictionTarget(unknown)) {
    ({ evaluationResults, createdAt } = unknown)
  } else if (unknown) {
    ({ result: evaluationResults, createdAt } = unknown)
  }

  if (!isInProgressEvaluation(evaluationResults)) {
    return
  }
  const now = new Date()
  createdAt = DateLike.toDate(createdAt as DateLike)

  const numerator = evaluationResults.progress?.completed || Number.NaN
  const denominator = evaluationResults.progress?.total || Number.NaN

  return _estimate({now, createdAt, numerator, denominator, durationUnit: 'minutes' })
}
