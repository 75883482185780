import React, { ExoticComponent, useState, useCallback, useRef, useMemo } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Input, Form, Select} from 'antd'
import { CardElement } from '@stripe/react-stripe-js'
import variables from '../variables'
import { State } from 'api'
import filterCountries from '../utils/filterCountries'
import { Box, Flex } from '@rebass/grid'
import { space, SpaceProps, LayoutProps, layout, TypographyProps, typography, color, ColorProps, } from 'styled-system'
import { ReactComponent as mastercard } from '../svg-assets/creditcard_icons/mastercard.svg'
import { ReactComponent as visa } from '../svg-assets/creditcard_icons/visa-small.svg'
import { ReactComponent as maestro } from '../svg-assets/creditcard_icons/maestro.svg'
import { ReactComponent as diners } from '../svg-assets/creditcard_icons/diners.svg'
import { ReactComponent as amex } from '../svg-assets/creditcard_icons/amex.svg'

interface Props {
  disabled: boolean,
  form: any
}

const PaymentInfoForm: React.FC<Props> = ({ disabled, form }) => {
  const [states, setStates] = useState([] as State[])

  const updateStates = useCallback((e: any) => {
    const s = variables.states[e]
    form.setFields([{name: 'state', value: ''}])
    setStates(s)
  }, [form, setStates])


  interface CardInputState {
    hasFocus?: boolean
    isCardComplete?: boolean
    validationError?: string | undefined
  }

  const cardInputState = useRef<CardInputState>({})

  const onCardChange = useCallback((e: any) => {
    const { current: state } = cardInputState
    state.isCardComplete = Boolean(e.complete)
    if (e.error && e.error.message) {
      state.validationError = e.error.message
    } else {
      state.validationError = undefined
    }
  }, [cardInputState])

  const onCardBlur = useCallback(() => {
    const { current: state } = cardInputState
    state.hasFocus = false
  }, [cardInputState])

  const onCardFocus = useCallback(() => {
    const { current: state } = cardInputState
    state.hasFocus = true
  }, [cardInputState])

  const validateCard = useCallback(() => {
    const { current: state } = cardInputState
    const error = state.validationError
    if (!error && (state.hasFocus || state.isCardComplete)) {
      return Promise.resolve()
    } else {
      const defaultMessage = 'Please enter card number, expiration, and CVC details'
      return Promise.reject(error || defaultMessage)
    }
  }, [cardInputState])

  const cardValidationRules = useMemo(
    () => [{ validator: validateCard }],
    [validateCard],
  )

  return (
    <Flex width={1} flexWrap="wrap">
      <SectionContainer width={1} mb={4}>
        <SectionLabel mb={4} fontSize={3} color="scorpion.medium" fontWeight="bold">
          Credit card
        </SectionLabel>
        <Flex mb={3} width={1}>
          <MasterCard mr={2} />
          <Visa mr={2} />
          <Diners mr={2} />
          <Amex mr={2} />
          <Maestro mr={2} />
        </Flex>
        <Flex width={1} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 4]}>
            <StyledFormItem mb={4} label="Card holder *" name="cardHolder" rules={[{required: true, message: 'Please add card holder name'}]}>
              <Input disabled={disabled} size="large" placeholder="Card holder" />
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]}>
            <StyledFormItem
              mb={4}
              label="Card info *"
              name="cardInfo"
              rules={cardValidationRules}
            >
              <CardElement
                options={{...variables.CREDIT_CARD_ELEMENT_STYLES, disabled }}
                onChange={onCardChange}
                onBlur={onCardBlur}
                onFocus={onCardFocus}
              />
            </StyledFormItem>
          </Box>
        </Flex>
      </SectionContainer>
      <SectionContainer width={1} mb={4}>
        <SectionLabel mb={4} fontSize={3} color="scorpion.medium" fontWeight="bold">
          Billing information
        </SectionLabel>
        <Flex width={1} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0,4]}>
            <StyledFormItem mb={4} label="Business legal name" name="company">
              <Input disabled={disabled} size="large" placeholder="Business legal name (Optional)" />
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]}>
            <StyledFormItem mb={4} label="VAT ID" name="vatId">
              <Input disabled={disabled} size="large" placeholder="VAT id (Optional)" />
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]} pr={[0,4]}>
            <StyledFormItem mb={4} label="Billing address *" name="billingAddress" rules={[{required: true, message: 'Please add billing address'}]}>
              <Input disabled={disabled} size="large" placeholder="Billing address" />
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]}>
            <StyledFormItem mb={4} label="Postal code *" name="postalCode" rules={[{required: true, message: 'Please add postal code'}]}>
              <Input disabled={disabled} size="large" placeholder="Postal code" />
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]} pr={[0,4]}>
            <StyledFormItem mb={4} label="City *" name="city" rules={[{required: true, message: 'Please add city'}]}>
              <Input disabled={disabled} size="large" placeholder="City" />
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]}>
            <StyledFormItem mb={4} label="Country *" name="country" rules={[{required: true, message: 'Please add country'}]}>
              <Select
                showSearch
                size="large"
                placeholder="Country"
                optionFilterProp="children"
                filterOption={filterCountries}
                disabled={disabled}
                onChange={updateStates}
              >
                {_.map(variables.countries, (country, i) => {
                  return (
                    <Select.Option value={country.isoCode} key={i}>{country.label}</Select.Option>
                  )
                })}
              </Select>
            </StyledFormItem>
          </Box>
          <Box width={[1, 1 / 2]} pr={[0,4]}>
          <StyledFormItem mb={4} label="State" name="state" rules={[{required: !_.isEmpty(states), message: 'Please add state'}]}>
            <Select
              showSearch
              size="large"
              placeholder="State"
              disabled={_.isEmpty(states) || disabled}
              optionFilterProp="children"
              filterOption={filterCountries}
            >
              {_.map(states, (state, i) => (
                <Select.Option value={state.isoCode} key={i}>
                  {state.label}
                </Select.Option>
                  
              ))}
            </Select> 
          </StyledFormItem>
          </Box>
        </Flex>
      </SectionContainer>
    </Flex>
  )
}

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
  width: 100%;
  .ant-form-item-label {
    font-weight: bold;
  }
  .ant-col {
    max-width: 100%;
  }
  .StripeElement {
    height: 39.78px;
    display: block;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    border: 1px solid #d9d9d9;
  }
  &.ant-form-item-has-error .StripeElement {
    border: 1px solid #ff4d4f;
  }
`

const Visa: ExoticComponent<any> = styled(visa)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 32px;
`
const MasterCard: ExoticComponent<any> = styled(mastercard)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 32px;
`
const Diners: ExoticComponent<any> = styled(diners)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 32px;
`
const Amex: ExoticComponent<any> = styled(amex)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 32px;
`
const Maestro: ExoticComponent<any> = styled(maestro)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  height: 32px;
`
const SectionLabel = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const SectionContainer = styled(Box)`
  border-bottom: 1px solid #F0F0F0;
`

export default PaymentInfoForm
