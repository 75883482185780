import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../ducks'
import { signInSuccess, oAuthError, clearLastLocation } from '../ducks/auth'
import { startSession } from '../utils/api/authApi'
import { Spin } from 'antd'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { ConsoleUser as User } from '../types/User'
import { signInPath } from '../route'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { color, ColorProps } from 'styled-system'
import _ from 'lodash'

interface Props extends ReturnType<typeof mapDispatchToProps> {
  userProfile: User
  lastLocation: string
  location: {
    hash: string
  }
}

interface State {
  redirect: boolean,
  redirectPath: string | null,
  success: boolean
}

class OauthRedirect extends React.Component<Props, State> {

  readonly state: State = {
    redirect: false,
    redirectPath: null,
    success: false
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.hash)

    if (params.error) {
      this.props.oAuthError(params)
      this.setState({ redirect: true })
      return undefined
    }

    startSession(String(params.access_token))
      .then((res) =>  {
        this.props.signInSuccess(res)
        this.setState({
          redirect: true,
          success: true,
          redirectPath: this.props.lastLocation
        })
        if (this.props.userProfile) {
          const profile = this.props.userProfile
          window.analytics.identify(profile.id, _.omit(profile, [ 'id' ]))
        }
        this.props.clearLastLocation()
      })
      .catch(() => this.setState({ redirect: true }))
  }

  render () {
    if (this.state.redirect && this.state.success) {
      return <Redirect to={this.state.redirectPath || '/'} />
    } else if (this.state.redirect) {
      return <Redirect to={signInPath} />
    } else {
      return (
        <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
          <Spin size="large"/>
        </SpinContainer>
      )
    }
  }
}

const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ signInSuccess, oAuthError, clearLastLocation }, dispatch);
}

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: state.auth.userProfile,
    lastLocation: state.auth.lastLocation,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthRedirect)
