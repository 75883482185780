import React, { ExoticComponent } from 'react'
import { Input, Form } from 'antd'
import { Button } from './index'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { Flex } from '@rebass/grid'
import { accountRecoveryPath } from '../route'
import { ReactComponent as GoogleLogo } from '../svg-assets/google-logo.svg'
import { ReactComponent as GithubLogo } from '../svg-assets/github-logo.svg'
import { auth0GoogleLogin, auth0GithubLogin } from '../utils/auth0Login'

interface Props {
  signedUpEmail: string,
  handleUserSignIn: (email: string, password: string) => void
}

class SignInForm extends React.Component<Props> {

  handleSubmit = (values: any) => {
    this.props.handleUserSignIn(values.email, values.password)
  }

  render() {
    return (
      <Form
        layout="vertical"
        onFinish={this.handleSubmit}
        hideRequiredMark
        initialValues={{
          email: this.props.signedUpEmail
        }}
        data-cy="sign-in-form"
      >
        <Flex flexDirection="column" alignItems="center" mt={4}>
          <SocialLoginButton
            bgcolor="white"
            color="scorpion.medium"
            type="default"
            onClick={auth0GoogleLogin}
            mb={3}
          >
            <Flex alignItems="center">
              <GoogleIcon size={[20, 20]} mr={2}/>
              Log in with Google
            </Flex>
          </SocialLoginButton>
          <SocialLoginButton
            bgcolor="white"
            color="scorpion.medium"
            type="default"
            onClick={auth0GithubLogin}
            mb={3}
          >
            <Flex alignItems="center">
              <GithubIcon size={[20, 20]} mr={2}/>
              Log in with Github
            </Flex>
          </SocialLoginButton>
          <AgreeTermText fontSize={0} color="scorpion.light" mb={4}>
            By logging in with social accounts, you agree to the
            <a target="_blank" rel="noopener noreferrer" href="https://aito.ai/terms-of-service/"> terms of service </a>
            and
            <a target="_blank" rel="noopener noreferrer" href="https://aito.ai/privacy-policy/"> privacy policies.</a>
          </AgreeTermText>
        </Flex>

        <FormDivider />

        <Form.Item label="E-mail" name="email" rules={[{required: true, message: 'Please enter your email'}]}>
          <Input size="large" placeholder="john.doe@gmail.com" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{required: true, message: 'Please enter your password!'}]}>
          <Input.Password size="large" placeholder="• • • • • •" />
        </Form.Item>
        <Form.Item>
          <Link to={accountRecoveryPath}>Forgot password?</Link>
        </Form.Item>

        <Flex flexDirection="column" alignItems="center" mb={3}>
          <Form.Item>
            <StyledButton
              htmlType="submit"
              data-cy="sign-in-button"
              px={[3, 4]}
            >
              Log in
            </StyledButton>
          </Form.Item>
        </Flex>
      </Form>
    )
  }
}

const StyledButton = styled(Button)<SpaceProps>`
  ${space};
`

const GoogleIcon: ExoticComponent<any> = styled(GoogleLogo)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
`

const GithubIcon: ExoticComponent<any> = styled(GithubLogo)<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
`

const SocialLoginButton = styled(Button)<SpaceProps>`
  ${space};
`

const AgreeTermText = styled.p<SpaceProps | ColorProps | TypographyProps>`
  ${space};
  ${color};
  ${typography};
  font-style: italic;
  text-align: center;
`

const FormDivider = styled.hr`
  border-style: solid;
  border-color: #d9d9d9;
  width: 70%;
  margin: 0 auto 32px auto;
`

export default SignInForm
