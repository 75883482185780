import React from 'react'
import { Switch as AntSwitch } from 'antd'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface Props {
  handleTypeSelect: (checked: boolean) => void,
  leftItem: string,
  rightItem: string,
  disabled?: boolean
}

const Switch: React.FC<Props> = props => {
  return (
    <ContainerDiv>
      {props.leftItem}
      <StyledSwitch
        defaultChecked
        onChange={props.handleTypeSelect}
        disabled={props.disabled}
        mx={2}
      />
      {props.rightItem}
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`

`
const StyledSwitch = styled(AntSwitch)<SpaceProps>`
  ${space};
`

export default Switch
