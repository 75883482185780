export const signInPath = `/account/authentication`
export const accountRecoveryPath = `/account/recovery`
export const oAuthRedirectPath = `/account/authentication/return`

export const invitesPath = `/invites`
export const ownershipRequestRawPath = (teamId: string) => `/${teamId}/ownership-transfer`

export const profileOverviewPath = `/profile`
export const profileInvoicesPath = `/profile/invoices`
export const profilePaymentPath = `/profile/payment`

export const instancesPath = `/instances`
export const createInstancePath = `/instances/create`
export const instanceRedirectRawPath = (instanceId: string) => `/instances/${instanceId}`
export const instanceOverviewRawPath = (instanceId: string) => `/instances/${instanceId}/overview`
export const instanceSettingsRawPath = (instanceId: string) => `/instances/${instanceId}/settings`
export const instanceGetStartedRawPath = (instanceId: string) => `/instances/${instanceId}/get-started`
export const instanceEvaluationRawPath = (instanceId: string) => `/instances/${instanceId}/evaluation`
export const predictionsRawPath = (instanceId: string) => `/instances/${instanceId}/predictions`
export const tablesRawPath = (instanceId: string) => `/instances/${instanceId}/tables`
export const evaluationRawPath = (instanceId: string, evaluationId: string) => `/instances/${instanceId}/evaluation/${evaluationId}`
export const integrationsRawPath = (instanceId: string) => `/instances/${instanceId}/integrations`
export const tablesCreateEmptyTableRawPath = (instanceId: string) => `/instances/${instanceId}/tables/empty`

export const instanceOverviewFileUploadRawPath = (instanceId: string, phase: string) => `/instances/${instanceId}/overview/${phase}`
export const instanceOverviewFileUploadPath = (instanceId: string, phase: string) => instanceOverviewFileUploadRawPath(encodeURIComponent(instanceId), phase)
export const tablesFileUploadRawPath = (instanceId: string, phase: string) => `/instances/${instanceId}/tables/${phase}`
export const tablesFileUploadPath = (instanceId: string, phase: string = 'upload') => tablesFileUploadRawPath(encodeURIComponent(instanceId), phase)
export const tablesCreateEmptyTable = (instanceId: string) => tablesCreateEmptyTableRawPath(instanceId)

export const instanceRedirectPath = (instanceId: string) => instanceRedirectRawPath(encodeURIComponent(instanceId))
export const instanceOverviewPath = (instanceId: string) => instanceOverviewRawPath(encodeURIComponent(instanceId))
export const instanceSettingsPath = (instanceId: string) => instanceSettingsRawPath(encodeURIComponent(instanceId))
export const instanceEvaluationPath = (instanceId: string) => instanceEvaluationRawPath(encodeURIComponent(instanceId))
export const instanceGetStartedPath = (instanceId: string) => instanceGetStartedRawPath(encodeURIComponent(instanceId))
export const predictionsPath = (instanceId: string) => predictionsRawPath(encodeURIComponent(instanceId))
export const tablesPath = (instanceId: string) => tablesRawPath(encodeURIComponent(instanceId))
export const evaluationPath = (instanceId: string, evaluationId: string) => evaluationRawPath(encodeURIComponent(instanceId), encodeURIComponent(evaluationId))
export const integrationsPath = (instanceId: string) => integrationsRawPath(encodeURIComponent(instanceId))

export const teamsPath = `/teams`
export const createTeamPath = `/teams/create`
export const teamOverviewRawPath = (teamId: string) => `/teams/${teamId}/overview`
export const teamSettingsRawPath = (teamId: string) => `/teams/${teamId}/settings`

export const teamOverviewPath = (teamId: string) => teamOverviewRawPath(encodeURIComponent(teamId))
export const teamSettingsPath = (teamId: string) => teamSettingsRawPath(encodeURIComponent(teamId))

export const invoicesPath = `/invoices`
