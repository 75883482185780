import React, { useState } from 'react'
import { Modal, InputNumber, Form, Select, Col, Row, Divider, Tooltip, Radio } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import _ from 'lodash'

const { Option } = Select
interface Props {
  visible: boolean
  handleSubmit: any
  cancelModal: any
  schema: any
}

interface FormValues {
  tableName: string,
  targetColumn: string,
  inputColumns: string[],
  successCaseSavings: string,
  testSize: string,
  unit: string,
  errorCaseCost: string,
  casesPerInterval: string
}

const initialState: FormValues = { 
  tableName: '',
  targetColumn: '',
  testSize: 'S',
  inputColumns: [],
  successCaseSavings: '',
  unit: 'min',
  errorCaseCost: '',
  casesPerInterval: ''
}

const CreateEvaluationModal: React.FC<Props> = props => {

  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState(initialState)
  const [sendingForm, setSendingForm] = useState(false)

  const s = props.schema?.schema
  const tables = _.orderBy(_.keys(s), item => item.toLowerCase())
  const columns: string[] = s ? _.orderBy(_.keys(s[formValues.tableName]?.columns || []), item => item.toLowerCase()) : []
  const possibleInputColumns = formValues.targetColumn ? columns.filter(e => e !== formValues.targetColumn) : []

  const onFormChange = (values: any) => {
    const key = _.first(_.keys(values))
    if (key && key === 'tableName') {
      setFormValues({ ...formValues, [key]: values[key], targetColumn: '', inputColumns: [] })
      form.setFieldsValue({ targetColumn: '', inputColumns: [] })
    } else if (key && key === 'targetColumn') {
      const inputCols = columns.filter((ic: string) => ic !== values.targetColumn) || []
      setFormValues({ ...formValues, [key]: values[key], inputColumns: inputCols })
      form.setFieldsValue({ inputColumns: inputCols })
    }
    else if (key) setFormValues({ ...formValues, [key]: values[key] })
  }

  const handleSubmit = async () => {
    setSendingForm(true)
    await props.handleSubmit(formValues)
    form.resetFields()
    setFormValues(initialState)
    setSendingForm(false)
    props.cancelModal()
  }

  const exitModal = () => {
    if (!sendingForm) {
      form.resetFields()
      setFormValues(initialState)
      props.cancelModal()
    }
  }
  

  return (
    <Modal
      title="Create new evaluation"
      centered
      visible={props.visible}
      onCancel={exitModal}
      onOk={() => form.validateFields().then(() => handleSubmit()).catch(() => null)}
      okText="Start evaluation"
      confirmLoading={sendingForm}
    >
      <StyledForm
        form={form}
        initialValues={initialState}
        onValuesChange={(values) => onFormChange(values)}
      >
        <StyledText>
          Dataset and prediction target 
          <Tooltip placement="top" title="Select the dataset, and the prediction target column for evaluation. For the best results, the target column must contain categorical values.">
            <StyledIcon />
          </Tooltip>
        </StyledText>
        <FormRow>
        <Col xs={10}>Dataset:</Col>
        <Col xs={14}>
          <Form.Item name="tableName" rules={[{ required: true, message: 'Required field'}]}>
            <StyledSelect disabled={sendingForm}>
              {tables.map(t => (
                <Option value={t} key={t}>{t}</Option>
              ))}
            </StyledSelect>
          </Form.Item>
        </Col>
        </FormRow>
        <FormRow>
          <Col xs={10}>Prediction target column:</Col>
          <Col xs={14}>
            <Form.Item name="targetColumn" rules={[{ required: true, message: 'Required field'}]}>
              <StyledSelect disabled={sendingForm}>
              {columns.map(c => (
                <Option value={c} key={c}>{c}</Option>
              ))}
              </StyledSelect>
            </Form.Item>
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={10}>Input columns:</Col>
          <Col xs={14}>
            <Form.Item name="inputColumns">
              <StyledSelect
                mode="multiple"
                allowClear
                disabled={sendingForm}
                maxTagCount='responsive'
              >
                {possibleInputColumns.map(c => <Option key={c} value={c}>{c}</Option>)}
              </StyledSelect>
            </Form.Item>
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={10}>
            Evaluation sample size
          </Col>
          <Col xs={14}>
            <Form.Item name="testSize">
              <Radio.Group disabled={sendingForm}>
                <Row><Radio value="S">Small - Quickest, but less accurate</Radio></Row>
                <Row><Radio value="M">Medium</Radio></Row>
                <Row><Radio value="L">Large - Slowest, but most accurate</Radio></Row>
              </Radio.Group>
            </Form.Item>
          </ Col>
          </FormRow>
        <Divider />
        <FormRow>
          <Col xs={16}>
            
          </Col>
        </FormRow>
        <StyledText>
          Automation use case details
          <Tooltip placement="top" title="These details are used to find you the best parameters for predictions. They are at the moment required fields, feel free to invent some crazy numbers to see how Aito works!">
            <StyledIcon />
          </Tooltip>
        </StyledText>
        <FormRow>
          <Col xs={12}>Savings per automated case:</Col>
          <Col xs={5}>
            <Form.Item name="successCaseSavings" rules={[{ required: true, message: 'Field must be a number' }]}>
              <InputNumber disabled={sendingForm} />
            </Form.Item>
          </Col>
          <Col xs={2}/>
          <Col xs={5}>
            <Form.Item name="unit" rules={[{ required: true, message: 'Field must be a number' }]}>
              <StyledSelect disabled={sendingForm}>
                <Option value="min">min</Option>
                <Option value="h">h</Option>
                <Option value="$">$</Option>
                <Option value="€">€</Option>
              </StyledSelect>
            </Form.Item>
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={12}>Cost of fixing one error case:</Col>
          <Col xs={5}>
            <Form.Item name="errorCaseCost" rules={[{ required: true, message: 'Field must be a number' }]}>
              <InputNumber disabled={sendingForm} />
            </Form.Item>
          </Col>
          <Col xs={2} />
          <Col xs={5}>
            <StyledSelect value={formValues.unit} disabled/>
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={12}>Cases per month:</Col>
          <Col xs={12}>
            <Form.Item name="casesPerInterval" rules={[{ required: true, message: 'Field must be a number' }]}>
              <InputNumber disabled={sendingForm} />
            </Form.Item>
          </Col>
        </FormRow>
      </StyledForm>
      <Divider />
      <p>
        The time needed to complete the evaluation depends on the size
        and complexity of your dataset. Durations of about 10 minutes are typical.
      </p>
    </Modal>
  )
}

const StyledIcon = styled(InfoCircleOutlined)`
  margin: 2px 0px 0px 10px;
`

const StyledSelect = styled(Select)`
  width: 100%
`

const StyledText = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`

const StyledForm = styled(Form)<SpaceProps>`
  margin-top: 20px;
  ${space};
`

const FormRow = styled(Row)<SpaceProps>`
  margin-bottom: 20px;
  ${space};
`

export default CreateEvaluationModal