const clientConfig = window.AITO_CLIENT_CONFIG || {}

const firstOf = (...values) => {
  return values
    .filter(v => v)
    .filter(s => String(s).trim() !== '')
    .shift()
}

const firstFromMap = (key, mapsOfValues = [clientConfig, process.env]) => {
  const value = firstOf(
    ...mapsOfValues
        .filter(mov => mov)
        .map(mov => mov[key])
    )
  return value
}

const config =  {
  aito: {
    applicationBaseUri: String(firstFromMap('REACT_APP_BASE_URL')),
    instanceDomain: String(firstFromMap('REACT_APP_INSTANCE_DOMAIN')),
  },
  auth0: {
    scope: 'openid',
    domain: String(firstFromMap('REACT_APP_AUTH0_DOMAIN')),
    clientID: String(firstFromMap('REACT_APP_AUTH0_CLIENT_ID')),
    responseType: 'token'
  },
  development: Boolean(firstFromMap('REACT_APP_DEVELOPMENT')),
  stripe: {
    publishableApiKey: String(firstOf(firstFromMap('STRIPE_PUBLISHABLE_KEY'), firstFromMap('REACT_APP_STRIPE_PUBLISHABLE_KEY'))),
  },
}

export default config
