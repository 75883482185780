import React from 'react'
import styled from 'styled-components'
import { ColorProps, color, SpaceProps, space, TypographyProps, typography, LayoutProps, layout } from 'styled-system'
import { ReactComponent as NotFoundIcon } from '../svg-assets/404-icon.svg'
import { Flex } from '@rebass/grid'
import { Heading } from '../component'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../svg-assets/aito-logo.svg'

const NotFound: React.FC = () => {
  return (
    <ContainerDiv width={1} flexDirection="column" justifyContent="center" alignItems="center" bg="white">
      <AitoLogo size={[100, 140]} />
      <StyledNotFoundIcon mb={4}/>
      <PageHeader fontWeight="bold" color="mullWine" mb={3}>Page not found!</PageHeader>
      <p>Sorry, but the page you were looking for could not be found.</p>
      <p>
        You can return to our <Link to="/">front page</Link>, or
        <a href="mailto:support@aito.ai"> drop us a line</a> if you can't find what you're looking for.
      </p>
    </ContainerDiv>
  )
}

const ContainerDiv = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`
const AitoLogo = styled(Logo)<LayoutProps>`
  ${layout};
`
const StyledNotFoundIcon = styled(NotFoundIcon)<SpaceProps>`
  ${space};
`
const PageHeader = styled(Heading)<ColorProps | TypographyProps | SpaceProps>`
  ${color};
  ${typography};
  ${space};
`
export default NotFound
