import React, { useCallback } from 'react'
import InstanceTemplate from './InstanceTemplate'
import { Heading, InstanceTypeTag, ApiKeyHolder } from '../../component'
import { Flex, Box } from '@rebass/grid'
import { Card, Tag as AntTag, Input, Spin, Upload, Progress } from 'antd'
import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dispatch, bindActionCreators } from 'redux'
import { LineChart, XAxis, Tooltip, YAxis, Line, CartesianGrid, ResponsiveContainer } from 'recharts'

import { theme, media } from '../../styles/theme'
import { Link, RouteComponentProps } from 'react-router-dom'
import { ApiKey } from '../../types/ApiKey'
import { AppState } from '../../ducks'
import { getInstance, InstanceStatus } from '../../ducks/instances'
import { startEditableFileUpload, setFileSchema, clearJob, InstanceJobStatus } from '../../ducks/jobs'
import { InstanceDetailsObject } from '../../types/InstanceDetails'
import { ReactComponent as ErrorIcon } from '../../svg-assets/404-icon.svg'
import { hostnameToUrl, urlToString } from '../../utils/hostnameToUrl'
import { DateLike, InstanceState, InstanceType } from 'api'
import FileUploadModal, { CreateTableEvent }  from '../../component/FileUploadModal'
import { UploadChangeParam } from 'antd/lib/upload'
import { InboxOutlined } from '@ant-design/icons'
import { FileUploadState } from '../../types/FileUploadState'
import InfoTooltip from '../../component/InfoTooltip'
import { instanceOverviewPath } from '../../route'
import { ApiSample, DataSample } from 'api/schema/metrics'
import { format } from 'date-fns'

type QueryUsage = { time: string, ['API calls']: number }
type DataUsage = { time: string, GB: number } | { time: string, MB: string}

const toMbOrGb = (bytes: number): string => {
  if (bytes < 1000*1000*1000) {
    return (bytes / (1000*1000)).toFixed(0) + 'MB'
  } else {
    return (bytes / (1000*1000*1000)).toFixed(1) + 'GB'
  }
}

const fillDataUsage = (data: DataSample[], now: Date): DataUsage[] => {
  let key: 'GB' | 'MB' = 'GB'
  let scale = 1000*1000*1000 // SI GB

  const maxDisk = _.maxBy(data, 'indexedData')?.indexedData
  if ((maxDisk || 0) < 1000*1000*1000) {
    scale = 1000*1000 // SI MB
    key = 'MB'
  }
  const reverseData = data.reverse()

  const metricsData: DataUsage[] = []
  for (let i = 0; i < 31; i++) {
    const t = new Date(now)
    t.setUTCHours(0, 0, 0, 0)
    t.setUTCDate(t.getUTCDate() - i)
    const closestSample = reverseData.find(({ at }) => DateLike.toDate(at) <= t)
    metricsData.push({
      time: format(t, 'dd.MM'),
      [key]: (closestSample?.indexedData || 0) / scale,
    } as DataUsage)
  }

  return metricsData.reverse()
}

const fillApiUsage = (data: ApiSample[], now: Date, resetDate: number): QueryUsage[] => {
  const metricsData: QueryUsage[] = []
  for (let i = 31; i --> 0; ) {
    const t = new Date(now)
    t.setUTCHours(0, 0, 0, 0)
    t.setUTCDate(t.getUTCDate() - i)
    const sample = data.find(({ at }) => DateLike.toDate(at).valueOf() === t.valueOf())
    metricsData.push({
      time: format(t, 'dd.MM'),
      'API calls': sample?.count || 0,
    } as QueryUsage)
  }

  return metricsData
}

const countQuota = (data: ApiSample[], now: Date, resetDate: number): number => {
  const since = new Date(now)
  since.setUTCDate(resetDate)
  since.setUTCHours(0, 0, 0, 0)
  if (now.getUTCDate() < resetDate) {
    since.setUTCMonth(since.getUTCMonth() - 1)
  }

  const queries = data.reduce((acc, { at, count }) => {
    if (DateLike.toDate(at) >= since) {
      return acc + count
    } else {
      return acc
    }
  }, 0)

  return queries
}

const makeResetDate = (now: Date, offset: number): Date => {
  const date = new Date(now)
  date.setUTCHours(0, 0, 0, 0)
  const nowOffset = date.getUTCDate() - 1
  if (nowOffset > offset) {
    date.setUTCMonth(date.getUTCMonth() + 1)
  }
  date.setUTCDate(offset + 1)
  return date
}

const UsageChart: React.FC<{
  metricsData: QueryUsage[] | DataUsage[]
}> = ({ metricsData }) => {
  const key = ['GB', 'MB', 'API calls'].find((k) => k in metricsData[0])

  return (
    <ResponsiveContainer width="100%" aspect={16/5}>
      <LineChart data={metricsData}>
        <YAxis orientation="right" tickLine={false} stroke={theme.colors.scorpion.lighter} tick={{ fill: theme.colors.scorpion.light }} />
        <XAxis dataKey="time" tickLine={false} stroke={theme.colors.scorpion.lighter} tick={{ fill: theme.colors.scorpion.light }} />
        <Tooltip />
        <CartesianGrid stroke={theme.colors.scorpion.lighter} vertical={false} />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey={key}
          stroke={theme.colors.jade.medium}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

interface RouteParam {
  instanceId?: string
}

interface Props extends RouteComponentProps<RouteParam>, ReturnType<typeof mapDispatchToProps> {
  instanceId: string,
  instances: { [k: string]: InstanceStatus },
  apiKeys: { [k: string]: ApiKey[] },
  userId: string,
  loading: boolean,
  error?: Error,
  match: any
  jobs: Record<string, InstanceJobStatus>
}

interface State {
  fileUploadJobId?: string
}

const pollInterval = 3000
const slackHref = 'https://aitoai.slack.com/join/shared_invite/zt-b3yfk345-sF46techNLhgzcLfJIC5pg#/'
const docsHref = 'https://aito.ai/docs/'

const FileUploadCard: React.FC<{
  onFile: (file: File) => void
}> = ({ onFile }) => {
  const onFileSelected = useCallback((event: UploadChangeParam) => {
    if (event.file) {
      onFile(event.file as unknown as File)
    }
  }, [onFile])

  const tooltipText = 'The CSV columns will be converted to aito\'s data format. Aito supports text in several languages as well as textual labels and numeric data. Other kinds of data, such as dates, will be treated as text.'

  const title = (
    <div>
      Upload CSV file
      <InfoTooltip text={tooltipText} />
    </div>
  )

  return (
    <StyledCard title={title}>
      <Upload.Dragger
        name="file"
        onChange={onFileSelected}
        accept=".csv"
        beforeUpload={() => false}
        fileList={[]}
      >
        <Inbox />
        <p>Click or drag a CSV file to this area to upload</p>
      </Upload.Dragger>
    </StyledCard>
  )
}

const Inbox = styled(InboxOutlined)`
  font-size: 40px;
  color: ${theme.colors.jade.medium};
`

class InstanceDetails extends React.Component<Props, State> {
  private interval: number | null = null

  state: State = {}

  async componentDidMount() {
    const { instanceId } = this.props.match.params
    const { userId } = this.props
    if (instanceId) {
      this.props.getInstance(instanceId, userId)
      this.startPolling()
    }
  }

  componentWillUnmount() {
    this.stopPolling()
  }

  private startPolling = () => {
    if (this.interval === null) {
      this.interval = setInterval(this.poll, pollInterval)
    }
  }

  private stopPolling = () => {
    if (this.interval !== null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  private poll = () => {
    const { userId, instances } = this.props
    const instanceId = this.props.match.params.instanceId || this.props.instanceId
    const { instance } = instances[instanceId] || { instance: null }
    let isDonePolling = true

    const pollingStates: InstanceState[] = ['CREATING', 'UPDATING']

    if (instanceId && (!instance || pollingStates.includes(instance.state))) {
      isDonePolling = false
      this.props.getInstance(instanceId, userId)
    }
    if (isDonePolling) {
      window.analytics.track('Instance ready', { instanceId })
      window.analytics.page('Instance details') // notify page change after Instance ready to trick appcues
      this.stopPolling()
    }
  }

  onFile = async (file: File): Promise<void> => {
    const instanceId = this.props.match.params.instanceId || this.props.instanceId
    if (instanceId && this.props.startEditableFileUpload) {
      const jobId = await this.props.startEditableFileUpload(instanceId, file)
      if (jobId) {
        this.setState({ fileUploadJobId: jobId })
      }
    }
  }

  onCloseUploadModal = () => {
    // Remove "#upload" and any other hash
    const { instanceId } = this.props.match.params
    this.props.history.replace(instanceOverviewPath(instanceId))
    const jobId = this.state.fileUploadJobId
    this.setState({
      fileUploadJobId: undefined
    })
    if (jobId) {
      this.props.clearJob(jobId)
    }
  }

  onCreateTable = async (event: CreateTableEvent) => {
    const instanceId = this.props.match.params.instanceId || this.props.instanceId
    if (instanceId && this.props.setFileSchema) {
      const apiKey = _.find(this.props.apiKeys[instanceId], k => k.type.includes('WRITE'))
      if (apiKey) {
        await this.props.setFileSchema(
          instanceId,
          event.jobId,
          event.tableName,
          event.hasHeader,
          event.columns,
          event.includeColumns,
          apiKey,
        )
        return // Don't close
      }
    }
    // This is unexpected
    this.onCloseUploadModal()
  }

  renderInstanceDetails = (instance: InstanceDetailsObject, apiKeys: ApiKey[], loading: boolean) => {
    const apiUrl: URL = hostnameToUrl(instance.hostname)
    const isCreating = instance.state === 'CREATING'
    const isDisabled = instance.state === 'DISABLED'
    const isUpdating = instance.state === 'UPDATING'

    let modalState: FileUploadState | undefined

    if (this.state.fileUploadJobId) {
      const job = this.props.jobs[this.state.fileUploadJobId]
      if (job && job.type === 'csv-upload-and-edit') {
        modalState = job.state
      }
    }

    const now = new Date()
    let quotaResetsAt: string | undefined
    let resetDate: number = 1
    let monthlyLimit: number = 1
    let queriesByDate: QueryUsage[] = []
    let diskByDate: DataUsage[] = []
    let queryQuota: number = 0
    let queriesThisPeriod: number = 0
    let diskQuota: number = 0
    let currentDiskUsage: number = 0
    let maxDiskUsage: number = 0
    try {
      const offset = Number(instance?.limits?.quota?.offset)
      monthlyLimit = Number(instance?.limits?.quota?.limit)
      quotaResetsAt = format(makeResetDate(now, offset), `MMMM do p OOOO`)
      resetDate = offset + 1
      queriesByDate = fillApiUsage(instance.queryUsage, now, resetDate)
      queriesThisPeriod = countQuota(instance.queryUsage, now, resetDate)
      queryQuota = Math.floor(100 * (queriesThisPeriod / monthlyLimit))
      diskByDate = fillDataUsage(instance.diskUsage, now)
      currentDiskUsage = _.first(instance.diskUsage)?.indexedData || 0
      maxDiskUsage = instance.storage
      diskQuota = Math.floor(100 * (currentDiskUsage / maxDiskUsage))
    } catch (e) {}

    const queryProgressColor = queryQuota < 80 ? theme.colors.jade.medium : theme.colors.sunsetOrange.medium
    const diskProgressColor = diskQuota < 80 ? theme.colors.jade.medium : theme.colors.sunsetOrange.medium

    return (
      <Flex flexWrap="wrap">
        <Box width={1} p={2}>
          <StyledCard>
            <Flex flexWrap="wrap" alignItems="center" mb={3}>
              <Box>
                <Heading>{instance.name}</Heading>
              </Box>
              <Box>
                <InstanceTypeTag ml={2} instanceType={instance.type} />
              </Box>
              {loading && <Box ml="auto"><Spin/></Box>}
            </Flex>

            <Flex flexWrap="wrap">
              <Box width={1 / 3}>
                <InfoLabel color="scorpion.light">
                  Team
                </InfoLabel>
                <Info color="silverTree.darker" fontWeight="bold">
                  {instance.teamName || 'Personal instance'}
                </Info>
              </Box>

              <Box width={1 / 3}>
                <InfoLabel color="scorpion.light">
                  Owned by
                </InfoLabel>
                <Info color="silverTree.darker" fontWeight="bold">
                  {instance.ownerName}
                </Info>
              </Box>
              <Box width={1 / 3}>
                <InfoLabel color="scorpion.light">
                  Current State
                </InfoLabel>
                <Info color="silverTree.darker" fontWeight="bold">
                  {instance.state}
                </Info>
              </Box>
            </Flex>
            { instance.type === InstanceType.Sandbox &&
              <CardWarningBanner mx="-24px" px="24px" mb="-24px" mt="12px" py="12px" backgroundColor={theme.colors.sunsetOrange.light}>
                NOTE: Your free Sandbox instance will be removed after 7 days of
                inactivity. <UnderlinedLink to="settings">Upgrade it to a paid
                tier</UnderlinedLink> to avoid losing your work.
              </CardWarningBanner>
            }
          </StyledCard>
        </Box>
        {isCreating || isDisabled ? null :

        <Box width={[1, 1 / 2]} p={2}>
          <StyledCard title="Overview">
            <Box>
              <CardLabel color="scorpion.dark" fontWeight="bold" mb={2}>
                API URL
              </CardLabel>
              <StyledInput size="large" value={urlToString(apiUrl, false)} mt={2} mb={4} />
              {apiKeys
                .sort((a, b) => a.type.localeCompare(b.type))
                .filter((apiKey: ApiKey) => !apiKey.disabled)
                .map((apiKey: ApiKey) => {
                  const isReadWriteKey = apiKey.type.includes('WRITE')
                  const tagColor = isReadWriteKey ?
                    theme.colors.carnationPink.medium : theme.colors.jade.medium
                  const tagText = isReadWriteKey ?
                    'Read/Write' : 'Read only'
                  return (
                    <div key={apiKey.id}>
                      <CardLabel color="scorpion.dark" fontWeight="bold" mb={3}>
                        API key
                        <Tag fontSize={[0, 1]} ml={2} color={tagColor}>
                          {tagText}
                        </Tag>
                      </CardLabel>
                      {isUpdating && isReadWriteKey ?
                        <Info color="silverTree.darker" fontWeight="bold">
                          The instance is currently updating and in read-only mode
                        </Info>
                      :
                        <ApiKeyHolder
                          instanceId={instance.id}
                          keyId={apiKey.id}
                          description={apiKey.description}
                        />
                      }
                      {!apiKey.description ? null :
                      <ApiUrlInfo color="scorpion.light" fontSize={0} mb={3}>
                        {apiKey.description}
                      </ApiUrlInfo>}
                    </div>
                  )
                })}
            </Box>
          </StyledCard>
        </Box>}

        {isCreating || isDisabled ? null :
        <Box width={[1, 1 / 2]} p={2}>
          <FileUploadCard onFile={this.onFile} />
          {modalState && <FileUploadModal
            jobState={modalState}
            onCancel={this.onCloseUploadModal}
            onCreateTable={this.onCreateTable}
            history={this.props.history}
          />}
        </Box>}

        {isCreating || isDisabled || (_.isEmpty(instance.queryUsage) && _.isEmpty(instance.diskUsage)) ? null :
        <Box width={[1]} p={2}>
          <StyledCard title="Metrics">
            <Flex flexWrap="wrap">
              <Box width={[1, 1/ 2]} pr={[0, 4]}>
                <Flex justifyContent="space-between">
                  <CardLabel color="scorpion.dark" fontWeight="bold" mb={3}>
                    API calls
                    <InfoTooltip text="Total number of API calls to your Aito instance per day. Note that this includes all API calls; the ones you directly make, through the Python SDK, and those triggered by Console functions. Metrics are updated every few minutes."/>
                  </CardLabel>
                  <CardLabel color="scorpion.dark" fontWeight="normal" mb={3}>
                  </CardLabel>
                </Flex>
                <Box>
                  <UsageChart metricsData={queriesByDate || []} />
                </Box>
                <Progress strokeColor={queryProgressColor} percent={queryQuota} status="normal" />
                <CardLabel color="scorpion.dark" fontWeight="normal">
                  {queriesThisPeriod}/{monthlyLimit} API calls this period.
                  Next reset on {quotaResetsAt ? quotaResetsAt : ''}.
                </CardLabel>
              </Box>
              <Box width={[1, 1/ 2]} pl={[0, 4]}>
              <Flex justifyContent="space-between">
                <CardLabel color="scorpion.dark" fontWeight="bold" mb={3}>
                  Disk usage
                  <InfoTooltip text="Disk space currently in use today, and what was used at midnight the days before. The disk usage means the data you have uploaded to your instance plus the index that Aito generates and maintains. Metrics are updated every few minutes." />
                </CardLabel>
                <CardLabel color="scorpion.dark" fontWeight="bold" mb={3}>
                </CardLabel>
                </Flex>
                <Box>
                  <UsageChart metricsData={diskByDate || []} />
                </Box>
                <Progress strokeColor={diskProgressColor} percent={diskQuota} status="normal" />
                <CardLabel color="scorpion.dark" fontWeight="normal">
                  {toMbOrGb(currentDiskUsage)}/{toMbOrGb(maxDiskUsage)} of disk
                  quota used. This includes the stored data in all tables and
                  their indexes.
                </CardLabel>
              </Box>
            </Flex>
          </StyledCard>
        </Box>}

        {!isCreating ? null :
          <Box width={1} p={2}>
            <StyledCard>
              <p>
                The instance is being created. Setting everything up correctly will take a few minutes. You will
                receive an email once your instance is ready for use. If you have questions or suggestions, please
                feel free to
                <a target="_blank" rel="noreferrer noopener" href={slackHref}> contact us on Slack</a>.
              </p>
              <p>
                Thank you for your patience!
              </p>
              <p>
                In the mean time, our <a target="_blank" rel="noreferrer noopener" href={docsHref}>guides and
                tutorials</a> can prepare you for what's in store.
              </p>
              <Flex flexDirection="column" justifyContent="center" alignItems="center" >
                <Spin size="large"/>
              </Flex>
            </StyledCard>
          </Box>
        }

        {!isDisabled ? null :
          <Box width={1} p={2}>
            <StyledCard>
              <p>Your instance is off-line. Please contact us if you need it back on-line.</p>
            </StyledCard>
          </Box>
        }
      </Flex>
    )
  }

  handleErrors(instance?: InstanceDetailsObject, error?: any) {
    if (error) return  <ErrorContainer><StyledErrorIcon mb={4}/></ErrorContainer>
    if (!instance) {
      return (
        <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
          <Spin size="large"/>
        </SpinContainer>
      )
    }
    return null
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances, apiKeys } = this.props
    const { instance, loading } = instances[instanceId] || { instance: null, loading: true }
    const { error } = instances[instanceId] || { error: undefined }
    const instanceName = !!instance ? instance.name : ''
    const keys = apiKeys[instanceId] || []

    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const isOwner = instance ? instance.ownerId === this.props.userId : false
    const state = !!instance ? instance.state : 'CREATING'

    return (
      <InstanceTemplate
        instanceId={instanceId}
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        activePage="overview"
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        {this.handleErrors(instance, error) ||
          (instance ? this.renderInstanceDetails(instance, keys, loading) : null)}
      </InstanceTemplate>
    )
  }
}

const Tag = styled(AntTag)<TypographyProps | SpaceProps>`
  ${typography};
  ${space};
  display: inline;
  line-height: unset;
`
const StyledCard = styled(Card)`
  height: 100%;
  .ant-card-body {
     @media ${media.sm} {
      height: 80%;
    }
  }
`
const InfoLabel = styled.span<ColorProps>`
  ${color};
  display: block;
`
const Info = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const CardLabel = styled.span<ColorProps | TypographyProps | SpaceProps>`
  ${color};
  ${space};
  ${typography};
  display: block;
`
const StyledInput = styled(Input)<SpaceProps>`
  ${space};
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px ${theme.colors.scorpion.lighter};
`
const ApiUrlInfo = styled.span<ColorProps | TypographyProps | SpaceProps>`
  ${color};
  ${space};
  ${typography};
  display: block;
  font-style: italic;
`
const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`
const StyledErrorIcon = styled(ErrorIcon)<SpaceProps>`
  ${space};
`

const CardWarningBanner = styled.div<SpaceProps & ColorProps>`
  ${space};
  ${color};
`

const UnderlinedLink = styled(Link)`
  color: black;
  text-decoration: underline;
`

const ErrorContainer = styled(Flex)<ColorProps>`
  ${color};
  justify-content: center;
  align-items: center;
  height: 100%;
`
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    startEditableFileUpload,
    setFileSchema,
    clearJob,
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    apiKeys: state.instances.apiKeys,
    userId: state.auth.userProfile.id,
    jobs: state.jobs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstanceDetails)
