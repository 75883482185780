import _ from 'lodash'
import React from 'react'
import { Card, Input, message, Modal, notification, Spin, Form } from 'antd'
import styled from 'styled-components'
import { Dispatch, bindActionCreators } from 'redux'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { Flex, Box } from '@rebass/grid'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { PaymentMethod } from '@stripe/stripe-js'
import { format, setISODay } from 'date-fns'

import { Button, InstanceTypeTag, CreditCard, AddOrChangePaymentModal, ModifyInstanceModal } from '../../component'
import { theme } from '../../styles/theme'

import { deleteInstance, getInstance, InstanceStatus, getUserInstances, UserInstancesState, modifyInstanceSubscription } from '../../ducks/instances'
import { getUserCreditCards, getUserDefaultCardId } from '../../ducks/user'
import { AppState } from '../../ducks'
import InstanceTemplate from './InstanceTemplate'
import { instancesPath } from '../../route'
import { hostnameToUrl, urlToString } from '../../utils/hostnameToUrl'
import { ConsoleUser as User } from '../../types/User'
import { InstanceProduct } from 'api'
import { getProducts } from '../../utils/api/paymentsApi'
import { QueryLimits } from 'api/schema/QueryLimits'

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  instances: { [k: string]: InstanceStatus },
  userInstances: UserInstancesState
  creditCards: PaymentMethod[],
  defaultCardId: string,
  userProfile: User,
  match: any
}

interface State {
  paymentModalVisible: boolean,
  modifyInstanceModalVisible: boolean,
  modifyResultModalVisible: boolean,
  modifyStatus: string,
  products: InstanceProduct[]
}

function getDayOfWeekForISODate(dow: number, plural: boolean = true): string {
  const modifiedDate = setISODay(new Date(), dow)
  return format(modifiedDate, 'EEEE') + (plural ? 's' : '')
}

function getQueryLimitText(limits?: QueryLimits): string {
  if (!limits?.quota?.limit) {
    return 'Could not fetch the limit'
  }
  return `${limits.quota.limit.toLocaleString('fi-FI')} / ${_.toLower(limits.quota.period)}`
}

class InstanceSettings extends React.Component<Props, State> {

  readonly state: State = {
    paymentModalVisible: false,
    modifyInstanceModalVisible: false,
    modifyResultModalVisible: false,
    modifyStatus: '',
    products: []
  }

  async componentDidMount() {
    window.analytics.page('Instance settings')
    const { instanceId } = this.props.match.params
    const { userProfile, creditCards, defaultCardId } = this.props
    if (this.props.userProfile) {
      this.props.getUserInstances(this.props.userProfile.id)
    }
    if (instanceId) {
      this.props.getInstance(instanceId, userProfile.id)
    }
    await this.getUserProducts()
    if (!defaultCardId || defaultCardId.trim().length === 0) await this.props.getUserDefaultCardId()
    if (!creditCards || creditCards.length === 0) await this.props.getUserCreditCards()
  }

  async getUserProducts() {
    try {
      const products = await getProducts()
      await this.setState({ products })
    } catch (e) {
      message.error('Getting user products failed')
    }
  }

  getInstanceState(instanceId: string): InstanceStatus {
    return this.props.instances[instanceId]
  }

  handleDeleteInstance = async () => {
    const { instanceId } = this.props.match.params
    const { instances } = this.props
    const { instance = null } = instances[instanceId] || {}
    const instanceType = instance?.type

    const { confirm } = Modal
    const deleteImmediate = instanceType === 'SANDBOX'

    const deleteConfirmation = {
      before: {
        title: `Are you sure to delete this instance?`,
        content: 'Once you delete an instance, there is no turning back. Please be certain.',
        okText: 'Delete',
        cancelText: 'Cancel',
      },
      after: {
        message: 'Instance deletion in progress',
        description: 'Instance deletion in progress. Data and resources are being terminated. Don\'t worry, we\'ll make sure everything is squeaky clean.',
      }
    }
    const cancelSubscriptionConfirmation = {
      before: {
        title: `Are you sure to end the subscription?`,
        content: 'Ending the subscription means the instance is deleted at the end of the current period. Please be certain.',
        okText: 'End subscription',
        cancelText: 'Cancel',
      },
      after: {
        message: 'Subscription ended',
        description: 'The subscription was cancelled, and the instance will be deleted at the end of the period.',

      }
    }
    const extnds = deleteImmediate ? deleteConfirmation : cancelSubscriptionConfirmation

    confirm({
      ...(extnds.before),
      okType: 'danger',
      centered: true,
      onOk: async () => {
        window.analytics.track('Instance delete', { instanceId })
        await this.props.deleteInstance(instanceId)
        const { error } = this.getInstanceState(instanceId)
        if (error) {
          message.error(error.message)
        } else {
          notification.success({
            duration: 5,
            ...(extnds.after)
          })
          this.props.history.push(instancesPath)
        }
      },
    })
  }

  togglePaymentModal = () => {
    this.setState({ paymentModalVisible: true })
  }

  handleUpdatePayment = () => {
    this.setState({ paymentModalVisible: false })
    this.props.getUserCreditCards()
  }

  closePaymentModal = () => {
    this.setState({ paymentModalVisible: false })
  }

  openModifyInstanceModal = () => {
    this.setState({ modifyInstanceModalVisible: true })
  }

  closeModifyInstanceModal = () => {
    this.setState({ modifyInstanceModalVisible: false })
  }

  closeModifyResultModal = () => {
    this.setState({ modifyResultModalVisible: false })
  }

  handleModifyInstance = async (body: any, modifyStatus: string) => {
    const { instanceId } = this.props.match.params
    await this.props.modifyInstanceSubscription(instanceId, body)
    const { error } = this.getInstanceState(instanceId)
    if (error) {
      message.error(error.message)
    } else {
      this.setState({
        modifyInstanceModalVisible: false,
        modifyResultModalVisible: true,
        modifyStatus
      })
    }
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances, creditCards, userInstances, defaultCardId } = this.props
    const { paymentModalVisible, modifyInstanceModalVisible, modifyResultModalVisible, modifyStatus } = this.state
    const { instance = null, loading = true } = instances[instanceId] || {}
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const instanceType = !!instance ? instance.type : ''
    const userProfile = this.props.userProfile || {id: '', fullName: '', email: ''}
    const isOwner = instance ? instance.ownerId === userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'
    const modifyResultModalTitle = modifyStatus === 'upgrade' ? "Instance upgrade done!" : "Instance downgrade pending!"

    const creditCard = creditCards && creditCards?.find(cc => cc.id === defaultCardId)

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12, offset: 2 }
      },
    }

    const instanceTypeLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 6, offset: 4 }
      },
    }

    const instanceStorageLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
        md: { span: 12, offset: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 2 },
        md: { span: 8 }
      },
    }

    const renderCreditCard = (paymentMethod: PaymentMethod[]) => {
      const cards = paymentMethod.filter(pm => !!pm.card).map(pm => {
        return {
          cardHolder: pm.billing_details.name,
          cardInfo: pm.card as PaymentMethod.Card
        }
      })

      return(
        <Flex flexWrap="wrap">
          {_.map(cards, (card, i) => {
            return (
              <CreditCard editable togglePaymentModal={this.togglePaymentModal} cardHolder={card.cardHolder} cardInfo={card.cardInfo} key={i} />
            )
          })}
        </Flex>
      )
    }

    return (
      <InstanceTemplate
        instanceId={instanceId}
        activePage="settings"
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        {!instance ?
          <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <Spin size="large"/>
          </SpinContainer>
          :
          <InstanceSettingsCard title="Settings" mb={3} extra={ loading && <Spin /> }>
            <SettingSectionLabel fontSize={3} color="scorpion.dark" fontWeight="bold">
              General settings
            </SettingSectionLabel>
            <StyledForm {...formItemLayout} labelAlign="left" colon={false}>
              <StyledFormItem label="Instance name" mb={3}>
                <Input size="large" defaultValue={instance.name} disabled />
                <SettingsDescriptionText fontSize={0} color="scorpion.light">
                  API URL: {instance ? urlToString(hostnameToUrl(instance.hostname), false) : 'N/A'}
                </SettingsDescriptionText>
              </StyledFormItem>

              <StyledFormItem label="Team" mb={3}>
                <Input size="large" defaultValue={instance.teamName} disabled />
                <SettingsDescriptionText fontSize={0} color="scorpion.light">
                  To change the team to which this instance belong, please
                  <a href="mailto:support@aito.ai"> contact us.</a>
                </SettingsDescriptionText>
              </StyledFormItem>

              <Flex width={[1, 1, 0.75]} justifyContent="spaceBetween">
                <Box width={1 / 2}>
                  <StyledFormItem {...instanceTypeLayout} label="Instance type" mb={3}>
                    <InstanceTypeTag instanceType={instance.type} />
                  </StyledFormItem>
                </Box>
                <Box width={1 / 2}>
                  <StyledFormItem {...instanceStorageLayout} labelAlign="right" label="Extra storage size" mb={3}>
                    <Input
                      size="large"
                      defaultValue={`${instance.extraStorage} GB`}
                      disabled
                    />
                  </StyledFormItem>
                </Box>
              </Flex>

              <StyledFormItem label="Instance version" mb={3}>
                <Input size="large" defaultValue={instance.applicationVersion} disabled />
                <SettingsDescriptionText fontSize={0} color="scorpion.light">
                  The version is updated automatically when a new version is made available.
                  {instance.updateWeekday ? ` Updates are performed on ${getDayOfWeekForISODate(instance.updateWeekday)} between 05:00 and 08:00 (UTC)`: ''}
                </SettingsDescriptionText>
              </StyledFormItem>

              {instance.limits?.quota?.limit ?
                <StyledFormItem label="Query limit" mb={3}>
                  <Input size="large" defaultValue={getQueryLimitText(instance.limits)} disabled />
                </StyledFormItem>
                : null
              }

              <StyledFormItem label="Payment method" mb={3}>
                {creditCards && creditCards.length > 0 && renderCreditCard(creditCards)}
                <SettingsDescriptionText fontSize={0} color="scorpion.light">
                  * Changing the payment method will change the method for all of the paid instances
                </SettingsDescriptionText>
              </StyledFormItem>
            </StyledForm>

            <SaveButtonContainerDiv mt={4} pt={3} flexWrap="wrap">
              <SaveButton mr={2} mt={2} disabled={loading}>Save changes</SaveButton>
              <StyledButton mr={2} mt={2} color="jade.medium" type="default" disabled={loading || instance.ownerId !== userProfile.id} onClick={this.openModifyInstanceModal}>
                Change subscription
              </StyledButton>
              <DeleteButton mt={2} danger disabled={loading || instance.ownerId !== userProfile.id} onClick={this.handleDeleteInstance}>
                {instanceType === 'SANDBOX' ? 'Delete instance' : 'Cancel subscription'}
              </DeleteButton>
            </SaveButtonContainerDiv>

            <AddOrChangePaymentModal
              visible={paymentModalVisible}
              onSubmit={this.handleUpdatePayment}
              onCancel={this.closePaymentModal}
              userInstances={userInstances.instances}
              isCreatingCard={false}
            />

            <ModifyInstanceModal
              modalVisible={modifyInstanceModalVisible}
              closeModal={this.closeModifyInstanceModal}
              handleModifyInstance={this.handleModifyInstance}
              instance={instance}
              products={this.state.products}
              creditCard={creditCard}
            />

            <Modal
              title={modifyResultModalTitle}
              footer={null}
              centered
              visible={modifyResultModalVisible}
            >
              <ModalText color="scorpion.medium" fontWeight="semibold">
                What now?
              </ModalText>
              {modifyStatus === 'upgrade' ? (
                <WhatNowList>
                  <li>
                    Your instance is now being updated, it can take up to 15 minutes for the changes to be applied. <a href="mailto:support@aito.ai">Contact us</a> in case of any questions.
                  </li>
                  <li>
                    {`Your subscription period has been changed and the new invoicing period starts today ${format(Date.now(), 'MMM dd yyyy')}.`}
                  </li>
                </WhatNowList>
              ) : (
                <WhatNowList>
                  <li>
                    Your instance will be downgraded when the current subscription period ends, in the meanwhile you can still use your instance as usual. <a href="mailto:support@aito.ai">Contact us</a> us in case of any questions.
                  </li>
                  <li>
                    The new charges will take effect at the start of the next subscription period.
                  </li>
                </WhatNowList>
              )}
              <Flex mt={3}>
                <Box mr={2}>
                  <Button onClick={this.closeModifyResultModal}>Back to settings</Button>
                </Box>
              </Flex>
            </Modal>
          </InstanceSettingsCard>
        }
      </InstanceTemplate>
    )
  }
}

const InstanceSettingsCard = styled(Card)<SpaceProps>`
  ${space};
`

const SettingSectionLabel = styled.p<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
`

const StyledForm = styled(Form)<SpaceProps>`
  ${space};
`

const SettingsDescriptionText = styled.span<TypographyProps | ColorProps>`
  ${color};
  ${typography};
  display: block;
  font-style: italic;
`

const SaveButtonContainerDiv = styled(Flex)`
  border-top: 1px solid ${theme.colors.scorpion.lighter};
`

const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const StyledButton = styled(Button)<ColorProps | SpaceProps>`
  ${color};
  ${space};
  border-color: ${theme.colors.jade.medium};
  box-shadow: none;
`

const DeleteButton = styled(Button)<ColorProps | SpaceProps>`
  ${color};
  ${space};
  box-shadow: none;
`
const SaveButton = styled(Button)<ColorProps | SpaceProps>`
  ${space};
`
const ModalText = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const WhatNowList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:before {
    content: "-";
    margin-right: 5px;
  }
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    deleteInstance,
    getUserCreditCards,
    getUserDefaultCardId,
    getUserInstances,
    modifyInstanceSubscription
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    userInstances: state.instances.userInstances,
    userProfile: state.auth.userProfile,
    creditCards: state.user.creditCards,
    defaultCardId: state.user.defaultCardId,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstanceSettings)
