import React from 'react'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import { Box, Flex } from '@rebass/grid'
import { Button } from './index'
import { Modal } from 'antd'
import 'react-multi-email/style.css'
import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'

interface Props {
  addMembersModalVisible: boolean,
  closeAddMembersModal: () => void,
  inviteeEmails: Array<string>,
  handleAddInviteeEmails: (emails: Array<string>) => void,
  handleAddMembersSubmit: () => void
}

const AddMemberModal: React.FC<Props> = props => {
  return (
    <Modal
      title="Add a member"
      footer={null}
      centered
      visible={props.addMembersModalVisible}
      onCancel={props.closeAddMembersModal}
    >
      <AddMemberModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
        Email
      </AddMemberModalLabel>
      <ReactMultiEmail
        placeholder="Enter the invitees email addresses"
        emails={props.inviteeEmails}
        onChange={(emails: Array<string>) => {
          props.handleAddInviteeEmails(emails)
        }}
        validateEmail={email => {
          return isEmail(email)
        }}
        getLabel={(
          email: string,
          index: number,
          removeEmail: (index: number) => void,
        ) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          )
        }}
      />
      <Flex mt={3} flexDirection="row">
        <Box mr={2}>
          <Button onClick={props.handleAddMembersSubmit}>Add members</Button>
        </Box>
        <Box ml={2}>
          <CancelButton color="scorpion.light" type="link" onClick={props.closeAddMembersModal}>Cancel</CancelButton>
        </Box>
      </Flex>
    </Modal>
  )
}

const AddMemberModalLabel = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const CancelButton = styled(Button)<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  ${space};
`

export default AddMemberModal
