import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Button, CodeSnippet } from './index'
import { stripIndent } from 'common-tags'
import { color, ColorProps, SpaceProps, space, TypographyProps, typography } from 'styled-system'
import { message, Select,  } from 'antd'
import { Flex, Box } from '@rebass/grid'
import { theme } from '../styles/theme'
import { AitoClient } from 'api'
const { Option } = Select

const generatePredictQuery = (tableName: string | undefined, rows: any, selectedColumn: string | undefined) => {
  if (!tableName) return null
  if (rows.length) {
    const randomRow = _.sample(rows)
    const randomRowKeys = _.sampleSize(_.keys(randomRow), 4)
    const rowToPredict = _.pick(randomRow, randomRowKeys)
    let columnToPredict = selectedColumn || ''
    if (!_.keys(randomRow).includes(columnToPredict)) columnToPredict = _.first(_.keys(randomRow)) || ''
    const whereColumns = _.omit(rowToPredict, [columnToPredict])

    const predictQuery = {
      from: tableName,
      where: whereColumns,
      predict: columnToPredict
    }
    return predictQuery
  } else {
    return null
  }
}

interface Props {
  instanceId: string
  aitoClient: AitoClient
  tables: string[]
  previewRows: any
  selected: string | undefined
  queryUpdated?: (query: string) => void
  onReady: () => void
}

interface State {
  predictQuery: any,
  loading: boolean,
  predictResult: any,
  selectedColumn: string | undefined
  predictColumn: string | null
}

class PredictQuerySender extends React.Component<Props, State> {
  public readonly state: State = {
    predictQuery: null,
    predictResult: null,
    loading: false,
    selectedColumn: _.first(_.keys(_.first(this.props.previewRows))),
    predictColumn: null
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { selected, previewRows } = props
    const { selectedColumn, predictColumn } = state
    if (!selected) {
      return { ...state, predictQuery: null }
    }
    if (!state.predictQuery || predictColumn !== selectedColumn) {
      let column = selectedColumn
      if (!_.keys(_.first(previewRows)).includes(selectedColumn || '')) {
        column = _.first(_.keys(_.first(props.previewRows)))
      }
      const predictQuery = generatePredictQuery(selected, previewRows, column || '')
      return { ...state, predictQuery, predictColumn: column, selectedColumn: column }
    }
    return state
  }

  public async componentDidMount() {
    const { selected, previewRows } = this.props
    const { selectedColumn } = this.state
    if (selected) await generatePredictQuery(selected, previewRows, selectedColumn)
    if (this.props.queryUpdated) this.props.queryUpdated(this.state.predictQuery)
  }

  public handlePredict = async (predictQuery: any) => {
    const { aitoClient } = this.props
    this.setState({ loading: true })
    const res = await aitoClient.predict(predictQuery)
    const predictResult = res.data.hits[0]
    this.setState({ loading: false, predictResult })
  }

  public handleReady = () => {
    this.props.onReady()
  }

  public handleSelectChange = async (e: any) => {
    await this.setState({ selectedColumn: e, predictResult: null })
  }

  public copyToClipboard = () => {
    const settings = this.props.aitoClient.settings

    const curl = `curl -X POST "${settings.baseURL}/_predict" \\\n\
      -H "accept: application/json" \\\n\
      -H "Content-Type: application/json" \\\n\
      -H "x-api-key: ${settings.key}" \\\n\
      -d ${JSON.stringify(JSON.stringify(this.state.predictQuery))}`

    const el = document.createElement('textarea')
    el.value = curl
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    message.info('Query copied to clipboard')
  }

  public render() {
    const { predictQuery, predictResult, loading } = this.state
    const responseExplain = predictResult
      ? `With probability of ${(predictResult.$p * 100).toFixed(0)}%, the predicted field "${predictResult.field}" has the value ${predictResult.feature}`
      : null

    const noDataText = (
      <NoDataText color="scorpion.light" my={3}>
        There's no data in this table, please try uploading another file.
      </NoDataText>
    )

    const optionKeys = _.keys(_.first(this.props.previewRows))
    const options = _.map(optionKeys, o => <Option value={o} key={o}>{o}</Option>)

    const content = (
      <Container flexWrap="wrap" mb={3}>
        <Box width={[1, 1 / 2]} pr={[0, 4]}>
          <h3>
            Select a field to predict &nbsp;
            <StyledSelect value={this.state.selectedColumn} onChange={this.handleSelectChange}>{options}</StyledSelect>
          </h3>
          <StyledText mb={3} fontStyle="italic" color="scorpion.medium">
            The other fields in this query are randomly picked from the data snapshot
          </StyledText>
          <QueryBox flexDirection="column" justifyContent="center" bg="nearWhite" p={2}>
            <CodeSnippet language="json">
              {stripIndent`
                  ${JSON.stringify(predictQuery, null, 2)}
                `}
            </CodeSnippet>
            <CurlButton
              bgcolor="transparent"
              color="carnationPink.dark"
              fontWeight="bolder"
              onClick={this.copyToClipboard}
            >
              Copy as Curl
            </CurlButton>
          </QueryBox>
          <StyledButton
            loading={loading}
            bgcolor="transparent"
            color="jade.medium"
            fontWeight="bolder"
            onClick={() => this.handlePredict(predictQuery)}
            m={2}
          >
            Execute query
          </StyledButton>
          {predictResult && <Button onClick={this.handleReady}>Learn more</Button>}
        </Box>
        {predictResult &&
        <Box width={[1, 1 / 2]} pl={[0, 4]}>
          <StyledText mt={[4, 0]} mb={4} color="mullWine" fontWeight="semibold" fontSize={2}>
            The query response:
          </StyledText>
          <QueryBox flexDirection="column" justifyContent="center" bg="nearWhite" p={2}>
            <CodeSnippet language="json">
              {stripIndent`
                ${JSON.stringify(predictResult, null, 2)}
              `}
            </CodeSnippet>
          </QueryBox>
          <StyledText mt={4} mb={3} color="mullWine" fontWeight="semibold" fontSize={2}>
            What the response means:
          </StyledText>
          <StyledText color="scorpion.medium">
            {responseExplain}
          </StyledText>
        </Box>
        }
      </Container>
    )

    return (
      predictQuery ? content : noDataText
    )
  }
}

const Container = styled(Flex)<ColorProps | SpaceProps>`
  ${color};
  ${space};
` as typeof Flex

const QueryBox = styled(Flex)<ColorProps | SpaceProps>`
  ${color};
  ${space};
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
`

const NoDataText = styled.p<ColorProps | SpaceProps>`
  ${color};
  ${space};
  text-align: center;
  font-style: italic;
`

const CurlButton = styled(Button)<ColorProps | SpaceProps | TypographyProps>`
  ${typography};
  ${color};
  ${space};
  border: 2px solid ${theme.colors.carnationPink.dark};
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
`

const StyledButton = styled(Button)<ColorProps | SpaceProps | TypographyProps>`
  ${typography};
  ${color};
  ${space};
  border: 2px solid ${theme.colors.jade.medium};
`

const StyledText: ExoticComponent<any> = styled.p<ColorProps | SpaceProps | TypographyProps>`
  ${color};
  ${space};
  ${typography};
`
const StyledSelect = styled(Select)`
  min-width: 200px;
`

export default PredictQuerySender
