import axios from 'axios'

export function getUserTeams(userId: string) {
  return axios.get(`/api/users/${userId}/teams`)
}

export function getUserInstances(userId: string) {
  return axios.get(`/api/users/${userId}/instances`)
}

export function getUserById(userId: string) {
  return axios.get(`/api/users/${userId}`)
}

export function leaveTeam(userId: string, teamId: string) {
  return axios.delete(`/api/users/${userId}/teams/${teamId}`)
}

export function updateUser(userId: string, body: {fullName: string}) {
  return axios.put(`/api/users/${userId}`, body)
}

export function updateUserTosStatus(userId: string) {
  return axios.put(`api/users/${userId}/tos-agreement`)
}
