import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { instanceOverviewPath } from '../../route'

interface InstanceParam {
  instanceId: string
}
interface Props extends RouteComponentProps<InstanceParam>{

}

const InstanceRedirect = (props: Props) => {
  const { instanceId } = props.match.params
  return <Redirect to={instanceOverviewPath(instanceId)} />
}

export default InstanceRedirect
