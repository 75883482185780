import React from 'react'
import styled from 'styled-components'
import { Card as AntCard } from 'antd'
import { Flex, Box } from '@rebass/grid'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { InstancePreview } from '../types/InstancePreview'
import InstanceTypeTag from './InstanceTypeTag'

interface Props {
  instance: InstancePreview
}

const InstanceCard: React.FC<Props> = props => {
  return (
    <Card>
      <Flex flexWrap="wrap" mb={[3, 4]}>
        <CardTitle fontSize={[1, 2]} mr={2} fontWeight="bold" color="mullWine" fontFamily="header">
          {props.instance.name}
        </CardTitle>
        <InstanceTypeTag instanceType={props.instance.type} />
      </Flex>
      <Flex flexWrap="wrap">
        {props.instance.team &&
          <Box width={1 / 2}>
            <InfoLabel color="scorpion.light">
              Team
            </InfoLabel>
            <br/>
            <TeamName color="silverTree.darker" fontWeight="bold">
              {props.instance.team.name || 'Sandbox'}
            </TeamName>
          </Box>
        }
        {/*TODO: Add Last editet or figure out if this is needed information for preview*/}
        {/* <Box width={1 / 2}>
          <InfoLabel color="scorpion.light">
            Last edited
          </InfoLabel>
          <br />
          <EditTime color="scorpion.dark">
            01.01.19 13:00
          </EditTime>
        </Box> */}
      </Flex>
    </Card>
  )
}

const Card = styled(AntCard)`
  cursor: pointer;
`

const CardTitle = styled.span<TypographyProps | SpaceProps | ColorProps>`
  ${typography};
  ${space};
  ${color};
`
const InfoLabel = styled.span<ColorProps>`
  ${color};
`
const TeamName = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

export default InstanceCard
