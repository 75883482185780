import { action } from 'typesafe-actions'
import _ from 'lodash'
import { Reducer } from 'redux'
import { Team, TeamPreview, TeamInvitation } from '../types/Team'
import { Error } from '../types/Error'
import {
  getUserTeams as getUserTeamsApi,
  leaveTeam as leaveTeamApi
} from '../utils/api/userApi'
import {
  getTeamById as getTeamByIdApi,
  editTeamName as editTeamNameApi,
  createTeam as createTeamApi,
  getTeamInvitations as getTeamInvitationsApi,
  inviteTeamMember as inviteTeamMemberApi,
  cancelTeamInvitation as cancelTeamInvitationApi,
  transferTeamOwnership as transferTeamOwnershipApi,
  removeTeamMember as removeTeamMemberApi,
  getTeamMembers as getTeamMembersApi,
  deleteTeam as deleteTeamApi
} from '../utils/api/teamApi'

import { TeamInfo } from '../types/Team'
import { TeamMember } from '../types/TeamMember'

enum actionTypes {
  CREATE_TEAM_REQUEST = 'teams/CREATE_TEAM_REQUEST',
  CREATE_TEAM_SUCCESS = 'teams/CREATE_TEAM_SUCCESS',
  CREATE_TEAM_ERROR = 'teams/CREATE_TEAM_ERROR',

  GET_USER_TEAMS_REQUEST = 'teams/GET_USER_TEAMS_REQUEST',
  GET_USER_TEAMS_SUCCESS = 'teams/GET_USER_TEAMS_SUCCESS',
  GET_USER_TEAMS_ERROR = 'teams/GET_USER_TEAMS_ERROR',

  GET_TEAM_BY_ID_REQUEST = 'teams/GET_TEAM_BY_ID_REQUEST',
  GET_TEAM_BY_ID_SUCCESS = 'teams/GET_TEAM_BY_ID_SUCCESS',
  GET_TEAM_BY_ID_ERROR = 'teams/GET_TEAM_BY_ID_ERROR',

  EDIT_TEAM_NAME_REQUEST = 'teams/EDIT_TEAM_NAME_REQUEST',
  EDIT_TEAM_NAME_SUCCESS = 'teams/EDIT_TEAM_NAME_SUCCESS',
  EDIT_TEAM_NAME_ERROR = 'teams/EDIT_TEAM_NAME_ERROR',

  GET_INVITATIONS_REQUEST = 'teams/GET_INVITATIONS_REQUEST',
  GET_INVITATIONS_SUCCESS = 'teams/GET_INVITATIONS_SUCCESS',
  GET_INVITATIONS_ERROR = 'teams/GET_INVITATIONS_ERROR',

  INVITE_TEAM_MEMBER_REQUEST = 'teams/INVITE_TEAM_MEMBER_REQUEST',
  INVITE_TEAM_MEMBER_SUCCESS = 'teams/INVITE_TEAM_MEMBER_SUCCESS',
  INVITE_TEAM_MEMBER_ERROR = 'teams/INVITE_TEAM_MEMBER_ERROR',

  CANCEL_TEAM_INVITATION_REQUEST = 'teams/CANCEL_TEAM_INVITATION_REQUEST',
  CANCEL_TEAM_INVITATION_SUCCESS = 'teams/CANCEL_TEAM_INVITATION_SUCCESS',
  CANCEL_TEAM_INVITATION_ERROR = 'teams/CANCEL_TEAM_INVITATION_ERROR',

  TRANSFER_TEAM_OWNERSHIP_REQUEST = 'teams/TRANSFER_TEAM_OWNERSHIP_REQUEST',
  TRANSFER_TEAM_OWNERSHIP_SUCCESS = 'teams/TRANSFER_TEAM_OWNERSHIP_SUCCESS',
  TRANSFER_TEAM_OWNERSHIP_ERROR = 'teams/TRANSFER_TEAM_OWNERSHIP_ERROR',

  REMOVE_TEAM_MEMBER_REQUEST = 'teams/REMOVE_TEAM_MEMBER_REQUEST',
  REMOVE_TEAM_MEMBER_SUCCESS = 'teams/REMOVE_TEAM_MEMBER_SUCCESS',
  REMOVE_TEAM_MEMBER_ERROR = 'teams/REMOVE_TEAM_MEMBER_ERROR',

  LEAVE_TEAM_REQUEST = 'teams/LEAVE_TEAM_REQUEST',
  LEAVE_TEAM_SUCCESS = 'teams/LEAVE_TEAM_SUCCESS',
  LEAVE_TEAM_ERROR = 'teams/LEAVE_TEAM_ERROR',

  GET_TEAM_MEMBERS_REQUEST = 'teams/GET_TEAM_MEMBERS_REQUEST',
  GET_TEAM_MEMBERS_SUCCESS = 'team/GET_TEAM_MEMBERS_SUCCESS',
  GET_TEAM_MEMBERS_ERROR = 'teams/GET_TEAM_MEMBERS_ERROR',

  DELETE_TEAM_REQUEST = 'teams/DELETE_TEAM_REQUEST',
  DELETE_TEAM_SUCCESS = 'teams/DELETE_TEAM_SUCCESS',
  DELETE_TEAM_ERROR = 'teams/DELETE_TEAM_ERROR'
}

export interface InvitationsState {
  loading: boolean
  error: Error | null
  pendingInvitations: Array<TeamInvitation> | null
}

export interface TeamState {
  teamId: string
  loading: boolean
  error: Error | null
  team: Team | null
  invitations?: InvitationsState
}

export interface UserTeamsState {
  loading: boolean
  error: Error | null
  teams: Array<TeamPreview> | null
}

export interface CreateTeamState {
  loading: boolean
  error: Error | null
}

export interface TeamsState {
  create: CreateTeamState,
  userTeams: UserTeamsState,
  teamById: { [teamId: string]: TeamState },
  teamMembers: { [teamId: string]: TeamMember[] }
}

const initialState: TeamsState = {
  create: { loading: false, error: null },
  userTeams: { loading: false, error: null, teams: null },
  teamById: {},
  teamMembers: {},
}

export const emptyInvitationsState: InvitationsState = {
  loading: false,
  error: null,
  pendingInvitations: null,
}

export function emptyTeamState(teamId: string): TeamState {
  return { teamId, loading: false, error: null, team: null }
}

function getStateTeamById(state: TeamsState, teamId: string): TeamState {
  return state.teamById[teamId] || emptyTeamState(teamId)
}

function getInvitationsByTeamId(state: TeamsState, teamId: string): InvitationsState {
  return getStateTeamById(state, teamId).invitations || emptyInvitationsState
}

function updateCreate(state: TeamsState, createState: Partial<CreateTeamState>): TeamsState {
  const create = state.create
  return { ...state, create: { ...create, ...createState } }
}

function updateUserTeams(state: TeamsState, userTeams: Partial<UserTeamsState>): TeamsState {
  const currentUserTeams: UserTeamsState = state.userTeams
  return { ...state, userTeams: { ...currentUserTeams, ...userTeams } }
}

function updateTeamMembers(state: TeamsState, teamId: string, newMembers: TeamMember[]): TeamsState {
  const members = state.teamMembers
  return { ...state, teamMembers: { ...members, [teamId]: newMembers }}
}

function updateTeam(state: TeamsState, teamId: string, teamState: Partial<TeamState>): TeamsState {
  let state2: TeamsState = state
  if (teamState.team) {
    // Update team preview
    const team = teamState.team
    const teams = state.userTeams.teams || []
    state2 = updateUserTeams(state, { teams: [...teams, { ...team, instanceCount: 0 }]} )
  }
  const teamById = state.teamById
  return {
    ...state2,
    teamById: {
      ...teamById,
      [teamId]: {
        ...getStateTeamById(state, teamId),
        ...teamState
      }
    }
  }
}

export const getUserTeams = (userId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_USER_TEAMS_REQUEST))
    try {
      const response = await getUserTeamsApi(userId)
      const teams = response.data
      dispatch(action(actionTypes.GET_USER_TEAMS_SUCCESS, { teams }))
    } catch (e) {
      dispatch(action(actionTypes.GET_USER_TEAMS_ERROR, { response: e.response.data }))
    }
  }
}

export const createTeam = (body: { name: string; ownerId: string }) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.CREATE_TEAM_REQUEST))
    try {
      const response = await createTeamApi(body)
      const team = response.data
      dispatch(action(actionTypes.CREATE_TEAM_SUCCESS, { teamId: team.id, team }))
      return team as TeamInfo
    } catch (e) {
      dispatch(action(actionTypes.CREATE_TEAM_ERROR, { response: e.response.data }))
    }
  }
}

export const getTeamById = (teamId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_TEAM_BY_ID_REQUEST, { teamId }))
    try {
      const response = await getTeamByIdApi(teamId)
      const team = response.data
      dispatch(action(actionTypes.GET_TEAM_BY_ID_SUCCESS, { team, teamId }))
    } catch (e) {
      dispatch(action(actionTypes.GET_TEAM_BY_ID_ERROR, { teamId, response: e.response.data }))
    }
  }
}

export const getTeamMembers = (teamId: string, userId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_TEAM_MEMBERS_REQUEST, { teamId }))
    try {
      const response = await getTeamMembersApi(teamId, userId)
      const teamMembers = response.data
      dispatch(action(actionTypes.GET_TEAM_MEMBERS_SUCCESS, { teamMembers, teamId }))
    } catch (e) {
      dispatch(action(actionTypes.GET_TEAM_MEMBERS_ERROR, { teamId, response: e.response.data }))
    }
  }
}

export const editTeamName = (teamId: string, body: { name: string }) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.EDIT_TEAM_NAME_REQUEST, { teamId }))
    try {
      await editTeamNameApi(teamId, body)
      dispatch(action(actionTypes.EDIT_TEAM_NAME_SUCCESS, { teamId }))
    } catch (e) {
      dispatch(action(actionTypes.EDIT_TEAM_NAME_ERROR, { teamId, response: e.response.data }))
    }
  }
}

export const deleteTeam = (teamId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.DELETE_TEAM_REQUEST, { teamId }))
    try {
      await deleteTeamApi(teamId)
      dispatch(action(actionTypes.DELETE_TEAM_SUCCESS, { teamId }))
    } catch (e) {
      dispatch(action(actionTypes.DELETE_TEAM_ERROR, { teamId, response: e.response.data }))
    }
  }
}

export const getTeamInvitations = (teamId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_INVITATIONS_REQUEST, { teamId }))
    try {
      const { data: pendingInvitations } = await getTeamInvitationsApi(teamId)
      dispatch(action(actionTypes.GET_INVITATIONS_SUCCESS, { teamId, pendingInvitations }))
    } catch (e) {
      dispatch(action(actionTypes.GET_INVITATIONS_ERROR, { teamId, response: e.response.data }))
    }
  }
}

export const inviteTeamMember = (
  teamId: string,
  body: { recipient: string; role: string },
) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.INVITE_TEAM_MEMBER_REQUEST, { teamId }))
    try {
      await inviteTeamMemberApi(teamId, body)
      dispatch(action(actionTypes.INVITE_TEAM_MEMBER_SUCCESS, { teamId }))
    } catch (e) {
      dispatch(action(actionTypes.INVITE_TEAM_MEMBER_ERROR, { teamId, response: e.response.data }))
    }
  }
}

export const cancelTeamInvitation = (teamId: string, invitationId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.CANCEL_TEAM_INVITATION_REQUEST, { teamId, invitationId }))
    try {
      await cancelTeamInvitationApi(teamId, invitationId)
      dispatch(action(actionTypes.CANCEL_TEAM_INVITATION_SUCCESS, { teamId, invitationId }))
    } catch (e) {
      dispatch(action(actionTypes.CANCEL_TEAM_INVITATION_ERROR, { teamId, invitationId, response: e.response.data }))
    }
  }
}

export const transferTeamOwnership = (teamId: string, userId: string, body: {role: string}) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.TRANSFER_TEAM_OWNERSHIP_REQUEST, { teamId, userId }))
    try {
      await transferTeamOwnershipApi(teamId, userId, body)
      dispatch(action(actionTypes.TRANSFER_TEAM_OWNERSHIP_SUCCESS, { teamId, userId }))
    } catch (e) {
      dispatch(action(actionTypes.TRANSFER_TEAM_OWNERSHIP_ERROR, { teamId, userId, response: e.response.data }))
    }
  }
}

export const removeTeamMember = (teamId: string, userId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.REMOVE_TEAM_MEMBER_REQUEST, { teamId, userId }))
    try {
      await removeTeamMemberApi(teamId, userId)
      dispatch(action(actionTypes.REMOVE_TEAM_MEMBER_SUCCESS, { teamId, userId }))
    } catch (e) {
      dispatch(action(actionTypes.REMOVE_TEAM_MEMBER_ERROR, { teamId, userId, response: e.response.data }))
    }
  }
}

export const leaveTeam = (userId: string, teamId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.LEAVE_TEAM_REQUEST, { teamId }))
    try {
      await leaveTeamApi(userId, teamId)
      dispatch(action(actionTypes.LEAVE_TEAM_SUCCESS, { teamId }))
    } catch (e) {
      dispatch(action(actionTypes.LEAVE_TEAM_ERROR, { teamId, response: e.response.data }))
    }
  }
}

const reducer: Reducer = (
  state: TeamsState = initialState,
  action: any,
): TeamsState => {
  switch (action.type) {
    case actionTypes.CREATE_TEAM_REQUEST: {
      return updateCreate(state, { loading: true })
    }

    case actionTypes.CREATE_TEAM_SUCCESS: {
      const { teamId, team } = action.payload
      const state2 = updateTeam(state, teamId, { team })
      return updateCreate(state2, { loading: false, error: null })
    }

    case actionTypes.CREATE_TEAM_ERROR: {
      return updateCreate(state, { loading: false, error: action.payload.response })
    }

    case actionTypes.GET_USER_TEAMS_REQUEST: {
      return updateUserTeams(state, { loading: true })
    }

    case actionTypes.GET_USER_TEAMS_SUCCESS: {
      const { teams } = action.payload
      return updateUserTeams(state, { loading: false, teams, error: null })
    }

    case actionTypes.GET_USER_TEAMS_ERROR: {
      return updateUserTeams(state, { loading: false, error: action.payload.response })
    }


    case actionTypes.GET_INVITATIONS_REQUEST: {
      const { teamId } = action.payload
      const invitations = getInvitationsByTeamId(state, teamId)
      return updateTeam(state, teamId, { invitations: { ...invitations, loading: true } })
    }

    case actionTypes.GET_INVITATIONS_SUCCESS: {
      const { pendingInvitations, teamId } = action.payload
      const invitations = getInvitationsByTeamId(state, teamId)
      return updateTeam(state, teamId, { invitations: { ...invitations, pendingInvitations, loading: false } })
    }

    case actionTypes.GET_INVITATIONS_ERROR: {
      const { teamId, response } = action.payload
      const invitations = getInvitationsByTeamId(state, teamId)
      return updateTeam(state, teamId, { invitations: { ...invitations, loading: false, error: response } })
    }

    case actionTypes.LEAVE_TEAM_REQUEST:
    case actionTypes.REMOVE_TEAM_MEMBER_REQUEST:
    case actionTypes.TRANSFER_TEAM_OWNERSHIP_REQUEST:
    case actionTypes.INVITE_TEAM_MEMBER_REQUEST:
    case actionTypes.EDIT_TEAM_NAME_REQUEST:
    case actionTypes.CANCEL_TEAM_INVITATION_REQUEST:
    case actionTypes.GET_TEAM_BY_ID_REQUEST:
    case actionTypes.DELETE_TEAM_REQUEST: {
      const { teamId } = action.payload
      return updateTeam(state, teamId, { loading: true })
    }

    case actionTypes.CANCEL_TEAM_INVITATION_SUCCESS: {
      const { teamId, invitationId } = action.payload
      const invitations = getInvitationsByTeamId(state, teamId)
      const pendingInvitations = _.filter(invitations.pendingInvitations, i => i.id !== invitationId)
      return updateTeam(state, teamId, { loading: false, error: null, invitations: { ...invitations, pendingInvitations} })
    }

    case actionTypes.LEAVE_TEAM_SUCCESS:
    case actionTypes.REMOVE_TEAM_MEMBER_SUCCESS:
    case actionTypes.TRANSFER_TEAM_OWNERSHIP_SUCCESS:
    case actionTypes.INVITE_TEAM_MEMBER_SUCCESS:
    case actionTypes.EDIT_TEAM_NAME_SUCCESS: {
      const { teamId } = action.payload
      return updateTeam(state, teamId, { loading: false, error: null })
    }

    case actionTypes.GET_TEAM_BY_ID_SUCCESS: {
      const { team, teamId } = action.payload
      return updateTeam(state, teamId, { loading: false, team, error: null })
    }

    case actionTypes.LEAVE_TEAM_ERROR:
    case actionTypes.REMOVE_TEAM_MEMBER_ERROR:
    case actionTypes.TRANSFER_TEAM_OWNERSHIP_ERROR:
    case actionTypes.INVITE_TEAM_MEMBER_ERROR:
    case actionTypes.CANCEL_TEAM_INVITATION_ERROR:
    case actionTypes.EDIT_TEAM_NAME_ERROR:
    case actionTypes.GET_TEAM_BY_ID_ERROR:
    case actionTypes.DELETE_TEAM_ERROR: {
      const { response, teamId } = action.payload
      return updateTeam(state, teamId, { loading: false, error: response })
    }

    case actionTypes.GET_TEAM_MEMBERS_SUCCESS: {
      const { teamId, teamMembers } = action.payload
      return updateTeamMembers(state, teamId, teamMembers)
    }

    case actionTypes.GET_TEAM_MEMBERS_ERROR: {
      return updateUserTeams(state, { error: action.payload.response })
    }

    default: {
      return state
    }
  }
}

export { reducer as teamsReducer }
