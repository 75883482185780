import React from 'react'
import InstanceTemplate from './InstanceTemplate'
import { GettingStartedContent } from '../../component'
import { Dispatch, bindActionCreators } from 'redux'
import { deleteInstance, getInstance, InstanceStatus } from '../../ducks/instances'
import { ConsoleUser as User } from '../../types/User'
import { getApiKeyByKeyId } from '../../utils/api/instanceApi'
import { AppState } from '../../ducks'
import { connect } from 'react-redux'
import { ApiKey } from '../../types/ApiKey'
import _ from 'lodash'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { ColorProps, color } from 'styled-system'
import { Spin } from 'antd'
import { AitoClient } from 'api'

interface Props extends ReturnType<typeof mapDispatchToProps> {
  instances: { [k: string]: InstanceStatus },
  apiKeys: { [k: string]: ApiKey[] },
  userProfile: User,
  match: any
}

interface State {
  aitoClient: AitoClient | null
}

class InstanceGetStarted extends React.Component<Props, State> {

  readonly state: State = {
    aitoClient: null
  }

  async componentDidMount() {
    window.analytics.page('Get started')
    const { instanceId } = this.props.match.params
    const { userProfile } = this.props
    if (instanceId) {
      await this.props.getInstance(instanceId, userProfile.id)
    }
    const { apiKeys, instances } = this.props
    const instanceApiKey = apiKeys[instanceId] || {}
    const instanceStatus = instances[instanceId] || {}
    const instance = instanceStatus.instance
    const rwApiKey = _.find(instanceApiKey, { type: 'READ_WRITE' })
    if (rwApiKey && instance) {
      const key = await getApiKeyByKeyId(instanceId, rwApiKey.id)
      this.setState({
        aitoClient: AitoClient.createClient({ hostname: instance.hostname, key: key.data.key }) })
    }
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances } = this.props
    const { instance = null } = instances[instanceId] || {}
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const userProfile = this.props.userProfile || {id: '', fullName: '', email: ''}
    const isOwner = instance ? instance.ownerId === userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'

    return (
      <InstanceTemplate
        instanceId={instanceId}
        activePage="getStarted"
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
      >
        {instance && this.state.aitoClient
          ? <GettingStartedContent instance={instance} aitoClient={this.state.aitoClient} />
          : <SpinContainer flexDirection="column" justifyContent="center" alignItems="center"><Spin size="large"/></SpinContainer>}
      </InstanceTemplate>
    )
  }
}

const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ getInstance, deleteInstance }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    apiKeys: state.instances.apiKeys,
    userProfile: state.auth.userProfile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstanceGetStarted)
