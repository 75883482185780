import React, { ExoticComponent } from 'react'
import { PageHeader, BottomNavigationBar, SideMenu } from '../../component'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { media, theme } from '../../styles/theme'
import { MenuAltLeft } from '@styled-icons/boxicons-regular/MenuAltLeft'
import { Settings } from '@styled-icons/material/Settings'
import MediaQuery from 'react-responsive'
import { teamsPath, teamOverviewPath, teamSettingsPath } from '../../route'

interface Props {
  children: React.ReactNode
  teamId: string
  teamName: string
  activePage: string
  isOwner: boolean
}

interface State {}

class TeamTemplate extends React.Component<Props, State> {
  private static defaultProps = { activePage: 'overview'}
  render() {
    const { teamName, teamId, isOwner } = this.props
    const breadcrumbItems = [
      {
        text: 'Teams',
        url: teamsPath,
        icon: false
      },
      {
        text: teamName,
        icon: true
      }
    ]

    const menuItems = [
      {
        key: 'overview',
        text: 'Overview',
        url: teamOverviewPath(teamId),
        icon: <OverviewIcon size={24} mr={2}/>,
        show: true
      },
      {
        key: 'settings',
        text: 'Settings',
        url: teamSettingsPath(teamId),
        icon: <SettingsIcon size={24} mr={2}/>,
        show: isOwner
      }
    ]

    return (
      <ContainerDiv>
        <PageHeader items={breadcrumbItems}/>
        <FlexContainer flexWrap="wrap">
          <MediaQuery query={media.sm}>
            <Box width={1 / 5}>
              <SideMenu items={menuItems} activePage={this.props.activePage} />
            </Box>
          </MediaQuery>

          <Box width={[1, 4 / 5]} p={3} mb={[4, 0]}>
            {this.props.children}
          </Box>
        </FlexContainer>

        <MediaQuery query={media.sm}>
          {(matches: any) => {
            if (matches) {
              return null
            }
            return (
              <BottomNavigationBar
                items={menuItems}
                activePage={this.props.activePage}
              />
            )
          }}
        </MediaQuery>
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled.div`
  height: calc(100vh - (${theme.sizes.navBarHeight}px + ${theme.sizes.pageHeaderHeight}px));
`

const FlexContainer = styled(Flex)`
  height: 100%;
`

const OverviewIcon: ExoticComponent<any> = styled(MenuAltLeft)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const SettingsIcon: ExoticComponent<any> = styled(Settings)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

export default TeamTemplate
