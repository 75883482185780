export const LanguageOptions: Array<{ label: string, value: string }> = [
  { label: 'Arabic', value: 'arabic' },
  { label: 'Armenian', value: 'armenian' },
  { label: 'Basque', value: 'basque' },
  { label: 'Brazilian Portuguese', value: 'brazilian' },
  { label: 'Bulgarian', value: 'bulgarian' },
  { label: 'Catalan', value: 'catalan' },
  { label: 'Chinese, Japanese, Korean', value: 'cjk' },
  { label: 'Czech', value: 'czech' },
  { label: 'Danish', value: 'danish' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'English', value: 'english' },
  { label: 'Finnish', value: 'finnish' },
  { label: 'French', value: 'french' },
  { label: 'Galician', value: 'galician' },
  { label: 'German', value: 'german' },
  { label: 'Greek', value: 'greek' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Hungarian', value: 'hungarian' },
  { label: 'Indonesian', value: 'indonesian' },
  { label: 'Irish', value: 'irish' },
  { label: 'Italian', value: 'italian' },
  { label: 'Latvian', value: 'latvian' },
  { label: 'Norwegian', value: 'norwegian' },
  { label: 'Persian', value: 'persian' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Romanian', value: 'romanian' },
  { label: 'Russian', value: 'russian' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Swedish', value: 'swedish' },
  { label: 'Thai', value: 'thai' },
  { label: 'Turkish', value: 'turkish' },
]
