import React, { useReducer } from 'react'

import { PrimitiveType } from 'api/schema/jobs/csvupload'
import { Analyzer, AnalyzerType, SupportedLanguage } from 'api/schema/aito'
import { SchemaInferred } from '../../types/FileUploadState'
import { FormInstance } from 'antd'

// View state reducer for FileUploadModal

export interface AnalyzerState {
  type: AnalyzerType
  delimiter: {
    delimiter: string
    trimWhitespace: boolean
  }
  language: {
    language: SupportedLanguage
    useDefaultStopWords: boolean
    // FIXME: allow n-grams here
  }
}

export interface ColumnEditorProps {
  // Display/input properties
  form: FormInstance
  originalName: string
  typeOptions: PrimitiveType[],
  formats: Record<string, number>
  samples: string[] | Record<string, string[]>
  instanceId: string,
  number: number

  // Editable properties, which are fed from the form
  type: PrimitiveType
  analyzer: AnalyzerState
  include: boolean
} 

export interface ColumnFormState {
  name: string
  type: PrimitiveType
  analyzer: AnalyzerState
  include: boolean
}

export interface TableFormState {
  tableName: string
  columns: Record<number, ColumnFormState>
}

export interface ModalState {
  view: 'preview' | 'schema'
  hasHeader?: boolean
}

export type ModalStateAction =
  | { type: 'set-view', view: 'preview' | 'schema' }
  | { type: 'set-has-header', hasHeader: boolean }

const modalStateReducer = (
  state: ModalState,
  action: ModalStateAction,
): ModalState => {
  switch (action.type) {
    case 'set-view':
      return { ...state, view: action.view }

    case 'set-has-header':
      return { ...state, hasHeader: action.hasHeader }
  }
}

export const useModalStateReducer = () => useReducer(modalStateReducer, { view: 'preview' })

export const toEditableColumns = (
  form: FormInstance,
  state: SchemaInferred,
  hasHeader: boolean,
  columns: Record<number, ColumnFormState | undefined>,
): ColumnEditorProps[] => {
  // Create item properties for display, based on what's been saved/edited and
  // defaults
  return state.inference.columns.map((column, i) => {
    const defaultAnalyzer = column.schema
      .map(item => item.type === 'text' ? item.analyzer : undefined)
      .find(item => item)

    const columnState = columns[i]

    let analyzer: AnalyzerState
    if (columnState && columnState.analyzer) {
      analyzer = columnState.analyzer
    } else if (defaultAnalyzer) {
      // the default analyzer is always a language analyzer
      analyzer = {
        type: 'language',
        language: {
          language: typeof defaultAnalyzer !== 'string' && defaultAnalyzer.type === 'language' ? defaultAnalyzer.language : 'english',
          useDefaultStopWords: true,
        },
        delimiter: {
          delimiter: ';',
          trimWhitespace: true,
        }
      }
    } else {
      analyzer = {
        type: 'standard',
        language: {
          language: 'english',
          useDefaultStopWords: true,
        },
        delimiter: {
          delimiter: ';',
          trimWhitespace: true,
        }
      }
    }

    const type = columnState && columnState.type ? columnState.type : column.schema[0].type
    const include = columnState && typeof columnState.include === 'boolean' ? columnState.include : true

    const firstRow = state.inference.exampleRows[0]
    const originalName = (hasHeader && firstRow[i]) || `Column\xa0${i + 1}`

    const typeOptions = state.inference.columns[i].schema.map(o => o.type)
    if (!typeOptions.find(t => t === 'text')) {
      typeOptions.push('text')
    }
    if (!typeOptions.find(t => t === 'string')) {
      typeOptions.push('string')
    }

    return {
      form,
      originalName,
      number: i,
      type,
      analyzer,
      typeOptions,
      formats: state.inference.columns[i].formats,
      samples: state.inference.columns[i].samples,
      include,
      instanceId: state.instanceId,
    } as ColumnEditorProps
  })
}

export const toAnalyzer = (state: AnalyzerState): Analyzer => {
  if (state.type === 'standard') {
    return 'standard'
  }
  if (state.type === 'whitespace') {
    return 'whitespace'
  }
  if (state.type === 'delimiter') {
    return {
      type: state.type,
      delimiter: state.delimiter.delimiter,
      trimWhitespace: state.delimiter.trimWhitespace,
    }
  }
  if (state.type === 'language') {
    return {
      type: state.type,
      customKeyWords: [],
      customStopWords: [],
      language: state.language.language,
      useDefaultStopWords: state.language.useDefaultStopWords,
    }
  }
  if (state.type === 'token-ngram') {
    // FIXME
    return {
      type: state.type,
      maxGram: 2,
      minGram: 2,
      source: 'standard',
      tokenSeparator: '-',
    }
  }
  // state.type === 'char-ngram'
  // FIXME
  return {
    type: state.type,
    maxGram: 1,
    minGram: 1,
  }
}

export const EditStateDispatcher = React.createContext<React.Dispatch<ModalStateAction>>(() => {})
