import React, { ExoticComponent, useCallback, useEffect, useState } from 'react'
import InstanceTemplate from './InstanceTemplate'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dispatch, bindActionCreators } from 'redux'
import styled from 'styled-components'

import { Link, RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../ducks'
import { getInstance, InstanceStatus, getUserInstances } from '../../ducks/instances'
import { startEditableFileUpload, setFileSchema, clearJob, InstanceJobStatus } from '../../ducks/jobs'
import { AitoClient, User } from 'api'
import { Analyzer, TableSchema } from 'api/schema/aito'

import { Box, Flex } from '@rebass/grid'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps  } from 'styled-system'
import { AddCircle } from '@styled-icons/material'
import { getApiKeyByKeyId } from '../../utils/api/instanceApi'
import { Divider, message, Modal, Space, Spin, Table, Upload, Tag as AntTag, Switch } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { ApiKey } from '../../types/ApiKey'
import FileUploadModal, { CreateTableEvent }  from '../../component/FileUploadModal'
import { FileUploadState } from '../../types/FileUploadState'
import { CodeSnippet, Heading } from '../../component'
import { theme } from '../../styles/theme'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import variables from '../../variables'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import axios from 'axios'
import { tablesPath } from '../../route'

const typeColors = variables.TYPE_COLORS as any

const FileUploadActionBar: React.FC<{
  onFile: (file: File) => void
}> = ({ onFile }) => {
  const onFileSelected = useCallback((event: UploadChangeParam) => {
    if (event.file) {
      onFile(event.file as unknown as File)
    }
  }, [onFile])

  return (
    <ActionBar m={2} py={2} justifyContent="flex-end">
      <ButtonBox>
        <Link to="tables/empty">
          <AddText fontSize={0} color="scorpion.light" fontWeight={5}>
            Create empty table
          </AddText>
          <AddIcon size={24} color="jade.medium" ml={2}/>
        </Link>
      </ButtonBox>
      <Upload
      name="file"
      onChange={onFileSelected}
      accept=".csv"
      beforeUpload={() => false}
      fileList={[]}
      >
        <ButtonBox>
          <AddText fontSize={0} color="scorpion.light" fontWeight={5}>
            Create table
          </AddText>
          <AddIcon size={24} color="jade.medium" ml={2}/>
        </ButtonBox>
      </Upload>
    </ActionBar>
  )
}

const DeleteModal: React.FC<{
  table: string
  visible: boolean
  onClick: (e: any) => void
  onCancel: () => void
  onOk: () => void
  loading: boolean
  buttonText: string
  headerText: string
  text: string
 }> = ({ table, visible, onClick, onCancel, onOk, loading, buttonText, headerText, text }) => {
  return (
    <>
      <LinkButton onClick={onClick}>{buttonText}</LinkButton>
      <Modal
        visible={visible}
        centered={true}
        onCancel={onCancel}
        onOk={onOk}
        confirmLoading={loading}
        okText={buttonText}
        okType="danger"
      >
        <Heading fontSize={3}>{headerText}</Heading>
        <p>{text} <b>{table}</b>?</p>
      </Modal>
    </>
  )
}

const TableModal: React.FC<{
  table: string
  visible: boolean
  schema: TableSchema
  rows: number
  onCancel: () => void
  aitoClient?: AitoClient
}> = ({ table, visible, schema, rows, onCancel, aitoClient }) => {
  const [loading, setLoading] = useState(true)
  const [hits, setHits] = useState([])
  const [tableStyle, setTableStyle] = useState(false)

  useEffect(() => {
    const sample = async (table: string) => {
      setLoading(true)
      const s = await aitoClient?.search({ from: table, limit: 5 })
      setHits(s?.data.hits)
      setLoading(false)
    }
    sample(table)
  }, [ table, aitoClient ])

  const switchChange = () => {
    setTableStyle(!tableStyle)
  }

  const schemaData = _.keys(schema.columns).map(column => {
    const analyzer = (schema.columns[column] as any)?.analyzer as Analyzer
    let analyzerInfo
    if (_.isObject(analyzer)) {
      if (analyzer.type === 'language') analyzerInfo = analyzer.language
      else analyzerInfo = analyzer.type
    } else {
      analyzerInfo = analyzer
    }

    const typeTag = (
      <Tag fontSize={[0, 1]} color={typeColors[schema.columns[column].type].bg}>
        <TypeText color={typeColors[schema.columns[column].type].color}>
          {schema.columns[column].type}
        </TypeText>
      </Tag>
    )

    const nullableTag = () => {
      const color = schema.columns[column].nullable ?
        theme.colors.jade.medium :
        theme.colors.sunsetOrange.medium
      return (
        <Tag fontSize={[0, 1]} color={color}>
          <TypeText color="white">
            {schema.columns[column].nullable ? 'true' : 'false'}
          </TypeText>
        </Tag>
      )
    }

    return {
      key: column,
      name: column,
      type: typeTag,
      nullable: nullableTag(),
      analyzer: analyzerInfo
    }
  })
  const columns = [
    { title: 'Name', key: 'name', dataIndex: 'name' },
    { title: 'Type', key: 'type', dataIndex: 'type' },
    { title: 'Analyzer', key: 'analyzer', dataIndex: 'analyzer' },
    { title: 'Nullable', key: 'nullable', dataIndex: 'nullable' },
  ]

  const hitsColumns = _.keys(schema.columns).map(col => (
    { key: col, title: col, dataIndex: col })
  )
  const hitsKeys = hits.map((h: object, i: number) => ({key: i, ...h}))

  let content
  if (loading) {
    content =
      <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
        <Spin size="large"/>
      </SpinContainer>
  } else {
    content =
    <>
      <Heading fontSize={3}>{table}</Heading>
      <TableInfoContainer>
      <Heading fontSize={2}>Info</Heading>
        <Flex flexWrap="wrap" mx={16} mb={16}>
          <Box width={[1 / 2]}>
            <InfoLabel color="scorpion.light">
              Rows
            </InfoLabel>
            <Info color="silverTree.darker" fontWeight="bold">
              {rows}
            </Info>
          </Box>
          <Box width={[1 / 2]}>
            <InfoLabel color="scorpion.light">
              Columns
            </InfoLabel>
            <Info color="silverTree.darker" fontWeight="bold">
              {_.keys(schema.columns).length}
            </Info>
          </Box>
        </Flex>
        <Divider />
        <TableItemContainer>
          <Flex justifyContent="space-between">
            <Heading fontSize={2}>Schema</Heading>
            <span>Pretty<StyledSwitch onChange={switchChange} />Raw</span>
          </Flex>
          <SchemaContainer>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={tableStyle.toString()}
                addEndListener={(node, done) => { node.addEventListener("transitionend", done, false) }}
                classNames="anim"
              >

                  {
                    tableStyle ?
                    <CodeSnippet language="json">
                      {JSON.stringify(schema, null, 2)}
                    </CodeSnippet> :
                    <Table
                      dataSource={schemaData}
                      columns={columns}
                      pagination={false}
                      scroll={{ x: 400 }}
                    />
                  }
              </CSSTransition>
            </SwitchTransition>
          </SchemaContainer>
        </TableItemContainer>
        <TableItemContainer>
          <Heading fontSize={2}>Sample</Heading>
          <Table
            dataSource={hitsKeys}
            columns={hitsColumns}
            pagination={false}
            scroll={{ x: 400 }}
          />
        </TableItemContainer>
      </TableInfoContainer>
    </>
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width="80%"
    >
      {content}
    </Modal>
  )
}

interface RouteParam {
}

interface Props extends RouteComponentProps<RouteParam>, ReturnType<typeof mapDispatchToProps> {
  match: any
  instances: Record<string, InstanceStatus>,
  userProfile: User,
  apiKeys: Record<string, ApiKey[]>,
  jobs: Record<string, InstanceJobStatus>
}

interface State {
  aitoClient: AitoClient | undefined
  schema: Record<string, TableSchema>
  rowCounts: Record<string, number>,
  loading: boolean
  actionLoading: boolean
  fileUploadJobId?: string
  showDeleteModal?: string
  showClearModal?: string
  showTableModal?: string
}

class Tables extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      aitoClient: undefined,
      loading: false,
      actionLoading: false,
      schema: {},
      rowCounts: {},
    }
  }

  async componentDidMount() {
    window.analytics.page('Instance tables')
    this.setState({ loading: true })
    const { instanceId } = this.props.match.params
    const { userProfile } = this.props
    if (instanceId) {
      await this.props.getInstance(instanceId, userProfile.id)

      const { apiKeys, instances } = this.props
      const instanceApiKey = apiKeys[instanceId] || {}
      const instanceStatus = instances[instanceId] || {}
      const instance = instanceStatus.instance

      // Deletion needs read/write key
      const apiKey = _.find(instanceApiKey, { type: 'READ_WRITE' })
      if (apiKey && instance) {
        try {
          const key = await getApiKeyByKeyId(instanceId, apiKey.id)
          const client = AitoClient.createClient({ hostname: instance.hostname, key: key.data.key })
          const aitoSchema: { schema: Record<string, TableSchema> } = await client.getSchema()
          const rowCounts = await Promise.all(_.keys(aitoSchema.schema).map(async table => {
            const s = await client.search({ from: table, limit: 0 })
            return { [table]: s.data.total }
          }))
          this.setState({
            aitoClient: client,
            schema: aitoSchema.schema,
            rowCounts: _.assign({}, ...rowCounts)
          })
        } catch (e) {

        }
      }
    }
    this.setState({ loading: false })
  }

  onFile = async (file: File): Promise<void> => {
    const { instanceId } = this.props.match.params
    if (instanceId && this.props.startEditableFileUpload) {
      const jobId = await this.props.startEditableFileUpload(instanceId, file)
      if (jobId) {
        this.setState({ fileUploadJobId: jobId })
      }
    }
  }

  onCloseUploadModal = () => {
    const { instanceId } = this.props.match.params
    this.props.history.replace(tablesPath(instanceId))
    const jobId = this.state.fileUploadJobId
    this.setState({
      fileUploadJobId: undefined
    })
    if (jobId) {
      this.props.clearJob(jobId)
    }
    // Inform return to tables view
    window.analytics.page('Instance tables')
  }

  onCreateTable = async (event: CreateTableEvent) => {
    const { instanceId } = this.props.match.params
    if (instanceId && this.props.setFileSchema) {
      const apiKey = _.find(this.props.apiKeys[instanceId], k => k.type.includes('WRITE'))
      if (apiKey) {
        await this.props.setFileSchema(
          instanceId,
          event.jobId,
          event.tableName,
          event.hasHeader,
          event.columns,
          event.includeColumns,
          apiKey,
        )
        const aitoSchema: { schema: Record<string, TableSchema> } = await this.state.aitoClient?.getSchema()
        const rowCounts = await Promise.all(_.keys(aitoSchema.schema).map(async table => {
          const s = await this.state.aitoClient?.search({ from: table, limit: 0 })
          return { [table]: s?.data.total }
        }))
        this.setState({
          schema: aitoSchema.schema,
          rowCounts: _.assign({}, ...rowCounts),
        })
        return // Don't close
      }
    }
    // This is unexpected
    this.onCloseUploadModal()
  }

  clearOrDeleteTable = async (table: string, action: 'delete' | 'clear') => {
    this.setState({ actionLoading: true })
    try {
      let actionReq
      if (action === 'clear') {
        actionReq = await this.state.aitoClient?.truncateTable(table)
      } else if (action === 'delete') {
        actionReq = await this.state.aitoClient?.deleteTableSchema(table)
      }
      const aitoSchema: { schema: Record<string, TableSchema> } = await this.state.aitoClient?.getSchema()
      const rowCounts = await Promise.all(_.keys(aitoSchema.schema).map(async table => {
        const s = await this.state.aitoClient?.search({ from: table, limit: 0 })
        return { [table]: s?.data.total }
      }))
      this.setState({
        schema: aitoSchema.schema,
        rowCounts: _.assign({}, ...rowCounts),
        actionLoading: false,
        showDeleteModal: undefined,
        showClearModal: undefined
      })

      if (action === 'clear') {
        message.info(`Cleared ${actionReq} rows from table ${table}`)
      } else if (action === 'delete') {
        message.info(`Deleted table ${table}`)
      }

    } catch (e) {
      this.setState({ actionLoading: false, showDeleteModal: undefined, showClearModal: undefined })
      let m
      if (axios.isAxiosError(e)) m = e?.response?.data?.message
      if (action === 'clear') {
        message.error(m ? 'Clearing table failed: ' + m : 'Clearing table failed')
      } else if (action === 'delete') {
        message.error(m ? 'Deleting table failed: ' + m : 'Deleting table failed')
      }
    }
  }


  render() {
    const { instanceId } = this.props.match.params
    const { instances } = this.props
    const { showTableModal, showDeleteModal, showClearModal } = this.state
    const { instance, loading: instanceLoading } = instances[instanceId] || { instance: null, loading: true }
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const isOwner = instance ? instance.ownerId === this.props.userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'

    let modalState: FileUploadState | undefined

    if (this.state.fileUploadJobId) {
      const job = this.props.jobs[this.state.fileUploadJobId]
      if (job && job.type === 'csv-upload-and-edit') {
        modalState = job.state
      }
    }

    const breakPoints = [ 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ] as Breakpoint[]
    const tables = _.sortBy(_.keys(this.state.schema))
    const canShowModal = !(showTableModal || showDeleteModal || showClearModal)
    const columns = [
      { title: 'Table', key: 'table', dataIndex: 'table' },
      { title: 'Columns', key: 'columns', dataIndex: 'columns', responsive: breakPoints.slice(2) },
      { title: 'Rows', key: 'rows', dataIndex: 'rows', responsive: breakPoints.slice(1) },
      { title: 'Action', key: 'action', dataIndex: 'action', render: (text: string, record: any) => (
        <Space size="middle">
        <DeleteModal
          visible={this.state.showClearModal === record.table}
          table={record.table}
          onClick={(e: any) => { e.stopPropagation(); this.setState({ showClearModal: record.table })}}
          onCancel={() => this.setState({ showClearModal: undefined })}
          onOk={() => this.clearOrDeleteTable(record.table, 'clear')}
          loading={this.state.actionLoading}
          buttonText="Clear table"
          headerText="Clear table"
          text="Clearing table will delete all rows, and leave you with empty table and schema. Are you sure you want to clear the table "
        />
        <DeleteModal
          visible={this.state.showDeleteModal === record.table}
          table={record.table}
          onClick={(e: any) => { e.stopPropagation(); this.setState({ showDeleteModal: record.table })}}
          onCancel={() => this.setState({ showDeleteModal: undefined })}
          onOk={() => this.clearOrDeleteTable(record.table, 'delete')}
          loading={this.state.actionLoading}
          buttonText="Delete"
          headerText="Delete table"
          text="Deleting a table is an irreversible operation. Are you sure you want to delete table "
        />
        <TableModal
          table={record.table}
          schema={this.state.schema[record.table]}
          visible={this.state.showTableModal === record.table}
          rows={this.state.rowCounts[record.table]}
          onCancel={() => this.setState({ showTableModal: undefined })}
          aitoClient={this.state.aitoClient}
        />
        </Space>)}
    ]
    const data = tables.map(table => (
      {
        key: table,
        table,
        rows: this.state.rowCounts[table],
        columns: _.keys(this.state.schema[table]?.columns).length,
      }
    ))

    let content = (
      <Container>
        <FileUploadActionBar onFile={this.onFile} />
        {modalState && <FileUploadModal
          jobState={modalState}
          onCancel={this.onCloseUploadModal}
          onCreateTable={this.onCreateTable}
          history={this.props.history}
        />}
        <Table
          columns={columns}
          dataSource={data}
          loading={this.state.loading || instanceLoading}
          onRow={(record) => ({ onClick: () => canShowModal && this.setState({ showTableModal: record.table })})}
        />
      </Container>
    )

    return (
      <InstanceTemplate
        instanceId={instanceId}
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        activePage="tables"
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        {content}
      </InstanceTemplate>
    )
  }
}

const Container = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`
const ActionBar = styled(Flex)`
  border-bottom: 1px solid rgba(196, 206, 213, 0.3);
`
const ButtonBox = styled(Box)`
  cursor: pointer;
  margin-left: 20px;
`
const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`
const AddText: ExoticComponent<any> = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`
const LinkButton = styled.span`
  color: ${theme.colors.jade.medium};
  padding: 16px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const InfoLabel = styled.span<ColorProps>`
  ${color};
  display: block;
`
const Info = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const TableInfoContainer = styled.div`
  margin: 20px;
`

const TableItemContainer = styled.div`
  margin-bottom: 20px;
`
const Tag = styled(AntTag)<TypographyProps>`
  ${typography};
  line-height: unset;
  margin-left: 20px;
`
const TypeText = styled.span`
  ${color};
`
const StyledSwitch = styled(Switch)`
  margin: 0 10px;
  background-color: ${theme.colors.jade.medium};
`
const SchemaContainer = styled.div`
  .anim-enter {
    opacity: 0;
  }
  .anim-exit {
    opacity: 1;
  }
  .anim-enter-active {
    opacity: 1;
  }
  .anim-exit-active {
    opacity: 0;
  }
  .anim-enter-active,
  .anim-exit-active {
    transition: 0.5s ease;
  }
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    getApiKeyByKeyId,
    getUserInstances,
    startEditableFileUpload,
    setFileSchema,
    clearJob,
   }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    apiKeys: state.instances.apiKeys,
    userProfile: state.auth.userProfile,
    jobs: state.jobs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tables)
