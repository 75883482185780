import React, { ExoticComponent } from 'react'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { AddCircle } from '@styled-icons/material'
import { Link } from 'react-router-dom'
import { Box, Flex } from '@rebass/grid'

interface Props {
  actionButton: {
    url: string,
    text: string
  }
}

const ActionBar: React.FC<Props> = props => {
  return (
    <ContainerDiv m={2} py={2} justifyContent="flex-end">
      <Box>
        <Link to={props.actionButton.url}>
          <ActionText fontSize={0} color="scorpion.light" fontWeight={5}>
            {props.actionButton.text}
          </ActionText>
          <AddIcon size={24} color="jade.medium" ml={2}/>
        </Link>
      </Box>
    </ContainerDiv>
  )
}

const ContainerDiv = styled(Flex)`
  border-bottom: 1px solid rgba(196, 206, 213, 0.3);
`
const ActionText = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`

export default ActionBar
