const filterCountries = (input: string, option: any) => {
  if (!option) return false
  const countryLabel = option.props.children.toLowerCase()
  const matchesLabel = countryLabel.indexOf(input.toLowerCase()) >= 0
  if (matchesLabel) return true
  const isoCode = option.value.toLowerCase()
  const matchesIsoCode = isoCode.indexOf(input.toLowerCase()) >= 0
  return matchesIsoCode
}

export default filterCountries