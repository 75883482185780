import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { userReducer } from './user'
import { teamsReducer } from './teams'
import { invitationsReducer } from './invitations'
import { instancesReducer } from './instances'
import { tosReducer } from './tos'
import { ownershipTransferReducer } from './ownershipTransfer'
import { evaluationsReducer } from './evaluations'
import { jobsReducer } from './jobs'

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  teams: teamsReducer,
  invitations: invitationsReducer,
  instances: instancesReducer,
  jobs: jobsReducer,
  tos: tosReducer,
  ownershipTransfer: ownershipTransferReducer,
  evaluations: evaluationsReducer
})

export type AppState = ReturnType<typeof rootReducer>
