import _ from 'lodash'

export const getNameInitials = (name: string) => {
  const pieces = _.split(name, ' ')
  let initials = ''

  for (let i = 0; i < pieces.length; i++) {
    initials += pieces[i].substring(0, 1)
  }
  return initials
}
