import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { CaretDown } from '@styled-icons/fa-solid'
import {Flex} from '@rebass/grid'

interface Props {
  items: Array<{text: string, url?: string, icon: boolean}>
}

const PageHeader: React.FC<Props> = props => {
  return (
    <ContainerDiv
      alignItems="center"
      justifyContent="space-between"
      bg="white"
      height="pageHeaderHeight"
      px={3}
    >
      <Breadcrumb fontSize={[3, 4]} fontFamily="header" >
        {_.map(props.items, (item, index) => {
          return (
            item.url ?
            <LinkItem key={index} href={item.url} color="scorpion.darker" fontWeight="regular">
              {item.text}
              {/* {item.icon ?  <CaretIcon color="scorpion.darker" size={22} mx={2}/> : null} */}
            </LinkItem>
              :
            <TextItem key={index} color="scorpion.darker" fontWeight="semibold">
              {item.text}
              {/* {item.icon ?  <CaretIcon color="scorpion.darker" size={22} mx={2}/> : null} */}
            </TextItem>
          )
        })}
      </Breadcrumb>
    </ContainerDiv>
  )
}

const ContainerDiv = styled(Flex)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${space};
  ${color};
  box-shadow: 0px 3px 8px rgba(238, 238, 238, 0.35);
` as typeof Flex

const Breadcrumb = styled(AntBreadcrumb)<TypographyProps>`
  ${typography};
`

const LinkItem: ExoticComponent<any> = styled(AntBreadcrumb.Item)<ColorProps | TypographyProps>`
  ${color};
  ${typography};
`

const TextItem: ExoticComponent<any> = styled(AntBreadcrumb.Item)<ColorProps | TypographyProps>`
  ${color};
  ${typography};
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CaretIcon = styled(CaretDown)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

export default PageHeader
