import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Menu } from 'antd'
import { color, ColorProps, layout, LayoutProps } from 'styled-system'
import { Link } from 'react-router-dom'

interface Props {
  activePage: string,
  items: Array<{key: string, text: string, url: string, icon: React.ReactNode}>
}

const BottomNavigationBar: React.FC<Props> = props => {
  return (
    <ContainerDiv width={1} bg="white">
      <StyledMenu defaultSelectedKeys={[props.activePage]} mode="horizontal">
        {_.map(props.items, item => {
          return (
            <StyledMenuItem key={item.key}>
              {item.icon}
              <span>{item.text}</span>
              <Link to={item.url} />
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div<LayoutProps | ColorProps>`
  ${layout};
  ${color};
  position: fixed;
  bottom: 0px;
  z-index: 999;
  box-shadow: 0px -3px 8px rgba(238, 238, 238, 0.35);
`

const StyledMenu = styled(Menu)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledMenuItem = styled(Menu.Item)`
  flex-grow: 1;
`

export default BottomNavigationBar
