import React, { useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Table, Row, Col } from 'antd'

import { ColumnsType } from 'antd/lib/table'
import Radio, { RadioChangeEvent } from 'antd/lib/radio'
import _ from 'lodash'
import Pagination from 'antd/lib/pagination'
import { List, Typography } from 'antd'

import { SchemaInferred } from '../../types/FileUploadState'
import { EditStateDispatcher } from './FileUploadModalState'

const DataSummary: React.FC<{
  filename: string
  rowCount: number
  columnCount: number
}> = ({ filename, rowCount, columnCount}) => (
  <>
    <Typography.Text strong>Summary</Typography.Text>
    <p>
      The file <code>{filename}</code> appears to
      have <b>{rowCount}</b> rows
      and <b>{columnCount}</b> columns.
    </p>
  </>
)

const ColumnNamesToggle: React.ExoticComponent<{
  hasHeader: boolean
}> = React.memo(({ hasHeader }) => {
  const dispatch = useContext(EditStateDispatcher)
  const onHasHeaderChanged = useCallback((e: RadioChangeEvent) => {
    dispatch({
      type: 'set-has-header',
      hasHeader: Boolean(e.target.value),
    })
  }, [dispatch])

  return (
    <>
      <Typography.Text strong>Column names</Typography.Text>
      <p>
        <Radio.Group onChange={onHasHeaderChanged} value={hasHeader} buttonStyle="solid">
          <div><Radio value={true}>The first row has column names</Radio></div>
          <div><Radio value={false}>There is no header row</Radio></div>
        </Radio.Group>
      </p>
    </>
  )
})

const FilePreviewContent: React.FC<{
  displayColumns: number
  hasHeader: boolean
  state: SchemaInferred
}> = ({
  displayColumns,
  hasHeader,
  state,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const {
    filename,
    inference: {
      rowCount: totalRows,
      exampleRows: rows,
    }
  } = state

  const [header, ...rest] = rows
  let titles = header

  const maxPages = Math.ceil(titles.length / displayColumns)
  const page = Math.min(maxPages, currentPage)

  // Create column definition
  const allColumns: ColumnsType<any> = titles.map((title, i) => ({
    title: hasHeader ? title : `col_${i + 1}`,
    dataIndex: i,
    key: i,
    ellipsis: true,
  }))

  // Pad with empty columns to make a multiple of displayColumns
  while (allColumns.length % displayColumns !== 0) {
    allColumns.push({
      title: '',
      dataIndex: 0,
      key: allColumns.length,
      render: () => '',
    })
  }
  const shownColumns = page * displayColumns <= titles.length ? displayColumns : titles.length % displayColumns

  const rowCount = hasHeader ? totalRows - 1 : totalRows
  const columnCount = titles ? titles.length : 0

  // Select columns to show
  const columns = _.take(_.drop(allColumns, (page - 1) * displayColumns), displayColumns)

  // Don't include column names
  const adjustedRows = _.take(hasHeader && header ? rest : rows, 5)

  if (displayColumns === 1) {
    // Render a single list with columns stacked on top of one another
    interface ColumnGroup {
      title: string
      rows: string[]
    }
    const columnGroups: ColumnGroup[] = allColumns.map(({ title }) => ({ title: String(title), rows: [] }))

    // Include only 3 rows per column
    _.take(adjustedRows, 3)
      .forEach((row, i) => {
        row.forEach((field, j) => {
          columnGroups[j].rows.push(field)
        })
      })

    return (
      <>
        <div style={{ margin: "0 8px 24px 8px"}}>
          <DataSummary {...{ filename, rowCount, columnCount }} />
          <ColumnNamesToggle hasHeader={hasHeader} />
        </div>
        <List
          dataSource={columnGroups}
          renderItem={(column: ColumnGroup) => (
            <List
              size="small"
              header={<b style={{margin: "0 8px"}}>{column.title}</b>}
              footer={(rowCount > rows.length) ? (<div style={{ userSelect: 'none', margin: "-4px 16px"}}>⋮</div>) : null}
              dataSource={column.rows}
              renderItem={(row) => (
                <List.Item>
                  <Typography.Text style={{ margin: "0 -8px" }} ellipsis>{row}</Typography.Text>
                </List.Item>
              )}
            />
          )}
        />
      </>
    )
  } else {
    return (
      <StyledTable
        size="middle"
        columns={columns}
        dataSource={adjustedRows}
        pagination={false}
        tableLayout="fixed"
        showHeader={true}
        title={() => (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <DataSummary {...{filename, rowCount, columnCount}} />
              </Col>
              <Col span={12}>
                <ColumnNamesToggle hasHeader={hasHeader} />
              </Col>
            </Row>

            <PaginationBar marginTop={48}>
              <Right>
                <Pagination
                  total={columnCount}
                  showTotal={(total, range) => `Columns ${range[0]}-${range[1]} out of ${total}`}
                  pageSize={displayColumns}
                  showSizeChanger={false}
                  current={page}
                  onChange={current => setCurrentPage(current)}
                  showQuickJumper={false}
                  hideOnSinglePage={true}
                />
              </Right>
            </PaginationBar>
          </>
        )}
        footer={() => {
          if (rowCount > rows.length) {
            return (
              <Row style={{ userSelect: 'none', marginLeft: '-8px', marginRight: '-8px' }} >
                {columns.map((_, i) => <Col style={{paddingLeft: '12px' }} flex={1} key={i} >{i < shownColumns ? '⋮' : ''}</Col>)}
              </Row>
            )
          } else {
            return null
          }
        }}
      />
    )
  }
}

const PaginationBar = styled.div<SpaceProps>`
  ${space};
`

const Right = styled.div`
  text-align: right;
`

const StyledTable = styled(Table)`
  & .ant-table-footer {
    background-color: white;
  }
`

export default FilePreviewContent
