import React from 'react'
import { Route } from 'react-router-dom'
import PageLayout from '../layout/PageLayout'
import PrivateRoute from './PrivateRoute'

interface Props {
  component: any,
  isPrivate?: boolean
  path?: string,
  exact?: boolean
}

const PageLayoutRoute: React.FC<Props> = ({ component: Component, isPrivate, ...rest }) => {
  const RouteComponent = isPrivate ? PrivateRoute : Route
  return (
    <RouteComponent
      {...rest}
      render={(matchProps: any) => (
        <PageLayout>
          <Component {...matchProps} />
        </PageLayout>
      )}
    />
  )
}

export default PageLayoutRoute
