import React from 'react'
import styled from 'styled-components'
import { color, ColorProps, typography, TypographyProps } from 'styled-system'
import { HeaderScale } from '../styles/theme'

interface SectionProps {
  children: React.ReactNode
}

const Level = React.createContext(1)

export const Section: React.FC<SectionProps> = props => {
  return (
    <Level.Consumer>
      {level => (
        <Level.Provider value={level + 1}>{props.children}</Level.Provider>
      )}
    </Level.Consumer>
  )
}

export const Heading: React.FC<any> = props => {
  return (
    <Level.Consumer>
      {level => {
        const headerLevel = Math.min(level, 6)
        let headerScale
        switch (headerLevel) {
          case 1:
            headerScale = HeaderScale.primaryHeader
            break
          case 2:
            headerScale = HeaderScale.secondaryHeader
            break
          case 3:
            headerScale = HeaderScale.thirdHeader
            break
          case 4:
            headerScale = HeaderScale.fourthHeader
            break
          default:
            headerScale = null
        }
        const header = `h${headerLevel}`
        return <StyledHeading as={header} fontSize={headerScale} fontWeight="bold" color="mullWine" {...props} />
      }}
    </Level.Consumer>
  )
}

const StyledHeading = styled.div<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
