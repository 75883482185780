// @ts-ignore
const theme = {
  // We will need to use this array syntax for breakpoints so that the short hand media queries work properly,
  // however, the use of break point as object is defined as aliases at the end of this file, by
  // doing that, we can then use the break point as namespace later in the code
  // eg: <Heading color={{ xs: 'green', sm: 'blue', md: 'red' }}>Hello</Heading>
  breakpoints: ['40em', '48em', '64em', '80em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },
  sizes: {
    navBarHeight: 64,
    pageHeaderHeight: 80
  },
  fonts: {
    body: "'Source Sans Pro', sans-serif",
    header: "'Raleway', sans-serif"
  },
  colors: {
    googleRed: '#de5246',
    black: '#000',
    nearBlack: '#111',
    nearWhite: '#f9f9f9',
    white: '#fff',
    transparent: 'transparent',
    mullWine: '#494B6A',
    scorpion: {
      darker: '#3e3e3e',
      dark: '#4a4a4a',
      medium: '#5e5e5e',
      light: '#aaaaaa',
      lighter: '#eeeeee'
    },
    jade: {
      darker: '#004121',
      dark: '#007e44',
      medium: '#00b06a',
      light: '#57d684',
      lighter: '#b0f1b5'
    },
    carnationPink: {
      darker: '#332721',
      dark: '#996467',
      medium: '#ffa9d3',
      light: '#ffcbd8',
      lighter: '#ffeff0'
    },
    silverTree: {
      darker: '#205341',
      dark: '#3f896e',
      medium: '#64b99a',
      light: '#88debf',
      lighter: '#baf5e0'
    },
    sunsetOrange: {
      darker: '#690a01',
      dark: '#b42515',
      medium: '#fd4f39',
      light: '#ffa273',
      lighter: '#ffe9cb'
    },
    bondiBlue: {
      darker: '#001526',
      dark: '#006a6d',
      medium: '#00a9b0',
      light: '#6ad7c6',
      lighter: '#d6f2f5'
    },
    pearlBrush: {
      darker: '#302e2c',
      dark: '#8d847f',
      medium: '#e7dbd3',
      light: '#f5f1e6',
      lighter: '#ffffff'
    },
    azureRadiance: {
      darker: '#013d7a',
      dark: '#015abe',
      medium: '#0077ff',
      light: '#65b2ff',
      lighter: '#c9e7ff'
    },
    eunry: {
      darker: '#563b3e',
      dark: '#926b71',
      medium: '#cb9ea5',
      light: '#e4b6bd',
      lighter: '#f5d5da'
    },
    oracle: {
      darker: '#00282f',
      dark: '#004548',
      medium: '#357972',
      light: '#a1c9b7',
      lighter: '#c5e4c7'
    }
  }
}

// break point aliases
theme.breakpoints.xs = 0
theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]
theme.breakpoints.xl = theme.breakpoints[3]

// header scale for h1, h2, h3, h4 to use in the Heading component, h5, h6 probably won't need scaling
const HeaderScale = {
  primaryHeader: [4, 5], // h1: 24px for mobile layout and 32px for min-width: 40em break point and up
  secondaryHeader: [3, 4], // h2: 20px for mobile layout and 24px for min-width: 40em break point and up
  thirdHeader: [2, 3], // h3: 18px for mobile layout and 20px for min-width: 40em break point and up
  fourthHeader: [1, 2] // h4: 16px for mobile layout and 18px for min-width: 40em break point and up
}

const media = {
  sm: `screen and (min-width: ${theme.breakpoints[0]})`,
  md: `screen and (min-width: ${theme.breakpoints[1]})`,
  lg: `screen and (min-width: ${theme.breakpoints[2]})`,
  xl: `screen and (min-width: ${theme.breakpoints[3]})`
}

module.exports.theme = theme
module.exports.HeaderScale = HeaderScale
module.exports.media = media
