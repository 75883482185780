import axios from 'axios'
import { InstanceType, CreateInstance, isCreateInstance } from 'api'

export function getTeamById(teamId: string) {
  return axios.get(`/api/teams/${teamId}`)
}

export function createTeam(body: { name: string; ownerId: string }) {
  return axios.post(`/api/teams`, body)
}

export function editTeamName(teamId: string, body: { name: string }) {
  return axios.put(`/api/teams/${teamId}`, body)
}

export function inviteTeamMember(
  teamId: string,
  body: { recipient: string; role: string },
) {
  return axios.post(`/api/teams/${teamId}/invitations`, body)
}

export function getTeamInvitations(teamId: string) {
  return axios.get(`/api/teams/${teamId}/invitations`)
}

export function cancelTeamInvitation(teamId: string, invitationId: string) {
  return axios.delete(`/api/teams/${teamId}/invitations/${invitationId}`)
}

export function getTeamInstances(teamId: string) {
  return axios.get(`/api/teams/${teamId}/instances`)
}

export function transferTeamOwnership(teamId: string, userId: string, body: {role: string}) {
  return axios.put(`/api/teams/${teamId}/members/${userId}`, body)
}

export function removeTeamMember(teamId: string, userId: string) {
  return axios.delete(`/api/teams/${teamId}/members/${userId}`)
}

type CreateInstanceTarget =
  | { instanceType: InstanceType.Sandbox, userId: string }
  | { instanceType: Exclude<InstanceType, InstanceType.Sandbox>, teamId: string }

export type CreateInstanceRequest = CreateInstanceTarget & CreateInstance

export function createInstance(request: CreateInstanceRequest) {
  const body = isCreateInstance.strip(request)
  if (request.instanceType === InstanceType.Sandbox) {
    return axios.post(`/api/users/${request.userId}/instances`, body)
  } else {
    return axios.post(`/api/teams/${request.teamId}/instances`, body)
  }
}

export function getTeamMembers(teamId: string, userId: string) {
  return axios.get(`/api/users/${userId}/teams/${teamId}/team-members`)
}

export function deleteTeam(teamId: string) {
  return axios.delete(`/api/teams/${teamId}`)
}

export function getOwnershipTransferBySecret(teamId: string, secret: string) {
  return axios.get(`/api/teams/${teamId}/ownership/${encodeURIComponent(secret)}`)
}

export function getOwnershipTransferByTeamId(teamId: string) {
  return axios.get(`/api/teams/${teamId}/ownership-transfers`)
}

export function acceptOwnershipTransfer(teamId: string, secret: string) {
  return axios.put(`/api/teams/${teamId}/ownership/${encodeURIComponent(secret)}/accept`)
}

export function declineOwnershipTransfer(teamId: string, secret: string) {
  return axios.put(`/api/teams/${teamId}/ownership/${encodeURIComponent(secret)}/decline`)
}
