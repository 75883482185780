import React from 'react'
import TeamTemplate from './TeamTemplate'
import { Box, Flex } from '@rebass/grid'
import { Card, message, Spin } from 'antd'
import styled from 'styled-components'
import { color, ColorProps, typography, TypographyProps} from 'styled-system'
import { TeamSettingsForm } from '../../component'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router'

import { getUserCreditCards } from '../../ducks/user'
import { AppState } from '../../ducks'
import { getTeamById, editTeamName, TeamState, emptyTeamState, deleteTeam } from '../../ducks/teams'

import { teamsPath } from '../../route'
import { User } from 'api'

interface RouteInfo {
  teamId: string
}

interface Props extends RouteComponentProps<RouteInfo>, ReturnType<typeof mapDispatchToProps>  {
  teamById: { [teamId: string]: TeamState }
  userProfile: User
}

class TeamSettings extends React.Component<Props> {

  public async componentDidMount() {
    window.analytics.page('Team settings')
    const { params } = this.props.match
    await this.props.getTeamById(params.teamId)
  }

  public getTeamState(teamId: string): TeamState {
    return this.props.teamById[teamId] || emptyTeamState(teamId)
  }

  public handleSettingsFormSubmit = async (teamName: string) => {
    const { teamId } = this.props.match.params
    await this.props.editTeamName(teamId, {name: teamName})
    const { error } = this.getTeamState(teamId)
    if (error) {
      message.error(error.message)
    } else {
      await this.props.getTeamById(teamId)
      message.success('Settings saved successfully')
    }
  }

  public deleteTeam = async (teamId: string, teamName: string) => {
    window.analytics.track('Delete team', { teamId })
    await this.props.deleteTeam(teamId)
    const { error } = this.getTeamState(teamId)
    if (error) {
      message.error('Something went wrong, please try again')
    } else {
      this.props.history.push(teamsPath)
      message.success(`Team ${teamName} deleted succesfully`)
    }
  }

  public render() {
    const { teamId } = this.props.match.params
    const { team, loading } = this.getTeamState(teamId)
    const teamName = team ? team.name : ''
    const isOwner = team ? team.owner.id === this.props.userProfile.id : false
    const hasInstances = team ? team.instances.length > 0 : false

    return (
      <TeamTemplate activePage="settings" teamId={teamId} teamName={teamName} isOwner={isOwner}>
        {!team ?
          <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <Spin size="large"/>
          </SpinContainer>
          :
          <Flex flexWrap="wrap">
            <Box width={1} py={2}>
              <Card title="Settings" extra={ loading && <Spin /> }>
                <SettingSectionLabel fontSize={3} color="scorpion.dark" fontWeight="bold">
                  General settings
                </SettingSectionLabel>
                <TeamSettingsForm
                  loading={loading}
                  teamName={team.name}
                  onSubmit={this.handleSettingsFormSubmit}
                  teamId={team.id}
                  deleteTeam={this.deleteTeam}
                  hasInstances={hasInstances}
                />
              </Card>
            </Box>
          </Flex>
        }
      </TeamTemplate>
    )
  }
}

const SettingSectionLabel = styled.p<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ getTeamById, editTeamName, deleteTeam, getUserCreditCards }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    teamById: state.teams.teamById,
    userProfile: state.auth.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings)
