import axios, { AxiosResponse } from 'axios'
import { PaymentMethod } from '@stripe/stripe-js'

import { SubscriptionAndInvoice, BillingDetails, InstanceProduct, isAitoPromoCode, AitoPromoCode } from 'api'

function axiosReject<T>(response: AxiosResponse<T>): Promise<T> {
  return Promise.reject({ message: response.statusText, response: response.data })
}

export async function getCustomerId(userId: string): Promise<{ customerId: string }> {
  const response = await axios.get(`/api/payments/${userId}/customerId`)
  if (response.status === 200) {
    return response.data
  }

  return axiosReject(response)
}

export async function getCustomerCreditCards(): Promise<PaymentMethod[]> {
  const response = await axios.get(`/api/payments/my/creditcards`)
  if (response.status === 200) {
    return response.data as PaymentMethod[]
  }

  return axiosReject(response)}

export async function getCustomerDefaultCardId(): Promise<{ id: string }> {
  const response = await axios.get(`/api/payments/my/creditcards/default`)
  if (response.status === 200) {
    return response.data as { id: string }
  }

  return axiosReject(response)}

export async function getCustomerInvoices(): Promise<SubscriptionAndInvoice[]> {
  const response = await axios.get(`/api/payments/my/invoices`, { params: { getCharge: true } })
  if (response.status === 200) {
    return response.data
  }

  return axiosReject(response)
}

export async function getCustomerUpcomingInvoices(): Promise<SubscriptionAndInvoice[]> {
  const response = await axios.get(`/api/payments/my/invoices/upcoming`)
  if (response.status === 200) {
    return response.data
  }

  return axiosReject(response)}

export async function updateBillingDetails(billingDetails: BillingDetails): Promise<any> {
  const response = await axios.post(`/api/payments/my/billingdetails`, billingDetails)
  if (response.status === 201) {
    return response.data
  }

  return axiosReject(response)
}

export async function createPaymentIntent(): Promise<any> {
  const response = await axios.get(`/api/payments/my/paymentintent`)
  if (response.status === 200) {
    return response.data
  }
  return axiosReject(response)
}

export async function makeCustomerDefaultCardId(paymentMethodId: string): Promise<any> {
  const response = await axios.post(`/api/payments/my/creditcards/default`, { paymentMethodId })
  if (response.status === 201) {
    return response.data
  }
  return axiosReject(response)
}

export async function deleteCreditCard(paymentMethodId: string): Promise<any> {
  const response = await axios.delete(`/api/payments/my/creditcards/${paymentMethodId}`)
  if (response.status === 200) {
    return response.data
  }
  return axiosReject(response)
}

export async function geteUserTaxExempt(): Promise<{ exempt: 'string', vat: number }> {
  const response = await axios.get('/api/payments/my/tax-exempt')
  if (response.status === 200) {
    return response.data
  }
  return axiosReject(response)
}

export async function getProducts(): Promise<InstanceProduct[]> {
  const response = await axios.get('/api/payments/products')
  if (response.status === 200) {
    return response.data
  }
  return axiosReject(response)
}

export async function getPromotionCode(promoCode: string) : Promise<AitoPromoCode | undefined> {
  try {
    const response = await axios.get(`/api/payments/my/promotionCode`, { params: { promoCode } })
    if (response.status === 200 || response.status === 201) {
      return isAitoPromoCode.validate(response.data)
    } else {
      return undefined
    }
  } catch (error) {
    return error.response
  }
}
