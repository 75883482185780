import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

interface Props {
  activePage: string,
  items: Array<{key: string, text: string, url: string, icon: React.ReactNode, disabled?: boolean, show?: boolean}>
}

const SideMenu: React.FC<Props> = props => {
  return (
    <StyledMenu
      selectedKeys={[props.activePage]}
      mode="inline"
    >
      {_.map(props.items, item => {
        return ( item.show ?
          <Menu.Item key={item.key} disabled={item.disabled}>
            {item.icon}
            <span>{item.text}</span>
            <Link to={item.url} />
          </Menu.Item> : null
        )
      })}
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  height: 100%;
`

export default SideMenu
