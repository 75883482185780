import axios from 'axios'

export function getInvitationBySecret(invitationSecret: string) {
  return axios.get(`/api/invitations/${encodeURIComponent(invitationSecret)}`)
}

export function acceptInvitation(body: { secret: string | string[] }) {
  return axios.post(`/api/invitations`, body)
}

export function ignoreInvitation(body: { secret: string | string[] }) {
  return axios.post(`/api/invitations/ignore`, body)
}
