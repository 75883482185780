import React from 'react'
import * as Scroll from 'react-scroll'
import styled from 'styled-components'
import { Collapse, Row, Col } from 'antd'
import { PredictQuerySender, DataPreview } from './index'
import { color, ColorProps, SpaceProps, space } from 'styled-system'
import { InstanceDetailsObject } from '../types/InstanceDetails'
import { theme } from '../styles/theme'
import _ from 'lodash'
import { AitoClient } from 'api'
import { CheckOutlined } from '@ant-design/icons'

interface Props {
  aitoClient: AitoClient
  instance: InstanceDetailsObject,
}

interface State {
  activeKey: Array<string>
  ready: Array<number>
  query: string
  tables: string[]
  previewRows: any
  selected: string | undefined
  loading: boolean
  errorMessage: string | null
}

class GettingStartedContent extends React.Component<Props, State> {
  readonly state: State = {
    activeKey: ['0', '1'],
    ready: [],
    query: '',
    loading: false,
    tables: [],
    previewRows: [],
    selected: undefined,
    errorMessage: null
  }

  handleOpenPanel = async (activeKey: string, completedKey: number) => {
    await this.setState(oldState => { return {
      activeKey: _.uniq(_.concat(oldState.activeKey, activeKey)),
      ready:  _.uniq(_.concat(oldState.ready, completedKey))
    }})
    const scroll = Scroll.scroller
    scroll.scrollTo('scroll-to-'.concat(activeKey), {
      duration: 1000,
      delay: 50,
      smooth: true,
      offset: 50
    })
  }

  resetOpenPanel = () => {
    const scroll = Scroll.scroller
    scroll.scrollTo('scroll-to-1', {
      duration: 1000,
      delay: 50,
      smooth: true,
      offset: 50
    })
    this.setState({ activeKey: ['0', '1'], ready: [], previewRows: [], selected: undefined })
  }

  queryUpdate(query: string) {
    this.setState({ query })
  }

  async getAitoTables() {
    const schemaReq = await this.props.aitoClient.getSchema()
    const tables = Object.keys(schemaReq.schema)
    return tables
  }

  async handleSelectedTable(table: string) {
    try {
      this.setState({ selected: table, loading: true })
      const tables = await this.getAitoTables()
      const searchQuery = await this.props.aitoClient.search({ from: table, limit: 5 })
      const previewRows = searchQuery.data.hits
      this.setState({ previewRows, tables, loading: false, selected: table })
    } catch(e) {
      this.setState({ loading: false })
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      const tables = await this.getAitoTables()
      this.setState({ tables, loading: false, selected: _.first(tables) })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { activeKey } = this.state
    const readyIcon = <ReadyIcon />
    return (
      <StyledCollapse
        bordered={false}
        activeKey={activeKey}
        bg="transparent"
      >
        <StyledPanel
          showArrow={false}
          header="Getting started with Aito"
          key="0"
          color={this.state.activeKey.includes('0') ? theme.colors.mullWine : '#aaa'}
          mb={3}
        >
          <Row>
            <StyledCol md={24} xs={24}>
              <p>You now have an Aito instance, great!</p>
              <ListHeaderText>Next you can:</ListHeaderText>
              <StyledOrderedList>
                <li>Upload data into Aito</li>
                <li>Make predictions</li>
                <li>Start using the Aito API</li>
              </StyledOrderedList>
            </StyledCol>
          </Row>
        </StyledPanel>
        <StyledPanel
          showArrow={false}
          header="1. Upload data to Aito"
          key="1"
          color={this.state.activeKey.includes('1') ? theme.colors.mullWine : '#aaa'}
          mb={3}
          extra={_.includes(this.state.ready, 1) ? readyIcon : null}
        >
          <Scroll.Element name="scroll-to-1" />
          <DataPreview
            instanceId={this.props.instance.id}
            aitoClient={this.props.aitoClient}
            tables={this.state.tables}
            previewRows={this.state.previewRows}
            selected={this.state.selected}
            loading={this.state.loading}
            handleSelectedTable={this.handleSelectedTable.bind(this)}
            doneButton={() => this.handleOpenPanel('2', 1)}
            tableDeleted={() => this.resetOpenPanel()}
          />
        </StyledPanel>
        <StyledPanel
          showArrow={false}
          header="2. Predict"
          key="2"
          color={this.state.activeKey.includes('2') ? theme.colors.mullWine : '#aaa'}
          mb={3}
          extra={_.includes(this.state.ready, 2) ? readyIcon : null}
        >
          <Scroll.Element name="scroll-to-2" />
          <PredictQuerySender
            instanceId={this.props.instance.id}
            aitoClient={this.props.aitoClient}
            queryUpdated={this.queryUpdate.bind(this)}
            selected={this.state.selected}
            tables={this.state.tables}
            previewRows={this.state.previewRows}
            onReady={() => this.handleOpenPanel('3', 2)}
          />
        </StyledPanel>
        <StyledPanel
          showArrow={false}
          header="3. Discover the API"
          key="3"
          color={this.state.activeKey.includes('3') ? theme.colors.mullWine : '#aaa'}
          mb={3}
        >
        <Scroll.Element name="scroll-to-3" />
        <Row gutter={16}>
          <Col md={24} xs={24}>
              <p>Take your instance into further use with the API.</p>
              <p>
                Get a quick overview of the API with the Swagger UI and if you're at home on the command line you can
                copy and paste the query above and run it with curl. Try changing the values in the query.
              </p>
              <ListHeaderText>Next steps: </ListHeaderText>
              <ul>
              <li>
                  <a href={`/instances/${this.props.instance.id}/overview`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Overview page
                  </a>: find the hostname and API keys of your instance
                </li>
                <li>
                  <a href={`https://aito.ai/docs/swagger/?aitoEnv=${this.props.instance.hostname}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  Swagger UI
                  </a>: interactive interface to explore Aito's API
                </li>
                <li>
                  <a href="https://aito.ai/docs/api"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    API docs
                  </a>: technical reference of the API
                </li>
                <li>
                  <a href="https://aito.document360.io/docs/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    Aito manual
                  </a>: guides, tutorials, and tips how to use Aito
                </li>
                <li>
                <a href={`https://www.youtube.com/channel/UCLVFis53pOci-S1YdIKQuOA`}
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  YouTube
                </a>: our video tutorials
                </li>
              </ul>
          </Col>
        </Row>
        </StyledPanel>
      </StyledCollapse>
    )
  }
}

const StyledCollapse = styled(Collapse)<ColorProps>`
  ${color};
`

const StyledPanel = styled(Collapse.Panel)<ColorProps | SpaceProps | any>`
  ${space};
  border-radius: 4px;
  border: 0;
  overflow: hidden;
  background-color: white;

  & > .ant-collapse-header {
    cursor: default;
    /* could not figure out how to use without important */
    color: ${props => props.color} !important;
  }
`

const StyledCol = styled(Col)`
  padding: 0 16px;
`

const ReadyIcon = styled(CheckOutlined)`
  color: ${theme.colors.jade.medium};
`
const StyledOrderedList = styled.ol`
  margin: 0;
  padding-left: 20px;
`
const ListHeaderText = styled.p`
  margin-bottom: 0;
`

export default GettingStartedContent
