import { TeamRole } from 'api'

const roleToString = (role: string) => {
  switch (role) {
    case TeamRole.Owner: return 'Owner'
    case TeamRole.Member: return 'Member'
    case TeamRole.Guest: return 'Guest'
    default: return ''
  }
}

export default roleToString
