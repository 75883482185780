import { action } from 'typesafe-actions'
import { Reducer } from 'redux'
import * as api from '../utils/api/tosApi'
import { TermsOfService } from '../types/TermsOfService'

enum actionTypes {
  TOS_REQUEST_PROCESSING = 'tos/TOS_REQUEST_PROCESSING',
  TOS_REQUEST_ERROR = 'tos/TOS_REQUEST_ERROR',
  GET_TOS_SUCCESS = 'tos/GET_TOS_SUCCESS',
}

export interface TosState {
  loading: boolean
  errorMessage: string | null
  latestTos: TermsOfService | null
}

const initialState: TosState = {
  loading: false,
  errorMessage: null,
  latestTos: null,
}

export const getLatestTos = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.TOS_REQUEST_PROCESSING))
    try {
      const response = await api.getLatestTos()
      const latestTos = response.data
      dispatch(action(actionTypes.GET_TOS_SUCCESS, latestTos))
    } catch (e) {
      const message = 'Failed to fetch latest terms of service'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.TOS_REQUEST_ERROR, { response: data, message }))
    }
  }
}

const reducer: Reducer = (
  state: TosState = initialState,
  action: any,
): TosState => {
  switch (action.type) {
    case actionTypes.TOS_REQUEST_PROCESSING: {
      return { ...state, loading: true }
    }

    case actionTypes.TOS_REQUEST_ERROR: {
      return { ...state, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.GET_TOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        latestTos: action.payload,
        errorMessage: null,
      }
    }

    default: {
      return state
    }
  }
}

export { reducer as tosReducer }
