import React, { ExoticComponent } from 'react'
import { PageHeader, BottomNavigationBar, SideMenu } from '../../component'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { media, theme } from '../../styles/theme'
import { MenuAltLeft } from '@styled-icons/boxicons-regular/MenuAltLeft'
import MediaQuery from 'react-responsive'
import { profileOverviewPath, profileInvoicesPath, profilePaymentPath } from '../../route'
import { FormatListBulleted } from '@styled-icons/material/FormatListBulleted'
import { Euro} from '@styled-icons/boxicons-regular/Euro'
interface Props {
  children: React.ReactNode
  activePage: string
  userFullname: string
}

interface State {}

class ProfileTemplate extends React.Component<Props, State> {

  private static defaultProps = { activePage: 'overview' }
  render() {
    const { userFullname } = this.props
    const menuItems = [
      {
        key: 'overview',
        text: 'Overview',
        url: profileOverviewPath,
        icon: <OverviewIcon size={24} mr={2}/>,
        show: true
      },
      {
        key: 'invoices',
        text: 'Invoices',
        url: profileInvoicesPath,
        icon: <InvoicesIcon size={24} mr={2}/>,
        show: true
      },
      {
        key: 'payment',
        text: 'Payment details',
        url: profilePaymentPath,
        icon: <PaymentIcon size={24} mr={2}/>,
        show: true
      }
    ]

    const breadcrumbItems = [
      {
        text: userFullname,
        icon: false
      }
    ]

    return (
      <ContainerDiv>
        <PageHeader items={breadcrumbItems}/>
        <FlexContainer flexWrap="wrap">
          <MediaQuery query={media.sm}>
            <Box width={1 / 5}>
              <SideMenu activePage={this.props.activePage} items={menuItems}/>
            </Box>
          </MediaQuery>

          <Box width={[1, 4 / 5]} p={3} mb={[4,0]}>
            {this.props.children}
          </Box>
        </FlexContainer>

        <MediaQuery query={media.sm}>
          {(matches: any) => {
            if (matches) {
              return null
            }
            return (
              <BottomNavigationBar
                activePage={this.props.activePage}
                items={menuItems}
              />
            )
          }}
        </MediaQuery>
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled.div`
  height: calc(100vh - (${theme.sizes.navBarHeight}px + ${theme.sizes.pageHeaderHeight}px));
`

const FlexContainer = styled(Flex)`
  height: 100%;
`

const OverviewIcon: ExoticComponent<any> = styled(MenuAltLeft)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const InvoicesIcon: ExoticComponent<any> = styled(FormatListBulleted)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const PaymentIcon: ExoticComponent<any> = styled(Euro)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

export default ProfileTemplate
