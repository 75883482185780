import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../ducks'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  ColorProps,
  color,
  TypographyProps,
  typography
} from 'styled-system'
import { Spin, Tag as AntTag } from 'antd'
import { Link, RouteComponentProps} from 'react-router-dom'
import { AddCircle } from '@styled-icons/material'

import { InstanceType, TeamRole } from 'api'

import { PageHeader, InstanceCard, ActionBar } from '../component'
import { ConsoleUser as User } from '../types/User'
import { getUserInstances, UserInstancesState } from '../ducks/instances'
import { InstancePreview } from '../types/InstancePreview'
import { createInstancePath, instanceOverviewPath, teamOverviewPath } from '../route'
import { getUserTeams, UserTeamsState } from '../ducks/teams'
import variables from '../variables'
import roleToString from '../utils/roleToString'
import { TeamPreview } from '../types/Team'

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  userInstances: UserInstancesState
  userProfile: User
  userTeams: UserTeamsState
}

interface State {
  instances: Array<InstancePreview>,
  loading: boolean
}

const breadcrumbItems = [
  {
    text: 'Instances',
    icon: false
  }
]

const roleOrder: Record<TeamRole, number> = {
  OWNER: 0,
  MEMBER: 1,
  GUEST: 2,
}

function userRoleOf(userId: string, team: TeamPreview): TeamRole | undefined {
    const leftMember = _.first(_.filter(team.members, { user: { id: userId }}))
    return leftMember && leftMember.role
}

function filterInstanceGroups(userId: string, groups: TeamPreview[]): TeamPreview[] {
  return _.filter(groups, group => {
    const role = userRoleOf(userId, group)
    return role === 'OWNER' || group.instanceCount > 0
  })
}

function sortedInstanceGroups(userId: string, groups: TeamPreview[]): TeamPreview[] {

  function roleCmp(left: TeamRole | undefined, right: TeamRole | undefined): number {
    const leftOrdinal = (left && left in roleOrder) ? roleOrder[left] : 3
    const rightOrdinal = (right && right in roleOrder) ? roleOrder[right] : 3
    return leftOrdinal - rightOrdinal
  }

  function stringCmp(left: string, right: string): number {
    const leftLower = left.toLowerCase()
    const rightLower = right.toLowerCase()
    if (leftLower < rightLower) return -1
    if (leftLower > rightLower) return 1
    return 0
  }

  // First by role (owner < member < guest), then by name
  function cmp(left: TeamPreview, right: TeamPreview): number {
    const leftRole = userRoleOf(userId, left)
    const rightRole = userRoleOf(userId, right)

    const c = roleCmp(leftRole, rightRole)
    if (c === 0) {
      return stringCmp(left.name, right.name)
    } else {
      return c
    }
  }

  return groups.sort(cmp)
}

const roleColors = variables.ROLE_COLORS as any

class Instances extends React.Component<Props, State> {

  constructor(props: any) {
    super(props)
    this.state = {
      instances: [],
      loading: true
    }
  }

  navigateToDetailPage = (instanceId: string) => {
    const { history } = this.props
    history.push(instanceOverviewPath(instanceId))
  }

  componentDidMount = async () => {
    window.analytics.page('Instances')
    if (!!this.props.userProfile) {
      this.props.getUserInstances(this.props.userProfile.id)
      this.props.getUserTeams(this.props.userProfile.id)
    }
  }

  renderInstances = (userId: string, instancesByTeam: _.Dictionary<InstancePreview[]>, teams: TeamPreview[]) => {
    return _.map(teams, team => {
      const teamId = team.id
      const teamInstances = instancesByTeam[teamId] || []
      // @ts-ignore
      const teamName = team.name
      const role = userRoleOf(userId, team)
      const pathname = team ? teamOverviewPath(teamId) : ''
      return (
        <InstanceList mt={3} key={teamId}>
          <Flex flexWrap="wrap" p={2} alignItems="center">
            <Link to={{ pathname }}>
              <TeamNameText fontSize={5} fontWeight="bolder" color="mullWine">
                  {teamName}
              </TeamNameText>
            </Link>
            {role ?
                <Tag fontSize={[0, 1]} color={roleColors[role].bg}>
                  <RoleText color={roleColors[role].color}>
                    {roleToString(role)}
                  </RoleText>
                </Tag>
              : null
            }
          </Flex>
          <Flex flexWrap="wrap">
            {_.map(teamInstances, i => {
              return (
                <Box
                  width={[1, 1 / 2]}
                  p={2}
                  onClick={() => this.navigateToDetailPage(i.id)}
                  key={i.id}
                >
                  <InstanceCard instance={i} />
                </Box>
              )
            })}
            {role === TeamRole.Owner &&
              <Box
                width={[1, 1 / 2]}
                p={2}
              >
                <Link to={{ pathname: createInstancePath, state: { teamId }}}>
                  <CreateInstanceCard bg="white" justifyContent="center" alignItems="center" flexWrap="wrap">
                    Create a new instance
                    <AddIcon size={24} color="silverTree.medium" ml={2} />
                  </CreateInstanceCard>
                </Link>
              </Box>
            }
          </Flex>
        </InstanceList>
      )
    })
  }

  render() {
    const actionButton = {
      text: 'Create new instance',
      url: createInstancePath
    }

    const userId = this.props.userProfile.id
    const { loading: userLoading, instances } = this.props.userInstances
    const { loading: teamLoading, teams } = this.props.userTeams
    const instancesByTeam = _.groupBy(_.filter(instances, i => i.type !== InstanceType.Sandbox), 'team.id')
    const sandboxInstances = _.filter(instances, i => i.type === InstanceType.Sandbox)

    const instanceGroups =
      filterInstanceGroups(userId, sortedInstanceGroups(userId, teams || []))

    return (
      <div>
        <PageHeader items={breadcrumbItems}/>
        <ContentDiv p={[2, 4]} maxWidth={900}>
          <ActionBar actionButton={actionButton} />
          {(userLoading || teamLoading)
            ? <Flex width={1} justifyContent="center"><StyledSpin size="large" mt={6} /></Flex>
            : (
              <div>
                <InstanceList mt={3}>
                  <Flex flexWrap="wrap" p={2} alignItems="center">
                    <TeamNameText fontSize={5} fontWeight="bolder" color="mullWine">
                        My Sandbox
                    </TeamNameText>
                  </Flex>
                  <Flex flexWrap="wrap">
                    {_.map(sandboxInstances, (si) => (
                      <Box
                        width={[1, 1 / 2]}
                        p={2}
                        onClick={() => this.navigateToDetailPage(si.id)}
                        key={si.id}
                      >
                        <InstanceCard instance={si} />
                      </Box>
                    ))}
                    <Box width={[1, 1 / 2]} p={2}>
                      {sandboxInstances && sandboxInstances.length > 0 ? null :
                        <Link to={{ pathname: createInstancePath }}>
                          <CreateInstanceCard bg="white" justifyContent="center" alignItems="center" flexWrap="wrap">
                            Create a new instance
                            <AddIcon size={24} color="silverTree.medium" ml={2} />
                          </CreateInstanceCard>
                        </Link>
                      }
                    </Box>
                  </Flex>
                </InstanceList>
                {this.renderInstances(userId, instancesByTeam, instanceGroups)}
              </div>
            )
          }
        </ContentDiv>
      </div>
    )
  }
}

const ContentDiv = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`

const StyledSpin = styled(Spin)<SpaceProps>`
  ${space};
`
const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const InstanceList = styled.div<SpaceProps>`
  ${space};
`
const TeamNameText = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const Tag = styled(AntTag)<TypographyProps>`
  ${typography};
  line-height: unset;
  margin-left: 20px;
`
const RoleText = styled.span`
  ${color};
`
const CreateInstanceCard = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
  min-height: 158px;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ getUserInstances, getUserTeams }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userInstances: state.instances.userInstances,
    userProfile: state.auth.userProfile,
    userTeams: state.teams.userTeams
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Instances)
