import React, { ExoticComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../svg-assets/aito-logo.svg'
import _ from 'lodash'
import {
  ColorProps,
  color,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  TypographyProps,
  typography
} from 'styled-system'
import { Button, InstanceTypeTag, AddOrChangePaymentModal, CreditCard, PowerByStripe } from '../component'
import { Flex, Box } from '@rebass/grid'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { message, Spin, Table, Card } from 'antd'
import { AddCircle } from '@styled-icons/material'
import { AppState } from '../ducks'
import { teamOverviewPath } from '../route'
import { theme } from '../styles/theme'
import { getOwnershipTransfer, acceptOwnershipTransfer, declineOwnershipTransfer } from '../ducks/ownershipTransfer'
import { getUserCreditCards, getUserDefaultCardId } from '../ducks/user'
import { getUserInstances, UserInstancesState } from '../ducks/instances'
import { InstancePreview } from '../types/InstancePreview'
import { getProducts } from '../utils/api/paymentsApi'
import { PaymentMethod } from '@stripe/stripe-js'
import { InstanceProduct, User } from 'api'


interface InstanceCol {
  name: string,
  cost: number,
  type: string
}

const instanceTableCol = [
  {
    title: 'Instance',
    dataIndex: 'name',
    key: 'instanceName',
  },
  {
    title: 'Cost',
    key: 'cost',
    dataIndex: 'cost',
    render: (cost: number) => (
      <React.Fragment>{(cost / 100).toFixed(2)} €</React.Fragment>
    )
  },
  {
    title: 'Type',
    key: 'instanceType',
    dataIndex: 'type',
    render: (type: any) => (
      <InstanceTypeTag instanceType={type} />
    )
  },
]

const InstancePreviewTable: React.FC<any> = ({ userInstances }) => {
  return (
    <Table
      rowKey="key"
      columns={instanceTableCol}
      dataSource={userInstances}
      pagination={false}
      scroll={{ y: 240 }}

    />
  )
}

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  ownershipTransfer: any,
  loading: boolean,
  userLoading: boolean,
  creditCards: PaymentMethod[],
  defaultCardId: string,
  userProfile: User,
  userInstances: UserInstancesState,
  error: string
}

interface State {
  paymentModalVisible: boolean,
  isCreatingCard: boolean,
  firstLoading: boolean,
  products: InstanceProduct[]
}

class OwnershipTransfer extends React.Component<Props, State> {

  readonly state: State = {
    paymentModalVisible: false,
    isCreatingCard: true,
    firstLoading: true,
    products: []
  }

  getTeamId(): string {
    return _.get(this.props, 'match.params.teamId')
  }

  async getUserProducts() {
    const products = await getProducts()
    await this.setState({ products })
  }

  async componentDidMount() {
    const secret = queryString.parse(this.props.location.search).secret || ''
    const teamId = this.getTeamId()
    window.analytics.page('Ownership transfer')
    Promise.all([
      this.props.getUserDefaultCardId(),
      this.props.getUserCreditCards(),
      this.props.getOwnershipTransfer(teamId, secret),
      this.getUserProducts()
    ]).then(() => this.setState({ firstLoading: false }))
    
    if (this.props.userProfile) this.props.getUserInstances(this.props.userProfile.id)
  }

  handleAccept = async () => {
    const secret = queryString.parse(this.props.location.search).secret || ''
    const teamId = this.getTeamId()
    await this.props.acceptOwnershipTransfer(teamId, secret)
    this.props.history.push(teamOverviewPath(this.props.ownershipTransfer.teamId))
    message.success('Ownership accepted')
    window.analytics.track('Accept ownership transfer', { teamId })
  }

  handleDecline = async () => {
    const secret = queryString.parse(this.props.location.search).secret || ''
    const teamId = this.getTeamId()
    await this.props.declineOwnershipTransfer(teamId, secret)
    this.props.history.push(teamOverviewPath(this.props.ownershipTransfer.teamId))
    message.info('Ownership transfer declined, you are still a member of the team')
    window.analytics.track('Decline ownership transfer', { teamId })
  }

  togglePaymentModal = (isCreatingCard: boolean) => {
    this.setState({ paymentModalVisible: true, isCreatingCard })
  }

  handleAddPayment = () => {
    this.setState({ paymentModalVisible: false })
    this.props.getUserCreditCards()
  }

  closePaymentModal = () => {
    this.setState({ paymentModalVisible: false })
  }

  render() {
    const { ownershipTransfer, error, creditCards, loading, userLoading } = this.props
    const { firstLoading, products } = this.state
    const anyLoading = firstLoading || loading || userLoading
    let vatP = 0
    if (!anyLoading && ownershipTransfer) {
      const instanceData: InstanceCol[] = ownershipTransfer.instances.map((i: InstancePreview) => {
        const p = products.find(pr => pr.type === i.type)
        if (p) vatP = p.taxPercentage
        return {
          key: i.id,
          name: i.name,
          type: i.type,
          cost: p?.price || 0,
          total: p?.total || 0,
        }
      })

      const total = _.sum(instanceData.map(i => i.cost)) / 100
      const vat = total * vatP / 100

      const renderNoCreditCardExist = (
        <Box width={1} px={2}>
          <PaymentCardContainer>
            <CreditCardText color="mullWine" mb={3}>
              In order to accept
            </CreditCardText>
            <AddPaymentButton color="jade.medium" alignItems="center" onClick={() => this.togglePaymentModal(true)}>
              Add payment method
              <AddIcon size={24} color="jade.medium" ml={2}/>
            </AddPaymentButton>
          </PaymentCardContainer>
        </Box>
      )

      const renderCreditCard = (paymentMethod: PaymentMethod[]) => {
        const cards = paymentMethod.filter(pm => !!pm.card).map(pm => {
          return {
            billingDetails: {...pm.billing_details, vatId: pm.metadata.vat_id},
            cardInfo: pm.card as PaymentMethod.Card
          }
        })

        return(
          <Flex flexWrap="wrap">
            {_.map(cards, (card, i) => {
              return (
                <Box width={1} px={2} key={i} mb={3}>
                  <PaymentCardContainer>
                    <CreditCard editable togglePaymentModal={this.togglePaymentModal} cardHolder={card.billingDetails.name} cardInfo={card.cardInfo} />
                    <CreditCardDescriptionText mt={2} fontSize={0} color="scorpion.light">
                      * Changing the payment method will change the method for all of the paid instances you currently own
                    </CreditCardDescriptionText>
                    <Box mt={3}>
                      <PowerByStripe />
                    </Box>
                  </PaymentCardContainer>
                </Box>
              )
            })}
          </Flex>
        )
      }
      

      return (
        !ownershipTransfer.result ?
          <ContainerDiv
            bg="white"
            flexDirection="column"
            alignItems="center"
        >
            <AitoLogo size={[100, 140]}/>
            <Content flexDirection="column" alignItems="left" width={[1, 1 / 2]} mb={36}>
              <p>
                <b>{ownershipTransfer.inviter}</b> has requested you to be the owner of team&nbsp;
                <b>{ownershipTransfer.teamName}</b>.<br />
                Please assign a payment method to be used for this team
              </p>
            </Content>
            <Content flexDirection="column" alignItems="left" width={[1, 1 / 2]}>
              <Header>Payment method</Header>
              {creditCards && creditCards.length > 0 ? renderCreditCard(creditCards) : renderNoCreditCardExist}
              <AddOrChangePaymentModal
                visible={this.state.paymentModalVisible}
                onSubmit={this.handleAddPayment}
                onCancel={this.closePaymentModal}
                isCreatingCard={this.state.isCreatingCard}
                userInstances={this.props.userInstances.instances}
              />
            </Content>
            <Content flexDirection="column" alignItems="left" width={[1, 1 / 2]} mb={48}>
              <Header>Instances owned by the team</Header>
              <InstancePreviewTable userInstances={instanceData} />
            </Content>
            <Content flexDirection="row" alignItems="left" width={[1, 1 / 2]}>
              <Content width={1 / 2} flexDirection="column">
                <Header>Total</Header>
                <Content width={1} flexDirection="row">
                  <BoldInfo>Price</BoldInfo>
                  <BoldInfo>{total.toFixed(2)} €</BoldInfo>
                </Content>
                <Content width={1} flexDirection="row" mb={24}>
                  <BoldInfo>VAT</BoldInfo>
                  <BoldInfo>{vat.toFixed(2)} €</BoldInfo>
                </Content>
                <TotalCost>{(total + vat).toFixed(2)} €/month </TotalCost>
              </Content>
              <Content width={1 / 2} flexDirection="column">
                <Header>What's next</Header>
                <p>- The subscriptions of the instances are changed immediately to you</p>
                <p>- Upon the acceptance of the ownership change, you will pay the total amount of {(total + vat).toFixed(2)}€</p>
                <p>- As an owner, you can create or delete instances from the team and invite new members</p>
              </Content>
            </Content>
            <ButtonContainer flexDirection="row">
              <StyledButton
                onClick={this.handleAccept}
                bgcolor={theme.colors.jade.medium}
                disabled={creditCards && creditCards.length === 0}
                color="white"
              >
                Accept
              </StyledButton>
              <StyledButton type="default"
                onClick={this.handleDecline}
              >
                Decline
              </StyledButton>
            </ButtonContainer>
          </ContainerDiv>
          :
          <p>This request no longer exist</p>
      )
    } else if (!anyLoading && error) {
      return (
        <ContainerDiv bg="white" flexDirection="column" justifyContent="center" alignItems="center">
          <AitoLogo size={[100, 140]}/>
          <h1>Invalid transfer link</h1>
          <p>It seems that the transfer link is either invalid or has expired.</p>
          <Button><a href='/'>Take me back</a></Button>
        </ContainerDiv>
      )
    } else {
      return (
        <ContainerDiv bg="white" flexDirection="column" justifyContent="center" alignItems="center">
          <Spin size="large"/>
        </ContainerDiv>
      )
    }
  }
}

const ContainerDiv = styled(Flex)<SpaceProps | ColorProps>`
  ${space};
  ${color};
  min-height: 100%;
` as typeof Flex

const Content = styled(Flex)<SpaceProps | ColorProps>`
  ${space};
  ${color};
` as typeof Flex 

const Header = styled.h3`
  font-weight: bold;
  margin-bottom: 12px;
`

const TotalCost = styled.div`
  color: ${theme.colors.jade.medium};
  font-weight: bold;
  font-size: 1.8rem;
`

const BoldInfo = styled.div`
  font-weight: bold;
  width: 100%;
`

const PaymentCardContainer = styled(Card)<SpaceProps>`
  ${space};
  height: 100%;
`

const CreditCardText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${color};
  ${space};
  ${typography};
  display: block;
`

const AddPaymentButton = styled(Flex)<ColorProps>`
  ${color};
  cursor: pointer;
`

const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`

const CreditCardDescriptionText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${color};
  ${typography};
  ${space};
  display: block;
  font-style: italic;
`

const ButtonContainer = styled(Flex)<SpaceProps | ColorProps>`
  ${space};
  ${color};
` as typeof Flex

const StyledButton = styled(Button)`
  margin: 36px;
` as typeof Button

const AitoLogo = styled(Logo)<LayoutProps>`
  ${layout};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getOwnershipTransfer,
    acceptOwnershipTransfer,
    declineOwnershipTransfer,
    getUserCreditCards,
    getUserDefaultCardId,
    getUserInstances
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: state.auth.userProfile,
    userInstances: state.instances.userInstances,
    creditCards: state.user.creditCards,
    defaultCardId: state.user.defaultCard,
    userLoading: state.user.loading,
    error: state.ownershipTransfer.errorMessage,
    loading: state.ownershipTransfer.loading,
    ownershipTransfer: state.ownershipTransfer.ownershipTransfer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipTransfer)
