import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Card, Modal, Spin, notification } from 'antd'
import { bindActionCreators, Dispatch } from 'redux'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, TypographyProps, typography } from 'styled-system'
import { Box, Flex } from '@rebass/grid'
import { AddCircle } from '@styled-icons/material'
import { PaymentMethod } from '@stripe/stripe-js'

import { AddOrChangePaymentModal, PowerByStripe, BillingInfoForm } from '../../component'
import { sendPasswordResetCode, updateUser } from '../../ducks/auth'
import { getUserCreditCards, getUserDefaultCardId, updateUserBillingDetails } from '../../ducks/user'
import { getUserInstances, UserInstancesState } from '../../ducks/instances'
import { AppState } from '../../ducks'
import { ConsoleUser as User, } from '../../types/User'
import ProfileTemplate from './ProfileTemplate'
import CreditCard from '../../component/CreditCard'
import { deleteCreditCard } from '../../utils/api/paymentsApi'

interface StaticProperties {
  userProfile: User,
  loading: boolean,
  errorMessage: string,
  creditCards: PaymentMethod[],
  defaultCardId: string,
  userLoading: boolean,
  userInstances: UserInstancesState
}

interface Props extends StaticProperties, ReturnType<typeof mapDispatchToProps> {}

interface State {
  paymentModalVisible: boolean,
  isCreatingCard: boolean
}
class PaymentDetails extends React.Component<Props, State> {

  readonly state: State = {
    paymentModalVisible: false,
    isCreatingCard: true
  }

  async componentDidMount() {
    window.analytics.page('Payment details')
    const { creditCards, defaultCardId, userProfile } = this.props
    if (!defaultCardId || defaultCardId.trim().length === 0) await this.props.getUserDefaultCardId()
    if (!creditCards || creditCards.length === 0) await this.props.getUserCreditCards()
    if (userProfile?.id) this.props.getUserInstances(userProfile.id)
  }

  togglePaymentModal = (isCreatingCard: boolean) => {
    this.setState({ paymentModalVisible: true, isCreatingCard })
  }

  handleAddPayment = () => {
    this.setState({ paymentModalVisible: false })
    this.props.getUserCreditCards()
  }

  closePaymentModal = () => {
    this.setState({ paymentModalVisible: false })
  }

  deleteModal = async (paymentId: string) => {
    const { confirm } = Modal
    const deleteConfirmation = {
      before: {
        title: `Are you sure to delete this credit card?`,
        content: 'You can only delete the credit card only if you have no active subscriptions.',
        okText: 'Delete',
        cancelText: 'Cancel',
      },
      after: {
        message: 'Credit card deletion succeeded',
      }
    }

    confirm({
      ...(deleteConfirmation.before),
      okType: 'danger',
      centered: true,
      onOk: async () => {
        try {
          await deleteCreditCard(paymentId)
          await this.props.getUserCreditCards()
          notification.success({
            duration: 5,
            ...(deleteConfirmation.after)
          })
        } catch (e) {
          notification.error({
            duration: 5,
            message: 'Credit card deletion failed',
            description: e.response.data.message
          })
        }
      }
    })
  }

  render() {
    const { paymentModalVisible, isCreatingCard } = this.state
    const userProfile = this.props.userProfile || null
    const creditCards = this.props.creditCards

    const renderNoCreditCardExist = (
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]} px={2} mb={3}>
          <PaymentCardContainer title="Payment method">
            <CreditCardText color="mullWine" mb={3}>
              In order to create dev/prod instances you will have to provide your payment information
            </CreditCardText>
            <AddPaymentButton color="jade.medium" alignItems="center" onClick={() => this.togglePaymentModal(true)}>
              Add payment method
              <AddIcon size={24} color="jade.medium" ml={2}/>
            </AddPaymentButton>
          </PaymentCardContainer>
        </Box>
      </Flex>
    )

    const renderCreditCard = (paymentMethod: PaymentMethod[]) => {
      const cards = paymentMethod.filter(pm => !!pm.card).map(pm => {
        return {
          id: pm.id,
          billingDetails: {...pm.billing_details, vatId: pm.metadata.vat_id},
          cardInfo: pm.card as PaymentMethod.Card
        }
      })

      return(
        <Flex flexWrap="wrap">
          {_.map(cards, (card, i) => {
            return (
              <Box width={1} px={2} key={i} mb={3}>
                <PaymentCardContainer title="Payment option">
                  <CreditCard editable togglePaymentModal={this.togglePaymentModal} cardHolder={card.billingDetails.name} cardInfo={card.cardInfo} />
                  <CreditCardDescriptionText mt={2} fontSize={0} color="scorpion.light">
                    * Changing the payment method will change the method for all of the paid instances
                  </CreditCardDescriptionText>
                  <Box mt={3}>
                    <PowerByStripe />
                  </Box>
                  <Box mt={4}>
                    <BillingSectionLabel mb={3} fontSize={3} color="scorpion.medium" fontWeight="bold">
                      Billing information
                    </BillingSectionLabel>
                    <BillingInfoForm
                      updateBillingDetails={this.props.updateUserBillingDetails}
                      billingDetails={card.billingDetails}
                      deleteCard={() => this.deleteModal(card.id)}
                    />
                  </Box>
                </PaymentCardContainer>
              </Box>
            )
          })}
        </Flex>
      )
    }

    const loadingContainer = (
      <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
        <Spin size="large"/>
      </SpinContainer>
    )

    return (
      <ProfileTemplate
        activePage="payment"
        userFullname={userProfile ? userProfile.fullName : ''}
      >
        {this.props.userLoading ?
          loadingContainer :
          <ContentDiv p={2}>
            {creditCards && creditCards.length > 0 ? renderCreditCard(creditCards) : renderNoCreditCardExist}
            <AddOrChangePaymentModal
              visible={paymentModalVisible}
              onSubmit={this.handleAddPayment}
              onCancel={this.closePaymentModal}
              isCreatingCard={isCreatingCard}
              userInstances={this.props.userInstances.instances}
            />
          </ContentDiv>
        }
      </ProfileTemplate>
    )
  }
}

const ContentDiv = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`

const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const PaymentCardContainer = styled(Card)<SpaceProps>`
  ${space};
  height: 100%;
`

const AddPaymentButton = styled(Flex)<ColorProps>`
  ${color};
  cursor: pointer;
`
const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`
const CreditCardText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${color};
  ${space};
  ${typography};
  display: block;
`

const BillingSectionLabel = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const CreditCardDescriptionText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${color};
  ${typography};
  ${space};
  display: block;
  font-style: italic;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    updateUser,
    sendPasswordResetCode,
    getUserCreditCards,
    getUserDefaultCardId,
    getUserInstances,
    updateUserBillingDetails
  }, dispatch)
}

const mapStateToProps = (state: AppState): StaticProperties => {
  return {
    creditCards: state.user.creditCards,
    userInstances: state.instances.userInstances,
    defaultCardId: state.user.defaultCard,
    userLoading: state.user.loading,
    userProfile: state.auth.userProfile,
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)
