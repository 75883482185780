import React, { ExoticComponent } from 'react'
import { Card, message, Modal, Result, Table } from 'antd'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, TypographyProps, typography } from 'styled-system'
import { Box, Flex } from '@rebass/grid'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { CheckCircle } from '@styled-icons/fa-regular'
import { CreditCard } from '@styled-icons/boxicons-solid/CreditCard'
import { PaymentMethod } from '@stripe/stripe-js'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'

import { InstanceType, SubscriptionAndInvoice } from 'api'

import ProfileTemplate from './ProfileTemplate'
import { AppState } from '../../ducks'
import { Button, ProfileSettingsForm, InstanceTypeTag } from '../../component'
import { sendPasswordResetCode, updateUser } from '../../ducks/auth'
import { getUserInstances, UserInstancesState } from '../../ducks/instances'
import { getUserInvoices, getUserUpcomingInvoices, getUserCreditCards, getUserDefaultCardId } from '../../ducks/user'
import { ConsoleUser as User } from '../../types/User'

interface UpcomingPayment {
  instanceName: string
  amount: string,
  dueDate?: string
  teamName: string
  instanceType: InstanceType,
  paymentMethod?: string
}

interface Props extends ReturnType <typeof mapDispatchToProps> {
  userProfile: User,
  loading: boolean,
  errorMessage: string,
  invoices: SubscriptionAndInvoice[] | null,
  upcomingInvoices: SubscriptionAndInvoice[] | null,
  instances: UserInstancesState | null,
  creditCards: PaymentMethod[] | null
  defaultCardId: string | null,
}

interface State {
  changePasswordModalVisible: boolean
}

class Profile extends React.Component<Props, State> {

  readonly state: State = {
    changePasswordModalVisible: false,
  }

  public async componentDidMount() {
    window.analytics.page('Profile')
    if (!this.props.creditCards || this.props.creditCards.length === 0) {
      this.props.getUserCreditCards()
    }
    if (!this.props.defaultCardId || _.trim(this.props.defaultCardId) === '') {
      this.props.getUserDefaultCardId()
    }
    const array = _.get(this.props, 'instances.instances')
    if (!!array || array.length === 0) {
      this.props.getUserInstances(this.props.userProfile.id)
    }
    if (this.props.invoices === null) {
      this.props.getUserInvoices()
    }
    if (this.props.upcomingInvoices === null) {
      this.props.getUserUpcomingInvoices()
    }
  }

  closeChangePasswordModal = () => {
    this.setState({ changePasswordModalVisible: false })
  }

  handleSendPasswordResetCode = async (email: string) => {
    await this.props.sendPasswordResetCode(email)
    if (this.props.errorMessage) {
      message.error(this.props.errorMessage)
    } else {
      this.setState({ changePasswordModalVisible: true })
    }
  }

  handleSaveForm = async (fullName: string) => {
    // tslint:disable-next-line:no-shadowed-variable
    const { userProfile, updateUser } = this.props
    await updateUser(userProfile.id, {fullName})
    const { errorMessage } = this.props
    if (errorMessage) {
      message.error(errorMessage)
    } else message.success('Updated successfully')
  }

  render() {
    const userProfile = this.props.userProfile || {id: '', fullName: '', email: ''}
    const instances: UserInstancesState | null = this.props.instances
    const upcomingInvoices = this.props.upcomingInvoices || []
    const defaultCard = (this.props.creditCards || []).find(cc => cc.id === this.props.defaultCardId)
    const defaultLast4 = _.get(defaultCard, 'card.last4')

    let upcomingPayment: UpcomingPayment[] = []

    if (!_.isEmpty(instances) && !_.isEmpty(upcomingInvoices)) {
      upcomingPayment = upcomingInvoices.map(ui => {
        const instanceId = ui.instanceId

        const totalAmount = ui?.invoice?.amount_due && _.isNumber(ui?.invoice?.amount_due) ? ui.invoice.amount_due : 0

        const instance = instances ? instances.instances.find(i => i.id === instanceId) : null
        const amount = `${totalAmount/100}${ui?.invoice?.currency}` // TODO: Currency symbol?
        const nextPaymentAttempt = ui.invoice ? ui.invoice.next_payment_attempt : null
        const dueDate = nextPaymentAttempt ? format(fromUnixTime(nextPaymentAttempt), 'yyyy-MM-dd HH:mm') : null
        const lastFourDigits = [_.get(ui, 'subscription.default_payment_method'), defaultLast4].find(e => !_.isNil(e))

        if (!instance) {
          return null as unknown as UpcomingPayment
        } else {
          // console.log(`Rendering the following upcoming invoices => ${JSON.stringify(ui, null, 2)}`)
          return {
            instanceName: instance.name,
            amount,
            instanceType: instance.type,
            dueDate,
            paymentMethod: lastFourDigits,
            teamName: _.get(instance, 'team.name')
          } as UpcomingPayment
        }
      }).filter(up => up !== null)
    }
    const upcomingPaymentColumns = [
      {
        title: 'Instance',
        dataIndex: 'instanceName',
        key: 'instanceName'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount'
      },
      {
        title: 'Due date',
        dataIndex: 'dueDate',
        key: 'dueDate'
      },
      {
        title: 'Team',
        dataIndex: 'teamName',
        key: 'teamName'
      },
      {
        title: 'Card',
        key: 'paymentMethod',
        dataIndex: 'paymentMethod',
        render: (payment: string) => (
          <Flex>
            <CreditCardIcon color="silverTree.darker" width={20} mr={1} />
            {payment}
          </Flex>
        )
      },
      {
        title: 'Type',
        key: 'instanceType',
        dataIndex: 'instanceType',
        render: (type: any) => (
          <InstanceTypeTag instanceType={type} />
        )
      }
    ]

    return (
      <ProfileTemplate
        activePage="overview"
        userFullname={userProfile.fullName}
      >
        <ContentDiv p={2}>
          <Flex flexWrap="wrap">
            <Box width={[1, 1, 1, 1 / 2]} px={2} mb={[2, 3]}>
              <ProfileSettingsCard title="User info">
                <Box py={3}>
                  <ProfileSettingsForm
                    userProfile={userProfile}
                    handleChangePassword={this.handleSendPasswordResetCode}
                    onSubmit={this.handleSaveForm}
                  />
                </Box>
              </ProfileSettingsCard>
            </Box>
            <Box width={[1, 1, 1, 1 / 2]} px={2} mb={[2, 3]}>
              <ProfileSettingsCard title="Upcoming payments">
                {
                  upcomingPayment
                   ? <Table rowKey="instanceName" dataSource={upcomingPayment} columns={upcomingPaymentColumns} pagination={false} />
                   : (
                     <Flex justifyContent="center">
                       <ProfileText color="scorpion.light">
                         You do not have any upcoming payment
                       </ProfileText>
                     </Flex>
                   )

                }

              </ProfileSettingsCard>
            </Box>

          </Flex>

          <StyledModal
            closable={false}
            visible={this.state.changePasswordModalVisible}
            footer={null}
            onCancel={this.closeChangePasswordModal}
            centered
          >
            <Result
              icon={<CheckIcon width={70} color="jade.medium"/>}
              status="success"
              title="Password reset link sent successfully"
              subTitle="We've sent a password reset link to your email address, please check your email and follow the instruction."
              extra={
                <Button onClick={this.closeChangePasswordModal}>
                  Close
                </Button>
              }
            />
          </StyledModal>
        </ContentDiv>
      </ProfileTemplate>
    )
  }
}

const ContentDiv = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`
const ProfileSettingsCard = styled(Card)<SpaceProps>`
  ${space};
  height: 100%;
`
const StyledModal = styled(Modal)<LayoutProps>`
  ${layout};
`
const CheckIcon: ExoticComponent<any> = styled(CheckCircle)<LayoutProps | ColorProps>`
  ${layout};
  ${color};
`
const CreditCardIcon: ExoticComponent<any> = styled(CreditCard)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const ProfileText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    updateUser,
    sendPasswordResetCode,
    getUserInvoices,
    getUserInstances,
    getUserUpcomingInvoices,
    getUserCreditCards,
    getUserDefaultCardId
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: state.auth.userProfile,
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
    invoices: state.user.invoices,
    upcomingInvoices: state.user.upcomingInvoices,
    instances: state.instances.userInstances,
    creditCards: state.user.creditCards,
    defaultCardId: state.user.defaultCardId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
