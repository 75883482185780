import React from 'react'
import _ from 'lodash'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
// @ts-ignore
import googlecode from 'react-syntax-highlighter/dist/esm/styles/hljs/googlecode'

interface Props {
  children: React.ReactNode,
  language: string,
  background?: string
}

const aitoCustomSyntaxStyle = (background: string | undefined) => {
  return (
    _.merge({}, _.cloneDeep(googlecode), {
    hljs: {
      background: background || 'none',
      margin: 0
    },
  })
)}

SyntaxHighlighter.registerLanguage('javascript', js)
SyntaxHighlighter.registerLanguage('json', json)

const CodeSnippet: React.FC<Props> = props => {
  const { children, language, background } = props
  return (
    <SyntaxHighlighter language={language} style={aitoCustomSyntaxStyle(background)}>
      {children}
    </SyntaxHighlighter>
  )
}

export default CodeSnippet
