import { theme } from './styles/theme'

import { listOfCountries, listOfCurrencies, listOfStates } from 'api'

/**
 * TODO: These must not be hard coded in the settings, as they'll likely get out of sync faster than we think
 */
const prodApiCalls = '50 000'
const prodStorage = '1 GB'
const devApiCalls = '10 000'
const devStorage = '500 MB'
const devServerHours = '12h'

const variables = {
  dateFnsTimeFormat: 'dd MMM yyyy, HH:mm',
  dateTimeFormat: 'DD MMM YYYY, HH:mm',
  ROLE_COLORS: {
    OWNER: { bg: theme.colors.azureRadiance.dark, color: 'white' },
    MEMBER: { bg: theme.colors.bondiBlue.medium, color: 'white' },
    GUEST: { bg: theme.colors.pearlBrush.dark, color: 'white' },
  },
  TYPE_COLORS: {
    String: { bg: theme.colors.jade.medium, color: 'white' },
    Text: { bg: theme.colors.jade.darker, color: 'white' },
    Int: { bg: theme.colors.azureRadiance.medium, color: 'white' },
    Decimal: { bg: theme.colors.azureRadiance.dark, color: 'white' },
    Boolean: { bg: theme.colors.pearlBrush.dark, color: 'white' },
  },
  CREDIT_CARD_ELEMENT_STYLES: {
    style: {
      base: {
        'color': theme.colors.scorpion.dark,
        'fontFamily': theme.fonts.body,
        'fontSmoothing': 'antialiased',
        'fontSize': '18px',
        '::placeholder': {
          color: '#BFBFBF',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true
  },
  countries: listOfCountries,
  states: listOfStates,
  currencies: listOfCurrencies,
  upgradeToDev: {
    title: "You are upgrading your instance",
    info: [
      `${devApiCalls} / month API calls`,
      `${devStorage} storage size`,
      `${devServerHours} active server hours`
    ]
  },
  upgradeToProd: {
    title: "You are upgrading your instance",
    info: [
      `${prodApiCalls} / month API calls`,
      `Extendable storage size, starting at ${prodStorage}`,
      "Unlimited active server hours "
    ]
  },
  upgradeStorage: {
    title: "You are increasing your storage size",
    info: []
  },
  downgradeStorage: {
    title: "You are decreasing your storage size",
    info: []
  },
  downgradeToDev: {
    title: "You are downgrading your instance",
    info: [
      `API calls from ${prodApiCalls} / month to ${devApiCalls} / month`,
      `Storage size fixed to ${devStorage}`,
      `From unlimited active server hours to ${devServerHours}`
    ]
  }
}

export default variables
