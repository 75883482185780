import _ from 'lodash'
import { action } from 'typesafe-actions'
import { Reducer } from 'redux'
import { Evaluation, PredictionTarget } from 'api'
import { aitoSchemaToColumns } from 'api/schema/datasets'
import { TableSchema } from 'api/schema/aito'

import {
  getInstanceEvaluations,
  getEvaluations,
  startEvaluation,
  createInstanceEvaluations,
  getEvaluationMetrics,
  deletePredictionTargetById,
} from '../utils/api/instanceApi'

enum actionTypes {
  GET_INSTANCE_EVALUATION_STATUS = 'evaluations/GET_INSTANCE_EVALUATION_STATUS',
  GET_INSTANCE_EVALUATION_STATUS_SUCCESS = 'evaluations/GET_INSTANCE_EVALUATION_STATUS_SUCCESS',
  EVALUATION_STATUS_ERROR = 'evaluations/EVALUATION_STATUS_ERROR',

  CREATE_EVALUATION = 'evaluations/CREATE_EVALUATION',
  CREATE_EVALUATION_SUCCESS = 'evaluations/CREATE_EVALUATION_SUCCESS',
  CREATE_EVALUATION_ERROR = 'evaluations/CREATE_EVALUATION_ERROR',

  GET_INSTANCE_EVALUATION_RESULT = 'evaluations/GET_INSTANCE_EVALUATION_RESULT',
  GET_INSTANCE_EVALUATION_RESULT_SUCCESS = 'evaluations/GET_INSTANCE_EVALUATION_RESULT_SUCCESS',

  CHANGE_INSTANCE_EVALUATION_CONFIDENCE = 'evaluations/CHANGE_INSTANCE_EVALUATION_CONFIDENCE',
  CHANGE_INSTANCE_EVALUATION_CONFIDENCE_SUCCESS = 'evaluations/CHANGE_INSTANCE_EVALUATION_CONFIDENCE_SUCCESS',
  CHANGE_INSTANCE_EVALUATION_CONFIDENCE_ERROR = 'evaluations/CHANGE_INSTANCE_EVALUATION_CONFIDENCE_ERROR',

  DELETE_PREDICTION_TARGET = 'evaluations/DELETE_PREDICTION_TARGET',
  DELETE_PREDICTION_TARGET_SUCCESS = 'evaluations/DELETE_PREDICTION_TARGET_SUCCESS',
  DELETE_PREDICTION_TARGET_ERROR = 'evaluations/DELETE_PREDICTION_TARGET_ERROR',
}

export interface EvaluationsState {
  evaluations: { [k: string]: PredictionTarget[] }
  evaluationResults: { [k: string]: Evaluation }
  errorMessage: string | undefined
  confidenceError: string | undefined
  loading: boolean
  deleteLoading: boolean
  changeLoading: boolean
}

export const createInstanceEvaluation = (instanceId: string, values: any, schema: { [k: string]: TableSchema }) => {
  return async (dispatch: any) => {
    try {
      dispatch(action(actionTypes.CREATE_EVALUATION))
      const columns = aitoSchemaToColumns(schema[values.tableName])
      const targetColumn = _.find(columns, { name: values.targetColumn })
      const inputColumns = _.filter(columns, (c) => _.includes(values.inputColumns, c.name))
      if (targetColumn) {
        const created = await createInstanceEvaluations(instanceId, values, inputColumns, targetColumn)
        await startEvaluation(created.data.id)
      }
      dispatch(action(actionTypes.CREATE_EVALUATION_SUCCESS))
    } catch (e) {
      dispatch(action(actionTypes.CREATE_EVALUATION_ERROR, { errorMessage: e.message }))
    }
  }
}

export const changeEvaluationConfidence = (instanceId: string, evaluationId: string, confidence: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(action(actionTypes.CHANGE_INSTANCE_EVALUATION_CONFIDENCE))
      const target = await getInstanceEvaluations(instanceId)
      const metrics = await getEvaluationMetrics(evaluationId, confidence)
      dispatch(action(actionTypes.CHANGE_INSTANCE_EVALUATION_CONFIDENCE_SUCCESS,
        { instanceId, evs: [ metrics.data ], target: target.data }
      ))
    } catch (e) {
      dispatch(action(actionTypes.CHANGE_INSTANCE_EVALUATION_CONFIDENCE_ERROR, { errorMessage: e.message }))
      return { error: e }
    }
  }
}

export const getInstanceEvaluationStatus = (instanceId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(action(actionTypes.GET_INSTANCE_EVALUATION_STATUS))
      const evs = await getInstanceEvaluations(instanceId)
      dispatch(action(actionTypes.GET_INSTANCE_EVALUATION_STATUS_SUCCESS, { instanceId, evaluationStatus: evs.data }))
    } catch (e) {
      dispatch(action(actionTypes.EVALUATION_STATUS_ERROR, { errorMessage: e.message }))
    }
  }
}

export const getEvaluationResults = (instanceId: string, evaluationTgId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(action(actionTypes.GET_INSTANCE_EVALUATION_RESULT))
      const target = await getInstanceEvaluations(instanceId)
      const evs = await getEvaluations(evaluationTgId)
      dispatch(action(actionTypes.GET_INSTANCE_EVALUATION_RESULT_SUCCESS,
        { instanceId, evs: evs.data, target: target.data }
      ))
    } catch (e) {
      dispatch(action(actionTypes.EVALUATION_STATUS_ERROR, { errorMessage: e.message }))
    }
  }
}

export const deletePredictionTarget = (predictionTargetId: string, instanceId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(action(actionTypes.DELETE_PREDICTION_TARGET))
      await deletePredictionTargetById(predictionTargetId)
      const evs = await getInstanceEvaluations(instanceId)
      dispatch(action(actionTypes.DELETE_PREDICTION_TARGET_SUCCESS,
        { instanceId, evaluationStatus: evs.data }
      ))
    } catch (e) {
      dispatch(action(actionTypes.DELETE_PREDICTION_TARGET_ERROR))
      return { error: e.message }
    }
  }
}

const initialState: EvaluationsState = {
  evaluations: {},
  evaluationResults: {},
  loading: false,
  deleteLoading: false,
  changeLoading: false,
  errorMessage: undefined,
  confidenceError: undefined,
}

const reducer: Reducer = (state: EvaluationsState = initialState, action: any): EvaluationsState => {
  switch (action.type) {

    case actionTypes.DELETE_PREDICTION_TARGET: {
      return { ...state, deleteLoading: true }
    }

    case actionTypes.GET_INSTANCE_EVALUATION_RESULT:
    case actionTypes.GET_INSTANCE_EVALUATION_STATUS: {
      return { ...state, loading: true }
    }

    case actionTypes.DELETE_PREDICTION_TARGET_SUCCESS:
    case actionTypes.GET_INSTANCE_EVALUATION_STATUS_SUCCESS: {
      const { instanceId, evaluationStatus } = action.payload
      const { evaluations } = state
      return  {
        ...state,
        evaluations: {...evaluations, [instanceId]: evaluationStatus },
        loading: false, errorMessage: undefined, deleteLoading: false
        }
    }

    case actionTypes.CHANGE_INSTANCE_EVALUATION_CONFIDENCE: {
      return { ...state, changeLoading: true }
    }

    case actionTypes.CHANGE_INSTANCE_EVALUATION_CONFIDENCE_SUCCESS: {
      const { instanceId, evs, target } = action.payload
      const { evaluationResults, evaluations } = state
      return  {
        ...state,
        evaluations: { ...evaluations, [instanceId]: target },
        evaluationResults: {...evaluationResults, [instanceId]: evs },
        changeLoading: false, errorMessage: undefined
      }
    }

    case actionTypes.GET_INSTANCE_EVALUATION_RESULT_SUCCESS: {
      const { instanceId, evs, target } = action.payload
      const { evaluationResults, evaluations } = state
      return  {
        ...state,
        evaluations: { ...evaluations, [instanceId]: target },
        evaluationResults: {...evaluationResults, [instanceId]: evs },
        loading: false, errorMessage: undefined
      }
    }

    case actionTypes.DELETE_PREDICTION_TARGET_ERROR: {
      return  { ...state, deleteLoading: false }
    }

    case actionTypes.CREATE_EVALUATION_ERROR:
    case actionTypes.EVALUATION_STATUS_ERROR: {
      const { errorMessage } = action.payload
      return  { ...state, errorMessage, loading: false, changeLoading: false, deleteLoading: false }
    }

    case actionTypes.CHANGE_INSTANCE_EVALUATION_CONFIDENCE_ERROR : {
      const { errorMessage } = action.payload
      return  { ...state, confidenceError: errorMessage, loading: false, changeLoading: false }
    }

    case actionTypes.CREATE_EVALUATION:
    case actionTypes.CREATE_EVALUATION_SUCCESS:
    default:
      return state
  }
}

export { reducer as evaluationsReducer }
