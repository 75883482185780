import { action } from 'typesafe-actions'
import { Reducer } from 'redux'

import { User } from 'api/console'

import { signInPath, } from '../route'
import * as api from '../utils/api/authApi'
import { auth0Login } from '../utils/auth0Login'
import * as userApi from '../utils/api/userApi'

enum actionTypes {
  AUTH_REQUEST_PROCESSING = 'users/AUTH_REQUEST_PROCESSING',
  USER_SIGN_IN_SUCCESS = 'users/USER_SIGN_IN_SUCCESS',
  USER_SIGN_IN_ERROR = 'users/USER_SIGN_IN_ERROR',
  USER_SIGN_UP_SUCCESS = 'users/USER_SIGN_UP_SUCCESS',
  USER_SIGN_UP_ERROR = 'users/USER_SIGN_UP_SUCCESS_ERROR',
  USER_SIGN_OUT = 'users/USER_SIGN_OUT',
  SEND_RESET_CODE_SUCCESS = 'users/SEND_RESET_CODE_SUCCESS',
  SEND_RESET_CODE_ERROR = 'users/SEND_RESET_CODE_ERROR',
  UPDATE_USER_SUCCESS = 'users/UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'users/UPDATE_USER_ERROR',
  UPDATE_FIRST_TIME_LOGIN = 'users/UPDATE_FIRST_TIME_LOGIN',
  OAUTH_ERROR = 'users/OAUTH_ERROR',
  CLEAR_SIGNIN_ERROR = 'users/CLEAR_SIGNIN_ERROR',
  SAVE_LAST_LOCATION = 'users/SAVE_LAST_LOCATION',
  CLEAR_LAST_LOCATION = 'users/CLEAR_LAST_LOCATION'
}

export interface AuthState {
  loading: boolean
  errorMessage: string | null
  userProfile: Partial<User> | null
  lastLocation: string | null
}

const initialState: AuthState = {
  loading: false,
  errorMessage: null,
  userProfile: null,
  lastLocation: null,
}

export const signUp = (fullName: string, email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.AUTH_REQUEST_PROCESSING))
    try {
      await api.createUser(fullName, email, password)
      dispatch(action(actionTypes.USER_SIGN_UP_SUCCESS))
    } catch (e) {
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.USER_SIGN_UP_ERROR, { response: data, message: e.message }))
    }
  }
}

export const signIn = (email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.AUTH_REQUEST_PROCESSING))
    try {
      await auth0Login(email, password)
    } catch (e) {
      dispatch(action(actionTypes.USER_SIGN_IN_ERROR, { message: e.error_description }))
    }
  }
}

export const signInSuccess = (user: User) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.USER_SIGN_IN_SUCCESS, user))
  }
}

export const sendPasswordResetCode = (email: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.AUTH_REQUEST_PROCESSING))
    try {
      await api.requestPasswordReset(email)
      dispatch(action(actionTypes.SEND_RESET_CODE_SUCCESS))
    } catch (e) {
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.SEND_RESET_CODE_ERROR, { response: data, message: e.message }))
    }
  }
}

export const updateUser = (userId: string, body: {fullName: string}) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.AUTH_REQUEST_PROCESSING))
    try {
      const res = await userApi.updateUser(userId, body)
      dispatch(action(actionTypes.UPDATE_USER_SUCCESS, res.data))
    } catch (e) {
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.UPDATE_USER_ERROR, { ...data, message: e.message }))
    }
  }
}

export const updateUserTosStatus = (userId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.AUTH_REQUEST_PROCESSING))
    try {
      const res = await userApi.updateUserTosStatus(userId)
      dispatch(action(actionTypes.UPDATE_USER_SUCCESS, res.data))
    } catch (e) {
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.UPDATE_USER_ERROR, { ...data, message: e.message }))
    }
  }
}

export const updateFirstTimeLoginStatus = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.UPDATE_FIRST_TIME_LOGIN))
  }
}

export const oAuthError = (error: object) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.OAUTH_ERROR, error))
  }
}

export const clearSignInError = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.CLEAR_SIGNIN_ERROR))
  }
}

export const signOut = () => {
  return async (dispatch: any) => {
    await api.logout()
    dispatch(action(actionTypes.SAVE_LAST_LOCATION, '/'))
    dispatch(action(actionTypes.USER_SIGN_OUT))
  }
}

export const unexpectedSignOut = (location: any) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.SAVE_LAST_LOCATION, location))
    dispatch(action(actionTypes.USER_SIGN_OUT))
  }
}

export const saveLastLocation = (location: any) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.SAVE_LAST_LOCATION, location))
  }
}

export const clearLastLocation = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.CLEAR_LAST_LOCATION))
  }
}

const unstoredLocations = ['/', signInPath]
const savedLocation = (location: string | null): string | null => {
  if (location && unstoredLocations.includes(location)) {
    return null
  }

  return location
}

const reducer: Reducer = (
  state: AuthState = initialState,
  action: any,
): AuthState => {
  switch (action.type) {
    case actionTypes.AUTH_REQUEST_PROCESSING: {
      return { ...state, loading: true }
    }

    case actionTypes.USER_SIGN_IN_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
        errorMessage: null,
      }
    }

    case actionTypes.USER_SIGN_IN_ERROR: {
      return { ...state, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.USER_SIGN_UP_SUCCESS: {
      return { ...state, loading: false, errorMessage: null }
    }

    case actionTypes.USER_SIGN_UP_ERROR: {
      return { ...state, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.SEND_RESET_CODE_SUCCESS: {
      return { ...state, loading: false, errorMessage: null }
    }

    case actionTypes.SEND_RESET_CODE_ERROR: {
      return { ...state, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.UPDATE_USER_SUCCESS: {
      return {...state, loading: false, errorMessage: null, userProfile: action.payload}
    }

    case actionTypes.UPDATE_USER_ERROR: {
      return { ...state, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.UPDATE_FIRST_TIME_LOGIN: {
      return {...state, userProfile: {...state.userProfile, firstTimeLogin: false}}
    }

    case actionTypes.OAUTH_ERROR: {
      return {...state, errorMessage: action.payload.error_description }
    }

    case actionTypes.CLEAR_SIGNIN_ERROR: {
      return {...state, errorMessage: null}
    }

    case actionTypes.SAVE_LAST_LOCATION: {
      const lastLocation = savedLocation(action.payload)
      return { ...state, lastLocation }
    }

    case actionTypes.CLEAR_LAST_LOCATION: {
      return { ...state, lastLocation: null }
    }

    case actionTypes.USER_SIGN_OUT: {
      const lastLocation = savedLocation(state.lastLocation)
      return {...initialState, lastLocation}
    }

    default: {
      return state
    }
  }
}

export { reducer as authReducer }
