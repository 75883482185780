import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Modal, Spin, message, Table } from 'antd'
import { Box, Flex } from '@rebass/grid'
import { color, ColorProps, typography, TypographyProps, SpaceProps, space } from 'styled-system'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { createPaymentIntent, makeCustomerDefaultCardId } from '../utils/api/paymentsApi'
import { PaymentInfoForm, Button, PowerByStripe, InstanceTypeTag } from '../component'
import config from '../config'
import { InstancePreview } from '../types/InstancePreview'

interface Props {
  visible: boolean,
  onCancel: () => void,
  onSubmit: () => void,
  userInstances?: Array<InstancePreview>,
  /* To detect whether we want to add card or update card with this form */
  isCreatingCard: boolean,
  stripe?: any,
  elements?: any
}

const stripePromise = loadStripe(config.stripe.publishableApiKey)

const ModalWrapper: React.FC<Props> = ({ visible, onCancel, onSubmit, isCreatingCard, userInstances }) => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({elements, stripe}) => (
          <AddOrChangePaymentModal
            elements={elements}
            stripe={stripe}
            visible={visible}
            onCancel={onCancel}
            onSubmit={onSubmit}
            isCreatingCard={isCreatingCard}
            userInstances={userInstances}
          />
        )}
      </ElementsConsumer>
    </Elements>  )
}

const instanceTableCol = [
  {
    title: 'Instance',
    dataIndex: 'name',
    key: 'instanceName',
    width: 150,
  },
  {
    title: 'Team',
    key: 'teamName',
    dataIndex: ['team', 'name'],
    width: 150,
  },
  {
    title: 'Type',
    key: 'instanceType',
    dataIndex: 'type',
    render: (type: any) => (
      <InstanceTypeTag instanceType={type} />
    )
  },
]

const InstancePreviewTable: React.FC<any> = ({ userInstances }) => {
  return (
    <Table rowKey="id" columns={instanceTableCol} dataSource={userInstances} pagination={false} scroll={{ y: 240 }} />
  )

}

const AddOrChangePaymentModal: React.FC<Props> =
  ({ visible, onCancel, onSubmit, isCreatingCard, stripe, elements, userInstances }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const submit = async (values: any) => {
    if (!elements || !stripe) return
    await setLoading(true)
    window.analytics.track('Add credit card')

    try {
      const intent = await createPaymentIntent()
      const confirm = await stripe.confirmCardSetup(intent.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: values.cardHolder,
            address: {
              line1: values.company,
              line2: values.billingAddress,
              city: values.city,
              postal_code: values.postalCode,
              country: values.country,
              state: values.state
            }
          },
          metadata: {
            vat_id: values.vatId
          }
        }
      })
      if (confirm.error) {
        message.error('Error occured, please try again')
      } else {
        await makeCustomerDefaultCardId(confirm.setupIntent.payment_method)
        message.success('Card added succesfully')
      }
    } catch (error) {
      message.error('Error occured, please try again')
    }

    setLoading(false)
    form.resetFields()
    onSubmit()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },

    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  return (
    <Modal
      centered
      title={isCreatingCard ? 'Add payment method' : 'Change payment method'}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={1000}
    >
      <Form form={form} {...formItemLayout} layout="vertical" onFinish={submit} labelAlign="left" colon={false} hideRequiredMark>
        <PaymentInfoForm disabled={!stripe || loading} form={form} />
        {
          (!isCreatingCard && userInstances) &&
            <UpdateCardInfoContainer py={3}>
              <StyledText color="carnationPink.dark">
                The following instances will be affected by this change
              </StyledText>
              <InstancePreviewTable userInstances={userInstances} />
            </UpdateCardInfoContainer>
        }
        <Flex width={1} mt={3} flexDirection="row" alignItems="center" justifyContent="space-between">
          <Flex>
            <Box mr={2}>
              <Button disabled={!stripe || loading} htmlType="submit">{isCreatingCard ? 'Add card' : 'Save card'}</Button>
            </Box>
            <Box ml={2}>
              <CancelButton color="scorpion.light" type="link" onClick={onCancel}>Cancel</CancelButton>
            </Box>
            <Box>
              {loading ? <Spin style={{ alignSelf: 'flex-end' }}/> : null }
            </Box>
          </Flex>
          <PowerByStripe />
        </Flex>
      </Form>
    </Modal>
  )
}

const CancelButton = styled(Button)<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  ${space};
`
const StyledText = styled.p<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const UpdateCardInfoContainer = styled.div<SpaceProps>`
  ${space};
`

export default ModalWrapper
