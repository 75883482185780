import React, { ExoticComponent } from 'react'
import { PageHeader, BottomNavigationBar, SideMenu } from '../../component'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { media, theme } from '../../styles/theme'
import { MenuAltLeft } from '@styled-icons/boxicons-regular/MenuAltLeft'
import { Settings } from '@styled-icons/material/Settings'
import { Tachometer } from '@styled-icons/boxicons-regular/Tachometer'
import { OnlinePrediction } from '@styled-icons/material/OnlinePrediction'
import { Spreadsheet } from '@styled-icons/boxicons-regular/Spreadsheet'
import { IntegrationInstructions } from '@styled-icons/material-outlined/IntegrationInstructions'
import MediaQuery from 'react-responsive'
import {
  instanceOverviewPath,
  instanceSettingsPath,
  teamOverviewPath,
  instanceEvaluationPath,
  predictionsPath,
  tablesPath,
  integrationsPath,
 } from '../../route'
import { InstanceState, DateLike } from 'api'
import { PendingSubscriptionChange } from 'api/schema/PendingSubscriptionChange'
import { Alert } from 'antd'
import { format } from 'date-fns'

interface Props {
  children: React.ReactNode
  instanceId: string
  activePage: string
  instanceName: string
  instanceTeam: string,
  instanceTeamId: string,
  isOwner: boolean,
  state: InstanceState,
  pendingOperation?: PendingSubscriptionChange
}

interface State {}

class InstanceTemplate extends React.Component<Props, State> {

  render() {
    const { instanceId, instanceName, instanceTeam, instanceTeamId, isOwner, state, pendingOperation } = this.props
    const usableStates: InstanceState[] = ['READY', 'UPDATING']
    const editableStates: InstanceState[] = [...usableStates, 'DISABLED']
    const canEdit = editableStates.includes(state)
    const menuItems = [
      {
        key: 'overview',
        text: 'Overview',
        url: instanceOverviewPath(instanceId),
        icon: <OverviewIcon size={24} mr={2}/>,
        show: true
      },
      {
        key: 'tables',
        text: 'Tables',
        url: tablesPath(instanceId),
        icon: <TablesIcon size={22} mr={2}/>,
        disabled: !usableStates.includes(state),
        show: true
      },
      {
        key: 'predictions',
        text: 'Predictions',
        url: predictionsPath(instanceId),
        icon: <PredictionsIcon size={22} mr={2}/>,
        disabled: !usableStates.includes(state),
        show: true
      },
      {
        key: 'evaluation',
        text: 'Evaluations',
        url: instanceEvaluationPath(instanceId),
        icon: <EvaluationsIcon size={22} mr={2}/>,
        disabled: !usableStates.includes(state),
        show: true
      },
      {
        key: 'integrations',
        text: 'Integrations',
        url: integrationsPath(instanceId),
        icon: <IntegrationsIcon size={22} mr={2}/>,
        disabled: !usableStates.includes(state),
        show: true
      },
      {
        key: 'settings',
        text: 'Settings',
        url: instanceSettingsPath(instanceId),
        icon: <SettingsIcon size={24} mr={2}/>,
        show: isOwner && canEdit
      },
    ]

    const breadcrumbItems = [
      {
        text: instanceTeam,
        url: teamOverviewPath(instanceTeamId),
        icon: false
      },
      {
        text: instanceName,
        icon: true
      }
    ]

    const descriptionText = pendingOperation && `This instance will deleted on ${format(DateLike.toDate(pendingOperation.appliedAt), 'yyyy-MM-dd')}`

    return (
      <ContainerDiv>
        {pendingOperation && pendingOperation.operationType==='DELETE' ?
          <StyledAlert
            message="Instance is scheduled for deletion"
            description={descriptionText}
            type="error"
            showIcon
          /> :
          undefined}
        <PageHeader items={breadcrumbItems}/>
        <FlexContainer flexWrap="wrap">
          <MediaQuery query={media.sm}>
            <Box width={1 / 5}>
              <SideMenu activePage={this.props.activePage} items={menuItems}/>
            </Box>
          </MediaQuery>

          <Box width={[1, 4 / 5]} p={3} mb={[4,0]}>
            {this.props.children}
          </Box>
        </FlexContainer>

        <MediaQuery query={media.sm}>
          {(matches: any) => {
            if (matches) {
              return null
            }
            return (
              <BottomNavigationBar
                activePage={this.props.activePage}
                items={menuItems}
              />
            )
          }}
        </MediaQuery>
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled.div`
  height: calc(100vh - (${theme.sizes.navBarHeight}px + ${theme.sizes.pageHeaderHeight}px));
`

const FlexContainer = styled(Flex)`
  height: 100%;
`
const StyledAlert = styled(Alert)`

`
const OverviewIcon: ExoticComponent<any> = styled(MenuAltLeft)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const SettingsIcon: ExoticComponent<any> = styled(Settings)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const EvaluationsIcon: ExoticComponent<any> = styled(Tachometer)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const PredictionsIcon: ExoticComponent<any> = styled(OnlinePrediction)<LayoutProps | ColorProps | SpaceProps>`
${layout};
${color};
${space};
`
const TablesIcon: ExoticComponent<any> = styled(Spreadsheet)<LayoutProps | ColorProps | SpaceProps>`
${layout};
${color};
${space};
`
const IntegrationsIcon: ExoticComponent<any> = styled(IntegrationInstructions)<LayoutProps | ColorProps | SpaceProps>`
${layout};
${color};
${space};
`

export default InstanceTemplate
