import React from 'react'
import styled from 'styled-components'
import {
  space,
  SpaceProps,
  layout,
  LayoutProps, ColorProps, TypographyProps, color, typography
} from 'styled-system'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router'
import { message } from 'antd'
import { PageHeader, CreateTeamForm } from '../component'
import { createTeam } from '../ducks/teams'
import { getUserCreditCards, getUserDefaultCardId } from '../ducks/user'
import { AppState } from '../ducks'
import { Error } from '../types/Error'
import { ConsoleUser as User } from '../types/User'
import { teamOverviewPath } from '../route'

interface RouteInfo {
  userId: string
}

interface Props extends RouteComponentProps<RouteInfo>, ReturnType<typeof mapDispatchToProps> {
  userProfile: User,
  loading: boolean,
  error: null | Error
}

const breadcrumbItems = [
  {
    text: 'Add a new team',
    icon: false
  }
]

class CreateTeam extends React.Component<Props> {

  handleCreateTeam = async (teamName: string) => {
    const { userId } = this.props.match.params
    const { createTeam } = this.props
    window.analytics.track('Create team')
    const body = {
      name: teamName,
      ownerId: userId
    }

    const res  = await createTeam(body)
    const { error } = this.props
    if (error) {
      message.error(error.message)
    } else if (res) {
      this.props.history.push(teamOverviewPath(res.id))
    }
  }

  render() {
    const { loading } = this.props

    return (
      <ContainerDiv>
        <PageHeader items={breadcrumbItems}/>
        <ContentDiv p={[2, 4]} mx={[2, 'auto']} maxWidth={900} my={[2, 4]} bg="white">
          <CreateTeamFormHeader fontSize={[3, 4]} fontWeight="bold" color="scorpion.medium">
            Team details
          </CreateTeamFormHeader>
          <CreateTeamForm
            onSubmit={this.handleCreateTeam}
            loading={loading}
          />
        </ContentDiv>
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled.div<SpaceProps | LayoutProps | ColorProps>`
  ${space};
  ${layout};
  ${color};
`
const ContentDiv = styled.div<SpaceProps | LayoutProps | ColorProps>`
  ${space};
  ${layout};
  ${color};
`
const CreateTeamFormHeader = styled.p<SpaceProps | ColorProps | TypographyProps>`
  ${space};
  ${color};
  ${typography};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ createTeam, getUserCreditCards, getUserDefaultCardId }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.teams.loading,
    error: state.teams.error,
    userProfile: state.auth.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam)
