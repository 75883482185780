import React from 'react'
import styled from 'styled-components'
import { Upload, Input, Row, Form } from 'antd'
import { Button } from '.'
import { theme } from '../styles/theme'
import { RcFile } from 'antd/lib/upload'
import { space, SpaceProps } from 'styled-system'
import { Dispatch, bindActionCreators } from 'redux'
import { AppState } from '../ducks'
import { startFileUpload, clearFileUpload } from '../ducks/instances'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ApiKey } from '../types/ApiKey'
import { ReactComponent as ErrorIcon } from '../svg-assets/404-icon.svg'
import { InboxOutlined, LoadingOutlined, PaperClipOutlined, CheckOutlined } from '@ant-design/icons'

interface ComponentProps {
  instanceId: string
}

interface Props extends ReturnType<typeof mapDispatchToProps> {
  instanceId: string,
  uploadStatus: any,
  apiKey: ApiKey,
  fileLoaded?: (table: string) => void
}

interface State {
  file?: File,
  tableName: string
}

const tableNameRegex = /[^-_a-zA-Z0-9]/g

const filenameToTableName = (filename: string) =>
  (filename
    .split('.')   // split
    .slice(0, -1) // remove extension
    .join('_') || filename).replace(tableNameRegex, '_')

class FileUpload extends React.Component<Props, State> {
  readonly state: State = {
    file: undefined,
    tableName: '',
  }

  uploadFile = async (e: any) => {
    const { file, tableName } = this.state
    if (file) {
      const upload = await this.props.startFileUpload(this.props.instanceId, file, tableName, this.props.apiKey)
      if (upload && this.props.fileLoaded) {
        this.props.clearFileUpload(this.props.instanceId)
        this.props.fileLoaded(tableName)
        return
      }
    }
    this.setState({ file: undefined, tableName: '' })
  }

  onFileSelected = (info?: any) => {
    this.setState({
      file: info ? info.file : undefined,
      tableName: info ? filenameToTableName(info.file.name) : ''
   })
  }

  onTableNameChanged = (e: any) => {
    this.setState({ tableName: e.target.value })
  }

  cancelFile = () => {
    this.setState({
      file: undefined,
      tableName: ''
    })
  }

  resetFileUpload = () => {
    this.cancelFile()
    this.props.clearFileUpload(this.props.instanceId)
  }

  renderForm() {
    const { file, tableName } = this.state

    if (!file) {
      return null
    }

    const validateStatus = tableName.match(tableNameRegex) ? 'error' : 'success'

    const errorMsg = validateStatus === 'error' && 'Table names may only contain alpha-numeric characters, hyphen and underscore'

    return (
      <Container>
        <Form onFinish={this.uploadFile}>
          <div>
            <p>Table name:</p>
            <Form.Item
              validateStatus={validateStatus}
              help={errorMsg || ''}
            >
              <StyledInput
                size="large"
                value={tableName}
                onChange={this.onTableNameChanged}
              />
            </Form.Item>
            <p>
              <PaperClip />
              {file.name}
            </p>
          </div>
          <ButtonRow>
            <CancelButton type="default" onClick={this.cancelFile}>Cancel</CancelButton>
            <SubmitButton htmlType="submit" disabled={validateStatus === 'error'} bgcolor="jade.medium" color="white">Submit</SubmitButton>
          </ButtonRow>
        </Form>
      </Container>
    )
  }

  renderDragger() {
    return (
      <StyledDragger
        name="file"
        onChange={this.onFileSelected}
        accept=".csv"
        beforeUpload={(file: RcFile, FileList: RcFile[]) => false}
      >
        <Inbox />
        <p>Click or drag a CSV file to this area to upload</p>
      </StyledDragger>
    )
  }

  renderLoading(uploadStatus: any) {
    return (
      <Loading>
        <LoadingIcon />
        <LoadingText>{uploadStatus.info}...</LoadingText>
      </Loading>
    )
  }

  renderReady(aitoStatus: any) {
    return (
      <Loading>
        <CheckIcon />
        <LoadingText>Ready</LoadingText>
        <LoadingText>Inserted {aitoStatus.completedCount} rows into {aitoStatus.table} table</LoadingText>
        <Button bgcolor="jade.medium" color="white" onClick={this.resetFileUpload}>Nice!</Button>
      </Loading>
    )
  }

  renderError() {
    return (
    <Loading>
        <StyledErrorIcon />
        <LoadingText>Something went wrong</LoadingText>
        <LoadingText>Please try again</LoadingText>
        <Button bgcolor="jade.medium" color="white" onClick={this.resetFileUpload}>OK</Button>
      </Loading>
    )
  }

  render() {
    const { file } = this.state
    const { uploadStatus } = this.props

    if (uploadStatus && uploadStatus.error) return this.renderError()
    if (uploadStatus && uploadStatus.loading) return this.renderLoading(uploadStatus)
    if (uploadStatus && uploadStatus.status === 'READY') return this.renderReady(uploadStatus.aitoStatus)
    else if (file) return this.renderForm()
    else return this.renderDragger()
  }
}

const StyledErrorIcon = styled(ErrorIcon)`
  margin-bottom: 20px;
  height: 48px;
`

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const LoadingIcon = styled(LoadingOutlined)`
  color: ${theme.colors.jade.medium};
  margin-bottom: 20px;
  font-size: 32px;
`

const CheckIcon = styled(CheckOutlined)`
  color: ${theme.colors.jade.medium};
  margin-bottom: 20px;
  font-size: 32px;
`

const LoadingText = styled.p`
  color: grey;
`

const StyledDragger = styled(Upload.Dragger)`
`
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Inbox = styled(InboxOutlined)`
  font-size: 40px;
  color: ${theme.colors.jade.medium};
`
const PaperClip = styled(PaperClipOutlined)`
  margin: 0 5px;
`

const ButtonRow = styled(Row)`
  display: flex;
`
const CancelButton = styled(Button)`
`
const SubmitButton = styled(Button)`
  margin-left: auto;
`

const StyledInput = styled(Input)<SpaceProps>`
  ${space};
  margin-bottom: 5px;
  .ant-input-wrapper {
    border-radius: 5px;
    box-shadow: 0 0 10px ${theme.colors.scorpion.lighter};
  }
  .ant-input,.ant-input-group-addon {
    background: white;
    border: none;
  }
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ startFileUpload, clearFileUpload }, dispatch)
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  return {
    instanceId: ownProps.instanceId,
    uploadStatus: _.find(state.instances.fileUploads, { instanceId: ownProps.instanceId }),
    apiKey: _.find(state.instances.apiKeys[ownProps.instanceId], k => k.type.includes('WRITE')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)
