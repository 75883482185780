import { isInstanceNameAvailable } from "./api/instanceApi"
import { Rule } from "antd/lib/form"
import _ from 'lodash'
import { SubdomainRegExp } from "api/types/subdomain"

export const passwordValidateRule: Rule[] = [
  {
    required: true,
    message: 'Please enter your new password',
  },
  {
    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/,
    message:
      'Password must contain numbers, uppercase and lowercase characters.',
  },
  {
    min: 12,
    message: 'Password must be at least 12 characters.',
  }
]

const InstanceNameInputField = 'instanceName'
const InstanceNameValidationDelay = 200

export const instanceNameValidateRule: Rule[] = [
  {
    required: true,
    message: 'Please enter your instance name.',
  },
  {
    pattern: SubdomainRegExp,
    message:
      'Instance name must begin with a letter and can only contain lowercase letters, numbers, and dashes.',
  },
  {
    // TODO: increase to 63 once we've tested that it works
    max: 20,
    message: 'Instance name must not exceed 20 characters.',
  },
  ({ getFieldValue }) => ({
    async validator(rule: any, value: string) {
      if (_.size(value) > 2 && SubdomainRegExp.test(value)) {
        await new Promise((resolve) =>
          setTimeout(() => resolve(undefined), InstanceNameValidationDelay)
        )
        // Has it changed since we started polling?
        if (getFieldValue(InstanceNameInputField) !== value) {
          return
        }
        if (!await isInstanceNameAvailable(value)) {
          return Promise.reject(`"${value}" is already taken, please try a more specific name`);
        }
      }
    }
  })
]
