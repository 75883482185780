import React from 'react'
import styled from 'styled-components'
import { Input, Form } from 'antd'
import { Button } from './index'
import { space, SpaceProps } from 'styled-system'
import { ConsoleUser as User } from '../types/User'

interface Props {
  onSubmit: (fullName: string) => void,
  handleChangePassword: (email: string) => void,
  userProfile: User,
}

class ProfileSettingsForm extends React.Component<Props> {

  handleSubmit = (values: any) => {
    this.props.onSubmit(values.fullName)
  }

  render() {
    const { userProfile } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        md: { span: 12, offset: 2 },
      },
    }

    return (
      <StyledForm
        {...formItemLayout}
        labelAlign="left"
        colon={false}
        onFinish={this.handleSubmit}
        initialValues={{
          fullName: userProfile.fullName,
          email: userProfile.email
        }}
      >
        <StyledFormItem label="Email" name="email" mb={3}>
         <Input size="large" disabled />
        </StyledFormItem>
        <StyledFormItem label="Name" name="fullName" mb={3}>
          <Input
            size="large"
          />
        </StyledFormItem>
        <StyledFormItem label="Password" mb={3}>
          <Button type="link" onClick={() => this.props.handleChangePassword(userProfile.email)}>Change password</Button>
        </StyledFormItem>
        <StyledFormItem label="Delete account" mb={4}>
          <p>Email us at <a href="mailto:support@aito.ai">support@aito.ai</a> to delete your entire account</p>
        </StyledFormItem>
        <Button htmlType="submit">Save changes</Button>
      </StyledForm>
    )
  }
}

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
`

const StyledForm = styled(Form)<SpaceProps>`
  ${space};
`
export default ProfileSettingsForm
