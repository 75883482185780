import { action } from 'typesafe-actions'
import { Reducer } from 'redux'

import { 
  getOwnershipTransferBySecret,
  getOwnershipTransferByTeamId as getTransferByTeamId,
  acceptOwnershipTransfer as acceptTransfer,
  declineOwnershipTransfer as declineTransfer
} from '../utils/api/teamApi'

enum actionTypes {
  OWNERSHIP_TRANSFER_REQUEST_PROCESSING = 'ownershipTransfer/OWNERSHIP_TRANSFER_REQUEST_PROCESSING',
  OWNERSHIP_TRANSFER_REQUEST_ERROR= 'ownershipTransfer/OWNERSHIP_TRANSFER_REQUEST_ERROR',
  OWNERSHIP_TRANSFER_REQUEST_SUCCESS = 'ownershipTransfer/OWNERSHIP_TRANSFER_REQUEST_SUCCESS',

  OWNERSHIP_TRANSFER_ACCEPT_PROCESSING = 'ownershipTransfer/OWNERSHIP_TRANSFER_ACCEPT_PROCESSING',
  OWNERSHIP_TRANSFER_ACCEPT_ERROR = 'ownershipTransfer/OWNERSHIP_TRANSFER_ACCEPT_ERROR',
  OWNERSHIP_TRANSFER_ACCEPT_SUCCESS = 'ownershipTransfer/OWNERSHIP_TRANSFER_ACCEPT_SUCCESS',

  OWNERSHIP_TRANSFER_DECLINE_PROCESSING = 'ownershipTransfer/OWNERSHIP_TRANSFER_DECLINE_PROCESSING',
  OWNERSHIP_TRANSFER_DECLINE_ERROR = 'ownershipTransfer/OWNERSHIP_TRANSFER_DECLINE_ERROR',
  OWNERSHIP_TRANSFER_DECLINE_SUCCESS = 'ownershipTransfer/OWNERSHIP_TRANSFER_DECLINE_SUCCESS',
}

export interface OwnershipTransferState {
  loading: boolean
  errorMessage: string | null
  ownershipTransfer: any
}

const initialState: OwnershipTransferState = {
  loading: false,
  errorMessage: null,
  ownershipTransfer: null,
}

export const getOwnershipTransfer = (teamId: string, secret: string | string[]) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.OWNERSHIP_TRANSFER_REQUEST_PROCESSING))
    try {
      const param = (typeof secret === 'string') ? secret : secret[0]
      const response = await getOwnershipTransferBySecret(teamId, param)
      const request = response.data
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_REQUEST_SUCCESS, request))
    } catch (e) {
      const message = 'Failed to fetch ownership transfer'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_REQUEST_ERROR, { response: data, message }))
    }
  }
}

export const getOwnershipTransferByTeamId = (teamId: string) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.OWNERSHIP_TRANSFER_REQUEST_PROCESSING))
    try {
      const response = await getTransferByTeamId(teamId)
      const request = response.data
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_REQUEST_SUCCESS, request))
    } catch (e) {
      const message = 'Failed to fetch ownership transfer'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_REQUEST_ERROR, { response: data, message }))
    }
  }
}

export const acceptOwnershipTransfer = (teamId: string, secret: string | string[]) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.OWNERSHIP_TRANSFER_ACCEPT_PROCESSING))
    try {
      const param = (typeof secret === 'string') ? secret : secret[0]
      const response = await acceptTransfer(teamId, param)
      const request = response.data
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_ACCEPT_SUCCESS, request))
    } catch (e) {
      const message = 'Failed to accept ownership transfer'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_ACCEPT_ERROR, { response: data, message }))
    }
  }
}

export const declineOwnershipTransfer = (teamId: string, secret: string | string[]) => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.OWNERSHIP_TRANSFER_DECLINE_PROCESSING))
    try {
      const param = (typeof secret === 'string') ? secret : secret[0]
      const response = await declineTransfer(teamId, param)
      const request = response.data
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_DECLINE_SUCCESS, request))
    } catch (e) {
      const message = 'Failed to decline ownership transfer'
      const data = e.response && typeof e.response.data === 'object' ? e.response.data : {}
      dispatch(action(actionTypes.OWNERSHIP_TRANSFER_DECLINE_ERROR, { response: data, message }))
    }
  }
}

const reducer: Reducer = (
  state: OwnershipTransferState = initialState,
  action: any,
): OwnershipTransferState => {
  switch (action.type) {
    case
    actionTypes.OWNERSHIP_TRANSFER_REQUEST_PROCESSING: {
      return { ...state, loading: true }
    }

    case actionTypes.OWNERSHIP_TRANSFER_REQUEST_ERROR: {
      return { ...state, ownershipTransfer: null, loading: false, errorMessage: action.payload.message }
    }

    case actionTypes.OWNERSHIP_TRANSFER_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        ownershipTransfer: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export { reducer as ownershipTransferReducer }
