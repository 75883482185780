import React, { ExoticComponent, useState } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip as ChartTooltip,
  BarChart,
  Bar,
  YAxis,
  XAxis,
  Tooltip
} from 'recharts'
import _ from 'lodash'
import { bindActionCreators, Dispatch } from 'redux'
import { AppState } from '../../ducks'
import { connect } from 'react-redux'
import { Box, Flex } from '@rebass/grid'
import { Card, Form, message, Modal, Select, Spin } from 'antd'
import { format } from 'date-fns'
import { Redirect, RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { ClockCircleOutlined, CheckOutlined } from '@ant-design/icons'

import InstanceTemplate from './InstanceTemplate'
import { media, theme } from '../../styles/theme'
import {
  deleteInstance,
  getInstance,
  InstanceStatus,
  getUserInstances,
  UserInstancesState
} from '../../ducks/instances'
import {
  getInstanceEvaluationStatus,
  getEvaluationResults,
  changeEvaluationConfidence,
} from '../../ducks/evaluations'

import { CompleteEvaluationResult, Evaluation, PredictionTarget, User, DateLike } from 'api'
import variables from '../../variables'
import { SelectValue } from 'antd/lib/select'
import { evaluationFinishedIn } from './evaluationUtils'
import { Button, CodeSnippet, Heading, InfoTooltip } from '../../component'
import { Code } from '@styled-icons/bootstrap/Code'
import { buildEvaluationQuery } from 'api/queries/querybuilder'

const { Option } = Select

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  instances: Record<string, InstanceStatus>,
  evaluations: Record<string, PredictionTarget[]>,
  evaluationResults: Record<string, Evaluation[]>,
  evaluationLoading: boolean,
  changeLoading: boolean,
  userInstances: UserInstancesState,
  userProfile: User,
  match: any
}

interface renderProps {
  cx: number
  cy: number
  midAngle: number
  outerRadius: number
  fill: string
  percent: number
  name: string
  showError?: boolean
}

const COLORS = ['#00C49F', '#FF8042', '#0088FE', '#FFBB28', '#8884D8']

const InfoHeader = (props: { title: string, text: string }) => (
  <span>
    {props.title}
    <InfoTooltip text={props.text} placement="left" />
  </span>
)

const TopHeader = (props: { title: string, query: any }) => {
  const [visible, setVisible] = useState(false)
  return (
  <HeaderSpan>
    {props.title}
    {props.query ?
      <QueryButton onClick={() => setVisible(true)}>
        See query
        <CodeIcon size={22} m={2} mr={0} />
      </QueryButton> : undefined
    }
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Heading fontSize={3}>Aito evaluation query</Heading>
      <CodeSnippet language="json">
        {JSON.stringify(props.query, null, 2)}
      </CodeSnippet>
    </Modal>
  </HeaderSpan>
  )
}

const renderLabel = (props: renderProps) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, percent } = props
  // find center point of each section
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)

  // center point of a section on outer ring
  const bx = cx + outerRadius * cos
  const by = cy + outerRadius * sin

  // point of angle in label line
  const mx = cx + (outerRadius + 15) * cos
  const my = cy + (outerRadius + 15) * sin

  // point of the end of label line
  const ex = mx + (cos >= 0 ? 1 : -1) * 12
  const ey = Math.max(Math.min(my, cy + outerRadius + 10), 10)
  const textAnchor = cos >= 0 ? 'start' : 'end'

  // show large sections and "Error" section
  if (percent > 0.02 || (props.showError && props.name === "Error")) {
    return (
      <g>
        <path d={`M${bx},${by}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    )
  } else {
    return undefined
  }
}

const sortData = (data: { [k: string]: number }) => (
  // find classes with largest values and map
  // { key: value } => [{ name: key, value: value }]
  Object
    .entries(data)
    .sort((a, b) => b[1] - a[1])
    .map(([k, v]) => ({ name: k, value: v }))
)

const compressData = (data: { name: string, value: number }[]) => {
  const other = _.takeRight(data, data.length - 4).reduce((a, b) => ({ name: 'Other', value: a.value + b.value }))
  const compressed = [..._.take(data, 4), other]
  return compressed
}

const tooltipContent = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <p>{`${payload[0]?.name}: ${payload[0]?.value} test cases`}</p>
      </ToolTipContainer>
    )
  }
  return null
}

const Chart = React.memo((props: { data: { value: number, name: string }[], tooltip?: boolean, showError?: boolean }) => {
  return (
    <ResponsiveContainer width="100%" minHeight="230px">
      <PieChart>
        <Pie
          data={props.data}
          dataKey="value"
          nameKey="name"
          paddingAngle={0}
          innerRadius="45%"
          label={(p) => renderLabel({ ...p, showError: props.showError })}
          legendType="circle"
          labelLine={false}
        >
          {
            props.data.map(
              (entry, index) =>
              <Cell key={entry.name} fill={COLORS[index % COLORS.length]}/>
            )
          }
        </Pie>
        <Legend layout="horizontal" verticalAlign="bottom" align="left" />
        {props.tooltip && <ChartTooltip content={tooltipContent} />}
      </PieChart>
    </ResponsiveContainer>
  )
})

const ClassesChart = React.memo((props: { data: { [k: string]: number }, title: string, text: string, emptyText: string }) => {

  let data = sortData(props.data)

  // combine smallest classes into one if there are many classes
  if (data.length > 5) {
    data = compressData(data)
  }

  let content
  if (_.isEmpty(props.data)) {
    content = (
      <PendingContainer
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CheckIcon />
        {props.emptyText}
      </PendingContainer>
    )
  } else {
    content = (
      <Chart data={data} tooltip />
    )
  }

  return (
    <Box width={[1, 1, 1, 1 / 2]} p={2}>
      <DataCard title={
        <InfoHeader
          title={props.title}
          text={props.text}
        />
      }>
      {content}
    </DataCard>
  </Box>
  )
})

const ConfidenceForm = (props: {
  optimalConfidence: number,
  changeLoading: boolean,
  instanceId: string,
  evId: string | undefined,
  changeEvaluationConfidence: any
}) => {
  const [form] = Form.useForm()
  const [confidence, setConfidence] = useState(props.optimalConfidence)

  const confidenceChange = async (e: SelectValue) => {
    setConfidence(e as number)
    const res = await props.changeEvaluationConfidence(props.instanceId, props.evId, e as number)
    if (res && res.error) {
      if (res.error.response.status === 404) {
        message.error('Data not found. Confidence level is not available for old evaluations. Please re-run your evaluation to experiment with this feature.', 10)
      } else {
        message.error('Something went wrong, please try again')
      }
      setConfidence(props.optimalConfidence)
      form.resetFields()
    }
  }

  const confidenceValues = _.concat(
    Math.min((props.optimalConfidence * 100 - 10) / 100, 1),
    _.range(7).map(i => (i + (props.optimalConfidence * 100) - 3) / 100),
    Math.max((props.optimalConfidence * 100 + 10) / 100, 0)
  ).filter(v => v <= 0.99 && v >= 0.01)

  return (
    <Form form={form} initialValues={{ confidence: props.optimalConfidence }}>
      <Form.Item name="confidence">
        <>
          <StyledSelect
            onChange={(e) => confidenceChange(e)}
            fontWeight="bold"
            color="silverTree.darker"
            disabled={props.changeLoading}
            value={confidence}
          >
            {confidenceValues.map(t => (
              <Option value={t} key={t}>{t}</Option>
            ))}
          </StyledSelect>
          {props.changeLoading && <SmallSpinContainer><Spin /></SmallSpinContainer>}
        </>
      </Form.Item>
    </Form>
  )
}

interface State {
  initialLoading: boolean
}

class EvaluationPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      initialLoading: true
    }
  }


  async componentDidMount() {
    window.analytics.page('Instance evaluation')
    const { instanceId, evaluationTgId } = this.props.match.params
    const { userProfile } = this.props
    try {
      if (this.props.userProfile) this.props.getUserInstances(this.props.userProfile.id)
      if (instanceId) {
        this.props.getInstance(instanceId, userProfile.id)
        this.props.getEvaluationResults(instanceId, evaluationTgId)
      }
    } finally {
      this.setState({ initialLoading: false })
    }
  }


  render() {
    const { instanceId, evaluationTgId } = this.props.match.params
    const { instances, evaluationResults, evaluations, changeLoading, evaluationLoading } = this.props
    const { initialLoading } = this.state
    const { instance = null, loading: instanceLoading = true } = instances[instanceId] || {}
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const userProfile = this.props.userProfile || { id: '', fullName: '', email: '' }
    const isOwner = instance ? instance.ownerId === userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'

    const target = _.find(evaluations[instanceId], { id: evaluationTgId })
    const ev = _.first(evaluationResults[instanceId])
    let result = ev?.result
    const config = ev?.configuration
    const inputColumns = config?.inputColumns.map(c => c.name)
    const inputColsText = inputColumns?.map(c => <div key={c}>{c}</div>)
    const InfoInputCols = () => {
      if (inputColumns?.length === 1) {
        return <>1 input column <InfoTooltip placement="bottom">{inputColsText}</InfoTooltip></>
      } else if (inputColumns?.length === 0) {
        return <>No input columns</>
      } else {
        return <>{inputColumns?.length} input columns <InfoTooltip placement="bottom">{inputColsText}</InfoTooltip></>
      }
    }


    let content
    if (result && result.state === 'complete') {
      result = result as CompleteEvaluationResult
      const table = target?.data.tableName
      const targetColumn = ev?.target.targetColumn.name
      const createdAt = ev && ev.createdAt && format(DateLike.toDate(ev.createdAt), variables.dateFnsTimeFormat)
      const {
        accuracy,
        testSamples,
        trainSamples,
        optimalConfidence,
        nonAutomatedClasses,
        errorClasses,
        importantColumns,
      } = result || {}
      const { impact } = target || {}
      let {
        casesPerInterval,
        successCaseSavings,
        errorCaseCost,
        unit,
      } = impact || {}

      const lostCases =  errorClasses ? _.sum(Object.values(errorClasses)) : 0
      const nonAutomatedCases = nonAutomatedClasses ? _.sum(Object.values(nonAutomatedClasses)) : 0
      const savedCases = testSamples ? testSamples - lostCases - nonAutomatedCases : 0

      const formatLabel = (label: string) => label.length > 13 ? label.substr(0, 13).concat('..') : label
      const longestLabelLength = _.keys(importantColumns)
        .map(formatLabel)
        .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0)

        const renderColumnsTooltip = ({ active, payload, label }: any) => {
          if (active && payload && payload.length) {
            return (
              <ToolTipContainer>
                <p>{payload[0].payload.name}</p>
                <p>{`Score: ${payload[0].value.toFixed(3)}`}</p>
              </ToolTipContainer>
            )
          }
          return null
        }

      let rawQuery
      if (ev) {
        // omit select fields to simplify the query little bit
        rawQuery = _.omit(buildEvaluationQuery(
          { type: 'instance', instanceId, tableName: table || '' },
          ev.configuration,
          ev.target,
          ev.settings,
          trainSamples + testSamples
        ), 'select')
      }

      const saveCasesPerInterval = Math.round(savedCases / testSamples * (casesPerInterval || 0))
      const errorCasesByInterval = Math.round(lostCases / testSamples * (casesPerInterval || 0))
      let saved = saveCasesPerInterval * (successCaseSavings || 0)
      let lost = errorCasesByInterval * (errorCaseCost || 0)
      if (unit === 'min' && saved > 1000) {
        saved = _.toInteger(saved / 60)
        lost = _.toInteger(lost / 60)
        unit = 'h'
      }

      const chartData = [
        { name: 'Correct', value: savedCases },
        { name: 'Error', value: lostCases },
        { name: 'Non-automated', value: nonAutomatedCases }
      ]

      content =
        <Container>
          <Flex flexWrap="wrap" p={2} alignItems="center">
            <Box width={1}>
              <DataCard title={<TopHeader title={`${instanceName} / ${table}`} query={rawQuery} />}>
                <Flex flexWrap="wrap">
                  <Box width={[1 / 2, 1 / 3]} mb={[2, 2]}>
                    <InfoLabel color="scorpion.light">
                      Target column
                    </InfoLabel>
                    <Info color="silverTree.darker" fontWeight="bold">
                      {targetColumn}
                    </Info>
                  </Box>

                  <Box width={[1 / 2, 1 / 3]} mb={[2, 2]}>
                    <InfoLabel color="scorpion.light">
                      Input columns
                    </InfoLabel>
                    <Info color="silverTree.darker" fontWeight="bold">
                      {InfoInputCols()}
                    </Info>
                  </Box>

                  <Box width={[1 / 2, 1 / 3]} mb={[2, 2]}>
                    <InfoLabel color="scorpion.light">
                      Date
                    </InfoLabel>
                    <Info color="silverTree.darker" fontWeight="bold">
                      {createdAt}
                    </Info>
                  </Box>

                  <Box width={[1 / 2, 1 / 3]} mb={[2, 0]}>
                    <InfoLabel color="scorpion.light">
                      Train samples
                    </InfoLabel>
                    <Info color="silverTree.darker" fontWeight="bold">
                      {trainSamples}
                    </Info>
                  </Box>

                  <Box width={[1 / 2, 1 / 3]} mb={[0, 0]}>
                    <InfoLabel color="scorpion.light">
                      Test samples
                    </InfoLabel>
                    <Info color="silverTree.darker" fontWeight="bold">
                      {testSamples}
                    </Info>
                  </Box>

                  <Box width={[1 / 2, 1 / 3]} mb={[0, 0]}>
                    <InfoLabel color="scorpion.light">
                      Confidence
                    </InfoLabel>
                      {ev && <ConfidenceForm
                        optimalConfidence={optimalConfidence}
                        changeLoading={changeLoading}
                        evId={ev.id}
                        instanceId={instanceId}
                        changeEvaluationConfidence={this.props.changeEvaluationConfidence}
                      />}
                  </Box>
                </Flex>
              </DataCard>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" pb={2}>
            <Box width={[1, 1, 1, 1 / 2]} p={2}>
              <DataCard title={
                <InfoHeader
                  title="Automation vitals"
                  text="This shows what share of cases you can potentially automate if considering the predictions in isolation and not accounting for other factors in your end-to-end workflow. The values shown are using the chosen confidence threshold and the test sample."
                />
              }>
                <Chart data={chartData} tooltip showError />
              </DataCard>
            </Box>
            <Box width={[1, 1, 1, 1 / 2]} p={2}>
              <DataCard title={
                <InfoHeader
                  title="Automation potential per month"
                  text="Using the use case values given by you when creating the evaluation, this shows the impact of your potential automation case. This is considering predictions in isolation and not accounting for other factors in your end-to-end workflow."
                />
              }>
                <Flex flexWrap="wrap" alignItems="center">
                  <Box width={[3 / 4]} p={10}>
                    <CardLabel fontWeight="bold" >
                      Savings per automated cases
                    </CardLabel>
                    <CardLabel>
                    {`Estimated ${saveCasesPerInterval} correctly automated cases`}
                    </CardLabel>
                  </Box>
                  <Box width={[1 / 4]}>
                    <CardLabel fontWeight="bold" color="green" fontSize={24}>
                      {`${saved} ${unit}`}
                    </CardLabel>
                  </Box>
                  <Box width={[3 / 4]} p={10}>
                    <CardLabel fontWeight="bold" >
                      Cost of fixing errors
                    </CardLabel>
                    <CardLabel>
                      {`Estimated ${errorCasesByInterval} wrongly automated cases`}
                    </CardLabel>
                  </Box>
                  <Box width={[1 / 4]}>
                    <CardLabel fontWeight="bold" color="red" fontSize={24}>
                    {`${lost} ${unit}`}
                    </CardLabel>
                  </Box>
                  <Box width={[3 / 4]} p={10}>
                    <CardLabel fontWeight="bold" >
                      Net savings of automation
                    </CardLabel>
                    <CardLabel>
                        {`Based on total ${casesPerInterval} cases per month`}
                    </CardLabel>
                  </Box>
                  <Box width={[1 / 4]}>
                    <CardLabel fontWeight="bold" color="grey" fontSize={24}>
                    {`${saved-lost} ${unit}`}
                    </CardLabel>
                  </Box>
                </Flex>
              </DataCard>
            </Box>
            { importantColumns &&
              <Box width={[1, 1, 1, 1 / 2]} p={2}>
                <DataCard title={
                  <InfoHeader
                    title="Column importance"
                    text="Column frequency in the evaluation test cases"
                  />
                }>
                  <ResponsiveContainer width="100%" aspect={16/7}>
                    <BarChart
                      data={_.sortBy(_.keys(importantColumns).map(k => ({ name: k, value: importantColumns[k] })), 'value').reverse()}
                      margin={{top: 5, right: 30, left: 20, bottom: 5}}
                      layout="vertical"
                    >
                      <XAxis type="number" ticks={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]} />
                      <YAxis
                        dataKey={(o) => formatLabel(o.name)}
                        type="category"
                        tick={{ fontSize: 14 }}
                        width={longestLabelLength * 7}
                      />
                      <Tooltip content={renderColumnsTooltip} />
                      <Bar dataKey="value" fill={theme.colors.jade.medium}/>
                    </BarChart>
                  </ResponsiveContainer>
                </DataCard>
              </Box>
            }
            { nonAutomatedClasses &&
              <ClassesChart
                data={nonAutomatedClasses}
                title="Top non-automated categories"
                text="The chart shows which categories are most present in non-automated cases. Concentrating on these classes in your data might raise the automation rate."
                emptyText="There were no cases which were not automated"
              />}
            { errorClasses &&
              <ClassesChart
                data={errorClasses}
                title="Top categories with errors"
                text="The chart shows which categories are most present in incorrectly predicted cases. Concentrating on these classes in your data might raise the automation rate."
                emptyText="There were no cases which were automated incorrectly"
              />}
            <Box width={[1, 1, 1, 1 / 2]} p={2}>
              <DataCard title={
                <InfoHeader
                  title="Overall prediction accuracy"
                  text="Accuracy is a basic measurement in machine learning and intelligent automation. With high accuracy, you can get most likely also get a high automation potential. For attended robots, the accuracy indicates how many often the predicted value would be correct."
                />
              }>
                <Flex flexWrap="wrap" alignItems="center" p={15}>
                  <Box width={[1, 1 / 2]}>
                    <CardLabel fontWeight="bold" fontSize={50}>
                      {_.toInteger(accuracy * 100)} %
                    </CardLabel>
                  </Box>
                  <Box width={[1, 1 / 2]}>
                    <CardLabel>
                      How often the top returned prediction was correct in the test set.
                    </CardLabel>
                  </Box>
                </Flex>
              </DataCard>
            </Box>
            <Box width={[1, 1, 1, 1 / 2]} p={2}>
              <DataCard title={
                <InfoHeader
                  title="Optimal confidence threshold"
                  text="Based on the use case details you gave when creating the evaluation, Aito has calculated the optimal value to be used as confidence threshold, maximising your automation net impact. Confidence threshold means how high the confidence of each individual prediction needs to be in order for it to be used in automation, without human review."
                />
              }>
                <Flex flexWrap="wrap" alignItems="center" p={15}>
                  <Box width={[1, 1 / 2]}>
                    <CardLabel fontWeight="bold" fontSize={50}>
                      {optimalConfidence.toFixed(2)}
                    </CardLabel>
                  </Box>
                  <Box width={[1, 1 / 2]}>
                    <CardLabel>
                      Recommended confidence threshold that maximises the net savings of your use case.
                    </CardLabel>
                  </Box>
                </Flex>
              </DataCard>
            </Box>
          </Flex>
        </Container>
      }

      const pendingPage = (evaluation: Evaluation | undefined | null) => {
        const texts = evaluationFinishedIn(evaluation)
        let progressText = `It might take several minutes, depending on the size of your dataset. `
        let remaining = ''
        if (texts && !Number.isNaN(texts.numerator + texts.denominator)) {
          if (texts.finishedInDuration) {
            remaining = `It's estimated to finish in ${texts.finishedInDuration}.`
          }
          progressText = `The job is ${texts.percent}% complete (${texts.numerator}/${texts.denominator} queries). ${remaining}`
        }

        return (
          <PendingContainer justifyContent="center" alignItems="center" flexDirection="column">
            <PendingIcon />
            <p>The evaluation process is running.</p>
            <p>{progressText}</p>
            <p>We will notify you when the results are available.</p>
          </PendingContainer>
        )
      }

      let page
      const loading = instanceLoading || evaluationLoading || initialLoading
      if (!loading && result) {
        if (result.state === 'pending') page = pendingPage(ev)
        else page = content
      } else if (!loading) {
        page = <Redirect to="/404"/>
      } else {
        page =
          <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <Spin size="large" />
          </SpinContainer>
      }

    return (
      <InstanceTemplate
        instanceId={instanceId}
        activePage="evaluation"
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        {page}
      </InstanceTemplate>
    )
  }
}

const Container = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`

const PendingContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const PendingIcon = styled(ClockCircleOutlined)`
  color: ${theme.colors.jade.medium};
  margin-bottom: 20px;
  font-size: 48px;
`

const DataCard = styled(Card) <SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  height: 100%;
  .ant-card-body {
     @media ${media.sm} {
      height: 80%;
    }
    height: auto;
  }
`

const CardLabel = styled.div<ColorProps | TypographyProps | SpaceProps>`
  ${color};
  ${space};
  ${typography};
  display: block;
`
const InfoLabel = styled.span<ColorProps>`
  ${color};
  display: block;
`
const Info = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const SpinContainer = styled(Flex) <ColorProps>`
  ${color};
  height: 100%;
`
const SmallSpinContainer = styled.span`
  margin-left: 10px;
`
const CheckIcon = styled(CheckOutlined)`
  color: ${theme.colors.jade.medium};
  margin-bottom: 20px;
  font-size: 48px;
`
const StyledSelect = styled(Select)<TypographyProps | ColorProps>`
  width: 100px;
  .ant-select-selection-item {
    ${typography};
    ${color};
  }
`
const ToolTipContainer = styled.div`
  background-color: white;
  filter: drop-shadow(5px 5px 5px #777);
  padding: 10px;
`
const HeaderSpan = styled.div`
  display: flex;
`
const QueryButton = styled(Button)`
  margin-left: auto;
  background-color: white;
  color: grey;
  padding: 0;
`
const CodeIcon: ExoticComponent<any> = styled(Code)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    deleteInstance,
    getUserInstances,
    getInstanceEvaluationStatus,
    getEvaluationResults,
    changeEvaluationConfidence,
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    userInstances: state.instances.userInstances,
    evaluationResults: state.evaluations.evaluationResults,
    evaluations: state.evaluations.evaluations,
    evaluationLoading: state.evaluations.loading,
    changeLoading: state.evaluations.changeLoading,
    userProfile: state.auth.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationPage)
