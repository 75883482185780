import React, { ExoticComponent } from 'react'
import _ from 'lodash'
import { format } from 'date-fns'
import TeamTemplate from './TeamTemplate'
import { Box, Flex } from '@rebass/grid'
import { Card, message, Modal, Spin, Table, Tag as AntTag } from 'antd'
import { AddMemberModal, Button, Heading, MemberCardExtraButtons, OwnershipTransferModal } from '../../component'
import styled from 'styled-components'
import { color, ColorProps, LayoutProps, typography, TypographyProps, SpaceProps, layout, space } from 'styled-system'
import { media } from '../../styles/theme'
import { RouteComponentProps } from 'react-router'
import InstanceTypeTag from '../../component/InstanceTypeTag'
import variables from '../../variables'
import { connect } from 'react-redux'
import { AddCircle } from '@styled-icons/material'
import { bindActionCreators, Dispatch } from 'redux'
import {
  cancelTeamInvitation,
  getTeamById,
  getTeamInvitations,
  inviteTeamMember,
  leaveTeam,
  removeTeamMember,
  transferTeamOwnership,
  TeamState,
  emptyTeamState,
  InvitationsState,
  emptyInvitationsState,
  getTeamMembers,
} from '../../ducks/teams'
import { getOwnershipTransferByTeamId, declineOwnershipTransfer } from '../../ducks/ownershipTransfer'
import { AppState } from '../../ducks'
import { ConsoleUser as User } from '../../types/User'
import { teamsPath, instanceOverviewPath, createInstancePath } from '../../route'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import { TeamMember } from '../../types/TeamMember'
import roleToString from '../../utils/roleToString'
import { TeamRole } from 'api'

interface RouteInfo {
  teamId: string
}

interface Props extends RouteComponentProps<RouteInfo>, ReturnType<typeof mapDispatchToProps> {
  teamById: { [teamId: string]: TeamState }
  teamMembers: { [teamId: string]: TeamMember[] }
  userProfile: User,
  ownershipTransfer: any
}

interface State {
  addMembersModalVisible: boolean,
  ownershipTransferModalVisible: boolean,
  inviteeEmails: Array<string>,
  chosenMemberIdToBeOwner: string
}

class TeamDetails extends React.Component<Props, State> {
  readonly state: State = {
    addMembersModalVisible: false,
    ownershipTransferModalVisible: false,
    inviteeEmails: [],
    chosenMemberIdToBeOwner: ''
  }

  getTeamId(): string {
    return _.get(this.props, 'match.params.teamId')
  }

  getTeamState(teamId: string): TeamState {
    return this.props.teamById[teamId] || emptyTeamState(teamId)
  }

  getTeamInvitations(teamId: string): InvitationsState {
    return this.getTeamState(teamId).invitations || emptyInvitationsState
  }

  async componentDidMount() {
    window.analytics.page('Team details')
    const teamId = this.getTeamId()
    this.props.getTeamMembers(teamId, this.props.userProfile.id)
    this.props.getOwnershipTransferByTeamId(teamId)
    await this.props.getTeamById(teamId)

    const { team } = this.getTeamState(teamId)
    const userProfile = this.props.userProfile || { id: '' }
    if (team && team.owner.id === userProfile.id) {
      await this.props.getTeamInvitations(teamId)
    }
  }

  showOwnershipTransferModal = () => {
    this.setState({ownershipTransferModalVisible: true})
  }
  closeOwnershipTransferModal = () => {
    this.setState({ownershipTransferModalVisible: false})
  }
  handleSelectTeamMember = (memberId: any) => {
    this.setState({chosenMemberIdToBeOwner: memberId })
  }
  handleOwnershipTransferSubmit = async () => {
    const { chosenMemberIdToBeOwner } = this.state
    const teamId = this.getTeamId()
    const reqBody = {
      role: TeamRole.Owner,
    }
    window.analytics.track('Send ownership transfer', { teamId })
    await this.props.transferTeamOwnership(teamId, chosenMemberIdToBeOwner, reqBody)
    const { error } = this.getTeamState(teamId)
    if (error) {
      message.error(error.message)
    } else {
      this.setState({ ownershipTransferModalVisible: false })
      await this.props.getTeamMembers(teamId, this.props.userProfile.id)
      await this.props.getTeamById(teamId)
      await this.props.getOwnershipTransferByTeamId(this.getTeamId())
      message.success('Ownership transfer request sent')
    }
  }

  showAddMemberModal = () => {
    this.setState({addMembersModalVisible: true})
  }
  closeAddMembersModal = () => {
    this.setState({addMembersModalVisible: false})
  }
  handleAddInviteeEmails = (emails: Array<string>) => {
    this.setState({ inviteeEmails: emails})
  }

  handleAddMembersSubmit = () => {
    const teamId = this.getTeamId()
    const { inviteeEmails } = this.state
    window.analytics.track('Add member', { teamId })

    _.map(inviteeEmails, async inviteeEmail => {
      const reqBody = {
        recipient: inviteeEmail,
        role: TeamRole.Member,
      }
      await this.props.inviteTeamMember(teamId, reqBody)
      const { error } = this.getTeamState(teamId)
      if (error) {
        message.error(error.message)
      } else {
        this.setState({inviteeEmails: [], addMembersModalVisible: false})
        await this.props.getTeamInvitations(teamId)
      }
    })
  }

  handleCancelInvitation = async (invitationId: string) => {
    const teamId = this.getTeamId()
    const { confirm } = Modal

    confirm({
      title: 'Are you sure to cancel this invitation?',
      content: 'After cancelling the invitation, you can still send an another invitation to this person',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      centered: true,
      onOk: async () => {
        await this.props.cancelTeamInvitation(teamId, invitationId)
        const { error } = this.getTeamState(teamId)
        if (error) {
          message.error(error.message)
        }
      },
    })
  }

  handleRemoveTeamMember = async (userId: string) => {
    const teamId = this.getTeamId()
    const { confirm } = Modal

    confirm({
      title: 'Are you sure to remove this member from the team?',
      content: 'After removing team members, you can still invite them back to the team',
      okText: 'Remove',
      okType: 'danger',
      cancelText: 'Cancel',
      centered: true,
      onOk: async () => {
        window.analytics.track('Delete team member', { teamId, deleted: userId })
        await this.props.removeTeamMember(teamId, userId)
        const { error } = this.getTeamState(teamId)
        if (error) {
          message.error(error.message)
        } else {
          await Promise.all([
            this.props.getTeamById(teamId),
            this.props.getTeamMembers(teamId, this.props.userProfile.id)
          ])
        }
      },
    })
  }

  handleLeaveTeam = async (userId: string) => {
    const teamId = this.getTeamId()
    const { confirm } = Modal

    confirm({
      title: `Are you sure to leave this team?`,
      content: 'After leaving the team, you are no longer be able to access this team instance or re-join unless invited by the team owner',
      okText: 'Leave',
      okType: 'danger',
      cancelText: 'Cancel',
      centered: true,
      onOk: async () => {
        await this.props.leaveTeam(userId, teamId)
        const { error } = this.getTeamState(teamId)
        if (error) {
          message.error(error.message)
        } else {
          this.props.history.push(teamsPath)
        }
      },
    })
  }

  navigateToInstanceDetail = (instanceId: string) => {
    this.props.history.push(instanceOverviewPath(instanceId))
  }

  memberCardExtraButtons = (onAddMemberClick: () => void, onTransferOwnershipClick: () => void) => {
    return (
      <MemberCardExtraButtons
        onTransferOwnershipClick={onTransferOwnershipClick}
        onAddMemberClick={onAddMemberClick}
      />
    )
  }

  instanceCardExtraButton = (teamId: string) => {
    return (
      <MediaQuery query={media.sm}>
        {(matches: any) => {
          if (matches) {
            return (
              <AddInstanceContainerDiv>
                <Link to={{
                  pathname: createInstancePath,
                  state: { teamId }
                }}>
                  <AddInstanceText fontSize={0} color="scorpion.light" fontWeight={5}>
                    Create an instance
                  </AddInstanceText>
                  <AddIcon size={24} color="jade.medium" ml={2} />
                </Link>
              </AddInstanceContainerDiv>
            )
          }
          return (
            <Flex flexDirection="row">
              <Link to={{
                pathname: createInstancePath,
                state: { teamId }
              }}>
                <AddIcon size={24} color="jade.medium" ml={2} />
              </Link>
            </Flex>
          )
        }}
      </MediaQuery>
    )
  }

  cancelOwnershipRequest = async (secret: string) => {
    const teamId = this.getTeamId()
    await this.props.declineOwnershipTransfer(teamId, secret)
    await this.props.getOwnershipTransferByTeamId(teamId)
    message.success('Ownership transfer cancelled')
  }

  renderRoleTag = (role: string) => {
    const roleColors = variables.ROLE_COLORS as any
    return (
      <Tag fontSize={[0, 1]} color={roleColors[role].bg}>
        <RoleText color={roleColors[role].color}>
          {roleToString(role)}
        </RoleText>
      </Tag>
    )
  }

  renderTeamOverview = (team: any, teamMembers: TeamMember[], loading: boolean) => {
    const { addMembersModalVisible, ownershipTransferModalVisible, inviteeEmails } = this.state
    const teamId = this.getTeamId()
    const userProfile = this.props.userProfile || {id: '', fullName: '', email: ''}
    const memberTypeLabel: any = {
      OWNER: this.renderRoleTag(TeamRole.Owner),
      MEMBER: this.renderRoleTag(TeamRole.Member),
      GUEST: this.renderRoleTag(TeamRole.Guest),
    }
    const isOwner = team.owner.id === userProfile.id
    const members = _.map(teamMembers, member => {
      return {
        ...member,
        key: member.user.id,
        roleComponent: memberTypeLabel[member.role]
      }
    })
    const instances = _.map(team.instances, instance => {
      return {
        ...instance,
        key: instance.id,
        type: instance.type
      }
    })

    const invitationsState = this.getTeamInvitations(teamId)
    const pendingInvitation =  _.map(invitationsState.pendingInvitations, invitation => {
      return {
        ...invitation,
        key: invitation.id
      }
    })
    const ownershipTransfer = this.props.ownershipTransfer.ownershipTransfer

    return (
      <Flex flexWrap="wrap">
        <Box width={1} pb={2}>
          <Card>
            <Flex flexWrap="wrap" alignItems="center" mb={3}>
              <Box>
                <Heading>{team.name}</Heading>
              </Box>
              {loading && <Box ml="auto"><Spin/></Box>}
            </Flex>

            <Flex flexWrap="wrap" mt={3}>
              <Box width={1 / 2}>
                <InfoLabel color="scorpion.light">
                  Owner
                </InfoLabel>
                <Info color="silverTree.darker" fontWeight="bold">
                  {team.owner.fullName}
                </Info>
              </Box>
              <Box width={1 / 2}>
                <InfoLabel color="scorpion.light">
                  Created on
                </InfoLabel>
                <EditTime color="scorpion.dark">
                  {team.createdAt && format(new Date(team.createdAt), variables.dateFnsTimeFormat)}
                </EditTime>
              </Box>
            </Flex>
          </Card>
        </Box>

        <Box width={1} py={2}>
          <Card
            title="Team members"
            extra={isOwner ? this.memberCardExtraButtons(this.showAddMemberModal, this.showOwnershipTransferModal) : null}
          >
            <Table dataSource={members} pagination={false}>
              <Table.Column title="Name" dataIndex={['user', 'fullName']} key="name"/>
              <Table.Column title="Email" dataIndex={['user', 'email']} key="email" />
              <Table.Column title="Role" dataIndex="roleComponent" key="role" />
              {isOwner ?
                <Table.Column
                  key="user.id"
                  render={record => (
                    record.key !== userProfile.id ?
                      <Button type="link" onClick={() => this.handleRemoveTeamMember(record.key)}>
                        Remove member
                      </Button>
                      :
                      null
                  )}
                />
                :
                <Table.Column
                  key="user.id"
                  render={record => (
                    record.key === userProfile.id ?
                      <Button type="link" onClick={() => this.handleLeaveTeam(userProfile.id)}>
                        Leave team
                      </Button>
                      :
                      null
                  )}
                />
              }
            </Table>

            {isOwner && pendingInvitation.length !== 0 &&
            <Box mt={4}>
              <Table dataSource={pendingInvitation} pagination={false}>
                <Table.Column title="Pending team members" dataIndex="email" key="email"/>
                <Table.Column
                  key="id"
                  render={record => (
                    <Button type="link" onClick={() => this.handleCancelInvitation(record.key)}>
                      Cancel invitation
                    </Button>
                  )}
                />
              </Table>
            </Box>
            }

            {isOwner && ownershipTransfer &&
            <Box mt={4}>
              <Table dataSource={[ownershipTransfer]} pagination={false}>
                <Table.Column title="Pending ownership transfer" dataIndex="recipientEmail" key="recipientEmail"/>
                <Table.Column
                  key="id"
                  render={record => (
                    <Button type="link" onClick={() => this.cancelOwnershipRequest(record.secret)}>
                      Cancel request
                    </Button>
                  )}
                />
              </Table>
            </Box>
            }
          </Card>
        </Box>

        <Box width={1} py={2} >
          <InstanceCard title="Instances" extra={isOwner ? this.instanceCardExtraButton(teamId) : null}>
            {instances.length ?
              <Table
                dataSource={instances}
                pagination={false}
                onRow={(record: any) => {
                  return {
                    onClick: () => this.navigateToInstanceDetail(record.key)
                  }
                }}
              >
                <Table.Column title="Name" dataIndex="name" key="name"/>
                <Table.Column
                  title="Instance type"
                  dataIndex="type"
                  key="type"
                  render={type => {
                    return (<InstanceTypeTag instanceType={type} />)
                  }}
                />
              </Table> :
              <NoInstanceContainerDiv>
                <NoInstanceText color="scorpion.light">
                  This team does not have any instances
                  {isOwner ?
                    <span>,&nbsp;
                    <Link to={{ pathname: createInstancePath,state: { teamId }}}>
                      click here to create your first.
                    </Link>
                    </span>
                  : <span>.</span>}
                </NoInstanceText>
              </NoInstanceContainerDiv>
            }
          </InstanceCard>
        </Box>
        {isOwner ?
          <AddMemberModal
            addMembersModalVisible={addMembersModalVisible}
            closeAddMembersModal={this.closeAddMembersModal}
            inviteeEmails={inviteeEmails}
            handleAddInviteeEmails={this.handleAddInviteeEmails}
            handleAddMembersSubmit={this.handleAddMembersSubmit}
          /> : null
        }
        <OwnershipTransferModal
          ownershipTransferModalVisible={ownershipTransferModalVisible}
          closeOwnershipTransferModal={this.closeOwnershipTransferModal}
          memberList={members}
          handleSelectTeamMember={this.handleSelectTeamMember}
          handleOwnershipTransferSubmit={this.handleOwnershipTransferSubmit}
          canCreateTransfer={_.isEmpty(ownershipTransfer)}
        />
      </Flex>
    )
  }

  render() {
    const teamId = this.getTeamId()
    const { team, loading } = this.getTeamState(teamId)
    const teamName = team ? team.name : ''
    const teamMembers = this.props.teamMembers[teamId]
    const isOwner = team ? team.owner.id === this.props.userProfile.id : false

    return (
      <TeamTemplate activePage="overview" teamId={teamId} teamName={teamName} isOwner={isOwner}>
        {!team ?
          <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <Spin size="large"/>
          </SpinContainer>
          :
          <Spin spinning={loading} size="large">
            {this.renderTeamOverview(team, teamMembers, loading)}
          </Spin>
        }
      </TeamTemplate>
    )
  }
}

const InfoLabel = styled.span<ColorProps>`
  ${color};
  display: block;
`
const Info = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const EditTime = styled.span<ColorProps>`
  ${color};
`
const Tag = styled(AntTag)<TypographyProps>`
  ${typography};
  line-height: unset;
`
const RoleText = styled.span`
  ${color};
`
const NoInstanceContainerDiv = styled.div`
  text-align: center;
`
const NoInstanceText = styled.p<ColorProps>`
  ${color};
  font-style: italic;
`
const AddInstanceContainerDiv = styled.div`
  cursor: pointer;
`

const AddInstanceText = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const InstanceCard = styled(Card)`
  .ant-table-row {
    cursor: pointer;
  }
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getTeamById,
    getTeamInvitations,
    inviteTeamMember,
    cancelTeamInvitation,
    transferTeamOwnership,
    removeTeamMember,
    leaveTeam,
    getTeamMembers,
    getOwnershipTransferByTeamId,
    declineOwnershipTransfer,
  },
    dispatch
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    teamById: state.teams.teamById,
    teamMembers: state.teams.teamMembers,
    userProfile: state.auth.userProfile,
    ownershipTransfer: state.ownershipTransfer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails)
