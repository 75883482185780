import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, Select, message } from 'antd'
import { Box, Flex } from '@rebass/grid'
import { SpaceProps, space } from 'styled-system'
import _ from 'lodash'

import { TaxId, BillingDetails, Address } from 'api'

import variables from '../variables'
import { Button } from '../component'
import filterCountries from '../utils/filterCountries'

interface Props {
  billingDetails: any
  updateBillingDetails: (billingDetails: BillingDetails) => Promise<any>
  deleteCard: () => Promise<any>
}

const BillingInfoForm: React.FC<Props> = ({ billingDetails, updateBillingDetails, deleteCard }) => {
  const [loading, setLoading] = useState(false)
  const [states, setStates] = useState(variables.states[billingDetails.address.country])
  const disabled = loading
  const [form] = Form.useForm()

  // eslint-disable-next-line
  useEffect(() => form.resetFields(), [billingDetails])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },

    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }
  const submit = async (values: any) => {
    await setLoading(true)
    let taxId: TaxId | undefined
    const address: Address = {
      name: undefined,
      streetAddress: values.billingAddress,
      postalCode: values.postalCode,
      city: values.city,
      country: values.country,
      state: values.state,
    }
    if (_.trim(values.vatId)) {
      taxId = {
        country: values.country,
        value: values.vatId
      }
    }

    const billingDetails: BillingDetails = { taxId, address, company: values.company }
    try {
      window.analytics.track('Update billing details')
      const update = await updateBillingDetails(billingDetails)
      form.resetFields()
      if(update.success) {
        message.success('Billing details updated successfully')
      } else {
        message.error(update.message || 'Error occured, please try again')
      }
      await setLoading(false)
    } catch(e) {
      await setLoading(false)
      message.error('Error occured, please try again')
    }
  }

  const updateStates = (e: any) => {
    const s = variables.states[e]
    form.setFields([{name: 'state', value: ''}])
    setStates(s)
  }

  return (
    <Form
      form={form}
      {...formItemLayout}
      layout="vertical"
      onFinish={submit}
      labelAlign="left" colon={false}
      hideRequiredMark
      initialValues={{
        company: billingDetails.address.line1,
        vatId: billingDetails.vatId,
        billingAddress: billingDetails.address.line2,
        postalCode: billingDetails.address.postal_code,
        city: billingDetails.address.city,
        country: billingDetails.address.country,
        state: billingDetails.address.state
      }}
    >
      <Flex width={1} flexWrap="wrap">
        <Box width={[1, 1 / 2]} pr={[0,4]}>
          <StyledFormItem mb={4} label="Company" name="company">
            <Input disabled={disabled} size="large" placeholder="Company (Optional)" />
          </StyledFormItem>
        </Box>
        <Box width={[1, 1 / 2]}>
          <StyledFormItem mb={4} label="VAT ID" name="vatId">
            <Input disabled={disabled} size="large" placeholder="VAT id (Optional)" />
          </StyledFormItem>
        </Box>
        <Box width={[1, 1 / 2]} pr={[0,4]}>
          <StyledFormItem mb={4} label="Billing address *" name="billingAddress" rules={[{required: true, message: 'Please add billing address'}]}>
            <Input disabled={disabled} size="large" placeholder="Billing address" />
          </StyledFormItem>
        </Box>
        <Box width={[1, 1 / 2]}>
          <StyledFormItem mb={4} label="Postal code *" name="postalCode" rules={[{required: true, message: 'Please add postal code'}]}>
            <Input disabled={disabled} size="large" placeholder="Postal code" />
          </StyledFormItem>
        </Box>
        <Box width={[1, 1 / 2]} pr={[0,4]}>
          <StyledFormItem mb={4} label="City *" name="city" rules={[{required: true, message: 'Please add city'}]}>
            <Input disabled={disabled} size="large" placeholder="City" />
          </StyledFormItem>
        </Box>
        <Box width={[1, 1 / 2]}>
          <StyledFormItem mb={4} label="Country *" name="country" rules={[{required: true, message: 'Please add country'}]}>
            <Select
              showSearch size="large"
              placeholder="Country"
              onChange={updateStates}
              disabled={disabled}
              optionFilterProp="children"
              filterOption={filterCountries}
            >
              {_.map(variables.countries, (country, i) => {
                return (
                  <Select.Option disabled={disabled} value={country.isoCode} key={i}>
                    {country.label}
                  </Select.Option>
                )
              })}
            </Select>
          </StyledFormItem>
        </Box>
        <Box width={[1, 1 / 2]} pr={[0,4]}>
          <StyledFormItem mb={4} label="State" name="state" rules={[{required: !_.isEmpty(states), message: 'Please add state'}]}>
            <Select
              showSearch
              size="large"
              placeholder="State"
              disabled={_.isEmpty(states) || disabled}
              optionFilterProp="children"
              filterOption={filterCountries}
            >
              {_.map(states, (state, i) => (
                <Select.Option value={state.isoCode} key={i}>
                  {state.label}
                </Select.Option>

              ))}
            </Select>
          </StyledFormItem>
        </Box>
      </Flex>
      <ButtonFlex wrap="flexWrap">
        <StyledFormItem mb={2} mr={4}>
          <Button disabled={loading} htmlType="submit">Update</Button>
        </StyledFormItem>
        <StyledFormItem mb={2}>
          <Button
            onClick={deleteCard}
            danger
            ghost
            disabled={loading}
          >
            Delete
          </Button>
        </StyledFormItem>
      </ButtonFlex>
    </Form>
  )
}

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
  .ant-col {
    max-width: 100%;
  }
`
const ButtonFlex = styled(Flex)`
  margin: 10px 0;
`

export default BillingInfoForm
