import React from 'react'
import styled from 'styled-components'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router'
import {message, Modal} from 'antd'
import { Box, Flex } from '@rebass/grid'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'

import { Button, NavigationBar } from '../component'
import { signOut, updateFirstTimeLoginStatus, updateUserTosStatus } from '../ducks/auth'
import { AppState } from '../ducks'
import { getLatestTos } from '../ducks/tos'
import { ConsoleUser } from '../types/User'
import { TermsOfService } from '../types/TermsOfService'
import { Error } from '../types/Error'

interface Props extends RouteComponentProps {
  signOut: () => (dispatch: any) => Promise<void>,
  updateUserTosStatus: (userId: string) => (dispatch: any) => Promise<void>,
  updateFirstTimeLoginStatus: () => (dispatch: any) => Promise<void>,
  getLatestTos: () => (dispatch: any) => Promise<void>,
  latestTos: TermsOfService,
  loading: boolean,
  error: null | Error,
  children: any,
  userProfile: ConsoleUser
}

interface State {
  isLatestTosAgreed: boolean
}

class Layout extends React.Component<Props> {
  readonly state: State = {
    isLatestTosAgreed: true,
  }

  async componentDidMount() {
    await this.props.getLatestTos()
    const { latestTos, error, userProfile } = this.props
    // Check whether the latest tos is agreed when it is not first time login. If it is first time login, this is
    // handled in this.handleWelcomeModalClose
    if (!userProfile.firstTimeLogin) {
      if (!error && latestTos) {
        const isLatestTosAgreed = latestTos.version === userProfile.tosVersionAgreed
        this.setState({isLatestTosAgreed})
      }
    }
  }

  handleAcceptLatestTos = async () => {
    const { userProfile } = this.props
    await this.props.updateUserTosStatus(userProfile.id)
    if (this.props.error) {
      message.error(this.props.error)
    } else {
      this.setState({ isLatestTosAgreed: true})
    }
  }

  render() {
    const { latestTos, loading } = this.props
    const { isLatestTosAgreed } = this.state
    return (
      <PageContainer>
        <NavigationBar handleSignOut={this.props.signOut}/>
        {this.props.children}
        {latestTos &&
          <Modal
            title="Terms of service and privacy policy updated"
            footer={null}
            centered
            visible={!isLatestTosAgreed}
            closable={false}
          >
            <ModalText color="scorpion.medium">
              We have updated our
              <a target="_blank" rel="noopener noreferrer" href={latestTos.url}> terms of service </a>
              and
              <a target="_blank" rel="noopener noreferrer" href="https://aito.ai/privacy-policy/"> privacy policies </a>.
              Please review and agree to them before continuing using our service. Otherwise, you will be logged out of
              the service.
            </ModalText>
            <Flex mt={3}>
              <Box mr={2}>
                <Button loading={loading} onClick={this.handleAcceptLatestTos}>Agree</Button>
              </Box>
              <Box ml={2}>
                <CancelButton color="scorpion.light" type="link" onClick={this.props.signOut}>Cancel</CancelButton>
              </Box>
            </Flex>
          </Modal>
        }
      </PageContainer>
    )

  }
}

const PageContainer = styled.div`
  height: 100%;
`

const ModalText = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const CancelButton = styled(Button)<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  ${space};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ signOut, updateUserTosStatus, getLatestTos, updateFirstTimeLoginStatus }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: state.auth.userProfile,
    latestTos: state.tos.latestTos,
    loading: state.tos.loading,
    error: state.tos.error
  }
}

const PageLayout = withRouter(Layout as React.ComponentType<any>)

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout)
