import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  space,
  SpaceProps,
  layout,
  LayoutProps, ColorProps, TypographyProps, color, typography
} from 'styled-system'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { message, Spin } from 'antd'
import { RouteComponentProps, Redirect } from 'react-router'
import { PaymentMethod } from '@stripe/stripe-js'
import queryString from 'query-string'
import { InstanceType, UUID, CreateInstance as CreateInstanceBody, InstanceProduct } from 'api'
import { PageHeader, CreateInstanceForm } from '../component'
import { TeamPreview } from '../types/Team'
import { Error } from '../types/Error'
import { instanceOverviewPath } from '../route'
import { AppState } from '../ducks'
import { createTeam, getUserTeams } from '../ducks/teams'
import { getUserDefaultCardId, getUserCreditCards } from '../ducks/user'
import { getUserInstances, UserInstancesState } from '../ducks/instances'
import { createInstance } from '../ducks/instances'
import { ConsoleUser as User } from '../types/User'

import { CreateInstanceRequest } from '../utils/api/teamApi'
import { getProducts } from '../utils/api/paymentsApi'

interface RouteInfo {
  userId: string
}

interface Props extends RouteComponentProps<RouteInfo>, ReturnType<typeof mapDispatchToProps> {
  userInstances: UserInstancesState,
  userProfile: User,
  creditCards: PaymentMethod[],
  defaultCardId: string,
  teams: Array<TeamPreview>,
  teamsLoading: boolean,
  teamError: null | Error,
  createdInstanceId: UUID | null,
  createInstanceLoading: boolean,
  createInstanceError: null | Error
}

interface State {
  createInstanceSuccess: boolean
  paymentModalVisible: boolean
  products: InstanceProduct[]
  loading: boolean
}

const breadcrumbItems = [
  {
    text: 'Create a new instance',
    icon: false
  }
]

class CreateInstance extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      createInstanceSuccess: false,
      paymentModalVisible: false,
      products: [],
      loading: false
    }
  }

  async getUserProducts() {
    const products = await getProducts()
    await this.setState({ products })
  }

  async componentDidMount() {
    const { id: userId } = this.props.userProfile
    window.analytics.page('Create instance')
    await this.setState({ loading: true })
    await Promise.all([
      this.getUserProducts(),
      this.props.getUserTeams(userId),
      this.props.getUserInstances(userId),
      this.props.getUserDefaultCardId(),
      this.props.getUserCreditCards(),
    ])
    await this.setState({ loading: false })
  }

  handleCreateInstance = async (teamId: string | undefined, body: CreateInstanceBody) => {
    const { createInstance } = this.props
    let request: CreateInstanceRequest
    window.analytics.track('Instance create', { instanceType: body.instanceType })
    const { instanceType, instanceName, extraStorage, promotionCode } = body

    if (instanceType === InstanceType.Sandbox) {
      const { userProfile: { id: userId } } = this.props
      request = { instanceType, instanceName, extraStorage, userId, promotionCode }
    } else if (teamId) {
      request = { instanceType, instanceName, extraStorage, teamId, promotionCode }
    } else {
      return false
    }

    await createInstance(request)
    const { createInstanceError } = this.props
    if (createInstanceError) {
      message.error(createInstanceError.message)
    } else {
      this.setState({createInstanceSuccess: true})
    }
  }

  handleCreateTeam = async (teamName: string) => {
    const { userProfile, createTeam } = this.props
    const ownerId = userProfile.id
    const body = {
      name: teamName,
      ownerId, // FIXME: request author always becomes the owner, not specified in body
    }

    const result = await createTeam(body)
    const { teamError } = this.props
    if (teamError) {
      message.error(teamError.message)
    }
    return result
  }

  render() {
    const { teams, teamsLoading, createInstanceLoading, location: propsLocation, userProfile, userInstances, createdInstanceId, creditCards, defaultCardId } = this.props
    const userOwnTeams = _.filter(teams, team => team.owner.id === userProfile.id)
    const sandboxInstanceExisted = _.some(userInstances.instances, { type: InstanceType.Sandbox})

    const location: any = propsLocation

    let instanceType: InstanceType
    if (queryString.parse(this.props.location.search).instanceType) {
      const instanceTypeString = queryString.parse(this.props.location.search).instanceType || ''
      instanceType = InstanceType[instanceTypeString as keyof typeof InstanceType]
    } else if (location.state) {
      instanceType = (location.state as any).instanceType
    } else {
      instanceType = InstanceType.Developer
    }

    return (
      <ContainerDiv>
        <PageHeader items={breadcrumbItems}/>
        <ContentDiv p={[2, 4]} maxWidth={900} bg="white" my={[0, 4]} mx="auto">
          <CreateInstanceFormHeader mb={10} fontSize={[3, 4]} fontWeight="bold" color="scorpion.medium">
            Instance details &nbsp;
            <Spin spinning={this.state.loading}/>
          </CreateInstanceFormHeader>
            {this.state.loading ? undefined :
              <CreateInstanceForm
                creditCards={creditCards}
                defaultCardId={defaultCardId}
                teams={userOwnTeams}
                teamsLoading={teamsLoading}
                createInstanceLoading={createInstanceLoading}
                handleCreateInstance={this.handleCreateInstance}
                handleCreateTeam={this.handleCreateTeam}
                getUserCreditCard={this.props.getUserCreditCards}
                getUserDefaultCardId={this.props.getUserDefaultCardId}
                defaultTeamId={location.state ? (location.state as any).teamId : undefined}
                instanceType={instanceType}
                sandboxInstanceExisted={sandboxInstanceExisted}
                products={this.state.products}
              />
            }
        </ContentDiv>
        {!this.state.createInstanceSuccess || createdInstanceId === null ? null :
          <Redirect to={instanceOverviewPath(createdInstanceId)} />
        }
      </ContainerDiv>
    )
  }
}

const ContainerDiv = styled.div<SpaceProps | LayoutProps | ColorProps>`
  ${space};
  ${layout};
  ${color};
`

const ContentDiv = styled.div<SpaceProps | LayoutProps | ColorProps>`
  ${space};
  ${layout};
  ${color};
`
const CreateInstanceFormHeader = styled.div<SpaceProps | ColorProps | TypographyProps>`
  ${space};
  ${color};
  ${typography};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getUserTeams,
    createInstance,
    createTeam,
    getUserInstances,
    getUserDefaultCardId,
    getUserCreditCards
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    userInstances: state.instances.userInstances,
    userProfile: state.auth.userProfile,
    teams: state.teams.userTeams.teams,
    teamsLoading: state.teams.userTeams.loading,
    teamError: state.teams.userTeams.error,
    createdInstanceId: state.instances.createInstance.instanceId,
    createInstanceLoading: state.instances.createInstance.loading,
    createInstanceError: state.instances.createInstance.error,
    creditCards: state.user.creditCards,
    defaultCardId: state.user.defaultCardId,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInstance)
