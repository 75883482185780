import { unexpectedSignOut } from '../ducks/auth'
import { ThunkMiddleware } from 'redux-thunk'

export const interceptHttp401: ThunkMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.payload && action.payload.response && action.payload.response.statusCode === 401) {
    const pathName = window.location.pathname + window.location.search
    dispatch(unexpectedSignOut(pathName))
  } else {
    next(action)
  }
}
