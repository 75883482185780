import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import { createConsoleStore } from './store'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { GlobalStyle, GlobalStylesDiv } from './styles/global'
import { theme } from './styles/theme'
import config from './config'

const store = createConsoleStore()
const stripePromise = loadStripe(config.stripe.publishableApiKey)

declare global {
  interface Window { analytics: any }
}

// We need to wrap the React app with the <Element> component from stripe and provide the publishable API key
const Index = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
    <Elements stripe={stripePromise}>
        <GlobalStyle />
        <GlobalStylesDiv>
          <App />
        </GlobalStylesDiv>
    </Elements>
    </Provider>
  </ThemeProvider>
)

ReactDOM.render(Index, document.getElementById('root'))
