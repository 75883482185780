import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../svg-assets/aito-logo.svg'
import {
  ColorProps,
  color,
  layout,
  LayoutProps,
  space,
  SpaceProps
} from 'styled-system'
import { Button } from '../component'
import { Flex } from '@rebass/grid'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { message, Spin } from 'antd'

import { getInvitation, acceptInvitation, ignoreInvitation } from '../ducks/invitations'
import { AppState } from '../ducks'
import { Invitation as InvitationInterface } from '../types/Invitation'
import { teamOverviewPath } from '../route'
import { theme } from '../styles/theme'

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  invitation: InvitationInterface,
  invitationErrorMessage: string,
  invitationLoading: boolean,
}

interface State {}

class Invitation extends React.Component<Props, State> {
  async componentDidMount() {
    window.analytics.page('Invitation')
    const inviteSecret = queryString.parse(this.props.location.search).invite || ''
    await this.props.getInvitation(inviteSecret)
  }

  handleIgnoreInvitation = async () => {
    const inviteSecret = queryString.parse(this.props.location.search).invite || ''
    await this.props.ignoreInvitation({ secret: inviteSecret })
    if (this.props.invitationErrorMessage) {
      message.error(this.props.invitationErrorMessage)
    } else {
      this.props.history.push('/')
    }
  }

  handleAcceptInvitation = async () => {
    const { invitation } = this.props
    const inviteSecret = queryString.parse(this.props.location.search).invite || ''
    await this.props.acceptInvitation({secret: inviteSecret})

    if (this.props.invitationErrorMessage) {
      message.error(this.props.invitationErrorMessage)
    } else {
      this.props.history.push(teamOverviewPath(invitation.team.id))
    }
  }

  render() {
    const { invitation, invitationErrorMessage, invitationLoading } = this.props

    if (!invitationLoading && invitation) {
      return (
        !invitation.result ?
          <ContainerDiv bg="white" flexDirection="column" justifyContent="center" alignItems="center">
            <AitoLogo size={[100, 140]}/>
            <p>{invitation.inviter.name} has invited you to join team {invitation.team.name} on Aito.ai</p>
            <p>Accept this invitation to be able to manage the team's instances </p>
            <ButtonContainer flexDirection="row">
              <StyledButton
                onClick={this.handleAcceptInvitation}
                bgcolor={theme.colors.jade.medium}
                color="white"
              >
                Accept
              </StyledButton>
              <StyledButton type="default"
                onClick={this.handleIgnoreInvitation}
              >
                Decline
              </StyledButton>
            </ButtonContainer>
          </ContainerDiv>
          :
          <p>This invitation no longer exist</p>
      )
    } else if (!invitationLoading && invitationErrorMessage) {
      return (
        <ContainerDiv bg="white" flexDirection="column" justifyContent="center" alignItems="center">
          <AitoLogo size={[100, 140]}/>
          <h1>Invalid invite</h1>
          <p>It seems that the invite is either invalid or has expired.</p>
          <Button><a href='/'>Take me back</a></Button>
        </ContainerDiv>
      )
    } else {
      return (
        <ContainerDiv bg="white" flexDirection="column" justifyContent="center" alignItems="center">
          <Spin size="large"/>
        </ContainerDiv>
      )
    }
  }
}

const ContainerDiv = styled(Flex)<SpaceProps | ColorProps>`
  ${space};
  ${color};
  height: 100%;
` as typeof Flex

const ButtonContainer = styled(Flex)<SpaceProps | ColorProps>`
  ${space};
  ${color};
` as typeof Flex

const StyledButton = styled(Button)`
  margin: 5px;
` as typeof Button

const AitoLogo = styled(Logo)<LayoutProps>`
  ${layout};
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ acceptInvitation, ignoreInvitation, getInvitation }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    invitationErrorMessage: state.invitations.errorMessage,
    invitationLoading: state.invitations.loading,
    invitation: state.invitations.invitation
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation)
