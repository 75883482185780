import React, { ExoticComponent } from 'react'
import { RouteComponentProps } from 'react-router'
import InstanceTemplate from './InstanceTemplate'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import {
  deleteInstance,
  getInstance,
  InstanceStatus,
  getUserInstances,
  UserInstancesState
} from '../../ducks/instances'
import {
  getInstanceEvaluationStatus,
  createInstanceEvaluation,
  deletePredictionTarget,
} from '../../ducks/evaluations'
import { UUID, AitoClient, PredictionTarget, User, DateLike } from 'api'
import { bindActionCreators, Dispatch } from 'redux'
import { AppState } from '../../ducks'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Box, Flex } from '@rebass/grid'
import { message, Modal, Space, Table } from 'antd'
import { evaluationPath } from '../../route'
import { getApiKeyByKeyId, getInstanceEvaluations } from '../../utils/api/instanceApi'
import { AddCircle } from '@styled-icons/material'
import _ from 'lodash'
import { Button, CreateEvaluationModal, Heading } from '../../component'
import { ApiKey } from '../../types/ApiKey'
import { TableSchema } from 'api/schema/aito'
import { format } from 'date-fns'
import variables from '../../variables'
import { ReactComponent as ErrorIcon } from '../../svg-assets/404-icon.svg'
import { evaluationFinishedIn } from './evaluationUtils'
import { theme } from '../../styles/theme'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'

const evaluationsPollingInterval = 15000
const defaultState = 'Started'

interface InstanceEvaluationState  {
  instanceId: UUID,
  evaluationId: UUID,
  state: string | undefined,
  text: string,
  hover: string | undefined
}

interface Props extends RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
  instances: { [k: string]: InstanceStatus },
  apiKeys: { [k: string]: ApiKey[] },
  evaluations: { [k: string]: PredictionTarget[] },
  evaluationLoading: boolean,
  deleteLoading: boolean,
  evaluationError: string | undefined,
  userInstances: UserInstancesState,
  userProfile: User,
  match: any
}

interface State {
  showModal: boolean
  showDeleteModal: string | undefined
  aitoSchema: { schema: { [k: string]: TableSchema }} | unknown
  schemaError: string | undefined,
  loading: boolean,
  evaluationState: InstanceEvaluationState[] | undefined
}

class InstanceEvaluations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: false,
      showDeleteModal: undefined,
      aitoSchema: {},
      schemaError: undefined,
      loading: true,
      evaluationState: undefined
    }
  }

  intervalId: number | undefined

  evaluationText(predictionTarget: PredictionTarget | null | undefined): string[] {
    const state = predictionTarget?.evaluationResults?.state
    const texts = evaluationFinishedIn(predictionTarget)

    if (!state) return [defaultState]
    else if (!texts) return [state === 'finished' ? 'Parsing results' : _.capitalize(state)]
    else if (!texts.percent) return [defaultState]

    return [`${texts.percent}% complete`, `Estimated to complete in ${texts.finishedInDuration}`]
  }

  predictionTargetsToEvaluationState(instanceId: UUID, predictionTargets: PredictionTarget[]): InstanceEvaluationState[] {
    const evaluationState: InstanceEvaluationState[] = (predictionTargets || []).map(pt => {
      const evaluationId: UUID = pt.id
      const [text, hover] = this.evaluationText(pt)
      return {
        instanceId,
        evaluationId,
        state: pt.evaluationResults?.state,
        text,
        hover
      }
    })
    return evaluationState
  }

  async pollEvaluations() {
    try {
      const { instanceId } = this.props.match.params
      const predictionTargets = (await getInstanceEvaluations(instanceId))?.data
      const evaluationState = this.predictionTargetsToEvaluationState(instanceId, predictionTargets)
      this.setState({ evaluationState })
    } catch (err) {
      // Continue, when method is called after the interval
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  async componentDidMount() {
    window.analytics.page('Instance evaluations')
    const { instanceId } = this.props.match.params
    const { userProfile } = this.props
    if (this.props.userProfile) this.props.getUserInstances(this.props.userProfile.id)
    if (instanceId) {
      await this.props.getInstance(instanceId, userProfile.id)
      this.props.getInstanceEvaluationStatus(instanceId)

      const { apiKeys, instances } = this.props
      const instanceApiKey = apiKeys[instanceId] || {}
      const instanceStatus = instances[instanceId] || {}
      const instance = instanceStatus.instance
      const apiKey = _.find(instanceApiKey, { type: 'READ_ONLY' })
      if (apiKey && instance) {
        try {
          const key = await getApiKeyByKeyId(instanceId, apiKey.id)
          const client = AitoClient.createClient({ hostname: instance.hostname, key: key.data.key })
          const schema: { schema: { [k: string]: TableSchema }} = await client.getSchema()
          this.setState({ aitoSchema: schema })
        } catch (e) {
          let m = 'error'
          if (e instanceof Error) m = e.message
          this.setState({ schemaError: m })
        }
      }
      const evaluationState = this.predictionTargetsToEvaluationState(instanceId, this.props.evaluations[instanceId])
      this.setState({ loading: false, evaluationState })
      this.intervalId = setInterval(this.pollEvaluations.bind(this), evaluationsPollingInterval)
    }
  }

  navigateToEvaluatePage = (instanceId: string, evaluationId: string) => {
    const { history } = this.props
    history.push(evaluationPath(instanceId, evaluationId))
  }

  createEvaluation = async (values: any) => {
    const { instanceId } = this.props.match.params
    const { aitoSchema } = this.state
    if (instanceId && aitoSchema) {
      window.analytics.track('Create evaluation')
      const s = aitoSchema as { schema: { [k: string]: TableSchema }}
      await this.props.createInstanceEvaluation(instanceId, values, s.schema)
      this.props.getInstanceEvaluationStatus(instanceId)
      // Polling happens due to call in componentDidMount
    }
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  deletePredictionTarget = async (id: string) => {
    const { instanceId } = this.props.match.params
    const del = await this.props.deletePredictionTarget(id, instanceId)
    this.setState({ showDeleteModal: undefined })

    if (del && del.error) {
      message.error(`Could not delete the evaluation`)
    } else {
      message.info('Evaluation deleted')
    }
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances, evaluations } = this.props
    const { instance = null, loading: instanceLoading = true } = instances[instanceId] || {}
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const userProfile = this.props.userProfile || {id: '', fullName: '', email: ''}
    const isOwner = instance ? instance.ownerId === userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'

    const instanceEvaluations: PredictionTarget[] = _.orderBy(evaluations[instanceId], 'createdAt', 'desc') || []
    const { evaluationState } = this.state

    const breakPoints = [ 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ] as Breakpoint[]
    const columns = [
      { title: 'Dataset', key: 'tableName', dataIndex: 'tableName' },
      { title: 'Target column', key: 'targetColumn', dataIndex: 'targetColumn' },
      { title: 'Evaluation status', key: 'textState', dataIndex: 'textState', responsive: breakPoints.slice(1) },
      { title: 'Created at', key: 'createdAt', dataIndex: 'createdAt', responsive: breakPoints.slice(2) },
      { title: 'Action', key: 'action', dataIndex: 'action', render: (text: string, record: any) => (
        <Space size="middle">
          <LinkButton
            onClick={(e: any) => { e.stopPropagation(); this.setState({ showDeleteModal: record.id })}}
          >
            Delete
          </LinkButton>
          <Modal
            visible={this.state.showDeleteModal === record.id}
            onOk={() => this.deletePredictionTarget(record.id)}
            onCancel={() => this.setState({ showDeleteModal: undefined })}
            confirmLoading={this.props.deleteLoading}
            centered={true}
            okText="Delete"
            okType="danger"
          >
            <Heading fontSize={3}>Delete evaluation</Heading>
            <p>Are you sure you want to delete this evaluation?</p>
          </Modal>
        </Space>)}
    ]

    const data = instanceEvaluations.map((e, i) => {
      const currentState = evaluationState?.find(es => es.evaluationId === e.id)
      const textState = currentState?.text || defaultState

      return {
        id: e.id,
        key: e.id,
        tableName: e.data.tableName,
        targetColumn: e.predictor.target.targetColumn.name,
        textState,
        createdAt: format(DateLike.toDate(e.createdAt), variables.dateFnsTimeFormat)
      }
    })

    const actionBar = (
      <ActionBar m={2} py={2} justifyContent="flex-end">
        <ButtonBox onClick={this.toggleModal}>
          <AddText fontSize={0} color="scorpion.light" fontWeight={5}>
            New evaluation
          </AddText>
          <AddIcon size={24} color="jade.medium" ml={2}/>
        </ButtonBox>
      </ActionBar>
    )

    const loading = instanceLoading || this.props.evaluationLoading || this.state.loading
    let content
    if (!loading && _.isEmpty(instanceEvaluations)) {
      content = (
        <Container>
          {actionBar}
          <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <NoEvaluationText>No evaluations found</NoEvaluationText>
            <Button onClick={() => {this.setState({ showModal: true })}}>Create evaluation</Button>
          </SpinContainer>
        </Container>
      )
    } else if (!loading && (this.state.schemaError || this.props.evaluationError)) {
      content =
        <ErrorContainer>
          <StyledErrorIcon mb={4}/>
          <p>We couldn't find your evaluation data, please try again soon</p>
        </ErrorContainer>
    } else {
      content = (
        <Container>
          {actionBar}
          <StyledTable
            columns={columns}
            dataSource={loading ? undefined : data}
            loading={loading}
            onRow={(record: any) => ({ onClick: () => !this.state.showDeleteModal && this.navigateToEvaluatePage(instanceId, record.id) })}
          />
        </Container>
      )
    }


    return (
      <InstanceTemplate
        instanceId={instanceId}
        activePage="evaluation"
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        {content}
        <CreateEvaluationModal
          visible={this.state.showModal}
          handleSubmit={this.createEvaluation}
          cancelModal={this.toggleModal}
          schema={this.state.aitoSchema}
        />
      </InstanceTemplate>
    )
  }
}

const Container = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`

const ActionBar = styled(Flex)`
  border-bottom: 1px solid rgba(196, 206, 213, 0.3);
`

const ButtonBox = styled(Box)`
  cursor: pointer;
`
const StyledTable = styled(Table)`
  .ant-table-tbody {
    background-color: white;
  }
`
const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`

const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`

const AddText: ExoticComponent<any> = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const StyledErrorIcon = styled(ErrorIcon)<SpaceProps>`
  ${space};
`

const ErrorContainer = styled(Flex)<ColorProps>`
  ${color};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`

const NoEvaluationText = styled.p`
  margin-top: 100px;
`
const LinkButton = styled.span`
  color: ${theme.colors.jade.medium};
  padding: 16px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    deleteInstance,
    getUserInstances,
    getInstanceEvaluationStatus,
    getApiKeyByKeyId,
    createInstanceEvaluation,
    deletePredictionTarget,
  }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    apiKeys: state.instances.apiKeys,
    userInstances: state.instances.userInstances,
    evaluations: state.evaluations.evaluations,
    evaluationLoading: state.evaluations.loading,
    deleteLoading: state.evaluations.deleteLoading,
    evaluationError: state.evaluations.errorMessage,
    userProfile: state.auth.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstanceEvaluations)
