import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PageLayoutRoute from '../route/PageLayoutRoute'
import PrivateRoute from '../route/PrivateRoute'
import Authentication from './Authentication'
import Instances from './Instances'
import NotFound from './404'
import AccountRecovery from './AccountRecovery'
import Profile from './profile/Profile'
import PaymentDetails from './profile/PaymentDetails'
import Invoices from './profile/Invoices'
import CreateInstance from './CreateInstance'
import InstanceDetails from './instance/InstanceDetails'
import InstanceSettings from './instance/InstanceSettings'
import InstanceGetStarted from './instance/InstanceGetStarted'
import InstanceRedirect from './instance/InstanceRedirect'
import InstanceEvaluations from './instance/InstanceEvaluations'
import Evaluation from './instance/Evaluation'
import Teams from './Teams'
import TeamDetails from './team/TeamDetails'
import TeamSettings from './team/TeamSettings'
import CreateTeam from './CreateTeam'
import OauthRedirect from './Oauth'
import Invitation from './Invitation'
import OwnershipTransfer from './OwnershipTransfer'
import Predictions from './instance/Predictions'
import Tables from './instance/Tables'
import Integrations from './instance/Integrations'
import CreateEmptyTable from './instance/CreateEmptyTable'

import {
  signInPath,
  accountRecoveryPath,
  oAuthRedirectPath,
  profileOverviewPath,
  profilePaymentPath,
  profileInvoicesPath,
  invitesPath,
  instancesPath,
  createInstancePath,
  instanceOverviewRawPath,
  instanceSettingsRawPath,
  instanceGetStartedRawPath,
  teamsPath,
  createTeamPath,
  teamOverviewRawPath,
  teamSettingsRawPath,
  instanceRedirectRawPath,
  ownershipRequestRawPath,
  instanceEvaluationRawPath,
  evaluationRawPath,
  predictionsRawPath,
  tablesRawPath,
  tablesFileUploadRawPath,
  instanceOverviewFileUploadRawPath,
  integrationsRawPath,
  tablesCreateEmptyTableRawPath
} from '../route'


export const landingPath = instancesPath

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        {/* TODO: handle redirect from invites to authentication page or team page depend on the user login session*/}
        <Redirect exact path="/" to={landingPath} />
        <Route exact path={signInPath} component={Authentication} />
        <Route exact path={accountRecoveryPath} component={AccountRecovery} />
        <Route exact path={oAuthRedirectPath} component={OauthRedirect} />

        <PrivateRoute exact path={invitesPath} component={Invitation} />
        <PrivateRoute exact path={ownershipRequestRawPath(':teamId')} component={OwnershipTransfer} />

        <PageLayoutRoute isPrivate exact path={profileOverviewPath} component={Profile} />
        <PageLayoutRoute isPrivate exact path={profilePaymentPath} component={PaymentDetails} />
        <PageLayoutRoute isPrivate exact path={profileInvoicesPath} component={Invoices} />

        <PageLayoutRoute isPrivate exact path={instancesPath} component={Instances} />
        <PageLayoutRoute isPrivate exact path={createInstancePath} component={CreateInstance} />
        <PageLayoutRoute isPrivate exact path={instanceRedirectRawPath(':instanceId')} component={InstanceRedirect} />
        <PageLayoutRoute isPrivate exact path={instanceOverviewRawPath(':instanceId')} component={InstanceDetails} />
        <PageLayoutRoute isPrivate exact path={instanceOverviewFileUploadRawPath(':instanceId', '*')} component={InstanceDetails} />
        <PageLayoutRoute isPrivate exact path={instanceSettingsRawPath(':instanceId')} component={InstanceSettings} />
        <PageLayoutRoute isPrivate exact path={instanceGetStartedRawPath(':instanceId')} component={InstanceGetStarted} />
        <PageLayoutRoute isPrivate exact path={instanceEvaluationRawPath(':instanceId')} component={InstanceEvaluations} />
        <PageLayoutRoute isPrivate exact path={integrationsRawPath(':instanceId')} component={Integrations} />
        <PageLayoutRoute isPrivate exact path={evaluationRawPath(':instanceId', ':evaluationTgId')} component={Evaluation} />
        <PageLayoutRoute isPrivate exact path={predictionsRawPath(':instanceId')} component={Predictions} />
        <PageLayoutRoute isPrivate exact path={tablesRawPath(':instanceId')} component={Tables} />
        <PageLayoutRoute isPrivate exact path={tablesCreateEmptyTableRawPath(':instanceId')} component={CreateEmptyTable} />
        <PageLayoutRoute isPrivate exact path={tablesFileUploadRawPath(':instanceId', '*')} component={Tables} />
        <PageLayoutRoute isPrivate exact path={teamsPath} component={Teams} />
        <PageLayoutRoute isPrivate exact path={createTeamPath} component={CreateTeam} />
        <PageLayoutRoute isPrivate exact path={teamOverviewRawPath(':teamId')} component={TeamDetails} />
        <PageLayoutRoute isPrivate exact path={teamSettingsRawPath(':teamId')} component={TeamSettings} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
