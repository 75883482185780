import React, { useEffect, useState } from 'react'
import InstanceTemplate from './InstanceTemplate'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dispatch, bindActionCreators } from 'redux'
import styled from 'styled-components'

import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../ducks'
import { getInstance, InstanceStatus, getUserInstances } from '../../ducks/instances'
import { Box, Flex } from '@rebass/grid'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps  } from 'styled-system'
import { getApiKeyByKeyId } from '../../utils/api/instanceApi'
import { media, theme } from '../../styles/theme'
import { Card, Form, Input, message, Select, Spin } from 'antd'
import { AitoClient, User } from 'api'
import { ApiKey } from '../../types/ApiKey'
import { TableSchema } from 'api/schema/aito'
import { SelectValue } from 'antd/lib/select'
import { Button, CodeSnippet, Heading } from '../../component'
import { ResponsiveContainer, BarChart, Bar, Tooltip, XAxis, YAxis } from 'recharts'
import axios from 'axios'

const { Option } = Select

const getQuery = (table: string, selectedColumn: string, columns: any, values: any) => {
  const withoutEmptyValues = _.omitBy(values, v => v === "" || _.isNil(v))
  const where =  _.mapValues(withoutEmptyValues, (value, key) => {
    const type = columns[key]?.type
    if (type === 'Int') return parseInt(value)
    else if (type === 'Boolean') return !!value
    else if (type === 'Decimal') return parseFloat(value)
    return value
  })
  const query = {
    from: table,
    where: _.omit(where, selectedColumn),
    predict: selectedColumn,
    limit: 5
  }
  return query
}

const PredictForm = (props: any) => {
  const [form] = Form.useForm()
  const [fields, setFields] = useState({})
  const cols = _.keys(props.columns)
  const typeColumns = props.aitoSchema.schema[props.selectedTable]?.columns
  useEffect(() => {
    const convertBooleans = _.mapValues(props.initialValues, (value) => {
      if (value === true) return 1
      else if (value === false) return 0
      else return value
    })
    form.setFieldsValue(convertBooleans)
    setFields(convertBooleans)
  }, [form, props.initialValues])

  return (
    <Form form={form} onFinish={props.onFinish} onValuesChange={e => setFields({ ...fields, ...e })}>
      <Flex flexWrap="wrap">
      <Box width={[1, 1, 1/2]} p={2}>
        <FormFields>
          {cols.map(c => (
            <div key={c}>
              <InfoLabel color="silverTree.darker" fontWeight="bold" mb={1}>{c}:</InfoLabel>
              <Form.Item name={c}>
                {props.columns[c]?.type === 'Boolean' ?
                  <Select disabled={props.disabled} allowClear>
                    <Option value={1}>true</Option>
                    <Option value={0}>false</Option>
                  </Select>
                :<Input disabled={props.disabled} allowClear />}
              </Form.Item>
            </div>
          ))}
        </FormFields>
      </Box>
      <Box width={[1, 1, 1/2]} p={2} backgroundColor={'#eee'}>
        <Heading fontSize={2}>Raw request</Heading>
        <CodeSnippet language="json">
          {JSON.stringify(
            getQuery(props.selectedTable, props.selectedColumn, typeColumns, fields),
            null, 2
          )}
        </CodeSnippet>
      </Box>
      <Box width={1}>
        <FormBottom>
          <StyledButton
            onClick={() => form.resetFields()}
            color="grey"
            ghost
          >
              Clear all
            </StyledButton>
          <Form.Item>
            {props.disabled && <StyledSpin mx={10}/>}
            <StyledButton htmlType="submit">Predict</StyledButton>
          </Form.Item>
        </FormBottom>
        </Box>
      </Flex>
    </Form>
  )
}

interface RouteParam {
}
interface Props extends RouteComponentProps<RouteParam>, ReturnType<typeof mapDispatchToProps> {
  match: any
  instances: { [k: string]: InstanceStatus },
  userProfile: User,
  apiKeys: { [k: string]: ApiKey[] },
}

interface State {
  aitoClient: AitoClient | undefined
  aitoSchema: { schema: Record<string, TableSchema> }
  schemaError: string | undefined
  loading: boolean
  predictLoading: boolean
  selectedTable: string
  selectedColumn: string
  initialValues: any
  result: any
  showResponse: boolean
}

class Predictions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      aitoClient: undefined,
      aitoSchema: { schema: {} },
      schemaError: undefined,
      loading: true,
      predictLoading: false,
      selectedTable: '',
      selectedColumn: '',
      result: undefined,
      initialValues: undefined,
      showResponse: false
    }
  }

  async componentDidMount() {
    const { instanceId } = this.props.match.params
    const { userProfile } = this.props
    if (instanceId) {
      await this.props.getInstance(instanceId, userProfile.id)

      const { apiKeys, instances } = this.props
      const instanceApiKey = apiKeys[instanceId] || {}
      const instanceStatus = instances[instanceId] || {}
      const instance = instanceStatus.instance
      const apiKey = _.find(instanceApiKey, { type: 'READ_ONLY' })
      if (apiKey && instance) {
        try {
          const key = await getApiKeyByKeyId(instanceId, apiKey.id)
          const client = AitoClient.createClient({ hostname: instance.hostname, key: key.data.key })
          const schema: { schema: Record<string, TableSchema> } = await client.getSchema()
          const firstTable = _.first(_.sortBy(_.keys(schema.schema))) || ''
          const firstColumn = _.first(_.sortBy(_.keys(schema.schema[firstTable].columns))) || ''
          const row = await client.search({ from: firstTable, limit: 1 })
          this.setState({
            aitoSchema: schema,
            aitoClient: client,
            selectedTable: firstTable,
            selectedColumn: firstColumn,
            initialValues: _.first(row.data.hits),
          })
        } catch (e) {
          let m = 'error'
          if (e instanceof Error) m = e.message
          this.setState({ schemaError: m })
        }
      }
      // Inform page change after data is fetched
      window.analytics.page('Instance predictions')
      this.setState({ loading: false })
    }
  }

  async onTableSelect(value: SelectValue) {
    let row
    if (this.state.aitoClient) {
      this.setState({ predictLoading: true })
      try {
        row = await this.state.aitoClient.search({ from: value as string, limit: 1 })
      } catch (e) {
        message.error('Searching example values failed')
      }
    }
    const firstColumn = _.first(_.keys(this.state.aitoSchema.schema[value as string].columns)) || ''
    this.setState({
      selectedTable: value as string,
      selectedColumn: firstColumn,
      initialValues: _.first(row?.data?.hits),
      predictLoading: false,
      result: undefined,
    })

  }

  onColumnSelect(value: SelectValue) {
    this.setState({ selectedColumn: value as string, result: undefined })
  }

  async predict(values: any) {
    if (this.state.aitoClient) {
      this.setState({ predictLoading: true })
      const columns = this.state.aitoSchema.schema[this.state.selectedTable].columns
      const query = getQuery(this.state.selectedTable, this.state.selectedColumn, columns, values)
      try {
        const prediction = await this.state.aitoClient.predict(query)
        this.setState({ result: prediction.data, predictLoading: false })
      } catch(e) {
        let m
        if (axios.isAxiosError(e)) m = e?.response?.data?.error
        message.error(`Prediction failed: ${m}`, 5)
        this.setState({ predictLoading: false, result: undefined })
      }
    }
  }

  render() {
    const { instanceId } = this.props.match.params
    const { instances } = this.props
    const { instance, loading: instanceLoading } = instances[instanceId] || { instance: null, loading: true }
    const instanceName = !!instance ? instance.name : ''
    const instanceTeam = !!instance ? instance.teamName : ''
    const instanceTeamId = !!instance ? instance.teamId : ''
    const isOwner = instance ? instance.ownerId === this.props.userProfile.id : false
    const state = !!instance ? instance.state : 'CREATING'

    const tableSchema = this.state.aitoSchema.schema[this.state.selectedTable] || {}
    const cols = tableSchema.columns || {}
    const formColumns = _.pickBy(cols, (_, key) => key !== this.state.selectedColumn)

    const formatLabel = (label: string) => label.length > 13 ? label.substr(0, 13).concat('..') : label
    const longestLabelLength = _.take(this.state.result?.hits, 5)
      .map((c: any) => String(c.feature))
      .map(formatLabel)
      .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0)

    const renderChartTooltip = ({ active, payload, label }: any) => {
      if (active && payload && payload.length) {
        return (
          <ToolTipContainer>
            <p>{label}</p>
            <p>{`Confidence: ${(payload[0].value * 100).toFixed(1)} %`}</p>
          </ToolTipContainer>
        )
      }
      return null
    }


    let content
    if (instanceLoading || this.state.loading) {
      content = (
        <SpinContainer flexDirection="column" justifyContent="center" alignItems="center" >
            <Spin size="large" />
          </SpinContainer>
      )
    } else if (this.state.schemaError) {
      content = (
        <Container>
          <Flex flexWrap="wrap" p={2}>
            <Box width={1}>
              <DataCard title={`${instanceName}`}>
                <Flex flexDirection="column" justifyContent="center" alignItems="center">
                  <ErrorText>
                    No tables found, please upload data first.
                  </ErrorText>
                </Flex>
              </DataCard>
            </Box>
          </Flex>
        </Container>
      )
    } else {
      content = (
        <Container>
          <Flex flexWrap="wrap" p={2}>
            <Box width={1}>
              <DataCard title={`${instanceName}`}>
                <Flex flexWrap="wrap">
                  <Box width={[1 / 2, 1 / 3]} px={20}>
                    <InfoLabel color="scorpion.light">
                      Table
                    </InfoLabel>
                    <StyledSelect
                      fontWeight="bold"
                      color="silverTree.darker"
                      disabled={this.state.predictLoading}
                      value={this.state.selectedTable}
                      onSelect={(e) => this.onTableSelect(e)}
                    >
                      {_.sortBy(_.keys(this.state.aitoSchema.schema)).map((k, i) => (
                        <Option key={k + i} value={k}>{k}</Option>
                      ))}
                    </StyledSelect>
                  </Box>
                  <Box width={[1 / 2, 1 / 3]} px={20}>
                    <InfoLabel color="scorpion.light">
                      Predict
                    </InfoLabel>
                    <StyledSelect
                      fontWeight="bold"
                      color="silverTree.darker"
                      disabled={this.state.predictLoading}
                      value={this.state.selectedColumn}
                      onSelect={(e) => this.onColumnSelect(e)}
                    >
                      {_.sortBy(_.keys(tableSchema?.columns)).map((k, i) => (
                        <Option key={k + i} value={k}>{k}</Option>
                      ))}
                    </StyledSelect>
                  </Box>
                </Flex>
              </DataCard>
            </Box>
          </Flex>
          <Flex p={2}>
            <Box width={1}>
              <DataCard>
                <Heading fontSize={3}>Request</Heading>
                <Flex flexWrap="wrap" px={20}>
                  <Box width={1} >
                    <PredictForm
                      columns={formColumns}
                      disabled={this.state.predictLoading}
                      onFinish={(values: any) => this.predict(values)}
                      initialValues={this.state.initialValues}
                      selectedColumn={this.state.selectedColumn}
                      selectedTable={this.state.selectedTable}
                      aitoSchema={this.state.aitoSchema}
                    />
                  </Box>
                  {this.state.result &&
                    <Flex width={1} flexWrap="wrap">
                      <Box width={1}>
                        <Heading fontSize={3}>Response</Heading>
                      </Box>
                      <Box width={[1, 1, 1/2]} p={20}>
                        <ResponsiveContainer aspect={16/9} width="100%">
                          <BarChart
                            data={_.take(this.state.result.hits, 5)}
                            layout="vertical"
                            margin={{top: 5, right: 30, left: 20, bottom: 5}}
                          >
                            <XAxis type="number" ticks={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]} />
                            <YAxis
                              dataKey={(o) => formatLabel(String(o.feature))}
                              type="category"
                              tick={{ fontSize: 14 }}
                              width={longestLabelLength * 7}

                            />
                            <Tooltip content={renderChartTooltip} />
                            <Bar dataKey="$p" fill={theme.colors.jade.medium}/>
                          </BarChart>
                        </ResponsiveContainer>
                      </Box>
                      <Box width={[1, 1, 1/2]} backgroundColor="#eee" p={20}>
                        <Heading fontSize={2}>Raw response</Heading>
                        <CodeSnippet language="json">
                          {JSON.stringify(this.state.result, null, 2)}
                        </CodeSnippet>
                      </Box>
                    </Flex>
                  }
                </Flex>
              </DataCard>
            </Box>
          </Flex>
        </Container>
      )
    }

    return (
      <InstanceTemplate
        instanceId={instanceId}
        instanceName={instanceName}
        instanceTeam={instanceTeam}
        activePage="predictions"
        instanceTeamId={instanceTeamId}
        isOwner={isOwner}
        state={state}
        pendingOperation={instance?.pendingSubscriptionChange}
      >
        {content}
      </InstanceTemplate>
    )
  }
}

const Container = styled.div<SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  margin: 0 auto;
`
const DataCard = styled(Card) <SpaceProps | LayoutProps>`
  ${space};
  ${layout};
  height: 100%;
  .ant-card-body {
     @media ${media.sm} {
      height: 80%;
    }
    height: auto;
  }
`
const ToolTipContainer = styled.div`
  background-color: white;
  filter: drop-shadow(5px 5px 5px #777);
  padding: 10px;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
`
const InfoLabel = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${space};
  ${typography};
  ${color};
`
const StyledSelect = styled(Select)<TypographyProps | ColorProps>`
  width: 100%;
  .ant-select-selection-item {
    ${typography};
    ${color};
  }
`
const SpinContainer = styled(Flex)<ColorProps>`
  ${color};
  height: 100%;
`
const StyledSpin = styled(Spin)<SpaceProps>`
  ${space};
`
const FormFields = styled.div<SpaceProps>`
  max-height: 1200px;
  padding-right: 5px;
  overflow-y: auto;
`
const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
`
const ErrorText = styled.p`
  color: grey;
`

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getInstance,
    getApiKeyByKeyId,
    getUserInstances,
   }, dispatch)
}

const mapStateToProps = (state: AppState) => {
  return {
    instances: state.instances.instances,
    apiKeys: state.instances.apiKeys,
    userProfile: state.auth.userProfile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Predictions)
