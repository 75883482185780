import React, { ExoticComponent } from 'react'
import { Input, Select, Radio, Divider, Form, InputNumber, message } from 'antd'
import { Flex, Box } from '@rebass/grid'
import { CaretDown } from '@styled-icons/fa-solid'
import styled from 'styled-components'
import {color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps} from 'styled-system'
import Dinero from 'dinero.js'
import _ from 'lodash'
import { PaymentMethod } from '@stripe/stripe-js'
import { add, format } from 'date-fns'
import { RadioChangeEvent } from 'antd/lib/radio'
import { InstanceType, isInstanceType, isUUID, CreateInstance, InstanceProduct, AitoPromoCode, isAitoPromoCode } from 'api'
import { TeamInfo } from '../types/Team'
import { Button, CreditCard, PowerByStripe, AddOrChangePaymentModal } from './index'
import { instanceNameValidateRule } from '../utils/formValidateRules'
import { getPromotionCode } from '../utils/api/paymentsApi'
import sandboxIcon from '../svg-assets/hobby-color.svg'
import sandboxIconGray from '../svg-assets/hobby-gray.svg'
import devIcon from '../svg-assets/dev-color.svg'
import devIconGray from '../svg-assets/dev-gray.svg'
import prodIcon from '../svg-assets/prod-color.svg'
import prodIconGray from '../svg-assets/prod-gray.svg'
import { theme } from '../styles/theme'
import { AddCircle } from '@styled-icons/material'

Dinero.globalLocale = 'en-GB'

const InstanceTypeIcon = styled.img<LayoutProps | SpaceProps>`
  ${layout};
  ${space};
  width: 70px;
  height: 70px;
  margin-right: 16px;
`

const instanceTypes = [
  {
    name: 'Sandbox',
    icon: sandboxIcon,
    grayIcon: sandboxIconGray,
  },
  {
    name: 'Developer',
    icon: devIcon,
    grayIcon: devIconGray,
  },
  {
    name: 'Production',
    icon: prodIcon,
    grayIcon: prodIconGray,
  }
]

interface DefaultCardInfo {
  cardHolder: string | null,
  cardInfo: PaymentMethod.Card | undefined
}

interface Props {
  teams: Array<TeamInfo>,
  teamsLoading: boolean,
  createInstanceLoading: boolean,
  handleCreateInstance: (teamId: string | undefined, body: CreateInstance) => void,
  handleCreateTeam: (teamName: string) => Promise<TeamInfo | undefined>,
  getUserCreditCard: () => void,
  getUserDefaultCardId: () => void,
  defaultTeamId?: string,
  instanceType: InstanceType,
  sandboxInstanceExisted: boolean,
  creditCards: PaymentMethod[],
  defaultCardId: string | undefined,
  products: InstanceProduct[]
}

interface State {
  teamId?: string,
  chosenTypeAsString: string | undefined,
  chosenType: InstanceType,
  currentStorage: number,
  newTeamName: string,
  paymentModalVisible: boolean,
  promoCode: string,
  aitoPromoCode: AitoPromoCode | undefined
  isPromoCodeValid: boolean,
}

class CreateInstanceForm extends React.Component<Props, State> {
  private readonly defaultInstanceType: InstanceType = InstanceType.Developer
  private readonly currentInstanceType: InstanceType = this.props.instanceType ? this.props.instanceType : this.defaultInstanceType
  private stringToInstanceType(t: string, defaultValue: InstanceType = this.defaultInstanceType): InstanceType {
    if (isInstanceType(t)) {
      return t
    } else {
      return defaultValue
    }
  }

  public readonly state: State = {
    teamId: undefined,
    chosenTypeAsString: this.currentInstanceType.toString(),
    chosenType: this.currentInstanceType,
    currentStorage: 0,
    newTeamName: '',
    promoCode: '',
    isPromoCodeValid: false,
    aitoPromoCode: undefined,
    paymentModalVisible: false,
  }

  componentDidMount() {
    this.setState({ teamId: this.props.defaultTeamId || undefined })
  }

  componentDidUpdate(nextProps: Props) {
    const { teamId } = this.state
    if (!teamId) {
      const { teams } = nextProps
      // Auto select your team if you only have one. If you have more than
      // one then you need to select it yourself
      const onlyTeamId = teams.length === 1 ? teams[0].id : undefined
      if (onlyTeamId) {
        this.setState(state => ({ teamId: state.teamId || onlyTeamId }))
      }
    }
  }

  handleTeamSelect = (value: string) => {
    this.setState({teamId: value})
  }

  handleTypeSelect = (e: RadioChangeEvent) => {
    const chosenTypeAsString: string = e.target.value
    const it: InstanceType = this.stringToInstanceType(chosenTypeAsString)

    this.setState({chosenTypeAsString, chosenType: it, currentStorage: 0})
  }

  handleStorageChange = (value: number | undefined) => {
    let num: number = Number(value)
    if (Number.isNaN(num) || num < 0 || num > 16) {
      num = 0
    }
    this.setState({currentStorage: num})
  }

  handleSubmit = async (values: any) => {
    const extraStorage: number = this.state.currentStorage
    const chosenType: InstanceType = this.state.chosenType
    let promotionCode: string | undefined
    if (this.state.isPromoCodeValid && !_.isEmpty(this.state.promoCode)) {
      promotionCode = this.state.promoCode
    }

    let teamId: string | undefined = this.state.teamId

    if (chosenType !== InstanceType.Sandbox && !isUUID(teamId)) {
      // No team was chosen in the team drop-down, but validation went through
      // nontheless. Presumably the newTeamName input field was filled in but
      // not submitted. Create it now in one go instead.
      if (typeof values.newTeamName !== 'string') {
        return false
      }
      const result = await this.props.handleCreateTeam(values.newTeamName)
      if (!result || !result.id) {
        return
      }
      teamId = result.id
    }

    const body = {
      instanceName: values.instanceName,
      instanceType: chosenType,
      promotionCode,
      extraStorage,
    }
    this.props.handleCreateInstance(teamId, body)
  }

  onTeamNameChange = (event: any) => {
    this.setState({
      newTeamName: event.target.value,
    })
  }

  handleAddNewTeam = async () => {
    const { newTeamName } = this.state
    const result = await this.props.handleCreateTeam(newTeamName)
    this.setState(state => ({
      newTeamName: '',
      teamId: (result && result.id) || state.teamId,
    }))
  }

  togglePaymentModal = () => {
    this.setState({ paymentModalVisible: true })
  }

  handleAddPayment = async () => {
    this.setState({ paymentModalVisible: false })
    this.props.getUserCreditCard()
    this.props.getUserDefaultCardId()
  }

  closePaymentModal = () => {
    this.setState({ paymentModalVisible: false })
  }

  onPromoCodeChange = (event: any) => {
    this.setState({ promoCode: event.target.value })
  }

  handleAddPromotionCode = async () => {
    const { promoCode } = this.state
    const aitoPromoCode: unknown | undefined = await getPromotionCode(promoCode)
    if (aitoPromoCode && isAitoPromoCode(aitoPromoCode)) {
      this.setState({ promoCode, isPromoCodeValid: true, aitoPromoCode })
    } else message.error(`The code ${promoCode} does not exist. Please try with a different code.`)
  }

  handleRemovePromotionCode = () => {
    this.setState({ promoCode: '', isPromoCodeValid: false })
  }

  render() {
    const { teams, teamsLoading, sandboxInstanceExisted, creditCards, defaultCardId } = this.props
    const { chosenType, currentStorage, newTeamName, teamId, paymentModalVisible, promoCode, isPromoCodeValid, aitoPromoCode } = this.state
    const chosenTypeObject  = _.find(this.props.products, { type: chosenType })

    const defaultPaymentMethod : PaymentMethod | undefined = _.find(creditCards, { id: defaultCardId })
    const defaultCreditCard = defaultPaymentMethod && {
      cardHolder: defaultPaymentMethod.billing_details.name,
      cardInfo: defaultPaymentMethod.card
    }
    // Calculate prices
    const additional = this.props.products.find(p => !p.type)
    const storagePrice = additional ? additional.price * currentStorage : 0
    const productPrice = chosenTypeObject ? chosenTypeObject.price : 0
    const productAmount = storagePrice + productPrice
    const vatPercentage = chosenTypeObject ? chosenTypeObject.taxPercentage : 0
    const vatAmount = productAmount * vatPercentage / 100
    const totalAmount = productAmount + vatAmount

    const zeroPrice = {
      totalAmount: Dinero({ amount: 0 }),
      productAmount: Dinero({ amount: 0 }),
      vatPercentage,
      vatAmount: Dinero({ amount: 0 })
    }

    const totalPrice = !chosenTypeObject || isPromoCodeValid ? zeroPrice : {
      totalAmount: Dinero({ amount: totalAmount }),
      productAmount: Dinero({ amount: productAmount }),
      vatPercentage,
      vatAmount: Dinero({ amount: vatAmount })
    }

    const addTeamButton = (required: boolean) =>
      <Form.Item label="Team" name="newTeamName" rules={[{required, message: 'Please select the team'}]}>
        <Flex flexWrap="wrap" width={1} alignItems="center">
          <Box width={2 / 3} pr={2}>
            <Input size="large" placeholder="Enter new team name" value={newTeamName} onChange={this.onTeamNameChange} />
          </Box>
          <Box width={1 / 3} pl={2}>
            <Button
              onClick={this.handleAddNewTeam}
              disabled={!newTeamName}
            >
              Add team
            </Button>
          </Box>
        </Flex>
      </Form.Item>

    const teamSelect = () =>
      <Form.Item label="Team" rules={[{required: true, message: 'Please select the team'}]}>
        <Select
          size="large"
          onChange={this.handleTeamSelect}
          placeholder="Select a team"
          suffixIcon={!teamsLoading && <CaretIcon color="scorpion.darker" size={13} />}
          loading={teamsLoading}
          disabled={teamsLoading}
          value={teamId}
          dropdownRender={menu => (
            <div>
              {menu}
              <StyledDivider my={2}/>
              <Box px={2} pb={1}>
                {addTeamButton(false)}
              </Box>
            </div>
          )}
        >
          {_.map(teams, team => <Select.Option key={team.id} value={team.id}>{team.name}</Select.Option>)}
        </Select>
      </Form.Item>

    const renderPaidWithCardInfo = (cardInfo: DefaultCardInfo) => (
      <React.Fragment>
        <Flex width={1}>
          <Box px={2} width={[1, 1 / 2]}>
            <Form.Item label="Payment">
              {cardInfo && cardInfo.cardInfo ?
                <CreditCard editable={false} cardHolder={cardInfo.cardHolder} cardInfo={cardInfo.cardInfo} /> :
                null
              }
            </Form.Item>
            <Box mb={4}>
              <PowerByStripe />
            </Box>
          </Box>
          <Box width={[1, 1 / 2]} pl={4} pr={2}>
            <Form.Item label="What's next">
              <WhatsNextList>
                <li>
                  The instance will be available immediately after creation
                </li>
                <li>
                  You have 24 hours to cancel your subscription, free of charge
                </li>
                <li>
                  We will charge {totalPrice.totalAmount.toFormat('0.00')}€ (including VAT) from your credit card on {format(add(Date.now(), { days: 1 }), 'MMM dd yyyy')}
                </li>
                <li>
                  Payments will continue monthly until you cancel the subscription
                </li>
              </WhatsNextList>
            </Form.Item>
          </Box>
        </Flex>
      </React.Fragment>
    )

    const renderPaidAddCardInfo = () => {
      return (
        <Box px={2} width={1}>
          <Form.Item label="Payment" rules={[{required: true, message: 'Please add a payment method'}]}>
            <AddNewPaymentButton mb={3} onClick={this.togglePaymentModal}>
              <ActionText fontSize={0} color="silverTree.medium" fontWeight={5}>
                Add new payment method
              </ActionText>
              <AddIcon size={24} color="jade.medium" ml={2}/>
            </AddNewPaymentButton>
            <PowerByStripe />
          </Form.Item>
        </Box>
      )
    }

    const renderInstancePrice = () => (
      <Flex width={1}>
        <Box width={1} px={2}>
          <Form.Item label="Total">
            <Flex width={[1, 1 / 2]} flexWrap="wrap" alignItems="center">
              <Box width={1 / 2}>
                <PriceText fontWeight="bold" color="mullWine">Price</PriceText>
              </Box>
              <Box width={1 / 2}>
              <PriceText fontWeight="bold" color="mullWine">{`${totalPrice.productAmount.toFormat('0.00')} €`}</PriceText>
              </Box>
              <Box width={1 / 2}>
                <PriceText fontWeight="bold" color="mullWine">VAT ({totalPrice.vatPercentage} %)</PriceText>
              </Box>
              <Box width={1 / 2}>
                <PriceText fontWeight="bold" color="mullWine">{`${totalPrice.vatAmount.toFormat('0.00')} €`}</PriceText>
              </Box>
              <Box width={1} mt={3}>
                <PriceText fontWeight="bold" fontSize={4} color="silverTree.medium">
                {`${totalPrice.totalAmount.toFormat('0.00')} €/month`}
                </PriceText>
              </Box>
            </Flex>
          </Form.Item>
        </Box>
      </Flex>
    )
    const renderPaymentMethodOptions = (defaultCreditCard: DefaultCardInfo | undefined) => {
      if (chosenType !== InstanceType.Sandbox) {
        if (!!defaultCreditCard) {
          return renderPaidWithCardInfo(defaultCreditCard)
        } else { // No default credit card
          return renderPaidAddCardInfo()
        }
      } else { // chosenType === Sandbox
        return <Box/>
      }
    }

    const renderPromotionCodeInput = () => {
      return (
        <Box width={[1, 1 / 2]} px={2}>
          <Form.Item label="Promotion code" name="promoCode">
            {!isPromoCodeValid ? (
              <Flex flexWrap="wrap" width={1} alignItems="center">
                <Box width={2 / 3} pr={2}>
                  <Input size="large" placeholder="Enter promo code" value={promoCode} onChange={this.onPromoCodeChange} />
                </Box>
                <Box width={1 / 3} pl={2}>
                  <Button
                    onClick={this.handleAddPromotionCode}
                    disabled={!promoCode}
                  >
                    Apply
                  </Button>
                </Box>
              </Flex>
            ) : (
              <React.Fragment>
                <Flex flexWrap="wrap" alignItems="center">
                  <PromoCode color="mullWine" fontWeight="semibold">{promoCode}</PromoCode>
                  <RemovePromoCodeButton ml={3}>
                    <ActionText fontSize={0} color="silverTree.medium" onClick={this.handleRemovePromotionCode}>
                      Remove
                    </ActionText>
                  </RemovePromoCodeButton>
                </Flex>
                <PromoCodeText>{aitoPromoCode?.description}</PromoCodeText>
              </React.Fragment>
            )}
          </Form.Item>
        </Box>
      )
    }

    return (
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={this.handleSubmit}
        initialValues={{ instanceType: chosenType }}
      >
        <FlexContainerDiv flexWrap="wrap">
          <Box width={[1, 1 / 2]} px={2}>
            <Form.Item label="Instance name" name="instanceName" rules={instanceNameValidateRule}>
              <Input size="large" placeholder="My instance name" suffix='.aito.app' />
            </Form.Item>
          </Box>
          <TypesContainer width={1} px={2}>
            <Form.Item name="instanceType" label="Instance type">
              <InstanceTypeBox onChange={this.handleTypeSelect}>
                {_.map(this.props.products.filter(p => p.type), (type, i) => {
                  const disabled = type.type !== chosenType
                  const icons = instanceTypes.find(i => i.name === type.name)
                  return (
                    <Box width={[1, 1 / 3]} pr={[0, 3]} pb={[3, 0]} key={i}>
                      <StyledRadioButton fontSize={12} color={disabled ? 'scorpion.light' : 'mullWine'} value={type.type} key={i}>
                        <Flex width={1} flexDirection="column" flexWrap="wrap">
                          <Box width={1} mb={3}>
                            <InstanceTypeIcon src={disabled ? icons?.grayIcon : icons?.icon} alt="instance type" />
                            <TypeName fontSize={3} fontWeight="bold">
                              {type.name}
                            </TypeName>
                          </Box>
                          <Flex width={1}>
                            <Box width={0.55}>
                              API call limit
                            </Box>
                            <Box width={0.45}>
                              {type.apiCallLimit}
                            </Box>
                          </Flex>
                          <Flex width={1}>
                            <Box width={0.55}>
                              Storage
                            </Box>
                            <Box width={0.45}>
                              {type.storage}
                            </Box>
                          </Flex>
                          <Flex width={1}>
                            <Box width={0.55}>
                              Active server time
                            </Box>
                            <Box width={0.45}>
                              {type.activeTime}
                            </Box>
                          </Flex>
                          <Flex width={1}>
                            <Box width={0.55}>
                              Sleep after inactivity
                            </Box>
                            <Box width={0.45}>
                              {type.sleepTime}
                            </Box>
                          </Flex>
                          <Flex width={1}>
                            <Box width={0.55}>
                              Delete after inactivity
                            </Box>
                            <Box width={0.45}>
                              {type.removedAfterInactive}
                            </Box>
                          </Flex>
                          <Flex width={1} mt={3} justifyContent="center">
                            <PriceText fontSize={3} fontWeight="bold">
                              {`${type.price / 100} €/month`}
                            </PriceText>
                          </Flex>
                        </Flex>
                      </StyledRadioButton>
                    </Box>
                  )
                })}
              </InstanceTypeBox>
            </Form.Item>
            <ReadMoreLink href="https://aito.ai/pricing/" target="_blank" rel="noreferrer noopener" mr={24}>
              Read more
            </ReadMoreLink>
          </TypesContainer>
          {chosenType === InstanceType.Production &&
            <Box width={1} px={2}>
              <Form.Item
                label="Add extra storage"
                required={true}
                validateTrigger="onChange"
                rules={[
                  {
                    type: 'number',
                    validator: async (rule: any, value: any) => {
                      const input = value || this.state.currentStorage
                      const inputAsNumber: number = Number(input)
                      if (Number.isNaN(inputAsNumber) || inputAsNumber > 16 || inputAsNumber < 0) {
                        return Promise.reject('Additional storage must be a number between 0 and 16')
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Flex width={[1, 1 / 2]} flexWrap="wrap">
                  <Flex width={0.4} flexWrap="wrap">
                    <InputNumber
                      min={0}
                      max={16}
                      onChange={this.handleStorageChange}
                      value={this.state.currentStorage}
                    />
                    <PriceText fontSize={3} color="mullWine" ml={2}>
                      {!Number.isNaN(this.state.currentStorage) && this.state.currentStorage !== 1 ? `GBs` : `GB`}
                    </PriceText>
                  </Flex>

                  <Box width={0.6} pl={3}>
                    <PriceText fontWeight="bold" fontSize={3} color="mullWine">
                    {storagePrice ? ` + ${Dinero({ amount: storagePrice }).toFormat('0')} €/month` : ''}
                    </PriceText>
                  </Box>
                </Flex>
              </Form.Item>
            </Box>
          }

          {chosenType !== InstanceType.Sandbox &&
            <Box width={1}>
              <Box width={[1, 1 / 2]} px={2}>
                {teamsLoading || teams.length > 0 ? teamSelect() : addTeamButton(true)}
              </Box>
            </Box>
          }

          {chosenType !== InstanceType.Sandbox &&
            renderPromotionCodeInput()
          }

          {renderPaymentMethodOptions(defaultCreditCard)}

          {
            chosenType === InstanceType.Sandbox && sandboxInstanceExisted && (
              <WarningText fontWeight="bold" color="sunsetOrange.dark" ml={2}>
                You have to delete your existing Sandbox instance to be able to create a new one.
              </WarningText>
            )
          }

          {chosenType !== InstanceType.Sandbox && renderInstancePrice()}
        </FlexContainerDiv>
        <Box my={3}>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={this.props.createInstanceLoading}
              disabled={(chosenType === InstanceType.Sandbox && sandboxInstanceExisted) ||
                        (chosenType !== InstanceType.Sandbox && (_.isNil(defaultCreditCard) && !isPromoCodeValid))}
            >
              Create Instance
            </Button>
          </Form.Item>
        </Box>
        <AddOrChangePaymentModal
          visible={paymentModalVisible}
          onSubmit={this.handleAddPayment}
          onCancel={this.closePaymentModal}
          isCreatingCard={true}
        />
      </Form>
    )
  }
}

const CaretIcon: ExoticComponent<any> = styled(CaretDown)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const TypesContainer = styled(Box)`
  position: relative;
  .ant-row {
    flex-flow: column;
  }

`
const ReadMoreLink = styled.a<SpaceProps>`
  ${space};
  position: absolute;
  top: 0;
  right: 0;
`
const FlexContainerDiv = styled(Flex)``

const InstanceTypeBox = styled(Radio.Group)`
  display: flex;
  flex-wrap: wrap;
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${theme.colors.mullWine};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: none;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-color: ${theme.colors.silverTree.medium};
  }
`
const StyledRadioButton = styled(Radio.Button)<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  border: 2px solid ${theme.colors.scorpion.light};
  border-radius: 18px !important;
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 20px;
`

const TypeName = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const PriceText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const WhatsNextList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:before {
    content: "-";
    margin-right: 5px;
  }
`
const StyledDivider = styled(Divider)<SpaceProps>`
  ${space};
`
const WarningText = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const AddNewPaymentButton = styled(Flex)`
  cursor: pointer;
`
const ActionText = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
  cursor: pointer;
`
const PromoCode = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`
const RemovePromoCodeButton = styled(Flex)`
  cursor: pointer;
`
const PromoCodeText = styled.p<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  font-style: italic;
`

export default CreateInstanceForm
