import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import { color, ColorProps, typography, TypographyProps, SpaceProps, space } from 'styled-system'
import stripeLogo from '../svg-assets/stripe-logo.svg'

const PowerByStripe: React.FC = () => {
  return (
    <Flex flexWrap="wrap" justifyContent="spaceBetween">
      <Box>
        <StripeText fontSize={0} color="scorpion.light">Powered by </StripeText>
        <StripeLogo src={stripeLogo} height="16" alt="" ml={1}/>
      </Box>
      <Flex justifyContent="center" mx={3}>
        |
      </Flex>
      <Box>
        <StyledLink fontSize={0} mr={3} href="https://aito.ai/terms-of-service" target="_blank" rel="noopener noreferrer">Terms </StyledLink>
        <StyledLink fontSize={0} href="https://aito.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy </StyledLink>
      </Box>
    </Flex>
  )
}

const StripeText = styled.span<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`
const StripeLogo = styled.img<SpaceProps>`
  ${space};
`
const StyledLink = styled.a<TypographyProps | ColorProps | SpaceProps>`
${typography};
${color};
${space};
`
export default PowerByStripe
