import { action } from 'typesafe-actions'
import { Reducer } from 'redux'
import { PaymentMethod } from '@stripe/stripe-js'

import { SubscriptionAndInvoice, BillingDetails } from 'api'

import * as api from '../utils/api/paymentsApi'
import { updateBillingDetails } from '../utils/api/paymentsApi'

enum actionTypes {
  GET_CREDIT_CARDS_REQUEST = 'users/GET_CREDIT_CARDS_REQUEST',
  GET_CREDIT_CARDS_SUCCESS = 'users/GET_CREDIT_CARDS_SUCCESS',
  GET_CREDIT_CARDS_ERROR = 'users/GET_CREDIT_CARDS_ERROR',

  GET_USER_INVOICES_REQUEST = 'users/GET_USER_INVOICES_REQUEST',
  GET_USER_INVOICES_SUCCESS = 'users/GET_USER_INVOICES_SUCCESS',
  GET_USER_INVOICES_ERROR = 'users/GET_USER_INVOICES_ERROR',

  GET_USER_UPCOMING_INVOICES_REQUEST = 'users/GET_USER_UPCOMING_INVOICES_REQUEST',
  GET_USER_UPCOMING_INVOICES_SUCCESS = 'users/GET_USER_UPCOMING_INVOICES_SUCCESS',
  GET_USER_UPCOMING_INVOICES_ERROR = 'users/GET_USER_UPCOMING_INVOICES_ERROR',

  GET_DEFAULT_CARD_REQUEST = 'users/GET_DEFAULT_CARD_REQUEST',
  GET_DEFAULT_CARD_SUCCESS = 'users/GET_DEFAULT_CARD_SUCCESS',
  GET_DEFAULT_CARD_ERROR = 'users/GET_DEFAULT_CARD_ERROR',
}

export interface PaymentState {
  loading: boolean
  errorMessage: string | null
  creditCards: PaymentMethod[] | null
  defaultCardId: string | null,
  invoices: SubscriptionAndInvoice[] | null
  upcomingInvoices: SubscriptionAndInvoice[] | null
}

const initialState: PaymentState = {
  loading: false,
  errorMessage: null,
  creditCards: null,
  defaultCardId: null,
  invoices: null,
  upcomingInvoices: null
}

export const getUserCreditCards = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_CREDIT_CARDS_REQUEST, {}))
    try {
      const creditCards: PaymentMethod[] = await api.getCustomerCreditCards()
      dispatch(action(actionTypes.GET_CREDIT_CARDS_SUCCESS, creditCards))
    } catch (e) {
      const message = e.response.data.message
      dispatch(action(actionTypes.GET_CREDIT_CARDS_ERROR, message))
    }
  }
}

export const getUserDefaultCardId = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_DEFAULT_CARD_REQUEST, {}))
    try {
      const defaultCardId = await api.getCustomerDefaultCardId()
      dispatch(action(actionTypes.GET_DEFAULT_CARD_SUCCESS, defaultCardId))
    } catch (e) {
      const message = e.response.data.message
      dispatch(action(actionTypes.GET_DEFAULT_CARD_ERROR, message))
    }
  }
}

export const getUserInvoices = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_USER_INVOICES_REQUEST, {}))
    try {
      const ci = await api.getCustomerInvoices()
      dispatch(action(actionTypes.GET_USER_INVOICES_SUCCESS, ci))
    } catch (e) {
      const message = e.response.data.message
      dispatch(action(actionTypes.GET_USER_INVOICES_ERROR, message))
    }
  }
}

export const getUserUpcomingInvoices = () => {
  return async (dispatch: any) => {
    dispatch(action(actionTypes.GET_USER_UPCOMING_INVOICES_REQUEST, {}))
    try {
      const uci = await api.getCustomerUpcomingInvoices()
      dispatch(action(actionTypes.GET_USER_UPCOMING_INVOICES_SUCCESS, uci))
    } catch (e) {
      const message = e.response.data.message
      dispatch(action(actionTypes.GET_USER_UPCOMING_INVOICES_ERROR, message))
    }
  }
}

export const updateUserBillingDetails = (billingDetails: BillingDetails) => {
  return async (dispatch: any) => {
    try {
      await updateBillingDetails(billingDetails)
      const creditCards: PaymentMethod[] = await api.getCustomerCreditCards()
      dispatch(action(actionTypes.GET_CREDIT_CARDS_SUCCESS, creditCards))
      return { success: true }
    } catch (e) {
      const message = e.response.data.message
      dispatch(action(actionTypes.GET_DEFAULT_CARD_ERROR, message ))
      return { success: false, message }
    }
  }
}

const reducer: Reducer = (
  state: PaymentState = initialState,
  event: any,
): PaymentState => {
  switch (event.type) {
    // Intentional fall-through
    case actionTypes.GET_DEFAULT_CARD_REQUEST:
    case actionTypes.GET_CREDIT_CARDS_REQUEST:
    case actionTypes.GET_USER_INVOICES_REQUEST:
    case actionTypes.GET_USER_UPCOMING_INVOICES_REQUEST: {
      return { ...state, loading: true }
    }
    case actionTypes.GET_CREDIT_CARDS_SUCCESS: {
      return { ...state, loading: false, creditCards: event.payload }
    }
    case actionTypes.GET_DEFAULT_CARD_SUCCESS: {
      return { ...state, loading: false, defaultCardId: event.payload.id }
    }

    case actionTypes.GET_USER_INVOICES_SUCCESS: {
      return { ...state, loading: false, invoices: event.payload }
    }

    case actionTypes.GET_USER_UPCOMING_INVOICES_SUCCESS: {
      return { ...state, loading: false, upcomingInvoices: event.payload }
    }

    // Intentional fall-through
    case actionTypes.GET_CREDIT_CARDS_ERROR:
    case actionTypes.GET_DEFAULT_CARD_ERROR:
    case actionTypes.GET_USER_INVOICES_ERROR:
    case actionTypes.GET_USER_UPCOMING_INVOICES_ERROR: {
      return { ...state, loading: false, errorMessage: event.payload }
    }

    default: {
      return state
    }
  }
}

export { reducer as userReducer }
