import React, { ExoticComponent } from 'react'
import { Box, Flex } from '@rebass/grid'
import { Select, Modal } from 'antd'
import 'react-multi-email/style.css'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import _ from 'lodash'

import { TeamRole } from 'api'

import { Button } from './index'
import { TeamMember } from '../types/TeamMember'

interface Props {
  ownershipTransferModalVisible: boolean,
  closeOwnershipTransferModal: () => void,
  memberList: Array<TeamMember>,
  handleSelectTeamMember: (value: any) => void,
  handleOwnershipTransferSubmit: () => void,
  canCreateTransfer: boolean
}

const OwnershipTransferModal: React.FC<Props> = props => {
  // String comparison, as we don't have an enum, and importing api to the FE is not feasible
  const teamMembers = _.filter(props.memberList, member => member.role === TeamRole.Member)
  return (
    <Modal
      title="Request ownership transfer"
      footer={null}
      centered
      visible={props.ownershipTransferModalVisible}
      onCancel={props.closeOwnershipTransferModal}
    >
      <ModalText color="mullWine" fontWeight="semibold">
        Request ownership transfer of the team to another member.
      </ModalText>
      <ModalText fontSize={0} color="silverTree.medium" fontWeight="semibold">
      Note: The new owner will be notified that you have requested ownership transfer.
      If the new owner accepts the request, the ownership of the team will be removed from you but you will remain as a member.
      </ModalText>
      <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
        Team member
      </ModalLabel>
      {teamMembers.length ?
        <StyledSelect placeholder="Select a team member" width={1} onChange={(value: any) => props.handleSelectTeamMember(value)}>
          {_.map(teamMembers, (member, index) => {
            return (
              <Select.Option key={index} value={member.user.id}>{member.user.email}</Select.Option>
            )
          })}
        </StyledSelect>
        :
        <StyledSelect placeholder="You are the only member of this team" width={1} disabled />
      }
      <Flex mt={3}>
        {!props.canCreateTransfer && <ModalText color="red" fontSize={0}>There can be only one active ownership transfer request</ModalText>}
      </Flex>
      <Flex flexDirection="row">
        <Box mr={2}>
          <Button
            onClick={props.handleOwnershipTransferSubmit}
            disabled={!props.canCreateTransfer}
          >Request ownership transfer</Button>
        </Box>
        <Box ml={2}>
          <CancelButton color="scorpion.light" type="link" onClick={props.closeOwnershipTransferModal}>Cancel</CancelButton>
        </Box>
      </Flex>
    </Modal>
  )
}

const ModalLabel = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`
const ModalText: ExoticComponent<any> = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`

const CancelButton = styled(Button)<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  ${space};
`
const StyledSelect = styled(Select)<LayoutProps>`
  ${layout};
`

export default OwnershipTransferModal
