import React from 'react'
import { Input, Form } from 'antd'
import { Button } from './index'
import styled from 'styled-components'
import {space, SpaceProps} from 'styled-system'

interface Props {
  handleSendPasswordResetCode: (email: string) => void,
}

interface State {
  email: string
}
class ForgotPasswordForm extends React.Component<Props, State> {

  readonly state: State = {
    email: ''
  }

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState({ email: value })
  }

  handleSubmit = (values: any) => {
    this.props.handleSendPasswordResetCode(values.email)
  }

  render() {
    return (
      <Form layout="vertical" hideRequiredMark onFinish={this.handleSubmit}>
        <StyledFormItem mb={3} label="E-mail" name="email" rules={[{required: true, message: 'Please enter your email'}]}>
          <Input size="large" placeholder="john.doe@gmail.com" onChange={this.handleEmailChange}/>
        </StyledFormItem>
        <StyledFormItem>
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </StyledFormItem>
      </Form>
    )
  }
}

const StyledFormItem = styled(Form.Item)<SpaceProps>`
  ${space};
`

export default ForgotPasswordForm
