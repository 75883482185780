import React, { ExoticComponent } from 'react'
import { media } from '../styles/theme'
import { Box, Flex } from '@rebass/grid'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { AddCircle } from '@styled-icons/material'
import { UserAdd } from '@styled-icons/typicons'
import { Pencil } from '@styled-icons/boxicons-solid'

interface Props {
  onTransferOwnershipClick: () => void,
  onAddMemberClick: () => void
}

const MemberCardExtraButtons: React.FC<Props> = props => {
  return (
    <MediaQuery query={media.sm}>
      {(matches: any) => {
        if (matches) {
          return (
            <Flex flexDirection="row">
              <Box mr={2}>
                <ButtonContainerDiv onClick={props.onTransferOwnershipClick}>
                  <ButtonText fontSize={0} color="scorpion.light" fontWeight={5}>
                    Transfer ownership
                  </ButtonText>
                </ButtonContainerDiv>
              </Box>
              <Box ml={2}>
                <ButtonContainerDiv onClick={props.onAddMemberClick}>
                  <ButtonText fontSize={0} color="scorpion.light" fontWeight={5}>
                    Invite a member
                  </ButtonText>
                  <AddIcon size={24} color="jade.medium" ml={2} />
                </ButtonContainerDiv>
              </Box>
            </Flex>
          )
        }
        return (
          <Flex flexDirection="row">
            <ButtonContainerDiv onClick={props.onTransferOwnershipClick}>
              <UserEditIcon size={24} color="jade.medium" ml={2} />
            </ButtonContainerDiv>
            <ButtonContainerDiv onClick={props.onAddMemberClick}>
              <UserAddIcon size={24} color="jade.medium" ml={2} />
            </ButtonContainerDiv>
          </Flex>
        )
      }}
    </MediaQuery>
  )
}

const ButtonContainerDiv = styled.div`
  cursor: pointer;
`

const ButtonText = styled.span<TypographyProps | ColorProps>`
  ${typography};
  ${color};
`

const AddIcon: ExoticComponent<any> = styled(AddCircle)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const UserAddIcon: ExoticComponent<any> = styled(UserAdd)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

const UserEditIcon: ExoticComponent<any> = styled(Pencil)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`

export default MemberCardExtraButtons
