import React, { ExoticComponent, useState } from 'react'
import _ from 'lodash'
import { Box, Flex } from '@rebass/grid'
import { Select, Modal, Form, InputNumber } from 'antd'
import 'react-multi-email/style.css'
import styled from 'styled-components'
import { InstanceTypeTag } from './index'
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { Button } from './index'
import variables from '../variables'
import { Close, Done } from '@styled-icons/material'
import { format } from 'date-fns'
import { InstanceProduct, InstanceType, UpdateInstanceSubscription } from 'api'
import Dinero from 'dinero.js'
import { PaymentMethod } from '@stripe/stripe-js'

interface Props {
  modalVisible: boolean,
  closeModal: () => void,
  handleModifyInstance: (body: any, modifyStatus: string) => void,
  instance: any
  products: InstanceProduct[]
  creditCard: PaymentMethod | undefined
}

const ModifyInstanceModal: React.FC<Props> = props => {
  const currentProduct  = _.find(props.products, { type: props.instance.type })
  const additional = props.products.find(p => !p.type)
  const vatPercentage = currentProduct ? currentProduct.taxPercentage : 0
  const currentStorageAmount = additional ? props.instance.extraStorage * additional.price : 0
  const currentInstanceAmount = currentProduct ? currentProduct.price + currentStorageAmount : 0
  const currentVatAmount = currentInstanceAmount * vatPercentage / 100

  const currentInstancePrice = {
    totalAmount: Dinero({ amount:  currentInstanceAmount + currentVatAmount }),
    productAmount: Dinero({ amount: currentInstanceAmount }),
    vatPercentage,
    vatAmount: Dinero({ amount: currentVatAmount })
  }

  const instanceTypes = [InstanceType.Developer, InstanceType.Production]
  const [newExtraStorage, setNewExtraStorage] = useState(props.instance.extraStorage)
  const [newInstanceType, setNewInstanceType] = useState(props.instance.type)
  const [disableStorageChange, setDisableStorageChange] = useState(false)

  const updateProduct = _.find(props.products, { type: newInstanceType })
  const updateStorageAmount = additional ? newExtraStorage * additional.price : 0
  const updateInstanceAmount = updateProduct ? updateProduct.price + updateStorageAmount : 0
  const updateVatAmount = updateInstanceAmount * vatPercentage / 100

  const updateInstancePrice = {
    totalAmount: Dinero({ amount:  updateInstanceAmount + updateVatAmount }),
    productAmount: Dinero({ amount: updateInstanceAmount }),
    vatPercentage,
    vatAmount: Dinero({ amount: updateVatAmount })
  }

  const currentProductAmount = parseInt(currentInstancePrice.productAmount.toFormat('0'))
  const updateProductAmount = parseInt(updateInstancePrice.productAmount.toFormat('0'))

  const modifyStatus = updateProductAmount === currentProductAmount ? null : (
    updateProductAmount > currentProductAmount ? {
      status: 'upgrade',
      buttonText: 'Upgrade instance',
      from: props.instance.type,
      to: newInstanceType
    } : {
      status: 'downgrade',
      buttonText: 'Downgrade instance',
      from: props.instance.type,
      to: newInstanceType
    }
  )

  const isUpgradeInstance = (): boolean => modifyStatus?.status === 'upgrade'
  const isValidCreditCard = (): boolean =>
    (
      props.creditCard?.card?.last4 &&
      props.creditCard?.card?.checks &&
      _.isEmpty(Object.values(props.creditCard.card.checks).filter(v => v === 'fail'))
    ) || false


  const upgradeIcon = <CheckIcon size={20} color="jade.medium" mr={2} />
  const downgradeIcon = <XIcon size={20} color="carnationPink.medium" mr={2} />
  let modifyInfo: any
  if (isUpgradeInstance()) {
    if (modifyStatus?.from !== modifyStatus?.to) {
      if (modifyStatus?.to === InstanceType.Developer) modifyInfo = {...variables.upgradeToDev, icon: upgradeIcon}
      else if (modifyStatus?.to === InstanceType.Production) modifyInfo = {...variables.upgradeToProd, icon: upgradeIcon}
    } else modifyInfo = variables.upgradeStorage
  } else if (modifyStatus && modifyStatus.status === 'downgrade') {
    if (modifyStatus.from !== modifyStatus.to) modifyInfo = {...variables.downgradeToDev, icon: downgradeIcon}
    else modifyInfo = variables.downgradeStorage
  }

  const submitButtonText = !modifyStatus ? 'Change subscription' : modifyStatus.buttonText
  const submitButtonEnabled = isUpgradeInstance() && isValidCreditCard()
  const submitButtonDisabled = !submitButtonEnabled

  const handleStorageChange = (value: number | string | undefined) => {
    let num: number = Number(value)
    if (Number.isNaN(num) || num < 0 || num > 16) {
      num = 0
    }
    setNewExtraStorage(num)
  }

  const handleTypeChange = (value: any) => {
    if (value === InstanceType.Developer) {
      setNewInstanceType(value)
      setNewExtraStorage(0)
      setDisableStorageChange(true)
    } else {
      setNewInstanceType(value)
      setDisableStorageChange(false)
      setNewExtraStorage(props.instance.extraStorage)
    }
  }

  const submit = async (values: any) => {
    const updateInstance: UpdateInstanceSubscription = {
      instanceType: values.instanceType,
      extraStorage: _.toNumber(_.defaultTo(values.extraStorage, 0))
    }

    if (modifyStatus) {
      props.handleModifyInstance(updateInstance, modifyStatus.status)
    }
  }

  const renderPrice = (price: any) => {
    return (
      <Flex width={1} mt={3} flexWrap="wrap">
        <Flex width={[1, 1 / 2]} flexWrap="wrap" alignItems="center">
          <Box width={1}>
            <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>Total</ModalLabel>
          </Box>
          <Box width={1 / 2}>
            <ModalText fontWeight="bold" color="mullWine">Price</ModalText>
          </Box>
          <Box width={1 / 2}>
            <ModalText fontWeight="bold" color="mullWine">{`${price.productAmount.toFormat('0.00')} €`}</ModalText>
          </Box>
          <Box width={1 / 2}>
            <ModalText fontWeight="bold" color="mullWine">VAT ({price.vatPercentage} %)</ModalText>
          </Box>
          <Box width={1 / 2}>
            <ModalText fontWeight="bold" color="mullWine">{`${price.vatAmount.toFormat('0.00')} €`}</ModalText>
          </Box>
          <Box width={1} mt={3}>
            <ModalText fontWeight="bold" fontSize={4} color="silverTree.medium">
            {`${price.totalAmount.toFormat('0.00')} €/month`}
            </ModalText>
          </Box>
        </Flex>
      </Flex>
    )
  }

  const renderModifyInfo = (details: any) => {
    return (
      <React.Fragment>
        <ModalText fontFamily="header" color="mullWine" fontWeight="bold" fontSize={2}>{details.title}</ModalText>
        {_.map(details.info, (info, i) => {
          return (
            <Flex alignItems="center" key={i} mb={2}>
              {details.icon}
              <ModalText m={0}>{info}</ModalText>
            </Flex>
          )
        })}
      </React.Fragment>
    )
  }

  const renderWhatsNext = (status: string) => {
    const currentTotalAmount = updateInstancePrice.totalAmount.toFormat('0.00')
    return (
      <React.Fragment>
        <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>What's next</ModalLabel>
        {status === 'upgrade' ? (
          <WhatsNextList>
            <li>
              {`The instance will be upgraded and the new price ${currentTotalAmount}€ will be charged immediately. The possible carry-over-money from your last payment will be used for the new payment.`}
            </li>
            <li>
              {`Your subscription period will be changed and the new period starts today ${format(Date.now(), 'MMM dd yyyy')}`}
            </li>
          </WhatsNextList>
        ) : (
          <WhatsNextList>
            <li>
              {`The instance will be downgraded and the new price ${currentTotalAmount}€ will be charged when the current subscription period ends.`}
            </li>
            <li>
              You can still use the benefits of the current instance until the end of the subscription period.
            </li>
          </WhatsNextList>
        )}
      </React.Fragment>
    )
  }

  const renderCurrentStatus = () => {
    if (!modifyStatus) return <ModalText fontFamily="header" color="mullWine" fontWeight="bold" fontSize={2}>No change applied</ModalText>
    else if (isUpgradeInstance() && !isValidCreditCard()) {
      return <ModalText fontFamily="header" color="mullWine" fontWeight="bold" fontSize={2}>Please add a valid credit card to proceed</ModalText>
    } else {
      const info = renderModifyInfo(modifyInfo)
      const whatsup = renderWhatsNext(modifyStatus.status)
      return (
        <React.Fragment>
          {info}
          {whatsup}
        </React.Fragment>
      )
    }
  }

  return (
    <Modal
      title="Change subscription"
      footer={null}
      centered
      visible={props.modalVisible}
      onCancel={props.closeModal}
    >
      <InstanceSectionContainer width={1} flexWrap="wrap">
        <Flex width={1} flexWrap="wrap">
          <Box width={1 / 3}>
            <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
              Instance type
            </ModalLabel>
            <InstanceTypeTag instanceType={props.instance.type} />
          </Box>
          <Box width={1 / 3}>
            <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
              Extra storage
            </ModalLabel>
            <ModalText color="mullWine" fontWeight="semibold">
              {`${props.instance.extraStorage || 0} GB`}
            </ModalText>
          </Box>
        </Flex>
        {renderPrice(currentInstancePrice)}
      </InstanceSectionContainer>
      <StyledForm
        onFinish={submit}
        initialValues={{
          instanceType: props.instance.type !== InstanceType.Sandbox ? props.instance.type : undefined
        }}
      >
        <InstanceSectionContainer width={1} py={3} flexDirection="column">
          <Flex width={1} mb={3} flexWrap="wrap" alignItems="center" justifyContent="space-between">
            <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
              New instance
            </ModalLabel>
            <a href="https://aito.ai/pricing/" target="_blank" rel="noreferrer noopener">
              Read more about pricing
            </a>
          </Flex>
          <Flex width={1} flexWrap="wrap">
            <Box width={1 / 3} mr={2}>
              <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
                Instance type
              </ModalLabel>
              <Form.Item name="instanceType" rules={[{required: true}]}>
                <StyledSelect
                  placeholder="Instance type"
                  onChange={handleTypeChange}
                >
                  {_.map(instanceTypes, (type, i) => {
                    return (
                      <Select.Option value={type} key={i}>
                        {type}
                      </Select.Option>
                    )
                  })}
                </StyledSelect>
              </Form.Item>
            </Box>
            <Box width={1 / 3} ml={2}>
              <ModalLabel color="scorpion.dark" fontWeight="bold" mb={2}>
                Extra storage
              </ModalLabel>
              <Form.Item
                name="extraStorage"
                validateTrigger="onChange"
                rules={[
                  {
                    required: true,
                    type: 'number',
                    validator: async (rule: any, value: any) => {
                      const input = value || props.instance.extraStorage
                      const inputAsNumber: number = Number(input)
                      if (Number.isNaN(inputAsNumber) || inputAsNumber > 16 || inputAsNumber < 0) {
                        return Promise.reject('Additional storage must be a number between 0 and 16')
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Flex width={1} flexWrap="wrap">
                  <Box width={2 / 3}>
                    <InputNumber
                      min={0}
                      max={16}
                      onChange={handleStorageChange}
                      value={newExtraStorage}
                      disabled={disableStorageChange || newInstanceType !== InstanceType.Production}
                    />
                  </Box>
                  <Box width={1 / 3}>
                    <ModalText fontSize={3} color="mullWine" ml={2}>
                      GB
                    </ModalText>
                  </Box>
                </Flex>
              </Form.Item>
            </Box>
          </Flex>
          {renderPrice(updateInstancePrice)}
        </InstanceSectionContainer>
        <InfoContainer width={1} py={3}>
          {renderCurrentStatus()}
        </InfoContainer>
        <Flex>
          <Box mr={2}>
            <Button htmlType="submit" disabled={!modifyStatus || submitButtonDisabled}>
              {submitButtonText}
            </Button>
          </Box>
          <Box ml={2}>
            <CancelButton color="scorpion.light" type="link" onClick={props.closeModal}>Cancel</CancelButton>
          </Box>
        </Flex>
      </StyledForm>
    </Modal>
  )
}

const ModalLabel = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`
const ModalText: ExoticComponent<any> = styled.p<TypographyProps | ColorProps | SpaceProps>`
  ${typography};
  ${color};
  ${space};
`
const InstanceSectionContainer = styled(Flex)`
  border-bottom: 1px solid #F1F1F1;
`
const CancelButton = styled(Button)<TypographyProps | ColorProps>`
  ${typography};
  ${color};
  ${space};
`
const StyledSelect = styled(Select)<LayoutProps>`
  ${layout};
`
const StyledForm = styled(Form)`
  width: 100%;
  .ant-form-item {
    margin-bottom: 0;
  }
`
const InfoContainer = styled(Box)`
`
const XIcon: ExoticComponent<any> = styled(Close)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const CheckIcon: ExoticComponent<any> = styled(Done)<LayoutProps | ColorProps | SpaceProps>`
  ${layout};
  ${color};
  ${space};
`
const WhatsNextList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:before {
    content: "-";
    margin-right: 5px;
  }
`
export default ModifyInstanceModal
